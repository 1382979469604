import React, { Component } from 'react';
import { app, firestore, auth } from 'firebase'
import alertCircle from '../../img/alert-circle.png';

import Swal from 'sweetalert2'
// import FMSHeader from './DefaultHeader';
import FMSHeader from '../Header/defaultHeader';

import home from '../../img/thriveFmsLogo.png';
import { Dimmer, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom';

//Components
import SelectDoctor from './SelectDoctor';
import MainWizard from './MainWizard';
import HomeLogin from '../../Home Login/index'
import crypto from 'crypto-js';
import { connect } from "react-redux";
import { userActions } from '../../modules/user-module.js'
class WizardDoctor extends Component {

    state = {
        user: null,
        loggedIn: false,
        claimingPersonName: '',
        suggestionId: null,
        branches: 1,
        readyToStart: false,
        publish_save: true,
        doctorList: null,
        selectDoctor: null,
        doctorsStatus: 1,
        loading: true,
        modalLoginIsOpen: false,
        showMenu: window.screen.width <= 1026 ? false : true,
        search: "",

    }

    constructor(props, context) {
        super(props, context);
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        let { accessScreens } = this.props.user.data ? this.props.user.data : {};

        let query = null;
        if (this.props.location.search) {
            let querySearch = this.props.location.search;
            query = querySearch.replace('?claim=', '');
        } else {
            query = this.props.location.pathname.split('=')[1];
        }
        this.setState({
            suggestionId: query,
            allowed: accessScreens && accessScreens.includes("doctors"),
            loading: false
        })
    }

    /*Simple functions*/
    branchChange = number => {
        this.setState({ branches: number })
    }
    clinicStatusSelected = number => {
        this.setState({ doctorsStatus: number })
    }
    readyToStartChange = (bool, doctorKey) => {
        let { doctors } = this.props
        if (bool === false) {
            this.props.updateDoctor(doctorKey, doctors).then(({ payload }) => {
                let updatedDoctor = payload[doctorKey]
                let clinics = updatedDoctor && updatedDoctor.clinics && Object.keys(updatedDoctor.clinics)
                clinics.forEach(key => {
                    this.props.updateBranches(key, this.props.clinics)
                })
                return this.setState({
                    readyToStart: bool,
                    publish_save: false,
                    selectDoctor: false
                })
            })

        }
        this.setState({
            readyToStart: bool,
            publish_save: false,
        })
    }
    addDoctor = bool => {
        this.setState({
            readyToStart: bool,
            publish_save: true,
            selectDoctor: {}
        })
    }
    SelectedDoctor = object => {
        this.setState({ selectDoctor: object })
    }

    handleFacilityClick = bool => {
        let _this = this;
        if (!this.state.selectDoctor) return;
        Swal.fire({
            heightAuto: false,
            title: 'Are you sure?',
            text: "Changes you did will be lost if you didn't save",
            // type: 'success',
            confirmButtonText: 'Discard changes',
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            allowOutsideClick: false
        }).then(function (result) {
            if (result.value) {
                _this.setState({
                    readyToStart: false,
                    doctorsStatus: 1,
                    doctorList: null,
                    publish_save: true,
                    selectDoctor: false

                });
            }
        })
    }

    showMenuFunc = () => {
        let { showMenu } = this.state;
        this.setState({
            showMenu: !showMenu,
        })
    }
    onSearchKey = e => {
        this.setState(
            {
                search: e.target.value.toLowerCase()
            }
        );
    };
    render() {
        let { publish_save, loading, showMenu, allowed } = this.state;
        let { loggedIn, user, accessType } = this.props.user.data ? this.props.user.data : {}
        return (
            (loggedIn && user) ?

                <div className="bootstrap">
                    <section id="main-wrapper" >
                        <div className="container-fluid">
                            <div className="row" style={{ overflow: 'hidden' }}>
                                <div className="col-1 side-nav" style={{ display: showMenu ? 'block' : 'none' }}>
                                    <ul>
                                        <li style={{ height: '60px' }}><a href="/#/"><img src={home} /></a></li>
                                        {/* <li><a href=""><i className="fab fa-buromobelexperte"></i>Dashboard</a></li> */}
                                        <li ><Link to="/dashboard"><i className="fab fa-buromobelexperte"></i>Dashboard</Link></li>
                                        <li ><Link to="/facility-management-system"><i className="far fa-hospital"></i>Facilities</Link></li>
                                        <li onClick={this.handleFacilityClick} style={{ borderLeft: '3px solid #0096FD' }}><a><i style={{ color: '#0096FD' }} className="fas fa-user-md"></i>Providers</a></li>
                                        <li ><Link to="/appointment-system"><i className="far fa-calendar-alt"></i>Calendar</Link></li>
                                        <li ><Link to="/patients"><i className="fas fa-users"></i>Patients</Link></li>
                                        <li ><Link to="/history"><i className="fa fa-history"></i>History</Link></li>
                                        <li ><Link to="/feedback"><i className="fa fa-star"></i>Feedback</Link></li>
                                        {
                                            accessType === 'superAdmin' ?
                                                (
                                                    process.env.REACT_APP_NAME !== 'saeed' ?
                                                        <li ><Link to="/helpers"><i className="fas fa-database"></i>Helpers</Link></li> : '')
                                                : ''
                                        }
                                        <li><Link to="/settings"><i className="fas fa-cogs"></i>Settings</Link></li>
                                    </ul>
                                </div>
                                <div className="col-11 main-body" style={{ width: '100%', maxWidth: !showMenu ? 'none' : '' }}>
                                    <FMSHeader showMenuFunc={this.showMenuFunc} query={this.props.location.search} onSearchKey={this.onSearchKey} handleFacilityClick={this.handleFacilityClick} readyToStartChange={this.addDoctor} publish_save={publish_save} title="Providers" />

                                    {
                                        (loggedIn && user) ?
                                            (allowed ?
                                                this.renderContent()
                                                :
                                                this.renderAccessDenied())

                                            : 'Please login'
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                : loading ?
                    <Dimmer active>
                        <Icon.Group size='big'>
                            <Icon loading size='big' name='spinner' />
                            <div style={{ fontSize: '16px', padding: '10px', fontStyle: 'initial' }}>loading</div>
                        </Icon.Group>
                    </Dimmer>
                    : <HomeLogin show={this.state.modalLoginIsOpen} getUser={() => this.props.getUser} />
        );
    }
    /*
     * Functions for content getting
     * */
    renderAccessDenied() {
        return (
            <div style={{ height: 'calc( 100% - 60px )' }}>
                <div className="emptystate">
                    <div className="emptystate1">
                        <img src={alertCircle} alt="Img" />
                        <div className="textMesg">No Permission</div>
                        <div className="detailMesg">Contact your admin to access this page</div>
                    </div>
                </div>
            </div>
        )
    }
    renderContent = () => {
        let { suggestionId, selectDoctor, doctorsStatus, publish_save, search } = this.state;
        let { user } = this.props.user.data ? this.props.user.data : {}
        let { doctors } = this.props

        if (selectDoctor) {
            return (
                <div style={{ height: 'calc( 100% - 60px )' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div style={{ padding: '20px 30px', fontSize: '20px', color: '#4A4A4A' }}>Profile</div>
                    </div>

                    <MainWizard
                        doctorList={selectDoctor}
                        wizardOptions={
                            {
                                branches: doctors && Object.keys(doctors).length,
                            }
                        }
                        selectDoctor={
                            {
                                readyToStartChange: this.readyToStartChange
                            }
                        }
                        currentUser={user}
                        suggestionId={suggestionId}
                        doctorsStatus={doctorsStatus}
                        publish_save={publish_save}
                        specialties={this.props.specialties}

                    />
                </div>
            )
        }
        return (

            <SelectDoctor
                Analytics={this.props.Analytics}
                selectDoctor={
                    {
                        search: search,
                        branchChange: this.branchChange,
                        clinics: doctors,
                        doctorsStatus: this.clinicStatusSelected,
                        SelectedDoctor: this.SelectedDoctor,
                        readyToStartChange: this.readyToStartChange,
                        addDoctor: this.addDoctor,
                        specialties: this.props.specialties
                    }
                }
                loading={this.state.loading}
            />
        )
    }

}
const mapStateToProps = ({ user, branches, specialties, Analytics }) => {
    return {
        user,
        clinics: branches ? branches.data : {},
        doctors: branches ? branches.doctors : [],
        specialties: specialties ? specialties.data : {},
        Analytics
    };
};
const mapActionsToProps = (dispatch) => ({
    getUser: () => dispatch(userActions.getUser()),
    updateDoctor: (doctorKey, oldList) => dispatch(userActions.updateDoctor(doctorKey, oldList)),
    updateBranches: (clinic, oldList) => dispatch(userActions.updateBranches(clinic, oldList)),
});
export default connect(
    mapStateToProps,
    mapActionsToProps
)(WizardDoctor);