import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Input, Select } from 'semantic-ui-react';
import { countries, codesLandline, codes, codesMobile, currency, validateEmail } from '../../../helpers/Full';

let countriesArray = countries.map((item, index) => {
    return { key: index, text: item, value: item }
})
let codesLandlineArray = (codes) => codes.map((item, index) => {
    return { key: index, text: item, value: item }
})
class Verification extends Component {
    static propTypes = {
        branchList: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        applyToAll: PropTypes.func.isRequired,
        updateParentState: PropTypes.func.isRequired,
    }
    state = {

    }

    constructor(props, context) {
        super(props, context);
    }
    setTollNumberDefault = () => {
        let { branchList, activeBranchKey } = this.props;
        branchList[activeBranchKey].isTollNumberDefault = !branchList[activeBranchKey].isTollNumberDefault;
        this.props.updateParentState(branchList);
    }
    changeAdminInfo = (e, { value }) => {
        let { branchList } = this.props;
        Object.keys(branchList).map(function (item, index) {
            branchList[item].adminPosition = value;
        })
        this.props.updateParentState(branchList);
    }
    addLandLineNumber = () => {
        const { activeBranchKey } = this.props;
        let { branchList } = this.props;
        let numbers = branchList[activeBranchKey].landLineNumbers;
        if (numbers.length < 10) {
            numbers.push({
                country: 'UAE 971',
                code: '800',
                number: ''
            });
            this.props.updateParentState(branchList);
        }
    }
    removeLandLineNumber = (index) => {
        const { activeBranchKey } = this.props;
        let { branchList } = this.props;
        let numbers = branchList[activeBranchKey].landLineNumbers;
        numbers.splice(index, 1);
        this.props.updateParentState(branchList);
    }
    changeAdminName = (e, { value }) => {
        let { branchList } = this.props;
        Object.keys(branchList).map(function (item, index) {
            branchList[item].adminName = value;
        })
        this.props.updateParentState(branchList);
    }
    /**
         *
         * @param index
         * @param type
         * @param e
         */
    changeCountry = (index, type, e, { value }) => {
        const { activeBranchKey } = this.props;
        let { branchList } = this.props;
        branchList[activeBranchKey][type][index].country = value;
        this.props.updateParentState(branchList);
    }
    changeFacilityEmail = (e, { value }) => {
        const { activeBranchKey } = this.props;
        let { branchList } = this.props;
        branchList[activeBranchKey].facilityEmail = value;
        this.props.updateParentState(branchList);
    }
    /**
     *
     * @param index
     * @param type
     * @param e
     */
    changeCode = (index, type, e, { value }) => {
        const { activeBranchKey } = this.props;
        if (value?.length > 3) {
            return
        }
        let { branchList } = this.props;
        branchList[activeBranchKey][type][index].code = value;
        this.props.updateParentState(branchList);
    }
    /**
    *
    * @param index
    * @param type
    * @param e
    */
    changeNumber = (index, type, e, { value }) => {
        // e.preventDefault()
        const { activeBranchKey } = this.props;
        let { branchList } = this.props;
        let val = value;
        let max = (type === 'landLineNumbers') ? 8 : 11;
        if (!isNaN(val)) {
            if (val.length < max && val < 9999999) {
                branchList[activeBranchKey][type][index].number = val;
                this.props.updateParentState(branchList);
            }
        } else {
            alert('please input number')
        }
    }
    changeCurrency = (indexR, type, e) => {
        let { branchList } = this.props;
        Object.keys(branchList).map(function (item, index) {
            branchList[item][type][indexR].currency = e.target.value;
        })
        this.props.updateParentState(branchList);
    }
    changeFees = (type, e) => {
        let { branchList } = this.props;
        let val = e.target.value;
        if (!isNaN(val)) {
            if (val.length < 8) {
                Object.keys(branchList).map(function (item, index) {
                    branchList[item][type] = Number(val);
                })
                this.props.updateParentState(branchList);
            }

        } else {
            alert('please input number')
        }
    }
    changeAttachVerify = (e, empty = false) => {
        let { branchList, activeBranchKey } = this.props;
        let result = '';
        if (!empty) {
            if (e.target.files[0] === undefined) return
            let reader = new FileReader();
            let file = e.target.files[0];
            result = {
                name: file.name
            };
            reader.readAsDataURL(file);
            reader.onload = function () {
                result.base64 = reader.result;
            }
        } else {
            result = '';
        }
        branchList[activeBranchKey].attachedCertificateUrl = result;
        this.props.updateParentState(branchList);
    }
    accept = (type) => {
        let { branchList } = this.props;
        Object.keys(branchList).map(function (item, index) {
            branchList[item][type] = !branchList[item][type];
        })
        this.props.updateParentState(branchList);
    }
    render() {
        return (
            <div>
                {this.renderContactInfo()}
                {this.renderAdminInfo()}
            </div>
        )
    }
    /*
     * Functions for content getting
     * */
    /**
     *
     * @returns {XML}
     */

    renderContactInfo = () => {
        const { applyToAll, branchList, activeBranchKey } = this.props;
        const _this = this;
        let email = branchList[activeBranchKey].facilityEmail ? branchList[activeBranchKey].facilityEmail : "";



        return (
            <div className="admin-box right-side-box" style={{ marginBottom: '16px' }}>
                <div className="box-title">
                    <strong>Contact Info</strong>
                </div>

                <div className="name-input">
                    <label>Landline work number</label>
                    {
                        branchList[activeBranchKey].landLineNumbers.map((item, index) => {

                            return (<div key={index} style={{ display: 'flex', alignItems: 'center', fontSize: '12px', padding: '2px 0' }}>
                                <Select
                                    error={!item.country && branchList[activeBranchKey].showError} options={countriesArray} value={item.country} onChange={_this.changeCountry.bind(this, index, 'landLineNumbers')} style={{ minWidth: '30%', marginRight: '2px' }} />
                                <Input
                                    type='number'
                                     value={item.code} onChange={_this.changeCode.bind(this, index, 'landLineNumbers')} 
                                     style={{ width: '20%', 
                                     justifyContent: 'center', 
                                     marginRight: '2px',
                                     borderColor: (!item.code && branchList[activeBranchKey].showError) ? 'darkred' : '#ddd'

                                      }} />
                                <Input
                                    type='tel'
                                    onChange={_this.changeNumber.bind(this, index, 'landLineNumbers')}>
                                    <input
                                        value={item.number}
                                        style={{
                                            height: '40px',
                                            fontSize: '10px',
                                            height: '100%',
                                            minWidth: '50%',
                                            borderColor: (!item.number && branchList[activeBranchKey].showError) ? 'darkred' : '#ddd'
                                        }} />
                                </Input>
                                {
                                    (index + 1) === branchList[activeBranchKey].landLineNumbers.length ? <span onClick={_this.addLandLineNumber} className="action">+</span> : <span onClick={_this.removeLandLineNumber.bind(this, index)} className="action">-</span>
                                }
                            </div>)
                        })
                    }
                </div>
                <div className="position-input">
                    <label style={{ display: 'flex', justifyContent: 'space-between' }}>Toll free number  <p onClick={this.setTollNumberDefault} className="setDef flexBox" style={{ width: '50%', justifyContent: 'flex-end', color: '#3489db' }}>
                        <span style={{ paddingRight: '4px' }}>Set Default</span>

                        <span style={{ fontSize: '16px' }} className={branchList[activeBranchKey].isTollNumberDefault ? "far fa-check-square" : "far fa-square"}></span>
                    </p>
                    </label>
                    <div className="inputParent">
                        {
                            branchList[activeBranchKey].tollNumber.map(function (item, index) {
                                return (<div key={index} style={{ display: 'flex', alignItems: 'center', fontSize: '12px', padding: '2px 0' }}>
                                    <Select options={countriesArray} value={item.country} onChange={_this.changeCountry.bind(this, index, 'tollNumber')} style={{ minWidth: '30%', marginRight: '2px' }} />
                                    <Select options={codesLandlineArray(codes)} value={item.code} onChange={_this.changeCode.bind(this, index, 'tollNumber')} style={{ minWidth: '20%', justifyContent: 'center', marginRight: '2px' }} />
                                    <Input type='tel' onChange={_this.changeNumber.bind(this, index, 'tollNumber')} name='tollNumber'>
                                        <input value={item.number} style={{ height: '40px', fontSize: '10px', height: '100%', minWidth: '50%' }} />
                                    </Input>
                                </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="contact-input" style={{ overflow: 'inherit' }}>
                    <label>Facility e-mail</label>
                    <div className="inputParent">
                        <div className="fRt facilityEmail">
                            <Input error={!validateEmail(email) && branchList[activeBranchKey].showError} style={{ width: '100%' }} type="email" name="facilityEmail" value={email} onChange={_this.changeFacilityEmail} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    renderAdminInfo = () => {
        const { applyToAll, branchList, activeBranchKey } = this.props;
        const _this = this;



        return (
            <div className="admin-box right-side-box">
                <div className="box-title">
                    <strong>Admin Info</strong>
                    <p className="sub-title">This info is not visible in the facility public profile</p>
                </div>

                <div className="name-input">
                    <label>Admin Name</label>
                    <Input className="rectangle2" error={!branchList[activeBranchKey].adminName.length && branchList[activeBranchKey].showError} type="text" name="text" style={{ width: '100%' }} onChange={this.changeAdminName} value={branchList[activeBranchKey].adminName} />
                </div>
                <div className="position-input">
                    <label>Position</label>
                    <Input className="rectangle2" error={!branchList[activeBranchKey].adminPosition.length && branchList[activeBranchKey].showError} type="text" name="text" style={{ width: '100%' }} onChange={this.changeAdminInfo} value={branchList[activeBranchKey].adminPosition} />
                </div>
                <div className="contact-input" style={{ overflow: 'inherit' }}>
                    <label>Contact Number "Mobile"</label>
                    {
                        branchList[activeBranchKey].adminPhone.map(function (item, index) {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                    <Select options={countriesArray} value={item.country} onChange={_this.changeCountry.bind(this, index, 'adminPhone')} style={{ minWidth: '30%', marginRight: '2px' }} />
                                    <Input value={item.code} type='number' onChange={_this.changeCode.bind(this, index, 'adminPhone')} style={{ width: '20%', justifyContent: 'center', marginRight: '2px' }} />

                                    <Input type='tel' onChange={_this.changeNumber.bind(this, index, 'adminPhone')} name='mobileNumber'>
                                        {
                                            (item.number.length) ?
                                                <input type="tel" name="mobileNumber" value={item.number ? item.number : ''} />
                                                : (
                                                    <input style={{ borderColor: 'darkred' }} type="tel" name="mobileNumber" value={item.number ? item.number : ''} />
                                                )
                                        }
                                    </Input>
                                </div>
                            )
                        })
                    }
                    {/* <button className="color-btn">Verify Number</button> */}
                </div>
                {this.renderVerifyDocument()}

                {/* <div className="box-footer">
                    {this.renderAccept()}
                </div> */}
            </div>
        )
    }

    renderVerifyDocument = () => {
        const { branchList, activeBranchKey } = this.props;
        let cert = branchList[activeBranchKey].attachedCertificateUrl;
        return (
            <div className="upload-input">

                <label>Upload Facility Varification Document</label>

                {
                    (!cert && branchList[activeBranchKey].showError) ? (
                        <div className="uplad-file">

                            <label style={{ borderColor: 'darkred' }}>Upload Document
                    <input onChange={this.changeAttachVerify} type="file" name="attachVerify" id="attachVerify" />
                                <i className="fas fa-file"></i>
                            </label>
                        </div>
                    ) :
                        (
                            <div className="uplad-file">
                                <div>
                                    <label>Upload Document
                        <input onChange={this.changeAttachVerify} type="file" name="attachVerify" id="attachVerify" />
                                        <i className="fas fa-file"></i>
                                    </label>
                                </div>

                                <div className="fas fa-file" style={{ paddingLeft: '10px' }}>
                                    <span> certificate </span> <span onClick={this.changeAttachVerify.bind(this, true)} className="fas fa-times" style={{ color: 'red', cursor: 'pointer', fontSize: '13px' }}></span>
                                </div>
                            </div>
                        )
                }
            </div>
        )
    }

    renderAccept = () => {
        // const { branchList, activeBranchKey } = this.props;
        // return (
        //     <div className="acceptSection">
        //         <div className="terms">
        //             {/* <p data-checked={branchList[activeBranchKey].terms}><span onClick={this.accept.bind(this, 'terms')} /><span>Accept <a target="_blank" href="/#/terms-and-conditions"> terms and conditions</a></span> <span className="req">*</span></p> */}
        //             {/* <p data-checked={branchList[activeBranchKey].privacy}><span onClick={this.accept.bind(this, 'privacy')} /><span>I agree with the <a target="_blank" href="/#/privacy-policy"> Privacy policy </a></span> <span className="req">*</span></p> */}
        //             <p onClick={this.accept.bind(this, 'interestedAd')} data-checked={branchList[activeBranchKey].interestedAd}><span /><span>I am interested in advertising in Thrive Network</span> </p>
        //         </div>
        //     </div>
        // )
        const { branchList, activeBranchKey } = this.props;
        return (
            (branchList[activeBranchKey].interestedAd) ? (
                <p style={{ fontSize: '14px', fontWeight: 'bold' }}><i onClick={this.accept.bind(this, 'interestedAd')} data-checked={branchList[activeBranchKey].interestedAd} className="fa fa-check-square" style={{ fontSize: '14px' }}></i>I am interested in advertising in Thrive Network</p >
            ) : (
                <p style={{ fontSize: '14px', fontWeight: 'bold' }}><i onClick={this.accept.bind(this, 'interestedAd')} data-checked={branchList[activeBranchKey].interestedAd} className="far fa-square" style={{ fontSize: '14px' }}></i>I am interested in advertising in Thrive Network</p>
            )
        )
    }

    renderFees = () => {
        const { applyToAll, branchList, activeBranchKey } = this.props;
        const _this = this;

        return (
            <div className="fees-box right-side-box">
                <div className="box-title">
                    <strong>Fees</strong>
                </div>
                <div className="single-fee">
                    <label>General Practitioner</label>
                    {
                        <span>
                            <select className="country">
                                {
                                    currency.map(function (itemC) {
                                        return (
                                            <option key={itemC} >{itemC}</option>
                                        )
                                    })
                                }
                            </select>
                            <input type="number" value={branchList[activeBranchKey].generalFee ? branchList[activeBranchKey].generalFee : 0} onChange={_this.changeFees.bind(this, 'generalFee')} />
                        </span>
                    }
                </div>
                <div className="single-fee">
                    <label>Specialist</label>
                    {
                        <span>
                            <select className="country">
                                {
                                    currency.map(function (itemC) {
                                        return (
                                            <option key={itemC} >{itemC}</option>
                                        )
                                    })
                                }
                            </select>
                            <input type="number" value={branchList[activeBranchKey].specialistFee ? branchList[activeBranchKey].specialistFee : 0} onChange={_this.changeFees.bind(this, 'specialistFee')} />
                        </span>
                    }
                </div>
                <div className="single-fee">
                    <label>Consultant</label>
                    {
                        <span>
                            <select className="country">
                                {
                                    currency.map(function (itemC) {
                                        return (
                                            <option key={itemC} >{itemC}</option>
                                        )
                                    })
                                }
                            </select>
                            <input type="number" value={branchList[activeBranchKey].consultantFee ? branchList[activeBranchKey].consultantFee : 0} onChange={_this.changeFees.bind(this, 'consultantFee')} />
                        </span>
                    }
                </div>

            </div >
        )
    }
}

export default Verification