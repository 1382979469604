import React from 'react';
import { HashRouter ,BrowserRouter} from 'react-router-dom';
import { Provider } from 'react-redux';
// import configureStore from './store';
// import Page404 from './pages/404Page';
import App from './components';
// import { PersistGate } from 'redux-persist/integration/react'
// import { store, persistor } from './store';
import { store } from './store';

// const store = configureStore();
// const { persistor, store } = configureStore()


const routes = (
    <Provider store={store}>
          {/* <PersistGate loading={null} persistor={persistor}> */}

        <BrowserRouter>
            <App />
        </BrowserRouter>
        {/* </PersistGate> */}

    </Provider>
);
export default routes;