import { createAction, createReducer } from 'redux-action';
import { getBookingsArray } from './../services/bookingService';
export const GET_BOOKINGS = 'GET_BOOKINGS';
export const GET_ALL_BOOKINGS = 'GET_ALL_BOOKINGS';
export const SET_ALL_USERS = 'SET_ALL_USERS';


export const getBookings = createAction(GET_BOOKINGS, (offlineBookings, onlineBookings, clinicDoctors, oldList) => {
  return getBookingsArray(offlineBookings, onlineBookings, clinicDoctors, oldList).then(data => {
    return data
  }).catch(err => console.log('LOG ::: err ::: ', err));
});

export const updateBookings = createAction("GET_BOOKINGS_UPDATES", (updatedList) => {
  return { bookings: updatedList }
})
export const setAllBookings = createAction(GET_ALL_BOOKINGS, (list) => {
  return list
})
export const setAllUsers = createAction(SET_ALL_USERS, (list) => {
  return list
})
const initialState = {
  bookings: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKINGS:
      console.log('GET_BOOKINGS', action.payload)

      return {
        ...state,
        bookings: action.payload,
      };
    case "GET_BOOKINGS_UPDATES":
      console.log('GET_BOOKINGS_UPDATES', action.payload)
      return {
        ...state,
        // bookings: action.payload,
      };
    case GET_ALL_BOOKINGS:
      return {
        ...state,
        allBookings: action.payload,
      };
    case SET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    default:
      return state;
  }
};