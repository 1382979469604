import React, { Component } from 'react';
import PropTypes from 'prop-types'

class StepsBar extends Component {
    static propTypes = {
        currentStep: PropTypes.number.isRequired,
        branchList: PropTypes.object.isRequired,
        changeCurrentStep: PropTypes.func.isRequired,
        changeErrClick: PropTypes.func.isRequired,
    }
    state = {
        parts: [
            'Basic Info',
            'Location',
            'Timings',
            'Specialties',
            'Insurance',
            'Amenities',
            // 'Doctors',
        ]
    }
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount = () => {
    }

    middleChangeCurrentStep = (index) => {
        let { branchList } = this.props;

        Object.keys(branchList).forEach(function (item) {
            if (branchList[item].haveError) {
                // errors = true;
                // console.log("item "+item)
            }
        });
        let part = index + 1;
        this.props.changeCurrentStep(part); 
    }

    render() {
        return (
            <div>
                {this.renderSteps()}
            </div>
        )
    }
    componentDidMount = () => {
    }



    renderSteps = () => {
        const { currentStep } = this.props;
        let currPartList = this.state.parts

        return (
            <div className="page-nav">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {
                        currPartList.map((item, index) => (
                            <li className="nav-item" data-prev={currentStep > (index + 1)} data-active={currentStep === (index + 1)} onClick={this.middleChangeCurrentStep.bind(this, index)} key={item} >
                                {
                                    (currentStep === (index + 1)) ? (
                                        <a className="nav-link active" aria-selected="true">{item}</a>
                                    ) : (
                                            <a className="nav-link" aria-selected="false">{item}</a>
                                        )
                                }
                            </li>
                        ))}
                </ul>
            </div>
        )
    }
}

export default StepsBar