import './ListDoctor.css'
import React, { Component } from 'react';
import firebase from '../../services/fire'
import { Input, Dropdown, Button } from 'semantic-ui-react';
import img2 from '../img/Apointment.svg';
import img3 from '../img/Reduce no show.svg';
import img1 from '../img/New patient.svg';
import Swiper from 'react-id-swiper';
import Swal from 'sweetalert2'

let citiesOptions = [{ key: '1', value: 'Abu Dhabi', text: 'Abu Dhabi' }, { key: '2', value: 'Ajman', text: 'Ajman' }, { key: '3', value: 'Dubai', text: 'Dubai' }, { key: '4', value: 'Fujairah', text: 'Fujairah' }, { key: '5', value: 'Ras al-Khaimah', text: 'Ras al-Khaimah' }, { key: '6', value: 'Sharjah', text: 'Sharjah' }, { key: '7', value: 'Umm al-Quwain', text: 'Umm al-Quwain' }]

class ListDoctor extends Component {
    constructor(props) {
        super(props)
        this.state = {

            name: "",
            email: "",
            number: "",
            clinicName: "",
            city: "",
        }
    }


    uploadPractice = () => {
        let { name, email, number, city, clinicName } = this.state
        if (name === "" || email === "" || number === "" || !email.includes("@")) {
            Swal.fire({
                heightAuto:false,
                title: 'Please fill all fields!',
                text: '',
                type: 'warning',
                confirmButtonText: 'Ok',
                showCancelButton: false,
                allowOutsideClick: true
            })
        } else {
            const post = {
                name: name,
                email: email,
                number: number,
                clinicName: clinicName,
                city: city
            }
            firebase.database().ref('doctorsForm').push(post).then(() => {
                Swal.fire({
                    heightAuto:false,
                    title: 'Your information uploaded successfully',
                    text: '',
                    type: 'success',
                    confirmButtonText: 'Ok',
                    showCancelButton: false,
                    allowOutsideClick: true
                }).then(() => {
                    this.props.dismiss()
                    this.setState({
                        name: "",
                        email: "",
                        number: "",
                        clinicName: "",
                        city: "",
                    })

                })
            });

        }

    };
    inputCahnged = (e, { value }) => {
        switch (e.target.name) {
            case "name":
                this.setState({ name: value });
                break;
            case "number":
                this.setState({ number: value });
                break;
            case "email":
                this.setState({ email: value });
                break;
            case "clinicName":
                this.setState({ clinicName: value });
                break;
            default: break;
        }
    };
    handleCitiesChange = (e, { name, value }) => {
        this.setState({ city: value })
    }
    render() {
        const { name, email, number, city, clinicName } = this.state

        const params = {

            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            grabCursor: true,

            containerClass: 'listdoctorleftside',
            wrapperClass: 'subContainer',
            slideClass: 'leftsidecell',
        };

        return (
            <div className="listdoctor">
                <div className="listdoctorview">
                    <div className="dismissButton"> <i className="fas fa-times" onClick={() => this.props.dismiss()}></i></div>

                    <Swiper {...params}>
                        <div>
                            <img alt='' className="doctorimg" src={img1} />
                            <div className="attractright">Capture</div>
                            <div className="newpatient">Digitize your practice and capture new potential customers by having rich profiles that people recommend to others.</div>
                        </div>
                        <div>
                            <img alt='' className="doctorimg" src={img2} />
                            <div className="attractright">Engage</div>
                            <div className="newpatient">Keep your patients engaged about your updates, pre-visit reminders and in-visit live interactions to deliver the best experience.</div>
                        </div>
                        <div>
                            <img alt='' className="doctorimg" src={img3} />
                            <div className="attractright">Analyze</div>
                            <div className="newpatient">Never miss any problem or opportunity by having live data about your process efficiency, patients' feedback patient outcomes and much more.</div>
                        </div>
                    </Swiper>

                    <div className="listdoctorrightside">
                        <div className="titleparent">
                            <div className="text">Tell me more about Thrive Pro</div>
                            <div className="view" />
                        </div>
                        <div className="doctorinfoparent">
                            <div className="cell">
                                <div className="title">Full name</div>
                                <Input className="inputfield" type="text" name="name" value={name} onChange={this.inputCahnged} />
                            </div>
                            <div className="cell">
                                <div className="title">Contact number</div>
                                <Input className="inputfield" type="text" name="number" value={number} onChange={this.inputCahnged} />
                            </div>
                        </div>
                        <div className="doctorinfoparent">
                            <div className="cell">
                                <div className="title">Email</div>
                                <Input className="inputfield" type="text" name="email" value={email} onChange={this.inputCahnged} />
                            </div>
                            <div className="cell">
                                <div className="title">Facility name</div>
                                <Input className="inputfield" type="text" name="clinicName" value={clinicName} onChange={this.inputCahnged} />
                            </div>
                        </div>
                        <div className="doctorinfoparent">
                            <div className="cell" style={{ width: '100%' }}>
                                <div className="title">City</div>
                                <Dropdown className="inputfield" fluid selection options={citiesOptions} value={city} onChange={this.handleCitiesChange} />

                            </div>
                        </div>
                        <Button primary className="sendrequestbutton" onClick={() => this.uploadPractice()}>Let’s upgrade your experience</Button>

                    </div>
                </div>
            </div>
        )
    }


}
export default ListDoctor