import './index.css'
import React, { Component } from 'react';
import logo from './img/oie.png'
import background from './img/landing_img.jpg';
import Swal from 'sweetalert2';
import firebase from '../services/fire'
import { Input, Button } from 'semantic-ui-react';
import Powered_by from './img/Powered_by.png';

import ListDoctor from './ListDoctor/ListDoctor'
import crypto from 'crypto-js';
import { connect } from "react-redux";
import { userActions } from '../modules/user-module.js'

class HomeLogin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            pw: '',
            error: null,
            forgot: false,
            loading: false,
            listDoctorClicked: false,
            keepLogin: false
        }
    }
    submitFormReset = (e) => {
        e.preventDefault();
        let _this = this;
        firebase.auth().sendPasswordResetEmail(this.state.email)
            .then(() => {
                Swal.fire({
                    heightAuto: false,
                    title: 'Done',
                    text: 'Please check your email',
                    type: 'success',
                    confirmButtonText: 'ok',
                    allowOutsideClick: false
                })
            })
            .catch(function (error) {
                _this.setState({
                    error: 'Email address does not exist',
                })
            });
    }
    submitForm = (e) => {
        let accessTypes = ["superAdmin", "admin", "frontD"]
        this.setState({ loading: true })
        const _this = this;
        e.preventDefault();
        let email = this.state.email,
            password = this.state.pw;
        firebase.auth().signInWithEmailAndPassword(email, password).then(function (user) {
            user.user.getIdTokenResult().then((idTokenResult) => {
                if (accessTypes.includes(idTokenResult.claims.accessType)) {
                    _this.props.getUser();
                    _this.setState({ thanks: true });
                } else {
                    firebase.auth().signOut()
                    _this.setState({ error: "You are not Authorized to access", loading: false })
                }
            })

        }, function (error) {
            let errorMessage = error.message;
            _this.setState({ error: errorMessage, loading: false })
        })
    };
    resetStatus = (status = false) => {
        this.setState({
            notVerified: status,
            forgot: false,
            loading: false
        });
    }
    dismiss = () => {
        this.setState({ listDoctorClicked: false });
    }
    render() {
        let { forgot, loading, listDoctorClicked, keepLogin } = this.state

        return (
            <div className="desktophd">
                {
                    (listDoctorClicked) ? (
                        <ListDoctor dismiss={this.dismiss} />
                    ) : ''
                }
                <div className="leftside">
                    <img alt='' className="colorlogotransparentbackground" src={process.env.REACT_APP_LOGOURL} />
                    {/* <div className="logintextmsg">Log in to your account</div> */}
                    <div className="edittextsparent">
                        <div className="group4">
                            <div className="yourname">Email</div>
                            <Input className="rectangle2" type="text" name="email" style={{ width: '100%' }} onChange={(e, { value }) => this.setState({ email: value })} />
                        </div>
                        {
                            forgot ?
                                '' :
                                <div className="group4">
                                    <div className="yourname">Password</div>
                                    <Input className="rectangle2" style={{ width: '100%' }} type="password" name='password' onChange={(e, { value }) => this.setState({ pw: value })} />
                                </div>
                        }
                    </div>

                    <Button loading={loading} primary className="loginbutton" onClick={forgot ? this.submitFormReset : this.submitForm}>
                        {
                            forgot ? 'Submit' : 'Log In'
                        }
                    </Button>
                    {
                        this.state.error ?
                            <p style={{ color: 'red', textAlign: 'center', margin: '10px' }}>{this.state.error}</p>
                            : ''
                    }
                    <div className="group8">
                        {
                            forgot ? '' :
                                <div className="keepmeloggedin" onClick={() => this.setState({ keepLogin: !keepLogin })}>
                                    <i className={keepLogin ? "far fa-check-square" : "far fa-square"} style={keepLogin ? { color: '#1678c2' } : {}}></i> Keep me logged in
                                </div>
                        }
                        <div className="forgetpassword" onClick={() => this.setState({ forgot: !forgot })}>
                            {
                                forgot ? "Login" : "Forget Password"
                            }

                        </div>

                    </div>
                    <img alt='' style={{ width: '30%' }} className="colorlogotransparentbackground" src={Powered_by} />


                </div>

                <div className="rightside">
                    {/* <div className="thriveproparent">
                        <div className="thrivepro">Thrive Pro</div>
                        <div className="u201cyourappointmentso"><span className="span1">“Live </span><span className="span2">Engagement </span><span className="span1">Higher </span><span className="span2">Experience“</span></div>
                        <div className="reducenoshow">Reduce No-Show: 70% of No-Shows due to <br />forgetting about appointments. Reduce your No-<br />Show rate by 35% by enabling automatic reminders<br /> for your patients.</div>
                        <div className="tellusmore">
                            <div className="group9" onClick={() => { this.setState({ listDoctorClicked: true }) }}>Tell Me More</div>
                        </div>
                    </div> */}
                    <img alt='' className="backgroundimg" src={background} />
                </div>
            </div>
        )
    }


}
const mapStateToProps = ({ user }) => {
    return { user };
};
const mapActionsToProps = (dispatch) => ({
    getUser: () => dispatch(userActions.getUser())
});
export default connect(
    mapStateToProps,
    mapActionsToProps
)(HomeLogin);