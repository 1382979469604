import React, { Component } from 'react';
import firebase from 'firebase/app'
import 'firebase/database';
import 'firebase/auth';
import Swal from 'sweetalert2'
import FMSHeader from '../Header/defaultHeader';
import home from '../../img/thriveFmsLogo.png';
import HomeLogin from '../../Home Login/index'
import SelectFacility from './SelectFacility';
import MainWizard from './MainWizard';
import { Dimmer, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import crypto from 'crypto-js';
import alertCircle from '../../img/alert-circle.png';
import { connect } from "react-redux";
import { userActions } from '../../modules/user-module.js'

class WizardClinic extends Component {

    state = {
        suggestionId: null,
        // For Select Facility Component
        branches: 1,
        facilityType: 'Facility',
        readyToStart: false,
        draftList: null,
        selectFacility: null,
        clinicsStatus: 1,
        /*for test*/
        /* branches: 2,
         facilityType: 'Hospital',
         readyToStart: true*/
        loading: true,
        showMenu: window.screen.width <= 1026 ? false : true,
        search: "",


    }

    constructor(props, context) {
        super(props, context);
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        let { accessScreens } = this.props.user.data ? this.props.user.data : {}
        let query = null;
        if (this.props.location.search) {
            let querySearch = this.props.location.search;
            query = querySearch.replace('?claim=', '');
        } else {
            query = this.props.location.pathname.split('=')[1];
        }
        this.setState({
            suggestionId: query,
            allowed: accessScreens.includes('fms'),
            loading: false
        })
    }

    /*Simple functions*/
    branchChange = number => {
        this.setState({ branches: number })
    }
    facilityNameChange = name => {
        this.setState({ facilityType: name })
    }
    clinicStatusSelected = number => {
        const _this = this;
        this.setState({ clinicsStatus: number })
    }
    readyToStartChange = (bool, clinicKey) => {
        let { branches } = this.props
        if (bool === false) {
            if (clinicKey) {
                return this.props.updateBranches(clinicKey, branches).then(() => {
                    this.setState({ updated: Date.now(), selectFacility: false, readyToStart: false })
                })
            }
        }
        this.setState({ readyToStart: false })
    }
    SelectedFacility = object => {
        this.setState({ selectFacility: object })
    }
    updateFacility = (clinicKey, clinicDoctors) => {
        let { branches, doctors } = this.props
        if (clinicKey) {
            return this.props.updateBranches(clinicKey, branches).then(() => {
                clinicDoctors.forEach(key => {
                    this.props.updateDoctor(key, doctors).then(() => {
                        this.setState({ updated: Date.now(), selectFacility: false, readyToStart: false })
                    })
                })

            })
        }

    }
    SelectedFacility = object => {
        this.setState({ selectFacility: object })
    }

    handleFacilityClick = bool => {
        let _this = this;
        if (!this.state.selectFacility) return;
        Swal.fire({
            heightAuto: false,
            title: 'Are you sure?',
            text: "Changes you did will be lost if you didn't save",
            // type: 'success',
            confirmButtonText: 'Discard changes',
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            allowOutsideClick: false
        }).then(function (result) {
            if (result.value) {
                _this.setState({
                    selectFacility: false,
                    readyToStart: false,
                    clinicsStatus: 1,
                    draftList: null
                });
            }
        })
    }

    showMenuFunc = () => {
        let { showMenu } = this.state;
        this.setState({
            showMenu: !showMenu,
        })
    }
    onSearchKey = e => {
        this.setState(
            {
                search: e.target.value.toLowerCase()
            }
        );
    };
    render() {
        let { loading, showMenu, allowed } = this.state;
        let { loggedIn, user, modalLoginIsOpen, accessType } = this.props.user.data ? this.props.user.data : {}

        return (
            (loggedIn && user) ?
                <div className="bootstrap">
                    <section id="main-wrapper" >
                        <div className="container-fluid">
                            <div className="row" style={{ overflow: 'hidden' }}>
                                <div className="col-1 side-nav" style={{ display: showMenu ? 'block' : 'none' }}>
                                    <ul>
                                        <li style={{ height: '60px' }}><a href="/#/"><img src={home} /></a></li>
                                        {/* <li><a href=""><i className="fab fa-buromobelexperte"></i>Dashboard</a></li> */}
                                        <li ><Link to="/dashboard"><i className="fab fa-buromobelexperte"></i>Dashboard</Link></li>
                                        <li onClick={this.handleFacilityClick} style={{ borderLeft: '3px solid #0096FD' }}><a><i style={{ color: '#0096FD' }} className="far fa-hospital"></i>Facilities</a></li>
                                        <li ><Link to="/wizard-doctor"><i className="fas fa-user-md"></i>Providers</Link></li>
                                        <li ><Link to="/appointment-system"><i className="far fa-calendar-alt"></i>Calendar</Link></li>
                                        <li ><Link to="/patients"><i className="fas fa-users"></i>Patients</Link></li>
                                        <li ><Link to="/history"><i className="fa fa-history"></i>History</Link></li>
                                        <li ><Link to="/feedback"><i className="fa fa-star"></i>Feedback</Link></li>
                                        {
                                            accessType === 'superAdmin' ?
                                                (
                                                    process.env.REACT_APP_NAME !== 'saeed' ?
                                                        <li ><Link to="/helpers"><i className="fas fa-database"></i>Helpers</Link></li> : '')
                                                : ''
                                        }
                                        <li ><Link to="/settings"><i className="fas fa-cogs"></i>Settings</Link></li>

                                    </ul>

                                </div>
                                <div className="col-11 main-body" style={{ width: '100%', maxWidth: !showMenu ? 'none' : '' }}>
                                    <FMSHeader showMenuFunc={this.showMenuFunc} query={this.props.location.search} onSearchKey={this.onSearchKey} addFacility={() => this.setState({ readyToStart: true, selectFacility: {} })} title="Facilities" />
                                    {
                                        (loggedIn && user) ? (
                                            allowed ?
                                                this.renderContent()
                                                :
                                                this.renderAccessDenied()
                                        ) : <div className="main-side">Please login</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                : loading ?
                    <Dimmer active>
                        <Icon.Group size='big'>
                            <Icon loading size='big' name='spinner' />
                            <div style={{ fontSize: '16px', padding: '10px', fontStyle: 'initial' }}>loading</div>
                        </Icon.Group>
                    </Dimmer>
                    :
                    <HomeLogin onClose={this.toggleModalLogin} show={modalLoginIsOpen} getUser={() => this.props.getUser} />

        );

    }
    /*
     * Functions for content getting
     * */
    renderAccessDenied() {
        return (
            <div style={{ height: 'calc( 100% - 60px )' }}>
                <div className="emptystate">
                    <div className="emptystate1">
                        <img src={alertCircle} alt="Img" />
                        <div className="textMesg">No Permission</div>
                        <div className="detailMesg">Contact your admin to access this page</div>
                    </div>
                </div>
            </div>
        )
    }
    renderContent = () => {
        let { suggestionId, draftList, selectFacility, search } = this.state;
        let { user } = this.props.user.data ? this.props.user.data : {}
        let { branches, specialties, Analytics } = this.props
        if (selectFacility) {
            return (
                <div style={{ height: 'calc( 100% - 60px )' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div style={{ padding: '20px 30px', fontSize: '20px', color: '#4A4A4A' }}>Facility profile</div>
                    </div>

                    <MainWizard
                        draftList={selectFacility}
                        wizardOptions={
                            {
                                branches: this.state.branches,
                                facilityType: this.state.facilityType,
                            }
                        }
                        selectFacility={
                            {
                                readyToStartChange: this.readyToStartChange
                            }
                        }
                        currentUser={user}
                        suggestionId={suggestionId}
                        specialties={specialties}
                    />
                </div>)
        }
        return (
            <SelectFacility
                Analytics={Analytics}
                selectFacility={
                    {
                        search: search,
                        branchChange: this.branchChange,
                        branches: this.state.branches,
                        clinics: branches,
                        clinicsStatus: this.clinicStatusSelected,
                        SelectedFacility: this.SelectedFacility,
                        facilityType: this.state.facilityType,
                        facilityNameChange: this.facilityNameChange,
                        readyToStartChange: this.readyToStartChange,
                        updateFacility: this.updateFacility,
                        specialties: specialties

                    }
                }
                loading={this.state.loading}
                addFacility={() => this.setState({ readyToStart: true, selectFacility: null })}
                allBookings={this.props.Bookings?.allBookings || []}
            />
        )
    }

}
const mapStateToProps = ({ user, branches, specialties, Bookings, Analytics }) => {
    return {
        user,
        Bookings,
        branches: branches ? branches.data : {},
        doctors: branches ? branches.doctors : [],
        specialties: specialties ? specialties.data : {},
        Analytics
    };
};
const mapActionsToProps = (dispatch) => ({
    getUser: () => dispatch(userActions.getUser()),
    updateBranches: (clinic, oldList) => dispatch(userActions.updateBranches(clinic, oldList)),
    updateDoctor: (doctorKey, oldList) => dispatch(userActions.updateDoctor(doctorKey, oldList)),

    // getOrderListSuccess: (updatedList) => dispatch(getOrderListSuccess(updatedList)),
});
export default connect(
    mapStateToProps,
    mapActionsToProps
)(WizardClinic);