import { createAction, createReducer } from 'redux-action';
import { fetchUser, fetchBranches, updateClinic, updateDoctor } from './../services/UserService';

export const GET_USER = 'GET_USER ';

const getUserAction = createAction(GET_USER, (user) => {
  return fetchUser(user).then(data => {
    return data
  }).catch(err => console.log('LOG ::: err ::: ', err));
});

const getUserBranches = createAction("GET_BRANCHES", (user) => {
  return fetchBranches(user).then(data => {
    return data
  }).catch(err => console.log('LOG ::: err ::: ', err));
});

const updateBranches = createAction("UPDATE_BRANCHES", (clinicKey, oldList) => {
  return updateClinic(clinicKey, oldList).then(data => {
    return data
  }).catch(err => console.log('LOG ::: err ::: ', err));

})
const updateDoctorAction = createAction("UPDATE_DOCTORS", (doctorKey, oldList) => {
  return updateDoctor(doctorKey, oldList).then(data => {
    return data
  }).catch(err => console.log('LOG ::: err ::: ', err));

})


export const userActions = {
  getUser: getUserAction,
  getBranches: getUserBranches,
  updateBranches,
  updateDoctor:updateDoctorAction
};


// All actions
export const allActions = {
  ...userActions,
};

const initialState = {
  data: {},
};
function extarctDoctors(data) {
  var doctorData = {}

  if (data && Object.keys(data)) {
    Object.keys(data).map(key => {
      data[key].allDoctors.forEach(record => {
        doctorData[record.id] = record
      })
    })
  }

  return doctorData

}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        data: action.payload,
      };
      break
    default:
      return state;
  }
};
// export const clinicsKeysReducer = (state = [], action) => {
//   switch (action.type) {
//       case "GET_CLINICS_KEYS":
//       return {
//         ...state,
//         data: action.payload,
//       };
//     default:
//       return state;
//   }
// };

export const branchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BRANCHES":
      return {
        ...state,
        data: action.payload,
        doctors: extarctDoctors(action.payload)

      };
    case "UPDATE_BRANCHES":
      return {
        ...state,
        data: action.payload,
      };
    case "UPDATE_DOCTORS":
      console.log("action.payloadaction.payload",action.payload);
      return {
        ...state,
        doctors: action.payload
      };
    default:
      return state;
  }
};