import { combineReducers } from 'redux';
import clinicsReducer from './modules/clinics-module';
import searchReducer from './modules/search-module';
import filterReducer from './modules/filter-module';
import facilityReducer from './modules/selectFacility-module';
import Bookings from './modules/bookingsModule';
import Analytics from './modules/analyticsModule';

import userReducer,{branchesReducer} from './modules/user-module'
// import {branchesReducer} from './modules/user-module'
// import {persistCombineReducers} from 'redux-persist'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
// import localForage from "localforage";

// const persistConfig = {
//     key: 'root',
//     storage:localForage,
//   }
const rootReducer = combineReducers({
    clinics: clinicsReducer,
    params: searchReducer,
    specialties: searchReducer,
    filter: filterReducer,
    facilityTypes: facilityReducer,
    user:userReducer,
    branches:branchesReducer,
    Bookings:Bookings,
    Analytics:Analytics
});

export default rootReducer;
