import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PatientsHistory from './steps/PatientsHistory'
import Filter from './steps/filter'
import _ from 'lodash'
import fire from 'firebase/app'
import 'firebase/database';
import 'firebase/auth';
import { connect } from 'react-redux';
import { getBookings } from '../../modules/bookingsModule';
import { Dimmer } from 'semantic-ui-react';

const startDate = new Date();
startDate.setDate(1);
startDate.setMonth(startDate.getMonth() - 1);

class MainWizard extends Component {

    static propTypes = {
        wizardOptions: PropTypes.shape({
            branches: PropTypes.isRequired,
        }).isRequired,
        selectFacility: PropTypes.shape({
            readyToStartChange: PropTypes.func.isRequired,
        }).isRequired,
        currentUser: PropTypes.object.isRequired,
        clinicsStatus: PropTypes.number.isRequired,

    };

    state = {
        clinicsList: {},
        activeBranchKey: 'branch1',
        currentStep: 1,
        logoUrl: null,
        doctorData: this.props.doctorData,
        clinicDoctors: this.props.clinicDoctors ? this.props.clinicDoctors : [],
        allBooking: [],
        onlineBoolean: false,
        offlineBoolean: false,
        onlinearray: [],
        offlinearray: [],
        clinicDoctorsarray: [],
        loading: true,
        dateRange: [startDate.getTime(), new Date().getTime()],
        statusArray: ['accepted', 'succeeded', 'canceled', 'discharged', 'rejected', 'removed', 'failed'],
        doctorsArray: [],
        patientNumber: '',
        userData: {},
        patientOptions: [],
        asyncOptions: []


    };
    constructor(props, context) {
        super(props, context);
        this.handleStateChangeMain = this.handleStateChangeMain.bind(this)
    }

    componentWillMount = () => {

        let _this = this;
        const { currentUser } = this.props;
        let listOfBranches = {};
        let { draftList } = _this.props;

        for (let i = 1; i <= this.props.wizardOptions.branches; i++) {
            listOfBranches['branch' + i] = {
                facilityName: '',
                facilityName_ar: '',
                created: '',
                doctors: [],
                selectedDoctors: [],
                facilityId: Object.keys(draftList)[0],
                status: 'new',
                userId: currentUser.id,
                //Global
                logoUrl: false,
                haveError: true,
                users: {
                    superAdmin: currentUser.id
                },
            }
        }
        this.setState({
            clinicsList: listOfBranches
        }, () => {
            let { clinicsList } = this.state;

            this.setState(prevState => ({
                ...prevState,
                clinicsList: clinicsList
            }), () => {
                let { draftList } = _this.props;
                let { clinicsList } = _this.state;
                if (draftList) {

                    Object.keys(draftList).map(function (item, index) {
                        return Object.keys(draftList[item]).map(function (subItem) {
                            clinicsList[Object.keys(clinicsList)[index]][subItem] = draftList[item][subItem];
                            if (subItem === 'logoUrl') {
                                _this.setState({
                                    logoUrl: draftList[item].logoUrl
                                })
                            }

                        })

                    })
                    _this.setState({ clinicsList: clinicsList })
                }
            })
        })
        window.addEventListener('offline', this.handleStateChangeMain);
        window.addEventListener('online', this.handleStateChangeMain);

    }
    componentWillUnmount() {
        window.removeEventListener('offline', this.handleStateChangeMain)
        window.removeEventListener('online', this.handleStateChangeMain)

    }
    handleStateChangeMain() {
        this.setState({ offline: !window.navigator.onLine })
    }



    searchFunction = async () => {

        let { activeBranchKey, dateRange, clinicsList } = this.state;
        let clinicDoctors = []
        let _this = this

        let facilityId = clinicsList[activeBranchKey] ? clinicsList[activeBranchKey].facilityId : null
        let superAdmin = clinicsList[activeBranchKey].users ? clinicsList[activeBranchKey].users.superAdmin : ''

        if (facilityId && superAdmin) {
            clinicDoctors = this.props.branches.data[facilityId].doctorsData
        } else {
            return
        }

        let userBookingsSnap = await fire.firestore().collection('bookings').where('facilityId', '==', facilityId).where('dateTS', '>=', new Date(dateRange[0]).setHours(0,0,0)).where('dateTS', '<=', new Date(dateRange[1]).setHours(23,59,59)).get()
        let asyncOptionsOnline = userBookingsSnap.docs.map(async res => {

            let userDataSnap = await fire.firestore().collection('users').doc(res.data().patient).get()
            let userData = userDataSnap.data()


            return {
                ...res.data(),
                key: res.id,
                bookingId: res.id,
                userData: userData,
                patientNumber: userData.phone,
                patientEmail: userData.email,
                patientAge: userData.birthday,
                insurance: userData.insurance,
                doctorName: clinicDoctors.filter(rec => rec.id === res.data().doctor)[0].name,
                refrenceNumber: userData.RN,
                type: "online",
                doctorData: clinicDoctors.filter(rec => rec.id === res.data().doctor)[0]

            }

        })

        let offlineUserBookingsSnap = await fire.firestore().collection('offlineBookings').where('facilityId', '==', facilityId).where('dateTS', '>=', new Date(dateRange[0]).setHours(0, 0, 0)).where('dateTS', '<=', new Date(dateRange[1]).setHours(23, 59, 59)).get()
        let asyncOptionsOffline = offlineUserBookingsSnap.docs.map(async res => {
            let userDataSnap = await fire.firestore().collection('offlineUsers').doc(res.data().patient).get()
            let userData = userDataSnap.data()
         console.log('userDatauserData',userData);
            return {
                ...res.data(),
                key: res.id,
                bookingId: res.id,
                userData: userData,
                patientNumber: userData.phone || userData.number,
                patientEmail: userData.email,
                patientAge: userData.birthday,
                insurance: userData.insurance,
                doctorName: clinicDoctors.filter(rec => rec.id === res.data().doctor)[0].name,
                refrenceNumber: userData.RN,
                type: "offline",
                doctorData: clinicDoctors.filter(rec => rec.id === res.data().doctor)[0]


            }

        })
        let allOptions = asyncOptionsOffline.concat(asyncOptionsOnline)
        Promise.all(allOptions).then((ress) => {
            this.setState({ asyncOptions: ress, loading: false })
        })


    }

    render() {
        let { clinicsList, offline } = this.state;
        const { clinicsStatus } = this.props;
        return (
            <div style={{ height: 'calc( 100%)' }}>
                {
                    offline && <Dimmer active />
                }
                {
                    (clinicsStatus === 1) ? (
                        <section data-branches-length={Object.keys(clinicsList).length} id="facility-page" style={{ padding: '0', height: '100%' }}>

                            <div className="tab-content sub-pages" id="myTabContent" style={{ overflow: 'auto', width: '100%' }}>

                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" style={{ height: '100%' }}>
                                    <div className="main-side" style={{ width: '100%', height: '100%', padding: '0', margin: '0' }}>
                                        {this.renderRightSteps()}
                                        {this.renderStep()}
                                    </div >
                                </div >
                            </div >
                        </section>
                    ) : ''
                }
            </div >
        )
    }

    /**
     *
     * @returns {XML}
     */
    renderStep = () => {
        let { clinicsList, activeBranchKey, patientNumber, statusArray, dateRange, doctorsArray, asyncOptions } = this.state;
        const { currentUser, clinicsStatus, clinicDoctors, doctorKey } = this.props;
        switch (clinicsStatus) {
            case 1:
                return <PatientsHistory
                    activeBranchKey={activeBranchKey}
                    clinicsList={clinicsList}
                    currentUser={currentUser}
                    doctorKey={doctorKey}
                    clinicDoctors={clinicDoctors}
                    patientData={(patientData) => { this.props.patientData(patientData) }}
                    allBooking={asyncOptions}
                    dateRange={dateRange}
                    statusArray={statusArray}
                    patientNumber={patientNumber}
                    doctorsArray={doctorsArray}
                />;
        }
    }
    renderRightSteps = () => {
        let { clinicsList } = this.state;
        const { clinicsStatus, clinicDoctors } = this.props;
        switch (clinicsStatus) {
            case 1:
                return <Filter
                    selectDateRange={this.props.selectDateRange}
                    dateRange={(dateRange) => {
                        this.setState({ dateRange }, () => {

                            if (dateRange.length > 1) {
                                this.searchFunction()

                            }
                        })
                    }}
                    statusArray={(statusArray) => {
                        this.setState({ statusArray }, () => {
                            this.searchFunction()
                        })
                    }}
                    doctorsArray={(doctorsArray) => { this.setState({ doctorsArray }) }}
                    patientNumber={(patientNumber) => {
                        this.setState({ patientNumber })
                    }}
                    clinicDoctors={clinicDoctors}

                />;
        }

    }

}

function mapStateToProps(state) {
    return {
        clinics: state.clinics.data,
        user: state.user.data.user,
        branches: state.branches,
        clinicsKeys: state.clinicsKeys,
        Bookings: state.Bookings
    }
}

const mapDispatchToProps = dispatch => ({
    getBookings: (offlineBookings, onlineBookings, clinicDoctors) => dispatch(getBookings(offlineBookings, onlineBookings, clinicDoctors)),

});

export default connect(mapStateToProps, mapDispatchToProps)(MainWizard)