import React, { Component } from 'react';
import './NewRequestsManageCell.css'
import PropTypes from 'prop-types'


class NewRequestsManageCell extends Component {
    static propTypes = {
        bookingItem: PropTypes.object.isRequired,
    }
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    acceptedRequestClicked = (item) => {
        let doctorsKeys = this.props.doctors
        this.props.patientData({ doctorsKeys, acceptedPatientData: item ,hideActions:true})
    }
    render() {
        let { bookingItem } = this.props;
        return (
            <div >
                {
                    (bookingItem.status === 'accepted') ? (

                        <div className="group15" style={{ backgroundColor: '#EDEDED',marginTop:'8px' }}>
                            <div className="group3" style={{ height: '100%' }}>
                                <div className="tarekjamal">{bookingItem.patientName}</div>
                                <div className="drsamehsuliman">{bookingItem.doctorName}</div>
                            </div>
                            <div className="manage" onClick={() => this.props.manageClicked(bookingItem)}>Manage</div>
                        </div>

                    ) : (bookingItem.status === 'discharged') ? (

                        <div className="group15">
                            <div className="group3" style={{cursor:'pointer'}} onClick={()=>this.acceptedRequestClicked(bookingItem)}>
                                <div className="appointmentdate2">{bookingItem.selectedDate.split(" at")[1]}</div>
                                <div  className="tarekjamal" >{bookingItem.patientName}</div>
                                <div className="notereason">{bookingItem.notes}</div>
                                <div className="drsamehsuliman">{bookingItem.doctorName}</div>
                            </div>
                            <div className="manage" style={{ color: '#3498db', cursor: 'default' }}>Discharged</div>
                        </div>

                    ) : (bookingItem.status === 'rejected' || bookingItem.status === 'failed' || bookingItem.status === 'canceled' || bookingItem.status === 'removed') ? (
                        <div className="group15">
                            <div className="group3" style={{cursor:'pointer'}}  onClick={()=>this.acceptedRequestClicked(bookingItem)}>
                                <div className="appointmentdate2">{bookingItem.selectedDate.split(" at")[1]}</div>
                                <div  className="tarekjamal" style={{ textDecorationLine: 'line-through', WebkitTextDecorationLine: 'line-through' }}>{bookingItem.patientName}</div>
                                <div className="notereason" style={{ textDecorationLine: 'line-through', WebkitTextDecorationLine: 'line-through' }}>{bookingItem.notes}</div>
                                <div className="drsamehsuliman" style={{ textDecorationLine: 'line-through', WebkitTextDecorationLine: 'line-through' }}>{bookingItem.doctorName}</div>
                            </div>
                            <div className="manage" style={{ color: '#D0021B', cursor: 'default' }}>Canceled</div>
                        </div>
                    ) : ''
                }
            </div>
        )
    }


}

export default NewRequestsManageCell