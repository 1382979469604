import React, { Component } from 'react';
import cIcon from '../../img/clinic-f.png';
import { firestore } from 'firebase'

import hIcon from '../../img/hospital-f.png';
import clinicPlaceHolder from '../../img/clinicprofileaspect.png';
import { Icon, Button, Radio, Loader, Dimmer } from 'semantic-ui-react'
import ReactTable from "react-table";
import moment from 'moment'
import "react-table/react-table.css";
// import "./reacttable.css";

class SelectFacility extends Component {
    state = {
        allBookings: [],
        currentStep: 1,

        selectedFacility: {},
        user: null,
        loggedIn: false,
        claimingPersonName: '',
        suggestionId: null,
        // For Select Facility Component
        branches: 1,
        facilityType: 'Facility',
        readyToStart: false,

        facilityTypes: {
            Clinic: {
                name: 'Clinic',
                icon: cIcon,
                selected: false
            },
            Hospital: {
                name: 'Hospital',
                icon: hIcon,
                selected: false
            }
        },
    }
    constructor(props, context) {
        super(props, context);
    }
    getTotalBookings(facilityId) {
        let { Analytics } = this.props
        return Analytics?.clinics[facilityId]?.totalBookings || 0
    }
    getTotalRevenue(facilityId) {
        let { Analytics } = this.props
        return Analytics?.clinics[facilityId]?.totalRevenu || 0
    }

    /**
     *
     * @param type
     * @param reset
     * @returns {function(this:SelectFacility)}
     */
    changeLocalFacilityType = (type, facilityId, reset = false) => {
        return function () {
            const { facilityTypes, selectedFacility } = this.state;
            const draftList = this.props.selectFacility.clinics;
            let types = facilityTypes;

            Object.keys(draftList).forEach((item, index) => {
                if (item === facilityId) {
                    selectedFacility[item] = draftList[item];
                    this.setState({
                        selectedFacility: selectedFacility
                    })
                    this.props.selectFacility.SelectedFacility(selectedFacility)
                }
            })

            Object.keys(types).forEach((item, index) => {
                types[item].selected = reset ? false : (type === item);
            })

            let facilityType = reset ? 'Facility' : type;
            this.props.selectFacility.branchChange(1);
            this.props.selectFacility.facilityNameChange(facilityType);
            this.props.selectFacility.readyToStartChange(true)

        }.bind(this)
    }

    /**
     * Change step
     * @param index
     */
    changeCurrentStep = (index) => {
        this.props.selectFacility.clinicsStatus(index)
        this.setState({
            currentStep: index,
        })
    }
    dataTableColumns() {
        let _this = this
        return ([
            {
                width: 100,
                accessor: "deactivated",
                Cell: props => <Radio
                    style={{ verticalAlign: 'middle !important' }}
                    toggle
                    checked={!props.value}
                    onChange={() => {
                        this.setState({ loading: true }, () => {
                            firestore().collection('clinics').doc(props.original.facilityId).update({ deactivated: props.value ? !props.value : true, updated: Date.now() }).then(function () {
                                if (props.original.doctors && (props.original.doctors).length) {
                                    props.original.doctors.map(item => {
                                        firestore().collection('doctors').doc(item).update({
                                            [`deactivated.${[props.original.facilityId]}`]:
                                                props.value ? !props.value : true, updated: Date.now()
                                        }).then(function () {
                                            _this.props.selectFacility.updateFacility(props.original.facilityId, props.original.doctors)
                                            _this.setState({ loading: false })
                                        });
                                    })

                                } else {
                                    _this.props.selectFacility.updateFacility(props.original.facilityId)
                                    _this.setState({ loading: false })

                                }

                            });
                        })

                    }} />
            },
            {
                accessor: "logoUrl",
                Cell: props => <div className="providerfieldText"><img style={{ minHeight: '60px', maxWidth: '60px', width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={props.value ? props.value : clinicPlaceHolder} alt={props.value} /></div>
            },
            {
                Header: "Name",
                accessor: "facilityName",
                Cell: props => <p className="providerfieldText">{props.value}</p>
            },
            // {
            //     Header: "Branch Name",
            //     accessor: "branchName",
            //     Cell: props => <p className="providerfieldText">{props.value}</p>
            // },
            {
                Header: "Address",
                accessor: "address",
                Cell: props => <p className="providerfieldText">{props.value.country}</p>
            },
            {
                Header: "Providers",
                accessor: "doctors",

                Cell: props => <p className="providerfieldText">{(props.value)?.length}</p>
            },
            {
                Header: "Views",
                accessor: "views",

                Cell: props => <p className="providerfieldText">{props.value}</p>
            },
            {
                Header: "Total Bookings",
                accessor: "facilityId",

                Cell: props => <p className="providerfieldText">{this.getTotalBookings(props.value)}</p>
            },
            {
                Header: "Total Revenue",
                accessor: "facilityId",

                Cell: props => <p className="providerfieldText">{this.getTotalRevenue(props.value)} {process.env.REACT_APP_CURRNECY}</p>
            },
            {
                Header: `${process.env.REACT_APP_TITLE} Revenue`,
                accessor: "facilityId",

                Cell: props => <p className="providerfieldText">{(this.getTotalRevenue(props.value) * 0.1).toFixed(2)} {process.env.REACT_APP_CURRNECY}</p>
            },

            {
                width: 90,
                accessor: "edit",
                Cell: props => <p className="providerfieldText">
                    <Button color={'twitter'} onClick={this.changeLocalFacilityType(props.original.facilityType, props.original.facilityId)}>Edit</Button>

                    {/* <Button
                        color="primary"
                        size="sm"
                        outline
                        className="top-right-button"
                        onClick={() => this.onClickItem(props.original)}
                    >
                        Edit
              </Button> */}
                </p>
            }
        ])


    }

    render() {
        let { facilityTypes } = this.state;

        return (
            <section id="facility-grid-page">
                {/* <ClinicStatusSteps branchList={this.props.selectFacility.clinics} changeCurrentStep={this.changeCurrentStep} currentStep={currentStep} /> */}
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" style={{ height: '100%' }}>
                        {
                            this.props.loading ?
                                <div style={{ height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Icon.Group size='big'>
                                        <Icon loading size='big' name='spinner' color='grey' />
                                    </Icon.Group>
                                </div>
                                : this.renderFacilitiesList(facilityTypes)
                        }
                    </div>
                </div>
            </section>
        )
    }

    /*
    * Functions for content getting
    * */

    /**
     * get Facilities list from redux
     * @param list
     * @returns {XML}
     */
    renderFacilitiesList = (list) => {
        let { loading } = this.state
        const draftList = this.props.selectFacility.clinics;
        const search = this.props.selectFacility.search;
        const specialities = this.props.selectFacility.specialties
        if (this.props.loading) {
            return (<div>Loading</div>)
        }

        if (window.screen.width <= 768) return (
            <div className="emptystate">
                <div className="emptystate1">
                    {/* <img src={Doctor} alt="Img" /> */}
                    <div className="textMesg">Open on desktop</div>
                    <div className="detailMesg">Add doctors to see information in this page</div>
                </div>
            </div>
        );

        if (!draftList) return (
            <div className="emptystate">
                <div className="emptystate1">
                    {/* <img src={Hospital} alt="Img" /> */}
                    <div className="textMesg">No Clinics</div>
                    <div className="detailMesg">Add clinic to see information in this page</div>
                    <a onClick={() => this.props.addFacility()} className="addfacbutton">Add Clinic</a>

                </div>
            </div>
        );

        let filteredClinics = Object.values(draftList).filter(item => {
            return (item.facilityName).toLowerCase().includes(search.toLowerCase())
        })
        let views = 0
        Object.values(filteredClinics).forEach(item => {
            return views += item.views ? item.views : 0
        })
        let totalProviders = []
        Object.values(filteredClinics).forEach(item => {
            if (item.doctors && item.doctors.length) {
                (item.doctors).map(doctor => {
                    if (!totalProviders.includes(doctor)) {
                        return totalProviders.push((doctor))
                    }
                })
            }
        })

        // let totalSpecialities =[]
        // Object.values(draftList).forEach(item => {
        //     Object.keys(specialities).forEach(specKey => {
        //         if ((item.selectedSpecialties).includes(specKey)) {
        //             if (specialities[specKey].services && (specialities[specKey].services).length) {
        //                 (specialities[specKey].services).map(service => {
        //                     if (!totalSpecialities.includes(service)) {
        //                         return totalSpecialities.push((service))
        //                     }
        //                 })
        //             }
        //         }
        //     })
        // })
        if (filteredClinics) {
            return (
                <div>
                    {
                        loading ?
                            <Dimmer active page>
                                <Icon.Group size='big'>
                                    <Icon loading size='big' name='spinner' color='grey' />
                                </Icon.Group>
                            </Dimmer>
                            : ''
                    }
                    <div className="facilityInfo">
                        <div className="cardHeader">
                            <div className="cardCount">{Object.values(filteredClinics).length}</div>
                            <div className="cardTitle">Profiles Created</div>
                            <div className="cardImage"><i className="fas fa-home"></i></div>
                        </div>
                        <div className="cardHeader">
                            <div className="cardCount">{views}</div>
                            <div className="cardTitle">Profiles Views</div>
                            <div className="cardImage"><i className="far fa-eye"></i></div>
                        </div>
                        <div className="cardHeader">
                            <div className="cardCount">{totalProviders.length}</div>
                            <div className="cardTitle">Providers</div>
                            <div className="cardImage"><i className="fas fa-user-md"></i></div>
                        </div>
                    </div>
                    <div className="single-medical">
                        {
                            <ReactTable
                                data={Object.values(filteredClinics)}
                                columns={this.dataTableColumns()}
                                defaultPageSize={10}
                                pageSize={Object.values(filteredClinics).length < 10 ? Object.values(filteredClinics).length : 10}
                                showPageJump={false}
                                showPageSizeOptions={false}
                                // PaginationComponent={DataTablePagination}
                                className='custuomReactTable'
                            />
                        }

                    </div>

                </div>

            )
        }
    }

}

export default SelectFacility