import fire from 'firebase/app'
import 'firebase/database';


export const fetchSpecialities = () => {
    let clinicsRef = fire.firestore().collection('specialties');
    let data = {};

    return new Promise( ( resolve, reject ) => {
        clinicsRef.get().then(snapshot => {
            snapshot.forEach(spec=>{
                data[spec.id] = spec.data()
            })
            resolve(data);
        });
    });
};
