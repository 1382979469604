import React, { Component } from 'react';
import './PatientsReviews.css'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import Moment from 'moment'
class PatientsReviews extends Component {

    static propTypes = {
        clinicsList: PropTypes.object.isRequired,
        currentUser: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            filteredReviews: [],
        }
    }

    componentWillReceiveProps(nextProps) {
        let { allReviews } = this.props;
        let { filteredReviews } = this.state;

        if (nextProps) {
            allReviews = nextProps.allReviews;
        }
        filteredReviews = [];
        (allReviews ? allReviews : []).map(item => {
            filteredReviews.push(item)
        });
        this.setState({ filteredReviews })
    }

    render() {
        let { allReviews, doctorsArray } = this.props;
        let { filteredReviews } = this.state;

        if (doctorsArray.length) {
            filteredReviews = []
            allReviews.map(review => {
                doctorsArray.map(item => {
                    if (item.key === review.doctorId) {
                        filteredReviews.push(review)
                    }
                })
            })
        }

        return (
            <div style={{ height: '80%', padding: '40px' }}>
                {
                    filteredReviews.length ?
                        <Table singleLine style={{ boxShadow: '0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04)', borderRadius: '14px', overflow: 'hidden' }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Patient Name</Table.HeaderCell>
                                    <Table.HeaderCell>Appointment Date</Table.HeaderCell>
                                    <Table.HeaderCell>Doctor Name</Table.HeaderCell>
                                    <Table.HeaderCell>Comment</Table.HeaderCell>
                                    <Table.HeaderCell>Rating</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    filteredReviews.map((item, index) => {
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell>{item.patientName}</Table.Cell>
                                                <Table.Cell>{Moment(item.created).format('DD/MM/YYYY')}</Table.Cell>
                                                <Table.Cell>{item.doctorName}</Table.Cell>
                                                <Table.Cell>{item.comment}</Table.Cell>
                                                <Table.Cell>{((item.score.score_0 + item.score.score_1 + item.score.score_2 + item.score.score_3) / 4)+1}</Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </Table>
                        : <div style={{
                            height: '80%',
                            display: 'flex',
                            padding: '40px',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>No records found for selected range</div>
                }

            </div >
        )
    }




}

export default PatientsReviews