import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import fire from 'firebase/app'
import 'firebase/database';
import _ from 'lodash'
class ActionButtons extends Component {
    static propTypes = {
        clinicsList: PropTypes.object.isRequired,
        readyToStartChange: PropTypes.func.isRequired,
        clinicsStatus: PropTypes.number.isRequired
    }
    state = {
        loading: false,
        error: null
    }
    constructor(props, context) {
        super(props, context);
    }

    /**
     *
     */
    alertSuccess = (clinicKey) => {
        let _this = this
        Swal.fire({
            heightAuto: false,
            title: 'Saved Successfully',
            // text: 'Please check your email',
            type: 'success',
            confirmButtonText: 'ok',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.hideLoading()
            }
        }).then(function () {
            _this.props.dismiss(clinicKey)
            //window.location.href = '/#/appointment-system';
            // _this.props.readyToStartChange(false)
        }).catch(function () {
            _this.props.dismiss()

            // window.location.href = '/#/appointment-system';
            // _this.props.readyToStartChange(false)
        })
    }



    /**
     * @returns {null}
     */
    saveBookingInfo = (e, complete = false) => {
        let _this = this;
        if (!navigator.onLine) {
            return Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: "Please check internet connection and try again..",
                allowOutsideClick: true,
            });
        }
        Swal.fire({
            heightAuto: false,
            title: "Are you sure?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            text: "This will update your doctor's timings, you can always modify it later.",
            type: 'warning',
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    heightAuto: false,
                    title: "Please wait..",
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Swal.showLoading()
                        if (_this.state.error) {
                            Swal.fire({
                                heightAuto: false,
                                title: _this.state.error,
                                allowOutsideClick: false,
                                onOpen: () => {
                                    return null;
                                }
                            })
                        }
                    }
                });
                let clinicsList = _.cloneDeep(this.props.clinicsList);
                let { loading } = this.state;
                if (loading) return null;
                this.setState({ loading: true })

                Object.keys(clinicsList).map(function (item) {
                    let clinicData = clinicsList[item].selectedDoctors ? clinicsList[item].selectedDoctors : []

                    fire.firestore().collection('clinics').doc(clinicsList[item].facilityId).update({
                        bookingEmail: clinicsList[item].bookingEmail,
                        bookingNumber: clinicsList[item].bookingNumber,
                        bookingActive: true,
                        users: clinicsList[item].users && clinicsList[item].users.superAdmin ? clinicsList[item].users : { superAdmin: fire.auth().currentUser.uid }
                    }).then(() => {
                        // clinicData.map(doctor => {
                        //     if (doctor.bookingActive) {
                        //         return fire.firestore().collection('doctors').doc(doctor.doctorId ? doctor.doctorId : doctor.id).update({
                        //             // bookingActive: doctor.bookingActive,
                        //             doctorTimings: doctor.doctorTimings,
                        //             // duration: doctor.duration
                        //         }).then(() => {
                        //             return
                        //         })
                        //     }
                        // });
                        _this.setState({ loading: false }, () => {
                            _this.alertSuccess(clinicsList[item].facilityId)
                        })

                    })


                })


            }
        });
    }

    render() {
        const { clinicsStatus } = this.props;
        return (
            <div className="save-btn" style={{ paddingBottom: '0' }}>

                <div className="submit">
                    {(clinicsStatus === 1 || clinicsStatus === 2) ?
                        (
                            <button onClick={this.saveBookingInfo.bind(this, true)}>
                                Submit
                            </button>
                        ) : ''
                    }
                </div>
            </div>
        )
    }
}

export default ActionButtons