import { createAction, createReducer } from 'redux-action';
import { GetFacilityType, SetFacilityType } from './../services/SelectFacilityServices';

export const GET_FTYPES = 'GET_FTYPES ';
export const SET_FTYPES = 'SET_FTYPES ';

const getFacilityTypesAction = createAction(GET_FTYPES, () => {
    return GetFacilityType().then(data => {
        return data;
    }).catch(err => console.log('---', err));

});

const setFacilityTypesAction = createAction(SET_FTYPES, (type) => {
    return SetFacilityType(type).then(data => {
        return data;
    }).catch(err => console.log('---', err));
});
export const actions = {
    getFacilityTypes: getFacilityTypesAction,
    setFacilityTypes: setFacilityTypesAction,
};

const initialState = {
  data: {},
};

const facilityReducer = createReducer(initialState, {
  [GET_FTYPES]: (actionPayload, state) => {
    return { ...state, data: actionPayload };
  },
  [SET_FTYPES]: (actionPayload, state) => {
    return { ...state, data: actionPayload };
  }
});

export default facilityReducer;
