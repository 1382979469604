import React, { Component } from 'react';
import './ManagePatient.css'
import PropTypes from 'prop-types'
import fire from 'firebase/app'
import 'firebase/database';
import swal from 'sweetalert2';
import '../../sweetalert.css'
import { Icon } from 'semantic-ui-react';

class ManagePatient extends Component {
    static propTypes = {
        bookingItem: PropTypes.object.isRequired,
    }
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    arrivePatientClicked = (bookingitem) => {
        swal.fire({
            heightAuto: false,
            title: 'Patient status',
            showCancelButton: true,
            html: "<div class='appointmentDate'> <div class='text'>Are you sure you want to arrive patient: </div> <div class='date'>" + bookingitem.patientName + "</div></div>",
            customClass: 'reschedulesweetalert',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.value) {
                let timeStamp = Date.now()

                fire.firestore().collection((bookingitem.online ? 'bookings' : 'offlineBookings')).doc(bookingitem.bookingId).update({ status: "succeeded", succeeded: timeStamp, modified: timeStamp, modifiedBy: fire.auth().currentUser.uid })


                this.props.manageClicked(bookingitem)
            }
        });

    }
    noShowPatientClicked = (bookingitem) => {
        let _this = this
        swal.fire({
            heightAuto: false,
            title: 'Patient status',
            showCancelButton: true,
            html: "<div class='appointmentDate'> <div class='text'>Are you sure you want to set patient: </div> <div class='date'>" + "&nbsp" + bookingitem.patientName + "&nbsp" + "</div>  <div class='text'> as No Show </div></div>",
            customClass: 'reschedulesweetalert',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.value) {
                let timeStamp = Date.now()
                fire.firestore().collection((bookingitem.online ? 'bookings' : 'offlineBookings')).doc(bookingitem.bookingId).update({ isArchived: true, status: "failed", failed: timeStamp, modified: timeStamp, modifiedBy: fire.auth().currentUser.uid })
            }
        });

    }
    viewPatientProfile = (bookingitem) => {
        let doctorsKeys = this.props.doctors
        this.props.patientData({ doctorsKeys, acceptedPatientData: bookingitem })
    }
    compareDates(appointmentDate) {
        let today = new Date();
        let appointmentTime = new Date(appointmentDate);
        appointmentTime.setHours(appointmentTime.getHours() + (appointmentTime.getTimezoneOffset() / 60))
        if (today.getTime() < appointmentTime.getTime()) {
            return true;
        }
        return false;
    }
    render() {
        let { bookingItem } = this.props;
        let _this = this;
        return (

            <div className="managepatient">
                <div style={{ cursor: 'pointer' }} onClick={() => this.viewPatientProfile(bookingItem)}>
                    <div className="appointmentdate">{bookingItem.selectedDate.split(" at")[1]}</div>
                    <div className="tarekjamal" >{bookingItem.patientName}</div>
                    <div className="noteandresaonoft">{bookingItem.notes}</div>
                    <div className="drsamehsuliman">{bookingItem.doctorName}</div>
                </div>
                {
                    (this.compareDates(bookingItem.start)) ? (
                        <div className="shownoshow">
                            <div className="noshow" style={{ opacity: '0.3' }} onClick={() => swal.fire({
                                heightAuto: false,
                                title: 'Alert',
                                text: "You can't NoShow patient before appointment date!",
                                type: 'warning',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'Dismiss'
                            })}>No Show</div>
                            <div className="show" style={{ opacity: '0.3' }} onClick={() => swal.fire({
                                heightAuto: false,
                                title: 'Alert',
                                text: "You can't Arrive patient before appointment date!",
                                type: 'warning',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'Dismiss'
                            })}>Arrive patient</div>
                        </div>
                    ) : (
                        <div className="shownoshow">
                            <div className="noshow" onClick={() => _this.noShowPatientClicked(bookingItem)}>No Show</div>
                            <div className="show" onClick={() => _this.arrivePatientClicked(bookingItem)}>Arrive patient</div>
                        </div>
                    )
                }

                <div className="status">
                    {
                        (bookingItem.service === 'onlineConsult') ? (
                            <Icon style={{ color: '#4A4A4A' }} name='video' />
                        ) : ''
                    }
                    {
                        (bookingItem.online) ? (
                            <div className={bookingItem.thrive ? "thrive" : "online"}>{bookingItem.thrive ? "Thrive" : "online"}</div>
                        ) : ''
                    }
                    {
                        (bookingItem.new) ? (
                            <div className="group1">new</div>
                        ) : ''
                    }
                </div>

            </div>
        )
    }


}

export default ManagePatient