import React, { Component } from 'react';
import PropTypes from 'prop-types'
import fire from 'firebase/app'
import 'firebase/database';
import FullCalendar from 'fullcalendar-reactwrapper';
import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';
import Moment from 'moment';
import swal from 'sweetalert2';
import triangle from '../../../img/Triangle.png'

import { connect } from 'react-redux';
import { updateBookings } from '../../../modules/bookingsModule';
import '../sweetalert.css'
import hexToRgba from "hex-to-rgba";

class ManageBooking extends Component {

    static propTypes = {
        clinicsList: PropTypes.object.isRequired,
        currentUser: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        doctorKey: PropTypes.string.isRequired,
        updateParentState: PropTypes.func.isRequired,
        clinicDoctors: PropTypes.array,
        changeClinicStatus: PropTypes.func,

    }
    state = {
        allBooking: this.props.allBooking,
        countPatientsIds: {},
        scroll: 0,
        defaultView: 'agenda',
        defaultDate: new Date(),
        checked: true
    }
    constructor(props, context) {
        super(props, context);
    }
    removeDuplicates = (arr, created) => {
        return (
            arr.map((offlineItem, index) => {
                if (offlineItem.created === created) {
                    arr.splice(index, 1);
                }
            })
        )
    }

    convertTimestamp(timestamp) {
        if (!timestamp) return null;

        var d = new Date(timestamp);
        d.setHours(d.getHours() + (new Date()).getTimezoneOffset() / 60);
        let time = d.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
        return time;
    }
    getDate(eventStart) {
        let date = String(Moment(new Date(eventStart).setHours(new Date(eventStart).getHours() + (new Date()).getTimezoneOffset() / 60)).format('MMM DD, YYYY [at] h:mm A'));
        return date
    }
    getAge(age) {
        let today = new Date();
        let birthday = new Date(age);
        let ageDifference = today.getFullYear() - birthday.getFullYear();
        return ageDifference;
    }
    changeAppointmentDate(event) {

        let oldDate = event.selectedDate;
        let date = String(Moment(new Date(event.start).setHours(new Date(event.start).getHours() + (new Date()).getTimezoneOffset() / 60)).format('MMM DD, YYYY [at] hh:mm A'));
        let timeStamp = Date.now()
        let stringDate = date.replace(" at", ",");
        let optimizedDate = new Date(stringDate);
        let post = {
            accepted: (event.accepted) ? event.accepted : null,
            canceled: (event.canceled) ? event.canceled : null,
            created: event.created,
            date: date,
            dateTS: optimizedDate.getTime(),
            doctor: event.doctorId,
            facilityAdmin: event.facilityAdmin,
            facilityId: event.facilityId,
            facilityName: event.facilityName,
            insurance: event.insurance,
            patient: event.patientId,
            patientName: event.patientName,
            status: event.status,
            modified: timeStamp,
            duration: event.duration ? event.duration : '',
            modifiedBy: fire.auth().currentUser.uid
        }

        fire.firestore().collection(event.online ? 'bookings' : 'offlineBookings').doc(event.bookingId).update(post)


        let splittedDateOld = oldDate.split(' at ')
        let splittedDateNew = date.split(' at ')

        let slotDateOld = (splittedDateOld && splittedDateOld.length > 1) ? splittedDateOld[0] : "";
        let slotDatenew = (splittedDateNew && splittedDateNew.length > 1) ? splittedDateNew[0] : "";

        let slotTimeOld = (splittedDateOld && splittedDateOld.length > 1) ? splittedDateOld[1] : "";
        let slotTimeNew = (splittedDateNew && splittedDateNew.length > 1) ? splittedDateNew[1] : "";

        //calculate old slots
        let formatter = Moment(slotTimeOld, 'hh:mm A')
        let addedValueOld = 0
        let removeSlots = []
        formatter.get('minutes')
        while (addedValueOld < event.duration) {
            removeSlots.push(formatter.format('hh:mm A'))
            formatter.add(15, 'minutes')
            addedValueOld = addedValueOld + 15
        }
        //calculate new slots

        let newformatter = Moment(slotTimeNew, 'hh:mm A')
        let addedValueNew = 0
        let addedSlots = []
        newformatter.get('minutes')
        while (addedValueNew < event.duration) {
            addedSlots.push(newformatter.format('hh:mm A'))
            newformatter.add(15, 'minutes')
            addedValueNew = addedValueNew + 15
        }

        if (slotDateOld === slotDatenew) {
            let db = fire.firestore().collection('slots').doc(event.doctorId).collection(event.facilityId).doc(slotDateOld)
            fire.firestore().runTransaction(function (transaction) {
                return transaction.get(db).then(value => {
                    let slots = (value.data() && value.data()[slotDateOld]) ? value.data()[slotDateOld] : []
                    slots = slots.concat(addedSlots)
                    removeSlots.forEach(slot => {
                        let slotIndex = slots.indexOf(slot)
                        if (slotIndex > -1) {
                            slots.splice(slotIndex, 1)
                        }
                    })
                    transaction.set(db, { [slotDateOld]: slots }, { merge: true })
                })
            })
        } else {
            let db = fire.firestore().collection('slots').doc(event.doctorId).collection(event.facilityId).doc(slotDateOld)
            fire.firestore().runTransaction(function (transaction) {
                return transaction.get(db).then(value => {
                    let slots = (value.data() && value.data()[slotDateOld]) ? value.data()[slotDateOld] : []
                    removeSlots.forEach(slot => {
                        let slotIndex = slots.indexOf(slot)
                        if (slotIndex > -1) {
                            slots.splice(slotIndex, 1)
                        }
                    })
                    transaction.set(db, { [slotDateOld]: slots }, { merge: true })
                })
            }).then(() => {
                fire.firestore().runTransaction(function (transaction) {
                    db = fire.firestore().collection('slots').doc(event.doctorId).collection(event.facilityId).doc(slotDatenew)
                    return transaction.get(db).then(value => {
                        let slots = (value.data() && value.data()[slotDatenew]) ? value.data()[slotDatenew] : []
                        slots = slots.concat(addedSlots)
                        transaction.set(db, { [slotDatenew]: slots }, { merge: true })
                    })
                })
            })
        }
        if (event.thrive) {
            let activity = {
                ts: Date.now(),
                modifiedBy: fire.auth().currentUser.uid,
                type: 'booking',
                before: "accepted",
                after: "accepted",
                bookingId: event.bookingId,
                patientName: event.patientName,
                date: date,
                facilityId: event.facilityId
            }
            fire.firestore().collection('activityLog').add(activity).then((result) => {
            })
        }

    }

    arrivePatientClicked(bookingitem) {
        swal.fire({
            heightAuto: false,
            title: 'Patient status',
            showCancelButton: true,
            html: "<div class='appointmentDate'> <div class='text'>Are you sure you want to arrive patient: </div> <div class='date'>" + bookingitem.patientName + "</div></div>",
            customClass: 'reschedulesweetalert',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.value) {
                let timeStamp = Date.now()
                fire.firestore().collection(bookingitem.online ? 'bookings' : 'offlineBookings').doc(bookingitem.bookingId).update({ status: "succeeded", succeeded: timeStamp, modified: timeStamp, modifiedBy: fire.auth().currentUser.uid })
            }
        });

    }
    noShowPatientClicked = (bookingitem) => {
        swal.fire({
            heightAuto: false,
            title: 'Patient status',
            showCancelButton: true,
            html: "<div class='appointmentDate'> <div class='text'>Are you sure you want to set patient: </div> <div class='date'>" + "&nbsp" + bookingitem.patientName + "&nbsp" + "</div>  <div class='text'> as No Show </div></div>",
            customClass: 'reschedulesweetalert',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.value) {
                let timeStamp = Date.now()
                fire.firestore().collection(bookingitem.online ? 'bookings' : 'offlineBookings').doc(bookingitem.bookingId).update({ isArchived: true, status: "failed", failed: timeStamp, modified: timeStamp, modifiedBy: fire.auth().currentUser.uid })
            }
        });

    }

    facilities() {
        window.location.href = '/#/facility-management-system';
    }

    compareDates(appointmentDate) {
        let today = new Date();
        let appointmentTime = new Date(appointmentDate);
        appointmentTime.setHours(appointmentTime.getHours() + (appointmentTime.getTimezoneOffset() / 60))
        if (today.getTime() < appointmentTime.getTime()) {
            return true;
        }
        return false;
    }

    getBusinessHours = (doctorKey) => {
        const { clinicsList, activeBranchKey } = this.props

        var timmings = null
        if (doctorKey !== "") {
            const { clinicDoctors } = this.props
            let clinicId = clinicsList[activeBranchKey].facilityId
            let doctor = clinicDoctors.filter(item => {
                return item.id === doctorKey
            })
            if (doctor.length > 0 && doctor[0].doctorTimings && doctor[0].doctorTimings[clinicId]) {
                timmings = doctor[0].doctorTimings[clinicId]
            }
        } else {
            timmings = clinicsList[activeBranchKey].openingDays
        }

        var businessHours = []
        var weekDays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
        var calenderFromTime = "23:59:00"
        var calenderToTime = "00:00:00"

        if (timmings) {
            for (var counter = 0; counter < weekDays.length; counter++) {
                let weekDateTime = timmings[weekDays[counter]]
                if (weekDateTime && weekDateTime.opened && weekDateTime.opened === true) {
                    let from = Moment(weekDateTime.fromTime, 'hh:mm A').format("HH:mm");
                    let to = Moment(weekDateTime.toTime, 'hh:mm A').format("HH:mm");

                    if (Moment(calenderFromTime, 'hh:mm A').hours() > Moment(weekDateTime.fromTime, 'hh:mm A').hours()) {
                        if (Moment(weekDateTime.fromTime, 'hh:mm A').minutes() > 30) {
                            calenderFromTime = Moment(weekDateTime.fromTime, 'hh:mm A').set({ "m": 30 }).format("HH:mm")
                        } else {
                            calenderFromTime = Moment(weekDateTime.fromTime, 'hh:mm A').set({ "m": 0 }).format("HH:mm")
                        }
                    }
                    switch (weekDateTime.isTwentyFour && weekDateTime.isTwentyFour === true) {
                        case true:
                            businessHours.push({
                                dow: [counter],
                                start: "00:00",
                                end: "23:59"
                            })
                            break
                        default:
                            switch (weekDateTime.isSplitShift && weekDateTime.isSplitShift === true) {
                                case true:
                                    if (from !== "Invalid date" && to !== "Invalid date") {
                                        businessHours.push({
                                            dow: [counter],
                                            start: from,
                                            end: to
                                        })
                                    }

                                    let pmFrom = Moment(weekDateTime.pmFromTime, 'hh:mm A').format("HH:mm");
                                    let pmTo = Moment(weekDateTime.pmToTime, 'hh:mm A').format("HH:mm");
                                    if (Moment(calenderToTime, 'HH:mm').hours() < Moment(weekDateTime.pmToTime, 'hh:mm A').hours()) {
                                        calenderToTime = pmTo
                                    }
                                    if (pmFrom && pmTo) {
                                        businessHours.push({
                                            dow: [counter],
                                            start: pmFrom,
                                            end: pmTo
                                        })
                                    }

                                    break;
                                default:
                                    if (Moment(calenderToTime, 'HH:mm').hours() < Moment(weekDateTime.toTime, 'hh:mm A').hours()) {
                                        calenderToTime = to
                                    }
                                    if (from !== "Invalid date" && to !== "Invalid date") {
                                        businessHours.push({
                                            dow: [counter],
                                            start: from,
                                            end: to
                                        })
                                    }

                            }
                            break;
                    }
                }
                if (weekDateTime && weekDateTime.isTwentyFour && weekDateTime.isTwentyFour === true) {
                    calenderFromTime = "00:00"
                    calenderToTime = "23:59"
                }
            }
            return ({ businessHours: businessHours, calenderFromTime: calenderFromTime, calenderToTime: calenderToTime })
        }
    }
    render() {

        let _this = this;
        let { doctorKey, allBooking, clinicsList, activeBranchKey, clinicDoctors } = this.props;
        let toggle = true;
        var bookings = []
        let patientsIds = []

        bookings = (allBooking ? allBooking : []).filter(item => {
            return (item.status === "accepted")
        });

        (allBooking ? allBooking : []).map((item) => {
            patientsIds.push(item.patientId)

        })
        var count = {};
        patientsIds.forEach(function (i) { count[i] = (count[i] || 0) + 1; });
        bookings.map((item, ) => {
            if (count[item.patientId] > 1) {
                return (
                    item.new = false
                )
            } else {
                return (
                    item.new = true
                )
            }

        })

        let filteredBookings = []
        if (doctorKey !== '') {
            bookings.map(item => {
                if (item.doctorId === doctorKey) {
                    filteredBookings.push(item)
                }
            })
        }
        const workingDays = _this.getBusinessHours(doctorKey)
        return (
            <div className="fullcalendar">
                <FullCalendar
                    nowIndicator={true}
                    id='calender'
                    header={{
                        left: '',
                        right: 'prev title next today month agendaWeek basicDay Settings'
                    }}
                    customButtons={
                        {
                            Settings: {
                                text: 'Settings',
                                click: function () {
                                    var EditDoctors = document.createElement('div');
                                    EditDoctors.innerText = 'Online booking setup'
                                    EditDoctors.onclick = () => { _this.props.changeClinicStatus(2); }
                                    EditDoctors.style = 'text-align:start; padding-left:10px; border-bottom: 1px solid #D8D8D8; height: 100%; display: grid; align-content: center; font-size:12px'
                                    // var EditClinics = document.createElement('div');
                                    // EditClinics.innerText = 'Add / Edit Clinics'
                                    // EditClinics.onclick = () => { _this.facilities() }
                                    // EditClinics.style = 'text-align:start; padding-left:10px; border-bottom: 1px solid #D8D8D8; height: 100%; display: grid; align-content: center; font-size:12px'
                                    var EditTimings = document.createElement('div');
                                    EditTimings.innerText = 'Edit doctors timings'
                                    EditTimings.onclick = () => { _this.props.changeClinicStatus(2); }
                                    EditTimings.style = 'text-align:start; padding-left:10px; height: 100%; display: grid; align-content: center; font-size:12px'

                                    // var container = document.createElement('DIV');
                                    // container.innerHTML = '<div style="padding-right:20px;">Hide Discharge / Canceled</div><div class="ui ' + (_this.state.checked ? 'checked' : '') + ' fitted toggle checkbox"><input type="radio" ' + (_this.state.checked ? 'checked' : '') + ' class="hidden" readonly="" tabindex="0" /><label></label></div>'
                                    // container.onclick = () => { _this.props.hideExtraData(!_this.state.checked); _this.setState({ checked: !_this.state.checked }); }
                                    // container.style = 'text-align:start; padding-left:10px; height: 100%; display: flex; align-content: center; font-size:12px; align-items:center; pa'

                                    var iDiv = document.createElement('div');
                                    iDiv.className = 'settingspopup'
                                    iDiv.style = 'width: 247px; border-radius: 8px; height: 50px; display: grid; align-items: center; position: absolute; box-shadow: #D4D2D2 0px 0px 14px; background-color: rgb(255, 255, 255); top: 38px; right: 0; z-index: 100;cursor:pointer'
                                    iDiv.appendChild(EditDoctors);
                                    // iDiv.appendChild(EditClinics);
                                    // iDiv.appendChild(container);

                                    let parent = document.getElementsByClassName('fc-toolbar fc-header-toolbar');
                                    if (toggle) {
                                        parent[0].childNodes[1].appendChild(iDiv);
                                    } else {
                                        let right = document.getElementsByClassName('fc-right');
                                        right[0].removeChild(right[0].childNodes[8])
                                    }
                                    toggle = !toggle


                                }
                            }
                        }
                    }

                    dayClick={function (date, jsEvent, view) {
                        if (process.env.REACT_APP_OFFLINE_ENABLED){
                            let doctorsKeys = clinicsList[activeBranchKey].doctors
                            let scroll = document.querySelector('.fc-scroller').scrollTop;
                            _this.props.patientData({ date: date, doctorsKeys, clinicDoctors })
                            _this.setState({ defaultView: view.name, defaultDate: view.start, scroll })
                        }
                    }}
                    eventRender={function (event, element, view) {
                        element.find('.fc-content').append('<div class="topside"><div class="patient-name">' + event.patientName + '</div><div class="status">' + (event.online ? '<div class=' + (event.thrive ? '"thrive"' : '"online"') + '"online">' + (event.thrive ? 'Thrive' : 'online') + '</div>' : '') + (event.new ? '<div class="new">new</div>' : '') + '</div></div>');
                        element.find('.fc-content').append('<div class="bottomside"><div class="dr-name">' + event.doctorName + '</div><div class="time">' + _this.convertTimestamp(event.start) + '</div></div>');
                        element.find('.fc-content').append('<div class="patientpopupinfo"><img class="rectangle" src=' + triangle + ' /><div class="viewbutton">View</div><div class="tarekjamal">' + event.patientName + '</div><div class="noteandresaonoft">' + event.notes + '</div><div class="drsamehsuliman">' + event.doctorName + '</div><div class="appointment">Appointment: <div class="appointmentdate">' + _this.getDate(event.start) + '</div></div><div class="shownoshow"><div class="noshow">No Show</div><div class="rectangle1"></div><div class="show">Arrive patient</div></div></div>');
                        if (_this.compareDates(event.start)) {
                            element.find('.noshow')[0].style.opacity = "0.3";
                            element.find('.show')[0].style.opacity = "0.3";
                            element.find('.noshow')[0].onclick = () => {
                                swal.fire({
                                    heightAuto: false,
                                    title: 'Alert',
                                    text: "You can't No Show patient before appointment date!",
                                    type: 'warning',
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'Dismiss'
                                })
                            };
                            element.find('.show')[0].onclick = () => {
                                swal.fire({
                                    heightAuto: false,
                                    title: 'Alert',
                                    text: "You can't Arrive patient before appointment date!",
                                    type: 'warning',
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'Dismiss'
                                })
                            };

                        } else {
                            element.find('.noshow')[0].onclick = () => { _this.noShowPatientClicked(event) };
                            element.find('.show')[0].onclick = () => { _this.arrivePatientClicked(event) };

                        }
                        element.find('.noteandresaonoft')[0].onclick = () => {
                            let doctorsKeys = clinicsList[activeBranchKey].doctors
                            let scroll = document.querySelector('.fc-scroller').scrollTop;
                            _this.props.patientData({ acceptedPatientData: event, doctorsKeys, clinicDoctors })
                            _this.setState({ defaultView: view.name, defaultDate: view.start, scroll })
                        };
                        element.find('.topside')[0].onclick = () => {
                            let doctorsKeys = clinicsList[activeBranchKey].doctors
                            let scroll = document.querySelector('.fc-scroller').scrollTop;
                            _this.props.patientData({ acceptedPatientData: event, doctorsKeys, clinicDoctors })
                            _this.setState({ defaultView: view.name, defaultDate: view.start, scroll })
                        };
                        element.find('.bottomside')[0].onclick = () => {
                            let doctorsKeys = clinicsList[activeBranchKey].doctors
                            let scroll = document.querySelector('.fc-scroller').scrollTop;
                            _this.props.patientData({ acceptedPatientData: event, doctorsKeys, clinicDoctors })
                            _this.setState({ defaultView: view.name, defaultDate: view.start, scroll })
                        };

                        element.find('.fc-content')[0].style.background = hexToRgba(event?.doctorColor || '#fff', 0.7)
                        element[0].style.borderLeft = '7px solid'
                        element[0].style.borderLeftColor = event.doctorColor

                        let startDate = new Date(event.start)
                        startDate.setHours(startDate.getHours() + (startDate.getTimezoneOffset() / 60))

                        if ((new Date(view.activeRange.start).getDay() - startDate.getDay()) >= 2 || new Date(view.activeRange.start).getDay() - startDate.getDay() < -2) {
                            element.find('.patientpopupinfo')[0].style.right = '60%'
                            element.find('.rectangle')[0].style.left = '99%'
                            element.find('.rectangle')[0].style.transform = 'rotate(180deg)'

                        } else {
                            element.find('.patientpopupinfo')[0].style.left = '60%'
                            element.find('.rectangle')[0].style.right = '99%'
                        }

                        if ((new Date(view.activeRange.start).getDay() - startDate.getDay()) == 6) {
                            element.find('.patientpopupinfo')[0].style.left = '60%'
                            element.find('.rectangle')[0].style.right = '99%'
                            element.find('.rectangle')[0].style.left = '-4%'
                            element.find('.rectangle')[0].style.transform = 'rotate(360deg)'

                        }
                    }}

                    eventDrop={function (info, delta, revertFunc, nul1, nul2, view) {

                        let scroll = document.querySelector('.fc-scroller').scrollTop;

                        swal.fire({
                            heightAuto: false,
                            title: 'Appointment reschedule',
                            html: "<div class='title'>Appointment for " + info.patientName + " will be rescheduled for</div> <div class='appointmentDate'> <div class='date'>" + _this.getDate(info.start) + "</div> <div class='text'>, do you want to confirm?</div></div>",
                            customClass: 'reschedulesweetalert',
                            confirmButtonText: 'Confirm and notify',
                            cancelButtonText: 'Cancel',
                            showCancelButton: true,

                        }).then((result) => {
                            if (result.value) {
                                _this.changeAppointmentDate(info)
                                _this.setState({ defaultView: view.name, defaultDate: view.start, scroll })
                            } else {
                                revertFunc()
                                _this.setState({ defaultView: view.name, defaultDate: view.start, scroll })
                            }
                        }, function (dismiss) {
                            revertFunc()
                            _this.setState({ defaultView: view.name, defaultDate: view.start, scroll })
                        });

                    }}

                    // eventResize={function (info, delta, revertFunc) {
                    //     swal.fire({
                    //         title: 'Appointment reschedule',
                    //         showCancelButton: true,
                    //         html: "<div class='title'>Appointment for " + info.patientName + " will be rescheduled for</div> <div class='appointmentDate'> <div class='date'>" + _this.getDate(info.start) + "</div> <div class='text'>, do you want to confirm?</div></div>",
                    //         customClass: 'reschedulesweetalert',
                    //         cancelButtonText: 'Cancel',
                    //         confirmButtonText: 'Confirm and notify',
                    //     }).then((result) => {
                    //         if (result) {
                    //             _this.changeAppointmentDate(info)
                    //         }
                    //     }, function (dismiss) {
                    //         revertFunc()
                    //     });
                    // }}
                    minTime={workingDays ? workingDays.calenderFromTime + ":00" : "00:00:00"}
                    maxTime={workingDays ? workingDays.calenderToTime + ":00" : "24:00:00"}
                    businessHours={workingDays ? workingDays.businessHours : []}
                    defaultDate={_this.state.defaultDate}
                    defaultView={_this.state.defaultView}
                    dayCount={'6'}
                    allDaySlot={false}
                    slotDuration={'00:15'}
                    navLinks={true}
                    editable={true}
                    eventDurationEditable={false}
                    eventLimit={true}
                    events={(doctorKey === '') ? bookings : filteredBookings}
                    scrollTime={'00:00:00'}
                    viewRender={function () {
                        setTimeout(function () {
                            if (document.querySelector('.fc-scroller')) {
                                (document.querySelector('.fc-scroller').scrollTop = _this.state.scroll)
                            }
                        }, 1);
                    }}
                />
            </div>
        )
    }


}

function mapStateToProps(state) {
    return {
        clinics: state.clinics.data,
        user: state.user.data.user,
        branches: state.branches,
        clinicsKeys: state.clinicsKeys,
        // Bookings: state.Bookings
    }
}

const mapDispatchToProps = dispatch => ({
    // getBookings: (data, onlineBookingList, clinicDoctors, oldList) => dispatch(getBookings(data, onlineBookingList, clinicDoctors, oldList)),
    updateBookings: (updatedList) => dispatch(updateBookings(updatedList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageBooking)