import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PatientsReviews from './steps/PatientsReviews'
import Filter from './steps/filter'
import _ from 'lodash'
import fire from 'firebase/app'
import 'firebase/database';
import 'firebase/auth';
import { connect } from 'react-redux';
import { Dimmer } from 'semantic-ui-react';

const startDate = new Date();
startDate.setDate(1);
startDate.setMonth(startDate.getMonth() - 1);

class MainWizard extends Component {

    static propTypes = {
        wizardOptions: PropTypes.shape({
            branches: PropTypes.isRequired,
        }).isRequired,
        selectFacility: PropTypes.shape({
            readyToStartChange: PropTypes.func.isRequired,
        }).isRequired,
        currentUser: PropTypes.object.isRequired,
        clinicsStatus: PropTypes.number.isRequired,

    };

    state = {
        clinicsList: {},
        activeBranchKey: 'branch1',
        currentStep: 1,
        logoUrl: null,
        doctorData: this.props.doctorData,
        clinicDoctors: this.props.clinicDoctors ? this.props.clinicDoctors : [],
        allReviews: [],
        onlinearray: [],
        clinicDoctorsarray: [],
        loading: true,
        dateRange: [startDate.getTime(), new Date().getTime()],
        doctorsArray: []
    };
    constructor(props, context) {
        super(props, context);
        this.handleStateChangeMain = this.handleStateChangeMain.bind(this)
    }

    componentWillMount = () => {

        let _this = this;
        const { currentUser } = this.props;
        let listOfBranches = {};
        let { draftList } = _this.props;

        for (let i = 1; i <= this.props.wizardOptions.branches; i++) {
            listOfBranches['branch' + i] = {
                facilityName: '',
                facilityName_ar: '',
                created: '',
                doctors: [],
                selectedDoctors: [],
                facilityId: Object.keys(draftList)[0],
                status: 'new',
                userId: currentUser.id,
                //Global
                logoUrl: false,
                haveError: true,
                users: {
                    superAdmin: currentUser.id
                },
            }
        }
        this.setState({
            clinicsList: listOfBranches
        }, () => {
            let { clinicsList } = this.state;

            this.setState(prevState => ({
                ...prevState,
                clinicsList: clinicsList
            }), () => {
                let { draftList } = _this.props;
                let { clinicsList } = _this.state;
                if (draftList) {

                    Object.keys(draftList).map(function (item, index) {
                        return Object.keys(draftList[item]).map(function (subItem) {
                            clinicsList[Object.keys(clinicsList)[index]][subItem] = draftList[item][subItem];
                            if (subItem === 'logoUrl') {
                                _this.setState({
                                    logoUrl: draftList[item].logoUrl
                                })
                            }

                        })

                    })
                    _this.setState({ clinicsList: clinicsList })
                    _this.getReviewsArray(clinicsList)

                }
            })
        })
        window.addEventListener('offline', this.handleStateChangeMain);
        window.addEventListener('online', this.handleStateChangeMain);

    }
    componentWillUnmount() {
        window.removeEventListener('offline', this.handleStateChangeMain)
        window.removeEventListener('online', this.handleStateChangeMain)

    }
    handleStateChangeMain() {
        this.setState({ offline: !window.navigator.onLine })
    }


    getReviewsArray = (clinicsList) => {
        let { activeBranchKey, dateRange } = this.state;
        let clinicDoctors = []
        let _this = this

        let facilityId = clinicsList[activeBranchKey] ? clinicsList[activeBranchKey].facilityId : null
        let superAdmin = clinicsList[activeBranchKey].users ? clinicsList[activeBranchKey].users.superAdmin : ''

        if (facilityId && superAdmin) {
            clinicDoctors = this.props.branches.data[facilityId].doctorsData
        } else {
            return
        }
        if (clinicsList && activeBranchKey) {
            var reviews = []
            var onlineCount = 0
            fire.firestore().collection('reviews').where('facilityId', '==', facilityId).where('created', '>=', new Date(dateRange[0]).getTime()).where('created', '<=', new Date(dateRange[1]).getTime()).get().then(reviewSnap => {
                if (reviewSnap.empty) {
                    return
                }

                reviewSnap.docChanges().forEach((record) => {
                    if (record.type === "added") {
                        fire.firestore().collection('doctors').doc(record.doc.data().doctorId).get().then(function (doc) {
                            if (doc.exists) {
                                let value = record.doc.data();
                                value.doctorName = doc.data().name
                                reviews.push(value)

                                onlineCount++
                                if (onlineCount >= reviewSnap.size) {
                                    _this.setState({ allReviews: reviews, clinicDoctorsarray: clinicDoctors })
                                }
                            } else {
                                // doc.data() will be undefined in this case
                            }
                        }).catch(function (error) {
                            console.log("Error getting document:", error);
                        });


                    }

                })

            })
            _this.setState({ clinicDoctorsarray: clinicDoctors })

        }

    }

    render() {
        let { clinicsList, offline } = this.state;
        const { clinicsStatus } = this.props;
        return (
            <div style={{ height: 'calc( 100%)' }}>
                {
                    offline && <Dimmer active />
                }
                {
                    (clinicsStatus === 1) ? (
                        <section data-branches-length={Object.keys(clinicsList).length} id="facility-page" style={{ padding: '0', height: '100%' }}>

                            <div className="tab-content sub-pages" id="myTabContent" style={{ overflow: 'auto', width: '100%' }}>

                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" style={{ height: '100%' }}>
                                    <div className="main-side" style={{ width: '100%', height: '100%', padding: '0', margin: '0' }}>
                                        {this.renderRightSteps()}
                                        {this.renderStep()}
                                    </div >
                                </div >
                            </div >
                        </section>
                    ) : ''
                }
            </div >
        )
    }

    /**
     *
     * @returns {XML}
     */
    renderStep = () => {
        let { clinicsList, activeBranchKey, allReviews, dateRange, doctorsArray } = this.state;
        const { currentUser, clinicsStatus, clinicDoctors } = this.props;
        switch (clinicsStatus) {
            case 1:
                return <PatientsReviews
                    activeBranchKey={activeBranchKey}
                    clinicsList={clinicsList}
                    currentUser={currentUser}
                    clinicDoctors={clinicDoctors}
                    allReviews={allReviews}
                    dateRange={dateRange}
                    doctorsArray={doctorsArray}
                />;
        }
    }
    renderRightSteps = () => {
        let { clinicsList } = this.state;
        const { clinicsStatus, clinicDoctors } = this.props;
        switch (clinicsStatus) {
            case 1:
                return <Filter
                    selectDateRange={this.props.selectDateRange}
                    dateRange={(dateRange) => {
                        this.setState({ dateRange }, () => {
                            if (dateRange.length > 1) {
                                console.log("dateRangedateRange", new Date(dateRange[0]))
                                console.log("dateRangedateRange", new Date(dateRange[1]))
                                this.getReviewsArray(clinicsList)
                            }
                        })
                    }}
                    doctorsArray={(doctorsArray) => { this.setState({ doctorsArray }) }}
                    clinicDoctors={clinicDoctors}
                />;
        }

    }

}

function mapStateToProps(state) {
    return {
        clinics: state.clinics.data,
        user: state.user.data.user,
        branches: state.branches,
        clinicsKeys: state.clinicsKeys,
        Bookings: state.Bookings
    }
}


export default connect(mapStateToProps)(MainWizard)