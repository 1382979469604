import React, { Component } from 'react';
import PropTypes from 'prop-types'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'react-image-crop/lib/ReactCrop.scss';
import Masonry from 'react-masonry-component';
import { Dropdown, Input } from 'semantic-ui-react'
import { connect } from 'react-redux';
import {
    searchInArrayWithObjects,
    searchKeyInArrayWithObjects,
    toObjectIns,
    searchKeySelectedInsurances,
} from '../../../helpers/Full';

class BasicInfo extends Component {

    static propTypes = {
        doctorObject: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        updateParentState: PropTypes.func.isRequired,
        fileTypes: PropTypes.array.isRequired,
    }
    state = {
        errors: {},
        pic: null,
        specialties: {},

        defaultClinics: [],
        allClinics: [],
        clinics: {},
        selectedClinics: [],


        Conditions: [],
        services: [],
        currentTextCon: '',
        currentTextSer: '',
        canAddCon: false,
        canAddSer: false,
        defaultConditions: [],
        defaultServices: [],
        topConditions: [],

        user: null,
        loggedIn: false,
        claimingPersonName: '',
        suggestionId: null,
        branches: 1,

        canAdd: false,
        currentText: '',

        titles: {
            GP: 'G.P',
            Specialist: 'Specialist',
            Consultant: 'Consultant',
        }
    }

    constructor(props) {
        super(props);
    }


    chechClinicStatus = () => {
        let { user, accessType } = this.props.user.data

        const _this = this;
        let { defaultClinics } = this.state;
        const { activeBranchKey, branches } = this.props;
        let doctorObject = JSON.parse(JSON.stringify(this.props.doctorObject))
        let clinics = { [activeBranchKey]: [] }
        let allClinics = { [activeBranchKey]: [] }
        let selectedClinics = []

        Object.keys(branches).map(key => {
            let val = JSON.parse(JSON.stringify(branches[key]));
            val.id = key;
            if (val.selectedSpecialties) {
                val.selectedSpecialties = toObjectIns(val.selectedSpecialties)
            }
            defaultClinics.push(val)

            let values = doctorObject[activeBranchKey].clinics[key];
            if (val.selectedSpecialties && values) {
                Object.keys(val.selectedSpecialties).map((trueSpecialities) => {
                    if (values.includes(trueSpecialities)) {
                        val.selectedSpecialties[trueSpecialities] = true
                    }
                })
                selectedClinics.push([key, val.selectedSpecialties]);
            }

            let index = searchKeySelectedInsurances(key, selectedClinics);
            if (index === undefined) {
                clinics[activeBranchKey].push({
                    value: key,
                    text: val.facilityName,
                    key: key,
                });
                allClinics[activeBranchKey].push(val)
            }

        })
        _this.setState({
            clinics: clinics,
            allClinics: allClinics,
            defaultClinics: defaultClinics,
            selectedClinics: selectedClinics
        });
        doctorObject[activeBranchKey].doctorSelectedClinics = selectedClinics
        _this.props.updateParentState(doctorObject)
    }

    /**
     *
     * @param arabic
     * @param e
     */
    changeNameDefault = (arabic = false, e) => {
        let val = e.target.value
        let { doctorObject, activeBranchKey } = this.props;
        if (arabic) {
            doctorObject[activeBranchKey].name_ar = val

        } else {
            doctorObject[activeBranchKey].name = val.charAt(0).toUpperCase() + val.slice(1);
        }
        this.props.updateParentState(doctorObject);
        this.setState({ updated: Date.now() })
    }

    componentWillMount = () => {
        let { user } = this.props.user.data
        if (user) {
            this.chechClinicStatus()
            this.getSpecialityNameById();
            this.getConditionsLists();
            this.getServicesLists();
        }
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    render() {
        const { activeBranchKey, doctorObject } = this.props;
        const { selectedClinics } = this.state;
        doctorObject[activeBranchKey].doctorSelectedClinics = selectedClinics;
        var generalPracticeExist = selectedClinics.filter(item => {
            if (item.length > 1 && item[1]["-Kq3DMB5D2RDecX9YF07"]) {
                return item
            }
        });
        return (
            <div>
                {this.renderName()}
                {this.renderFindClinics()}
                {
                    !generalPracticeExist.length ? this.renderTitle() : ''
                }
                {this.renderConditions()}
                {this.renderServices()}
                {this.renderConsultation()}
            </div >
        )
    }

    /**
     * Name (& ar)
* @returns {XML}
        */
    renderName = () => {
        const { activeBranchKey, doctorObject } = this.props;

        return (
            <div className="name-input box" >
                <div>
                    <label>Full Name <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                    <Input
                        style={{ width: '98%' }}
                        error={!doctorObject[activeBranchKey].name.length && doctorObject[activeBranchKey].showError}
                        type="text"
                        name="name"
                        placeholder="Input name"
                        value={doctorObject[activeBranchKey].name}
                        onChange={this.changeNameDefault.bind(this, false)} />
                </div>
                <div className="arabic-input">
                    <label><span style={{ color: 'darkred', fontSize: '14px' }}>*</span> الاسم الكامل</label>
                    <Input style={{ width: '98%', textAlign: 'right' }} error={!doctorObject[activeBranchKey].name_ar.length && doctorObject[activeBranchKey].showError} type="text" name="name_ar" placeholder="Input Arabic name" value={doctorObject[activeBranchKey].name_ar} onChange={this.changeNameDefault.bind(this, true)} />
                </div>

            </div>
        )
    }



    /**
        *
        * @param id
        */
    addClinic = (e, { value }) => {
        let { allClinics, clinics, selectedClinics } = this.state,
            { doctorObject, activeBranchKey } = this.props,
            index = searchKeyInArrayWithObjects(value, 'id', allClinics[activeBranchKey]);
        let selectedSpecialties = allClinics[activeBranchKey][index].selectedSpecialties;
        selectedClinics.push([value, selectedSpecialties]);

        clinics[activeBranchKey].splice(index, 1);
        allClinics[activeBranchKey].splice(index, 1);
        this.setState({
            clinics: clinics,
            currentText: '',
            selectedClinics: selectedClinics,

        })
        doctorObject[activeBranchKey].doctorSelectedClinics = selectedClinics
        this.props.updateParentState(doctorObject);
    }

    /**
     *
 * @returns {XML}
            */

    renderFindClinics = () => {
        let { clinics, currentText, specialties } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let { selectedClinics } = this.state;
        var selectedClinic = doctorObject[activeBranchKey].clinics;
        selectedClinics.map((item, index) => {
            var selectedSpeciality = []

            Object.keys(item[1]).map((innerIndex) => {
                if (item[1][innerIndex]) {
                    selectedSpeciality.push(innerIndex)
                }
            })
            selectedClinic[item[0]] = selectedSpeciality
        })

        return (
            <div className="doctor-gray-box box">
                <label className="section-title">Facility <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                <p className="sub-title">Pick all the facilities and specialities for this provider</p>
                <Dropdown
                    error={!selectedClinics.length && doctorObject[activeBranchKey].showError}
                    selection
                    fluid
                    options={clinics[activeBranchKey]}
                    // noResultsMessage="Can't find this Facility!"
                    placeholder="Type facility name"
                    className="selectiondropdown"
                    upward={false}
                    value={currentText}
                    search
                    selectOnBlur={false}
                    onChange={this.addClinic} />

                <div className="wizardMain" style={{ top: '15px', position: 'relative' }}>
                    <div className="Insurances">
                        <div className="selectedSpecialties ins" >
                            <div className="inputWrap">
                                <div className="items flexBox align_justify_left">
                                    <Masonry>
                                        {
                                            (selectedClinics) ? (
                                                selectedClinics.map((item, index) => (
                                                    <div key={this.getNameById(item[0], 'defaultClinics') + '_' + index} className="itemParent flexBox">
                                                        <div title={'Remove ' + this.getNameById(item[0], 'defaultClinics')} onClick={this.removeSelectedClinics.bind(this, item)} className="item flexBox">
                                                            <span>{this.getNameById(item[0], 'defaultClinics')}</span>
                                                            <span>x</span>
                                                        </div>
                                                        {
                                                            item[1] ? (
                                                                <div className="networks">
                                                                    {
                                                                        Object.keys(item[1]).map((net) => (
                                                                            <p title={net} data-checked={item[1][net]} className="flexBox align_justify_left itemIns" onClick={this.addSpecialityToClinic.bind(this, index, net)} key={net + '_' + index}><span className="check" />
                                                                                <span style={{ width: '80%' }}>{specialties[net]}</span>
                                                                            </p>
                                                                        ))
                                                                    }
                                                                </div>
                                                            ) : ''
                                                        }
                                                    </div>
                                                ))
                                            ) : ''
                                        }
                                    </Masonry>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    /**
     * @param id_networks_array
     */
    removeSelectedClinics = (id_networks_array) => {
        let { allClinics, clinics, selectedClinics } = this.state,
            { doctorObject, activeBranchKey } = this.props,
            index = selectedClinics.indexOf(id_networks_array),
            index2 = Object.keys(doctorObject[activeBranchKey].clinics).indexOf(id_networks_array[0]),

            name = this.getNameById(id_networks_array[0], 'defaultClinics');

        if (index > -1) {

            selectedClinics.splice(index, 1);
            let val = { text: name, value: id_networks_array[0] };
            let val2 = { name: name, id: id_networks_array[0], selectedSpecialties: id_networks_array[1] };
            clinics[activeBranchKey].push(val);

            allClinics[activeBranchKey].push(val2)
        }


        if (index2 > -1) {
            delete doctorObject[activeBranchKey].clinics[id_networks_array[0]]
        }

        this.setState({
            clinics: clinics,
            allClinics: allClinics,
            selectedClinics: selectedClinics
        })
        doctorObject[activeBranchKey].modifiedFalg = true

        this.props.updateParentState(doctorObject);
    }


    addSpecialityToClinic = (insKey, networkIndex) => {
        let { selectedClinics } = this.state;
        let { activeBranchKey, doctorObject } = this.props,
            activeBranch = doctorObject[activeBranchKey];

        selectedClinics = selectedClinics.slice(0);
        selectedClinics[insKey][1][networkIndex] = !selectedClinics[insKey][1][networkIndex];
        doctorObject[activeBranchKey].modifiedFalg = true

        this.props.updateParentState(doctorObject);
        this.setState({ updated: Date.now() })

    }
    /**
      *
      * @param id
      * @param where
      */
    getNameById = (id, where) => {
        let x = this.state[where].filter(function (v) {
            return v.id === id; // Filter out the appropriate one
        })[0];
        if (x) {
            return x.facilityName
        }
    };

    getSpecialityNameById = () => {
        const allSpecialties = this.props.specialties;
        let specialties = {}
        allSpecialties && Object.keys(allSpecialties).map(item => {
            let data = allSpecialties[item]
            specialties[item] = data.name
        })
        this.setState({
            specialties: specialties
        })

    };

    changeDocTitle = (e) => {
        const { activeBranchKey } = this.props;
        let doctorObject = this.props.doctorObject;
        doctorObject[activeBranchKey].title = e.target.id;
        this.props.updateParentState(doctorObject);
        this.setState({ updated: Date.now() })


    }
    renderTitle = () => {
        const { activeBranchKey } = this.props;
        let doctorObject = this.props.doctorObject;
        return (
            <div className="box button-box" style={{ paddingTop: '16px' }}>
                <label>Title</label>
                {(doctorObject[activeBranchKey].title === 'G.P') ? (
                    <div>
                        <button className="color-btn" onClick={this.changeDocTitle} id="G.P">G.P</button>
                        <button className="normal-btn" onClick={this.changeDocTitle} id="Specialist">Specialist</button>
                        <button className="normal-btn" onClick={this.changeDocTitle} id="Consultant">Consultant</button>
                    </div>
                ) : (doctorObject[activeBranchKey].title === 'Specialist') ? (
                    <div>
                        <button className="normal-btn" onClick={this.changeDocTitle} id="G.P">G.P</button>
                        <button className="color-btn" onClick={this.changeDocTitle} id="Specialist">Specialist</button>
                        <button className="normal-btn" onClick={this.changeDocTitle} id="Consultant">Consultant</button>
                    </div>
                ) : (doctorObject[activeBranchKey].title === 'Consultant') ? (
                    <div>
                        <button className="normal-btn" onClick={this.changeDocTitle} id="G.P">G.P</button>
                        <button className="normal-btn" onClick={this.changeDocTitle} id="Specialist">Specialist</button>
                        <button className="color-btn" onClick={this.changeDocTitle} id="Consultant">Consultant</button>
                    </div>
                ) : <div>
                                <button className="normal-btn" onClick={this.changeDocTitle} id="G.P">G.P</button>
                                <button className="normal-btn" onClick={this.changeDocTitle} id="Specialist">Specialist</button>
                                <button className="normal-btn" onClick={this.changeDocTitle} id="Consultant">Consultant</button>
                            </div>
                }
            </div>

        )
    }

    onSelectInputCon = (e) => {
        if (e) {
            let _this = this;
            if (!e.length) return;
            setTimeout(function () {
                let r = document.querySelector(".Select-noresults");
                _this.setState({
                    canAddCon: r !== null,
                    currentTextCon: e
                })
            }, 400)

        }
    }
    onSelectInputSer = (e) => {
        if (e) {
            let _this = this;
            if (!e.length) return;
            setTimeout(function () {
                let r = document.querySelector(".Select-noresults");
                _this.setState({
                    canAddSer: r !== null,
                    currentTextSer: e
                })
            }, 400)

        }
    }
    blurSelectCon = () => {
        let _this = this;
        setTimeout(function () {
            _this.setState({
                canAddCon: false,
                currentTextCon: ''
            })
        }, 300)
    }
    /**
        *
        * @param id
        */
    addCondition = (e, { value }) => {
        // id = id.value ? id.value : id; //check if added from dropdown

        let { Conditions } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let conditions = doctorObject[activeBranchKey].conditions;

        Conditions.forEach(function (item, index) {
            if (item.value === value) {
                conditions.push(value);
                Conditions.splice(index, 1);
            }
        })
        this.props.updateParentState(doctorObject);
        this.setState({
            Conditions: Conditions,
            currentText: ''
        })
    }
    addService = (id) => {
        //id = id.value ? id.value : id; //check if added from dropdown

        let { services } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let doctorServices = doctorObject[activeBranchKey].services;

        services.forEach(function (item, index) {
            if (item.value === id) {
                doctorServices[item.value] = 0
                services.splice(index, 1);
            }
        })
        this.props.updateParentState(doctorObject);
        this.setState({
            services: services,
            currentText: ''
        })
    }
    getServicesLists = () => {
        let _this = this
        let { defaultServices } = this.state
        firebase.firestore().collection('services').get().then(snap => {
            var services = []
            snap.forEach(item => {
                let val = item.data()
                val.id = item.id
                defaultServices.push(val)
                services.push({
                    value: item.id,
                    text: item.data().name,
                    key: item.id,
                    specialties: item.data().specialties
                })
            })
            _this.setState({ services, defaultServices })
        })
    }
    getConditionsLists = () => {
        let { Conditions, defaultConditions } = this.state;
        const { doctorObject, activeBranchKey } = this.props;
        let conditions = doctorObject[activeBranchKey].conditions;
        Conditions = []


        let ConditionsRef = firebase.firestore().collection('conditions');
        ConditionsRef.get().then(snapshot => {
            snapshot.forEach(item => {
                let val = {};
                val.id = item.id
                val.key = item.key
                val.name = item.data().name
                val.specialties = item.data().specialties
                if (conditions.indexOf(item.id) === -1) {
                    Conditions.push({
                        value: item.id,
                        text: item.data().name,
                        specialties: item.data().specialties
                    });
                }
                defaultConditions.push(val)
            })
            this.setState({
                Conditions: Conditions,
                defaultConditions: defaultConditions,
            });
        });
    }
    renderConditions = () => {
        let { Conditions } = this.state;
        const { doctorObject, activeBranchKey } = this.props;
        let conditions = doctorObject[activeBranchKey].conditions;
        // let customConditions = doctorObject[activeBranchKey].customConditions;
        var selectedSpecialities = []
        for (var index in doctorObject[activeBranchKey].doctorSelectedClinics) {
            for (var innerIndex in doctorObject[activeBranchKey].doctorSelectedClinics[index][1]) {
                if (!selectedSpecialities.includes(innerIndex) && doctorObject[activeBranchKey].doctorSelectedClinics[index][1][innerIndex]) {
                    selectedSpecialities.push(innerIndex)
                }
            }
        }
        const filteredConditions =
            Conditions.filter(item => {
                if (conditions.includes(item.value)) {
                    return
                }
                for (var index in selectedSpecialities) {
                    if (item.specialties && item.specialties.includes(selectedSpecialities[index])) {
                        return item
                    }
                }

            })

        return (
            <div className="doctor-gray-box box" style={{ display: 'flow-root', paddingTop: '16px' }}>
                <label className="section-title">Areas of interest</label>
                <p className="sub-title">Enrich your profile with your areas of interest ,Keep them simple to understand for the public to have higher exposure in search results</p>
                <Dropdown
                    selection
                    fluid
                    options={filteredConditions}
                    // noResultsMessage="Can't find this condition, click the Add button to suggest it?"
                    placeholder="Type condition name"
                    className="selectiondropdown"
                    upward={false}
                    search
                    onChange={this.addCondition}
                    onOpen={this.onSelectInputCon}
                    onBlur={this.blurSelectCon}
                    // allowAdditions
                    selectOnBlur={false}

                    additionLabel='Add custom condition: '
                    onAddItem={this.addCustomCondition} />
                <div className={conditions && conditions.length ? "gray-box box" : ''} style={{ display: 'flow-root', paddingBottom: '20px' }}>
                    {
                        (conditions.length) ? (
                            conditions.map((item, index) => (
                                <button key={index} style={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    fontSize: '12px',
                                    width: 'auto'
                                }} title={'Remove ' + this.getConditionNameById(item, 'defaultConditions')} onClick={this.removeSelectedConditions.bind(this, item)} className="tag-button input-half" key={this.getConditionNameById(item, 'defaultConditions') + '_' + index} >{this.getConditionNameById(item, 'defaultConditions')}<i className="fas fa-times"></i></button>
                            ))
                        ) : ''
                    }
                </div>
                {/* {
                    (customConditions && customConditions.length) ? (
                        <div className="wizardMain">
                            <div className="Specialties">
                                <div className="selectedSpecialties custom" style={{ border: 'none', minHeight: '30px', marginBottom: '0', marginTop: '110px' }} >
                                    <div className="inputWrap">
                                        <div className="title flexBox align_justify" style={{ paddingBottom: '5px' }}>
                                            <h2 style={{
                                                fontSize: '14px',
                                                textTransform: 'uppercase',
                                                color: '#4d4d4d',
                                                marginBottom: '10px',
                                                display: 'block',
                                                fontWeight: '600'
                                            }}>Waiting Approval</h2>
                                        </div>
                                        <div className="subTitle" style={{ paddingBottom: '0px' }}>
                                            <p style={{
                                                fontSize: '12px',
                                                color: '#4d4d4d',
                                                marginBottom: '10px',
                                                display: 'block',
                                                fontWeight: '600',
                                            }}>this condition will be added as soon as our administration approve it.</p>
                                        </div>
                                        <div className="items flexBox align_justify_left">
                                            {
                                                customConditions.map((item, index) => (
                                                    <div title={'Remove ' + item} onClick={this.removeCustomConditions.bind(this, item)} key={item + '_' + index} className="item flexBox">
                                                        <span>{item}</span>
                                                        <span>x</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ''
                } */}
            </div>

        )
    }
    addCustomCondition = (e, { value }) => {
        let { defaultConditions } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let customConditions = doctorObject[activeBranchKey].customConditions;
        if (value.length > 2 && !searchInArrayWithObjects(value, 'name', defaultConditions) && customConditions.indexOf(value) === -1) {
            customConditions.push(value)
        }
        doctorObject[activeBranchKey].modifiedFalg = true
        this.props.updateParentState(doctorObject);
    }
    addCustomService = (e, { value }) => {
        let { defaultServices } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let customServices = doctorObject[activeBranchKey].customServices;
        if (value.length > 2 && !searchInArrayWithObjects(value, 'name', defaultServices) && customServices.indexOf(value) === -1) {
            customServices.push(value)
        }
        doctorObject[activeBranchKey].modifiedFalg = true
        this.props.updateParentState(doctorObject);
    }
    removeCustomConditions = (id) => {
        let { doctorObject, activeBranchKey } = this.props;
        let customConditions = doctorObject[activeBranchKey].customConditions;
        let index = customConditions.indexOf(id);
        customConditions.splice(index, 1);

        this.props.updateParentState(doctorObject);
    }
    removeCustomService = (id) => {
        let { doctorObject, activeBranchKey } = this.props;
        let customServices = doctorObject[activeBranchKey].customServices;
        let index = customServices.indexOf(id);
        customServices.splice(index, 1);
        this.props.updateParentState(doctorObject);
    }
    /**
    * @param id
    */
    removeSelectedConditions = (id) => {
        let { Conditions } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let conditions = doctorObject[activeBranchKey].conditions;
        let condition = this.getConditionById(id, 'defaultConditions');

        let index = conditions.indexOf(id);
        if (index > -1) {
            conditions.splice(index, 1);
            let ConditionsValue = { text: condition["name"], value: id, specialties: condition["specialties"] };
            Conditions.push(ConditionsValue);
        }
        this.setState({
            Conditions: Conditions
        })
        this.props.updateParentState(doctorObject);
    }

    removeSelectedService = (id) => {
        let { services } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let doctorServices = doctorObject[activeBranchKey].services;
        let service = this.getConditionById(id, 'defaultServices');
        delete doctorServices[id]
        let val = { text: service.name, value: id, specialties: service.specialties, key: id };
        services.push(val);
        this.setState({
            services
        })
        this.props.updateParentState(doctorObject);
    }
    /**
     *
     * @param id
     * @param where
     */
    getConditionNameById = (id, where) => {
        let x = this.state[where].filter(function (v) {
            return v.id === id; // Filter out the appropriate one
        })[0];
        if (x) {
            return x.name
        }
    };
    getConditionById = (id, where) => {
        let x = this.state[where].filter(function (v) {
            return v.id === id; // Filter out the appropriate one
        })[0];
        if (x) {
            return x
        }
    };



    renderServices = () => {
        let { services } = this.state;
        const { doctorObject, activeBranchKey } = this.props;
        let doctorServices = doctorObject[activeBranchKey].services;
        // let customServices = doctorObject[activeBranchKey].customServices;
        var selectedSpecialities = []
        for (var index in doctorObject[activeBranchKey].doctorSelectedClinics) {
            for (var innerIndex in doctorObject[activeBranchKey].doctorSelectedClinics[index][1]) {
                if (!selectedSpecialities.includes(innerIndex) && doctorObject[activeBranchKey].doctorSelectedClinics[index][1][innerIndex]) {
                    selectedSpecialities.push(innerIndex)
                }
            }
        }


        let selectedServices = doctorServices ? Object.keys(doctorServices) : []
        const filteredServices =
            services.filter(item => {
                for (var index in selectedSpecialities) {
                    if (item.specialties && item.specialties.includes(selectedSpecialities[index]) && !selectedServices.includes(item.value)) {
                        return item
                    }
                }

            })
        return (
            <div className="doctor-gray-box box" style={{ paddingTop: '16px' }}>
                <label className="section-title" >Services</label>
                <p className="sub-title">"List services (procedures) provided and make it easier for your patients to find about you.</p>
                <Dropdown
                    selection
                    fluid
                    options={filteredServices}
                    // noResultsMessage="Can't find this service, click the Add button to suggest it?"
                    placeholder="Type service name"
                    className="selectiondropdown"
                    upward={false}
                    search
                    onChange={(e, { value }) => this.addService(value)}
                    onOpen={this.onSelectInputSer}
                    onBlur={this.blurSelectCon}
                    // allowAdditions
                    selectOnBlur={false}

                    additionLabel='Add custom service: '
                    onAddItem={this.addCustomService} />
                <div className={doctorServices && Object.keys(doctorServices).length ? "gray-box box" : ""} style={{ display: 'flow-root', paddingBottom: '20px' }}>
                    {
                        (doctorServices) ? (
                            Object.keys(doctorServices).map((item, index) => (
                                <button key={index} style={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    fontSize: '12px',
                                    width: 'auto'
                                }} title={'Remove ' + this.getConditionNameById(item, 'defaultServices')} className="tag-button input-half" key={this.getConditionNameById(item, 'defaultServices') + '_' + index} >{this.getConditionNameById(item, 'defaultServices')}
                                    <input style={{ marginLeft: '4px', display: 'inline', padding: '0 0 0 5px', width: '60px' }} onChange={this.changeNumber.bind(this, 'services', item)} value={doctorServices[item]} />
                                    <i className="fas fa-times" onClick={this.removeSelectedService.bind(this, item)}></i>
                                </button>
                            ))
                        ) : ''
                    }
                </div>
                {/* {
                    (customServices.length) ? (
                        <div className="wizardMain">
                            <div className="Specialties">
                                <div className="selectedSpecialties custom" style={{ border: 'none', minHeight: '30px', marginBottom: '0', marginTop: '110px' }} >
                                    <div className="inputWrap">
                                        <div className="title flexBox align_justify" style={{ paddingBottom: '5px' }}>
                                            <h2 style={{
                                                fontSize: '14px',
                                                textTransform: 'uppercase',
                                                color: '#4d4d4d',
                                                marginBottom: '10px',
                                                display: 'block',
                                                fontWeight: '600'
                                            }}>Waiting Approval</h2>
                                        </div>
                                        <div className="subTitle" style={{ paddingBottom: '0px' }}>
                                            <p style={{
                                                fontSize: '12px',
                                                color: '#4d4d4d',
                                                marginBottom: '10px',
                                                display: 'block',
                                                fontWeight: '600',
                                            }}>this service will be added as soon as our administration approve it.</p>
                                        </div>
                                        <div className="items flexBox align_justify_left">
                                            {
                                                customServices.map((item, index) => (
                                                    <div title={'Remove ' + item} onClick={this.removeCustomService.bind(this, item)} key={item + '_' + index} className="item flexBox">
                                                        <span>{item}</span>
                                                        <span>x</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ''
                } */}
            </div>

        )
    }


    changeNumber = (type, clinicId, e) => {
        const { activeBranchKey, doctorObject } = this.props;
        let val = e.target.value;
        if (doctorObject[activeBranchKey][type] === 0) {
            doctorObject[activeBranchKey][type] = {}
        }
        switch (type) {
            case "consultFee":
                if (val > -1) {
                    if (doctorObject[activeBranchKey]["bookingActive"] && doctorObject[activeBranchKey]["bookingActive"][clinicId]) {
                        doctorObject[activeBranchKey]["bookingActive"][clinicId] = Number(val);
                    } else {

                        if (!doctorObject[activeBranchKey][type]) {
                            doctorObject[activeBranchKey][type] = {}
                        }
                        doctorObject[activeBranchKey][type][clinicId] = Number(val);
                    }
                    this.props.updateParentState(doctorObject);
                }
                break;
            case "services":
                if (val > -1) {
                    if (!doctorObject[activeBranchKey]["services"]) {
                        doctorObject[activeBranchKey]["services"] = {}
                    }
                    doctorObject[activeBranchKey]["services"][clinicId] = Number(val)
                    this.props.updateParentState(doctorObject);
                }
                break
            default:
                break
        }
        this.setState({ updated: Date.now() })


    }
    renderConsultation = () => {
        const { activeBranchKey, doctorObject } = this.props;
        let { selectedClinics } = this.state;
        var selectedClinic = doctorObject[activeBranchKey].clinics;

        return (
            <div className="box" style={{ paddingTop: '16px' }}>
                <label className="section-title">Consultation Fee</label>
                <div className={selectedClinics && selectedClinics.length ? "gray-box box" : ""} style={{ display: 'flow-root' }}>
                    {
                        selectedClinics ?
                            selectedClinics.map(item => {
                                return (
                                    <div key={item[0]} className="select-input" style={{ alignItems: 'center', display: "flex", padding: "5px", position: "relative" }}>
                                        <div style={{ fontSize: '14renderConditionspx', width: "50%" }}>{this.getNameById(item[0], 'defaultClinics')}</div>
                                        <div style={{ display: "flex", width: "50%" }}>

                                            <input onChange={this.changeNumber.bind(this, 'consultFee', item[0])} value={(doctorObject[activeBranchKey].bookingActive && doctorObject[activeBranchKey].bookingActive[item[0]]) ? doctorObject[activeBranchKey].bookingActive[item[0]] : (doctorObject[activeBranchKey].consultFee && doctorObject[activeBranchKey].consultFee[item[0]]) ? doctorObject[activeBranchKey].consultFee[item[0]] : ''} type="number" style={{ marginBottom: 0, width: "70%" }} />
                                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '15px', paddingLeft: '10px' }}>{process.env.REACT_APP_CURRNECY}</div>
                                        </div>
                                    </div>)
                            }) : ''
                    }
                </div>

            </div>
        )
    }

}

const mapStateToProps = ({ user, branches }) => {
    return {
        user,
        branches: branches && branches.data ? branches.data : {}
    };
};
const mapActionsToProps = (dispatch) => ({
    // getUser: () => dispatch(userActions.getUser())
    // getOrderListSuccess: (updatedList) => dispatch(getOrderListSuccess(updatedList)),
});
export default connect(
    mapStateToProps,
    mapActionsToProps
)(BasicInfo);