import React, { Component } from 'react';
import clinicPlaceHolder from '../../img/clinicprofileaspect.png';
import { Icon, Button } from 'semantic-ui-react'
import ReactTable from "react-table";
import "react-table/react-table.css";
import fire from 'firebase/app'

class SelectFacility extends Component {
    state = {
        currentStep: 1,
        allReviews: [],
        selectedFacility: {},
        user: null,
        loggedIn: false,
        claimingPersonName: '',
        suggestionId: null,
        branches: 1,
        readyToStart: false,
    }
    constructor(props, context) {
        super(props, context);
    }

    /**
     *
     * @param type
     * @param reset
     * @returns {function(this:SelectFacility)}
     */
    changeLocalFacilityType = (type, facilityId) => {
        return function () {
            const { selectedFacility } = this.state;
            const draftList = this.props.selectFacility.clinics;
            Object.keys(draftList).forEach((item, index) => {
                if (item === facilityId) {
                    selectedFacility[item] = draftList[item];
                    this.setState({
                        selectedFacility: selectedFacility
                    })
                    this.props.selectFacility.SelectedFacility(selectedFacility)
                }
            })

            this.props.selectFacility.branchChange(1);
            this.props.selectFacility.readyToStartChange(true)

        }.bind(this)
    }
    componentWillMount = () => {
        const draftList = this.props.selectFacility.clinics;
        let _this = this

        var reviews = []
        var onlineCount = 0
        Object.keys(draftList).map(facilityId => {
            fire.firestore().collection('reviews').where('facilityId', '==', facilityId).get().then(reviewSnap => {
                if (reviewSnap.empty) {
                    return
                }

                reviewSnap.docChanges().forEach((record) => {
                    if (record.type === "added") {
                        let value = record.doc.data();
                        reviews.push(value)

                        onlineCount++
                        if (onlineCount >= reviewSnap.size) {
                            _this.setState({ allReviews: reviews })
                        }
                    }

                })

            })
        })

    }
    dataTableColumns() {
        return ([
            {
                accessor: "logoUrl",
                Cell: props => <div className="providerfieldText"><img style={{ minHeight: '60px', maxWidth: '60px', width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={props.value ? props.value : clinicPlaceHolder} alt={props.value} /></div>
            },
            {
                Header: "Name",
                accessor: "facilityName",
                Cell: props => <p className="providerfieldText">{props.value}</p>
            },
            {
                Header: "Branch Name",
                accessor: "branchName",
                Cell: props => <p className="providerfieldText">{props.value}</p>
            },
            {
                Header: "Address",
                accessor: "address",
                Cell: props => <p className="providerfieldText">{props.value.country}</p>
            },


            {
                width: 90,
                accessor: "edit",
                Cell: props => <p className="providerfieldText">
                    <Button color={'twitter'} onClick={this.changeLocalFacilityType(props.original.facilityType, props.original.facilityId)}>View</Button>
                </p>
            }
        ])


    }

    render() {
        return (
            <section id="facility-grid-page">
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" style={{ height: '100%' }}>
                        {
                            this.props.loading ?
                                <div style={{ height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Icon.Group size='big'>
                                        <Icon loading size='big' name='spinner' color='grey' />
                                    </Icon.Group>
                                </div>
                                : this.renderFacilitiesList()
                        }
                    </div>
                </div>
            </section>
        )
    }

    /*
    * Functions for content getting
    * */

    /**
     * get Facilities list from redux
     * @param list
     * @returns {XML}
     */
    renderFacilitiesList = (list) => {
        let { allReviews } = this.state;
        const draftList = this.props.selectFacility.clinics;
        if (!draftList || Object.keys(draftList).length === 0) return (
            <div className="emptystate">
                <div className="emptystate1">
                    <div className="textMesg">No Clinics</div>
                    <div className="detailMesg">Add clinic to see information in this page</div>
                </div>
            </div>
        );
        let averageScore = 0
        allReviews.map(item => {
            let score = ((item.score.score_0 + item.score.score_1 + item.score.score_2 + item.score.score_3) / 4) + 1
            averageScore += score
        })
        return (
            <div >
                <div className="facilityInfo">
                    <div className="cardHeader">
                        <div className="cardCount">{allReviews.length}</div>
                        <div className="cardTitle">Total Feedback</div>
                        <div className="cardImage"><i className="fas fa-star"></i></div>
                    </div>
                    <div className="cardHeader">
                        <div className="cardCount">{(averageScore / allReviews.length).toFixed(2)}</div>
                        <div className="cardTitle">Average Feedback</div>
                        <div className="cardImage"><i className="fas fa-percentage"></i></div>
                    </div>

                </div>
                <div className="single-medical" >
                    <ReactTable
                        data={Object.values(draftList)}
                        columns={this.dataTableColumns()}
                        defaultPageSize={10}
                        pageSize={Object.values(draftList).length < 10 ? Object.values(draftList).length : 10}
                        showPageJump={false}
                        showPageSizeOptions={false}
                        // PaginationComponent={DataTablePagination}
                        className='custuomReactTable'
                    />

                </div>
            </div>
            // <div className="single-medical" >

            //     <ul>
            //         {Object.keys(draftList).map((item, index) => (

            //             <li key={item}>
            //                 <div className="medical-logo">
            //                     {
            //                         (draftList[Object.keys(draftList)[index]].logoUrl) ? (
            //                             <img src={draftList[Object.keys(draftList)[index]].logoUrl} alt="Img" />
            //                         ) : (
            //                             <img src={clinicPlaceHolder} alt="Img" />
            //                         )
            //                     }
            //                 </div>
            //                 <div className="single-branch">
            //                     <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: 'tfBold', fontSize: '16px' }}>{draftList[Object.keys(draftList)[index]].facilityName}</p>
            //                     <p className="branch-name">
            //                         {draftList[Object.keys(draftList)[index]].branchName ? (
            //                             draftList[Object.keys(draftList)[index]].branchName
            //                         ) : ''}
            //                     </p>
            //                     <p className="branch-location">{draftList[Object.keys(draftList)[index]].address.country}</p>

            //                     {(currentStep === 1) ? (
            //                         <div>
            //                             <button className="button" onClick={this.changeLocalFacilityType(index)}>View</button>
            //                         </div>
            //                     ) : (currentStep === 2) ? (
            //                         <div>
            //                             <button className="button" onClick={this.changeLocalFacilityType(index)}>Activate</button>
            //                         </div>
            //                     ) : ''}
            //                 </div>
            //             </li>
            //         ))}
            //     </ul>

            // </div>

        )

    }

}

export default SelectFacility