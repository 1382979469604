import { createStore, compose, applyMiddleware } from 'redux';
// import { syncHistoryWithStore } from 'react-router-redux';
import thunk from 'redux-thunk';
import { browserHistory } from 'react-router';
import promiseMiddleware from 'redux-promise-middleware';
import rootReducer from './reducers';
// import persistState from 'redux-localstorage';
import { persistStore } from 'redux-persist'
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import firebase from 'firebase/app'
import 'firebase/auth'
import LogRocket from 'logrocket';
if (!window.location.href.includes("localhost")){
  LogRocket.init('akdf6j/clinica-one');
}

firebase.auth().onAuthStateChanged(function (user, error) {
  
      if (user && user.emailVerified) {
        LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {          
          name: user.uid,
          email: user.email,
          // Add your own custom user variables here, ie:
          subscriptionType: 'pro'
        });
      }
  }
)
// const middlewares = [thunk, promiseMiddleware,createLogger(),LogRocket.reduxMiddleware()];
const middlewares = [thunk, promiseMiddleware,LogRocket.reduxMiddleware()];


// const createStoreWithMiddleware = applyMiddleware(
//     ...middlewares
// )(createStore);

// const configureStore = function (initialState) {
//     return createStoreWithMiddleware(rootReducer, initialState);
// };

export const store = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(applyMiddleware(...middlewares)),
  );
  
export const persistor = persistStore(store);
// export default () => {
//     let store = createStoreWithMiddleware(persistedReducer)
//     let persistor = persistStore(store)
//     return { store, persistor }
//   }

// export default configureStore;