import React, { Component } from 'react';
import PropTypes from 'prop-types'
import 'react-image-crop/lib/ReactCrop.scss';
import Select from 'react-select';
import DatePicker from 'react-modern-datepicker';
import { searchInArrayWithObjects } from '../../../helpers/Full';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Dropdown } from 'semantic-ui-react'
import RightAwards from '../RightSteps/RightAwards'
import Swal from 'sweetalert2';

class Awards extends Component {
    static propTypes = {
        doctorObject: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        updateParentState: PropTypes.func.isRequired,
    }

    constructor(props, context) {
        super(props, context);
    }
    state = {
        selectedAward: [],
        canAddAward: false,
        currentTextAward: '',

        selectedFacility: [],
        canAdd: false,
        currentText: '',
        FromDate: null,
        doctorAward: {
            awardName: '',
            facilityName: '',
            year: '',
            city: '',
            country: '',
        },
    }
    selectCountry(val) {
        let { doctorObject, activeBranchKey } = this.props;
        let { doctorAward } = this.state;
        // doctorObject[activeBranchKey].doctorAwardsObject.country = val
        doctorAward.country = val;

        this.props.updateParentState(doctorObject);
    }

    selectRegion(val) {
        let { doctorAward } = this.state;

        let { doctorObject, activeBranchKey } = this.props;
        // doctorObject[activeBranchKey].doctorAwardsObject.city = val
        doctorAward.city = val;
        this.props.updateParentState(doctorObject);

    }
    handleFromDateChange(date) {
        let { doctorAward } = this.state;

        let { doctorObject, activeBranchKey } = this.props;
        doctorAward.year = date

        this.setState({
            FromDate: date
        });
        this.props.updateParentState(doctorObject);

    }

    render() {
        let { doctorAward } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let doctorObjects = doctorObject[activeBranchKey].doctorAwards;

        return (
            <div>
                <div style={{ display: 'flow-root' }}>
                    <div className="box" >
                        <label>Award <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                        {this.renderSelectedAwards()}
                    </div>
                    <div className="box">
                        <label>Awarding Organization <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                        {this.renderSelectedClinics()}
                    </div>
                    <div className="box address-box" style={{ marginTop: '0' }}>
                        <div className="input-half marg-right">
                            <label>Country <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                            <CountryDropdown
                                classes="custom-select"
                                value={doctorAward.country}
                                onChange={(val) => this.selectCountry(val)} />
                        </div>
                        <div className="input-half marg-right">
                            <label>City <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                            <RegionDropdown
                                classes="custom-select"
                                blankOptionLabel="Select City"
                                defaultOptionLabel="Select City"
                                country={doctorAward.country}
                                value={doctorAward.city}
                                onChange={(val) => this.selectRegion(val)} />
                        </div>
                        <div className="input-half marg-right">
                            <label>Award Date <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                            <DatePicker
                                format={'YYYY-MM-DD'}
                                className="custom-select"
                                date={this.state.FromDate}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                peekNextMonth
                                fixedHeight
                                onChange={this.handleFromDateChange.bind(this)}
                                placeholderText="Select From date"
                            />
                        </div>
                    </div>
                    <button className="save-btn-local" onClick={this.addDoctorObject}>Add</button>
                </div>
                {
                    (doctorObjects.length) ? (
                        <div className="right-side" style={{ width: '100%', marginTop: '24px' }}>
                            <RightAwards updateParentState={this.props.updateParentState}
                                activeBranchKey={activeBranchKey}
                                doctorObject={doctorObject} />
                        </div>
                    ) : ''
                }
            </div>
        )
    }
    addDoctorObject = () => {
        let { doctorObject, activeBranchKey } = this.props;
        let { doctorAward } = this.state;
        let doctorObjects = doctorObject[activeBranchKey].doctorAwards;
        doctorObject[activeBranchKey].doctorAwardsObject = {};
        let doctorPublicObjects = doctorObject[activeBranchKey].doctorAwardsObject;

        if (doctorAward.facilityName.length && doctorAward.awardName.length && doctorAward.country.length && doctorAward.city.length && doctorAward.year.length) {
            doctorPublicObjects.country = doctorAward.country
            doctorPublicObjects.city = doctorAward.city
            doctorPublicObjects.year = doctorAward.year
            doctorPublicObjects.facilityName = doctorAward.facilityName
            doctorPublicObjects.awardName = doctorAward.awardName
            doctorObjects.push(doctorPublicObjects)

            doctorAward.facilityName = ''
            doctorAward.awardName = ''
            doctorAward.country = ''
            doctorAward.city = ''
            doctorAward.year = ''

            doctorObject[activeBranchKey].awards = [];
            doctorObject[activeBranchKey].awardFacility = [];
            this.setState({
                FromDate: null,
                currentText: '',
                currentTextAward: ''
            });
        }else{
            Swal.fire({
                heightAuto:false,
                type:'error',
                title:'Alert',
                text:'Please fill all requirments'
            })
        }
        this.props.updateParentState(doctorObject);

    }
    componentWillMount = () => {
        const { doctorObject, activeBranchKey } = this.props;
        let { doctorAward } = this.state;

        doctorAward.facilityName = ''
        doctorAward.awardName = ''
        doctorAward.country = ''
        doctorAward.city = ''
        doctorAward.year = ''

        doctorObject[activeBranchKey].awards = [];
        doctorObject[activeBranchKey].awardFacility = [];
    }

    addFacility = (e, { value }) => {
        this.setState({ currentText: value })

        let { doctorAward } = this.state;
        let { selectedFacility } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        doctorObject[activeBranchKey].awardFacility = [];
        let facility = doctorObject[activeBranchKey].awardFacility;
        if (value.length > 2 && !searchInArrayWithObjects(value, 'name', selectedFacility) && facility.indexOf(value) === -1) {
            facility.push(value)
            // doctorObject[activeBranchKey].doctorAwardsObject.facilityName = currentText
            doctorAward.facilityName = value

        }
        this.props.updateParentState(doctorObject);
    }
    removeFacility = (id) => {
        let { doctorAward } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let facility = doctorObject[activeBranchKey].awardFacility;
        let index = facility.indexOf(id);
        // doctorObject[activeBranchKey].doctorAwardsObject.facilityName = ''
        doctorAward.facilityName = ''

        facility.splice(index, 1);

        this.props.updateParentState(doctorObject);
    }
    handleChange = (e, { value }) => {
        let _this = this
        let { doctorObject, activeBranchKey } = this.props;
        let facility = doctorObject[activeBranchKey].awardFacility;

        if (value === "") {
            facility.filter((item, index) => {
                _this.removeFacility(item)
            })
        }
        this.setState({ currentText: value })
    }

    renderSelectedClinics = () => {
        let { currentText } = this.state;
        return (
            <Dropdown
                selection
                search
                fluid
                noResultsMessage="Type facility name"
                placeholder="Type facility name"
                className="selectiondropdown"
                upward={false}
                allowAdditions
                text={currentText}
                value={currentText}
                additionLabel='Add facility: '
                onAddItem={this.addFacility}
                onChange={this.addFacility}
                clearable
            />
        )
    }

    addAward = (e, { value }) => {
        this.setState({ currentTextAward: value })

        let { doctorAward } = this.state;

        let { currentTextAward, selectedAward } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        doctorObject[activeBranchKey].awards = [];
        let award = doctorObject[activeBranchKey].awards;
        if (currentTextAward.length > 2 && !searchInArrayWithObjects(currentTextAward, 'name', selectedAward) && award.indexOf(currentTextAward) === -1) {
            award.push(currentTextAward)
            // doctorObject[activeBranchKey].doctorAwardsObject.awardName = currentTextAward
            doctorAward.awardName = currentTextAward

        }
        this.props.updateParentState(doctorObject);
    }
    removeAward = (id) => {
        let { doctorAward } = this.state;

        let { doctorObject, activeBranchKey } = this.props;
        let award = doctorObject[activeBranchKey].awards;
        let index = award.indexOf(id);
        // doctorObject[activeBranchKey].doctorAwardsObject.awardName = ''
        doctorAward.awardName = ''

        award.splice(index, 1);

        this.props.updateParentState(doctorObject);
    }
    handleAwardChange = (e, { value }) => {
        let _this = this
        let { doctorObject, activeBranchKey } = this.props;
        let award = doctorObject[activeBranchKey].awards;

        if (value === "") {
            award.filter((item, index) => {
                _this.removeAward(item)
            })
        }
        this.setState({ currentTextAward: value })
    }

    renderSelectedAwards = () => {
        let { currentTextAward } = this.state;


        return (
            <Dropdown
                selection
                search
                fluid
                noResultsMessage="Type award name"
                placeholder="Type award name"
                className="selectiondropdown"
                upward={false}
                allowAdditions
                text={currentTextAward}
                value={currentTextAward}
                additionLabel='Add Award: '
                onAddItem={this.addAward}
                onChange={this.handleAwardChange}
                clearable
            />
        )
    }

}
export default Awards