import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { validateEmail } from '../../helpers/Full'
import BasicInfo from './steps/BasicInfo'
import Location from './steps/Location'
import Timings from './steps/Timings'
import Specialties from './steps/Specialties'
import Insurances from './steps/Insurances'
import Amenities from './steps/Amenities'
import StepsBar from './StepsBar'
import StepButtons from './StepButtons'
import Verification from './RightSteps/Verification'
import Swal from 'sweetalert2'
import _ from 'lodash'
import randomstring from "randomstring"
import clinicPlaceholder from '../../img/placeholder-clinic.png'

class MainWizard extends Component {

    static propTypes = {
        wizardOptions: PropTypes.shape({
            branches: PropTypes.isRequired,
            facilityType: PropTypes.string.isRequired,
        }).isRequired,
        selectFacility: PropTypes.shape({
            readyToStartChange: PropTypes.func.isRequired,
        }).isRequired,
        currentUser: PropTypes.object.isRequired,

    };
    state = {
        branchList: {},
        activeBranchKey: 'branch1',
        currentStep: 1,
        logoUrl: null,
        fileTypes: ['.jpg', '.jpeg', '.gif', '.png'],
        activeError: false,
        claimed: false,
        noLogo: false,
        Informations: {
            BasicInfo: 'Basic Info',
            Specialties: 'Specialties',
            Insurance: 'Insurance',
            Amenities: "Amenities",
            Location: "Location",
            Timing: "Timing"
        },
        loading: true


    };
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount = () => {
        const branchId = '-' + randomstring.generate(15);

        let _this = this;

        const { facilityType } = this.props.wizardOptions;
        const { currentUser } = this.props;

        let listOfBranches = {};
        let adminName = currentUser.info.adminName ? currentUser.info.adminName : currentUser.info.name ? currentUser.info.name : '';
        let adminPosition = currentUser.info.adminPosition ? currentUser.info.adminPosition : '';
        let adminPhone = currentUser.info.adminPhone ? currentUser.info.adminPhone : [{ country: 'OMN 968', code: '800', number: '' }];

        for (let i = 1; i <= this.props.wizardOptions.branches; i++) {
            listOfBranches['branch' + i] = {
                branchName: 'Branch name',
                branchId: branchId,
                branchesId: [],
                facilityType: facilityType,

                //Basic Info
                facilityName: '',
                facilityName_ar: '',
                description: '',
                description_ar: '',
                pictures: [],
                //Location
                landLineNumbers: [{
                    country: 'OMN 968',
                    code: '2',
                    number: ''
                }],
                tollNumber: [{
                    country: 'OMN 968',
                    code: '800',
                    number: ''
                }],
                isTollNumberDefault: false,
                facilityEmail: '',
                zoom: 15,
                location: {
                    latitude: 25.226988,
                    longitude: 55.823451
                },
                address: {
                    country: '',
                    city: '',
                    area: '',
                    route: '',
                    street: '',
                    street_route: '',
                    building: '',
                    boxNumber: ''
                },
                address_ar: {
                    country: '',
                    city: '',
                    area: '',
                    route: '',
                    street: '',
                    street_route: '',
                    building: '',
                    boxNumber: ''
                },
                //Timings
                openingDays: {
                    'SAT': {},
                    'SUN': {},
                    'MON': {},
                    'TUE': {},
                    'WED': {},
                    'THU': {},
                    'FRI': {}
                },
                //Specialties
                selectedSpecialties: [],
                customSpecialties: [],
                //Insurances
                selectedInsurances: {},
                customInsurances: [],
                customNetworks: {},
                //Amenities
                amenities: [],
                customAmenities: [],
                //Doctors
                customDoctors: [],
                addedDoctors: [],
                //Verification
                adminName: adminName,
                adminPosition: adminPosition,
                adminPhone: adminPhone,
                attachedCertificateUrl: '',
                interestedAd: false,
                terms: false,
                privacy: false,
                //Global
                logoUrl: false,
                haveError: true,
                status: 'verified',
                added: Date.now(),
                created: Date.now(),
                users: {
                    superAdmin: currentUser.id
                },
                noFacilityName: true
            }
        }
        this.setState({
            branchList: listOfBranches
        }, () => {
            let { branchList } = this.state;
            Object.keys(branchList).map(function (item) {
                Object.keys(branchList[item].openingDays).map(function (day) {

                    //TODO remove this part
                    branchList[item].openingDays[day] = {
                        selected: false,
                        opened: false,
                        fixed: false,
                        isTwentyFour: false,
                        fromTime: false,
                        toTime: false,
                        isSplitShift: false,
                        pmFromTime: false,
                        pmToTime: false,
                    }
                    if (facilityType === 'Hospital') {
                        branchList[item].numberOfBeds = 0;
                        branchList[item].emergencyService = false;
                    }
                })
            })

            this.setState(prevState => ({
                ...prevState,
                branchList: branchList
            }), () => {
                let { draftList } = _this.props;
                let { branchList } = _this.state;
                if (draftList) {
                    Object.keys(draftList).map(function (item, index) {
                        Object.keys(draftList[item]).map(function (subItem) {
                            branchList[Object.keys(branchList)[index]][subItem] = draftList[item][subItem];
                            if (subItem === 'logoUrl') {
                                _this.setState({
                                    logoUrl: draftList[item].logoUrl,
                                })
                            }
                        })

                    })
                    // console.log('---', branchList)
                    _this.setState({ branchList: branchList }, () => {
                        _this.middleUpdateCurrentStatus();
                    })
                }
                _this.setState({ loading: false })

            })
            this.middleUpdateCurrentStatus();
        })

    }


    changeClaimed = (bool) => {
        this.setState({
            claimed: bool
        })
    }

    /*
    * Functions for this component
    * */

    /**
     * @param e
     * @returns {null}
     */
    handleChangeBranchName = (e) => {
        let val = e.target.value,
            key = e.target.id;
        val = val.charAt(0).toUpperCase() + val.substr(1);
        if (val.length > 20) return null;
        this.setState(prevState => ({
            branchList: {
                ...prevState.branchList,
                [key]: {
                    ...prevState.branchList[key],
                    branchName: val
                }
            }
        }))
    }
    /**
     *
     * @param e
     */
    handleKeyPressBranchName = (e) => {
        const button = e.key;
        if (button === 'Enter') e.target.blur();
    }
    /**
     *
     * @param e
     */
    blurBranchName = (e) => {
        const key = e.target.id;
        if (!e.target.value.length) {
            this.setState(prevState => ({
                branchList: {
                    ...prevState.branchList,
                    [key]: {
                        ...prevState.branchList[key],
                        branchName: 'Branch name'
                    }
                }
            }))
        }
    }

    /*
     * Functions for child components
     * */
    /**
     * Function which need use in the child components
     * @param ids
     * @param callback
     */
    applyToAll = (ids = [], callback = null) => {
        let _this = this;

        Swal.fire({
            heightAuto: false,
            title: 'Are you sure?',
            // text: 'Please try again later',
            // type: 'success',
            confirmButtonText: 'Apply to all',
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            allowOutsideClick: false
        }).then(function (result) {
            //TODO xxx
            if (result.value) {
                let branchListX = _.cloneDeep(_this.state.branchList);
                let { activeBranchKey } = _this.state;

                ids.map(function (item) {

                    Object.keys(branchListX).forEach(function (key) {
                        let currentChanging = _.cloneDeep(branchListX[activeBranchKey]);

                        if (Array.isArray(currentChanging[item])) {
                            branchListX[key][item] = currentChanging[item].slice(0);
                        } else {
                            branchListX[key][item] = currentChanging[item];
                        }
                    });
                })
                _this.setState({
                    branchList: branchListX
                }, () => {
                    _this.middleUpdateCurrentStatus();
                    if (callback) {
                        callback()
                    }
                })
            }
        })

    }

    /**
     * Live update warning status
     */
    middleUpdateCurrentStatus = () => {
        let { facilityType } = this.props.wizardOptions;

        switch (facilityType) {
            case 'Clinic':
            case 'Hospital':
                this.checkSelectedReqFields();
                break;
            case 'Pharmacy':

        }
    }
    /**
     * Live update warning status {strings, arrays}
     */
    checkSelectedReqFields = () => {
        let { currentStep } = this.state;

        let { branchList } = this.state;

        Object.keys(branchList).forEach(function (item) {
            let errors = [];
            switch (currentStep) {
                case 1:
                    let issetLogo = 0;
                    if (branchList[item].logoUrl !== false) issetLogo = 1;
                    errors.push(
                        issetLogo,
                        branchList[item].facilityName.length,
                        branchList[item].facilityName_ar.length,
                        branchList[item].pictures.length,
                        branchList[item].adminName.length,
                        branchList[item].adminPosition.length,
                        (branchList[item].adminPhone[0].number.length === 7) ? 1 : 0,
                        branchList[item].attachedCertificateUrl.length,
                        // +branchList[item].terms,
                        // +branchList[item].privacy,


                    );
                    break;
                case 2:
                    errors.push(
                        branchList[item].selectedSpecialties.length
                    );
                    break;
                case 3:
                    let inss = branchList[item].selectedInsurances;
                    let custInss = branchList[item].customInsurances;
                    let custNetw = branchList[item].customNetworks;
                    if (inss.length) {
                        inss.map(function (item) {
                            let netws = (item.length > 1) ? item[1] : [];
                            let issetOne = 0;
                            if (netws) {
                                Object.keys(netws).map(function (itemR) {
                                    if (netws[itemR]) {
                                        issetOne = 1;
                                    }
                                })
                            }
                            if (custNetw[item[0]]) {
                                if (custNetw[item[0]].length)
                                    issetOne = 1;
                            }
                            errors.push(issetOne);
                        })
                    }
                    if (custInss.length) {
                        custInss.map(function (item) {
                            errors.push(item[1].length)
                        })
                    }
                    /*errors.push(
                        branchList[item].selectedInsurances.length
                    );*/
                    break;

                // case 4:
                //     errors.push(
                //         branchList[item].amenities.length
                //     );
                //     break;

                case 5:
                    let email = branchList[item].facilityEmail;
                    errors.push(
                        branchList[item].address.country.length,
                        branchList[item].address.city.length,
                        branchList[item].address.building.length,
                        branchList[item].address_ar.country.length,
                        branchList[item].address_ar.city.length,
                        branchList[item].address_ar.building.length,
                        errors.push(validateEmail(email) ? 1 : 0)
                    );

                    // if(email.length)

                    branchList[item].tollNumber.map(function (item, index) {
                        if (item.number.length) {
                            errors.push(item.number.length >= 3 ? 1 : 0)
                        }
                    })
                    errors.push(branchList[item].landLineNumbers[0].number.length === 7 ? 1 : 0)
                    break;
                case 6:
                    errors.push(0);
                    let allDays = branchList[item].openingDays;
                    Object.keys(allDays).map(function (day) {
                        let curDayStatus = allDays[day].opened;
                        if (curDayStatus) {
                            errors = [];
                        }
                    });
                    break;


            }
            branchList[item].haveError = (errors.indexOf(0) === -1) ? false : true
            branchList[item].noFacilityName = (branchList[item].facilityName.length !== 0) ? false : true

        });
        this.setState({
            loading: false,
            branchList: branchList
        })
    }

    myfunction(arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11, arg12, arg13, arg14, arg15, arg16, arg17, arg18, arg19, arg20, arg21, arg22, arg23, arg24, arg25, arg26, arg27, arg28, arg29, arg30, arg31) {
        var r;
        r = arg1 + arg2 + arg3 + arg4 + arg5 + arg6 + arg7 + arg8 + arg9 + arg10 + arg11 + arg12 + arg13 + arg14 + arg15 + arg16 + arg17 + arg18 + arg19 + arg20 + arg21 + arg22 + arg23 + arg24 + arg25 + arg26 + arg27 + arg28 + arg29 + arg30 + arg31;
        return (r);
    }
    checkCompletitionFields() {

        let { branchList } = this.state;
        let arg1 = 0, arg2 = 0, arg3 = 0, arg4 = 0, arg5 = 0, arg6 = 0, arg7 = 0, arg8 = 0, arg9 = 0, arg10 = 0, arg11 = 0, arg12 = 0, arg13 = 0, arg14 = 0, arg15 = 0, arg16 = 0, arg17 = 0, arg18 = 0, arg19 = 0, arg20 = 0, arg21 = 0, arg22 = 0, arg23 = 0, arg24 = 0, arg25 = 0, arg26 = 0, arg27 = 0, arg28 = 0, arg29 = 0, arg30 = 0, arg31 = 0;
        let _this = this;
        Object.keys(branchList).forEach(function (item) {
            arg12 = 6;
            //BasicInfo
            if (branchList[item].logoUrl && branchList[item].logoUrl.length) {
                arg1 = 5;
            }
            if (branchList[item].facilityName.length) {
                arg2 = 5;
            }
            if (branchList[item].facilityName_ar.length) {
                arg3 = 5;
            }
            if (branchList[item].description.length) {
                arg4 = 4;
            }
            if (branchList[item].description_ar.length) {
                arg5 = 4;
            }
            if (branchList[item].pictures && branchList[item].pictures.length) {
                arg6 = 5;
            }
            if (branchList[item].adminName.length) {
                arg7 = 4;
            }
            if (branchList[item].adminPosition.length) {
                arg8 = 4;
            }
            if (branchList[item].adminPhone[0].number.length) {
                arg9 = 3;
            }
            if (branchList[item].attachedCertificateUrl) {
                arg10 = 4;
            }
            // +branchList[item].terms,

            // +branchList[item].privacy,

            //Specialties
            if (branchList[item].selectedSpecialties.length) {
                arg11 = 7;
            }

            //Insurances
            // if (branchList[item].selectedInsurances.length) {
            //     arg12 = 6;
            // }

            //Amenities
            if (branchList[item].amenities.length) {
                arg13 = 6;
            }

            //Location
            if (branchList[item].address.country) {
                if (branchList[item].address.country.length) {
                    arg14 = 2;
                }
            }
            if (branchList[item].address.city) {
                if (branchList[item].address.city.length) {
                    arg15 = 2;
                }
            }
            if (branchList[item].address.building) {
                if (branchList[item].address.building.length) {
                    arg16 = 2;
                }
            }
            if (branchList[item].address.area) {
                if (branchList[item].address.area.length) {
                    arg17 = 1;
                }
            }
            if (branchList[item].address.street_route) {
                arg18 = 1;
            }
            if (branchList[item].address.boxNumber) {
                arg28 = 1;
            }
            if (branchList[item].address_ar.country) {
                if (branchList[item].address_ar.country.length) {
                    arg19 = 2;
                }
            }
            if (branchList[item].address_ar.city) {
                if (branchList[item].address_ar.city.length) {
                    arg20 = 2;
                }
            }
            if (branchList[item].address_ar.building) {
                if (branchList[item].address_ar.building.length) {
                    arg21 = 2;
                }
            }
            if (branchList[item].address_ar.area) {
                if (branchList[item].address_ar.area.length) {
                    arg22 = 1;
                }
            }

            if (branchList[item].address_ar.street_route) {
                arg23 = 1;
            }
            if (branchList[item].address_ar.boxNumber) {
                arg29 = 1;
            }
            branchList[item].tollNumber.map(function (item, index) {
                if (item.number.length) {
                    arg24 = 5;
                }
            })
            if (branchList[item].landLineNumbers[0].number.length) {
                arg25 = 5;
            }
            if (branchList[item].facilityEmail.length) {
                arg26 = 5;
            }
            if (branchList[item].customInsurances.length) {
                arg30 = -5;
                branchList[item].customInsurances.map((item) => {
                    if (item[1].length) {
                        arg31 = 5;
                    }
                })
            }
            //Timings
            let allDays = branchList[item].openingDays;
            Object.keys(allDays).map(function (day) {
                let curDayStatus = allDays[day].opened;
                if (curDayStatus) {
                    arg27 = 5;
                }
            });

            // //Doctors
            // if (branchList[item].addedDoctors.length) {
            //     console.log("havelogo ", branchList[item].addedDoctors.length)
            // }

        });
        let value = _this.myfunction(arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11, arg12, arg13, arg14, arg15, arg16, arg17, arg18, arg19, arg20, arg21, arg22, arg23, arg24, arg25, arg26, arg27, arg28, arg29, arg30, arg31);
        return value;
    }

    /**
     * Change step
     * @param index
     */
    changeCurrentStep = (index) => {


        let { branchList } = this.state;
        if (index === 7) {
            this.setState({
                activeBranchKey: 'branch1'
            })
        }
        this.setState({
            currentStep: index,
            branchList: branchList
        }, () => {
            this.middleUpdateCurrentStatus();
        })
    }
    /**
     *
     * @param list
     * @param callback
     */
    updateCurrentState = (list, callback) => {
        this.checkCompletitionFields()

        // console.log(list);
        this.setState({
            branchList: list
        })
        this.middleUpdateCurrentStatus();
        if (callback)
            callback();
    }



    /**
     * Left bar Logo
     * @param e
     */
    addLogo = (e) => {
        let _this = this;
        let extension = e.target.files[0].name.split('.').pop().toLowerCase(),
            isSuccess = this.state.fileTypes.indexOf('.' + extension) > -1;
        if (isSuccess) {
            let reader = new FileReader(),
                file = e.target.files[0],
                id = 'logoUrl',
                branchListR = this.state.branchList;

            reader.onloadend = () => {

                this.setState({
                    logoUrl: reader.result,
                    uploadError: '',
                    noLogo: false
                })
                let cvs = document.createElement('canvas');
                let ctx = cvs.getContext("2d");
                let image = new Image();
                image.onload = function () {
                    cvs.width = this.width;
                    cvs.height = this.height;
                    ctx.drawImage(image, 0, 0);
                    let newImageData = cvs.toDataURL(file.type, 80 / 100);

                    Object.keys(branchListR).forEach(function (key) {
                        branchListR[key][id] = newImageData;
                    });
                    _this.setState({
                        branchList: branchListR,
                        logoUrl: newImageData,
                    },
                        () => {
                            _this.checkSelectedReqFields()
                        }
                    )
                };
                image.src = reader.result;
            };
            reader.readAsDataURL(file);
        } else {
            this.setState({
                uploadError: '* .' + extension + ' file is not supported'
            })
        }

    }
    /**
     *
     * @param url
     */
    claimLogo = (url) => {
        let branchListR = this.state.branchList;
        Object.keys(branchListR).forEach(function (key) {
            branchListR[key]['logoUrl'] = url;
            branchListR[key]['status'] = 'claimedNew';
        });
        this.setState({
            logoUrl: url,
            branchList: branchListR
        })
    }

    changeErrClick = (check) => {
        let _this = this;
        this.setState({
            activeError: check,
            noLogo: true
        })
        setTimeout(function () {
            _this.setState({
                activeError: false
            })
        }, 1500)
    }
    changeBranchFromChild = (name) => {
        this.setState({ activeBranchKey: name })
    }

    render() {
        const { facilityType } = this.props.wizardOptions;
        let { currentStep, branchList, logoUrl, activeBranchKey } = this.state;
        this.checkCompletitionFields();

        return (
            <div style={{ height: 'calc( 100% - 60px )' }}>
                {/* <TopBar changeErrClick = {this.changeErrClick} changeCurrentStep={this.changeCurrentStep} branchList={branchList} currentStep={currentStep} facilityType={facilityType} /> */}
                <section data-branches-length={Object.keys(branchList).length} id="facility-page" style={{ height: '100%', overflow: 'auto' }}>
                    <div className="left-side">
                        {this.renderSidebar()}
                    </div>
                    <div className="tab-content sub-pages" id="myTabContent">
                        {
                            (currentStep === 1) ? (
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="main-side">
                                        {this.renderStep()}

                                    </div >
                                    <div className="right-side">
                                        {this.renderRightSteps()}
                                    </div >
                                </div >
                            ) : (currentStep === 2) ? (
                                <div className="tab-pane fade show active" id="Location" role="tabpanel" aria-labelledby="Location-tab">
                                    <div className="main-side">
                                        {this.renderStep()}
                                    </div >
                                </div >
                            ) : (currentStep === 3) ? (
                                <div className="tab-pane fade show active" id="Timing" role="tabpanel" aria-labelledby="Timing-tab">
                                    <div className="main-side">
                                        {this.renderStep()}
                                    </div >
                                </div >
                            ) : (currentStep === 4) ? (
                                <div id="Specialities" className="tab-pane fade show active" role="tabpanel" aria-labelledby="Specialities-tab">
                                    <div className="main-side">
                                        {this.renderStep()}
                                    </div >
                                    {/* <div className="right-side right-side-box">
                                    {this.renderRightSteps()}
                                </div > */}
                                </div >
                            ) : (currentStep === 5) ? (
                                <div className="tab-pane fade show active" id="Insurance" role="tabpanel" aria-labelledby="Insurance-tab">
                                    <div className="main-side">
                                        {this.renderStep()}

                                    </div >
                                    {/* <div className="right-side right-side-box">
                                    {this.renderRightSteps()}
                                </div > */}
                                </div >
                            ) : (currentStep === 6) ? (
                                <div className="tab-pane fade show active" id="Amenities" role="tabpanel" aria-labelledby="Amenities-tab">
                                    <div className="main-side">
                                        {this.renderStep()}
                                    </div >
                                </div >
                            ) : ''
                        }
                        <StepButtons updateParentState={this.updateCurrentState} logoUrl={logoUrl} changeErrClick={this.changeErrClick} readyToStartChange={this.props.selectFacility.readyToStartChange} facilityType={facilityType} changeCurrentStep={this.changeCurrentStep} branchList={branchList} activeBranchKey={activeBranchKey} currentStep={currentStep} />

                    </div >
                </section>
            </div>
        )
    }

    convertTimestamp(timestamp) {
        if (!timestamp) return null;

        var d = new Date(timestamp),	// Convert the passed timestamp to milliseconds
            yyyy = d.getFullYear(),
            mm = ('0' + (d.getMonth() + 1)).slice(-2),	// Months are zero based. Add leading 0.
            dd = ('0' + d.getDate()).slice(-2),			// Add leading 0.
            hh = d.getHours(),
            h = hh,
            min = ('0' + d.getMinutes()).slice(-2),		// Add leading 0.
            ampm = 'AM',
            time;

        if (hh > 12) {
            h = hh - 12;
            ampm = 'PM';
        } else if (hh === 12) {
            h = 12;
            ampm = 'PM';
        } else if (hh == 0) {
            h = 12;
        }

        // ie: 2013-02-18, 8:35 AM	
        time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;

        return time;
    }
    /*
     * Functions for content getting
     * */

    /**
     *
     * @returns {XML}
     */
    renderSidebar = () => {


        const { facilityType } = this.props.wizardOptions;
        let { branchList, logoUrl, activeBranchKey, uploadError, activeError, currentStep, loading } = this.state;
        let timeAdded = this.convertTimestamp(branchList[activeBranchKey].created);
        let timeClamied = this.convertTimestamp(branchList[activeBranchKey].added);
        let timeEdited = this.convertTimestamp(branchList[activeBranchKey].edited);
        if (currentStep === 8) return null;
        return (
            <div>
                <div className="logo">
                    <div className="clinicPic" style={!logoUrl && branchList[activeBranchKey].showError ? { borderColor: 'darkred' } : { borderColor: '#dadfe4' }}>
                        <img data-isset={!!logoUrl} src={logoUrl ? logoUrl : clinicPlaceholder} alt="IMG" />
                    </div>
                    <label className="imp" type="file" name="profPic" id="profPic">Update Logo <span className="req" style={{ color: 'darkred' }}>*</span>
                        <input onChange={this.addLogo} type="file" name="profPic" id="profPic" />
                    </label>
                    {
                        (uploadError) ? <p className="err">{uploadError}</p> : ''
                    }
                </div>

                <div className="profile-completition">
                    <p>Completition {String(this.checkCompletitionFields())}%</p>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{ width: String(this.checkCompletitionFields()).concat("%") }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>

                <div className="activities-date">
                    <i>{(timeAdded) ? <div>Added: {timeAdded}</div> : ''}</i>
                    <i>{(timeClamied) ? <div>Clamied: {timeClamied}</div> : ''}</i>
                    <i>{(timeEdited) ? <div>Edited: {timeEdited}</div> : ''}</i>
                </div>
                <StepsBar changeErrClick={this.changeErrClick} changeCurrentStep={this.changeCurrentStep} branchList={branchList} currentStep={currentStep} facilityType={facilityType} />
            </div>
        )
    }

    /**
     *
     * @returns {XML}
     */
    renderStep = () => {
        const { facilityType } = this.props.wizardOptions;
        const { suggestionId } = this.props;
        let { branchList, activeBranchKey, fileTypes, currentStep, claimed } = this.state;

        switch (currentStep) {
            case 1:
                return <BasicInfo
                    updateParentState={this.updateCurrentState}
                    applyToAll={this.applyToAll}
                    activeBranchKey={activeBranchKey}
                    branchList={branchList}
                    facilityType={facilityType}
                    fileTypes={fileTypes}
                    claimLogo={this.claimLogo}
                    suggestionId={suggestionId}
                    changeClaimed={this.changeClaimed}
                    claimed={claimed}
                />;
                break;

            case 2:
                return <Location
                    updateParentState={this.updateCurrentState}
                    activeBranchKey={activeBranchKey}
                    branchList={branchList}
                    facilityType={facilityType}
                />;
                break;
            case 3:
                return <Timings
                    updateParentState={this.updateCurrentState}
                    applyToAll={this.applyToAll}
                    activeBranchKey={activeBranchKey}
                    branchList={branchList}
                />;
                break;
            case 4:
                return <Specialties
                    updateParentState={this.updateCurrentState}
                    applyToAll={this.applyToAll}
                    activeBranchKey={activeBranchKey}
                    branchList={branchList}
                    specialties={this.props.specialties}

                />;
            case 5:
                return <Insurances
                    updateParentState={this.updateCurrentState}
                    applyToAll={this.applyToAll}
                    activeBranchKey={activeBranchKey}
                    branchList={branchList}
                />;
                break;
            case 6:
                return <Amenities
                    updateParentState={this.updateCurrentState}
                    applyToAll={this.applyToAll}
                    activeBranchKey={activeBranchKey}
                    branchList={branchList}
                />;
                break;

            // case 7:
            //     return <Doctors
            //         updateParentState={this.updateCurrentState}
            //         applyToAll={this.applyToAll}
            //         activeBranchKey={activeBranchKey}
            //         branchList={branchList}
            //         fileTypes={this.state.fileTypes}
            //     />;
            //     break;
        }

    }
    renderRightSteps = () => {
        let { branchList, activeBranchKey, currentStep } = this.state;

        switch (currentStep) {

            case 1:
                return <Verification
                    updateParentState={this.updateCurrentState}
                    applyToAll={this.applyToAll}
                    activeBranchKey={activeBranchKey}
                    branchList={branchList}
                />;
        }

    }

}

export default MainWizard