import React, { Component } from 'react';
import './AddPatient.css'
import Moment from 'moment';
import { Dropdown, Input, Button, Dimmer } from 'semantic-ui-react'
import fire, { auth, firestore } from 'firebase/app'
import 'firebase/database';
import 'firebase/auth';
import swal from 'sweetalert2';
import { connect } from 'react-redux';
import { updateBookings } from '../../../modules/bookingsModule';
import '../sweetalert.css'
import {
    DateInput,
    DateTimeInput,
} from 'semantic-ui-calendar-react';
import moment from 'moment';
import VideoChatContainer from '../confrence/VideoChatContainer';
class AddPatient extends Component {

    constructor(props) {
        super(props)
        this.state = {
            patientName: '',
            years: [],
            months: [{ value: "Jan", key: "Jan", count: 1 }, { value: "Feb", key: "Feb", count: 2 }, { value: "Mar", key: "Mar", count: 3 }, { value: "Apr", key: "Apr", count: 4 }, { value: "May", key: "May", count: 5 }, { value: "Jun", key: "Jun", count: 6 }, { value: "Jul", key: "Jul", count: 7 }, { value: "Aug", key: "Aug", count: 8 }, { value: "Sep", key: "Sep", count: 9 }, { value: "Oct", key: "Oct", count: 10 }, { value: "Nov", key: "Nov", count: 11 }, { value: "Dec", key: "Dec", count: 12 }],
            days: [],
            showCalender: false,
            patientBirth: '',
            doctors: this.props.patientData.clinicDoctors ? this.props.patientData.clinicDoctors : [],
            selectedDoctor: '',
            appointmentDate: null,
            services: [],
            selectedServices: null,
            selectedServicesName: null,
            // duration: 30,
            selectPatient: false,
            selectedPatientRecord: false,
            notes: this.props.patientData && this.props.patientData.acceptedPatientData ? this.props.patientData.acceptedPatientData.notes : '',
            cheched: true,
            referenceNumber: null,
            showRequired: false,
            patientOptions: [],
            showInsurance: false,
            selectedImg: ''
        }
    }


    componentWillMount = async () => {
        let _this = this
        let { patientData } = this.props
        let appointmentDate = ''
        let patienDetails = {}
        console.log('this.props.patientData', patientData)

        if (patientData.acceptedPatientData) {
            let { acceptedPatientData } = patientData
            this.getActivityLog()
            let { patientId, online } = acceptedPatientData
            if (patientId) {
                let patienSnap = await firestore().collection(online ? 'users' : 'offlineUsers').doc(patientId).get()
                patienDetails = patienSnap.data()
            }
            let selectedServicesName = ""
            if (acceptedPatientData.service) {
                let service = acceptedPatientData.service
                if (service === 'onlineConsult') {
                    selectedServicesName = 'Online Consultation'
                } else if (service === 'inpersonConsult') {
                    selectedServicesName = 'In Clinic Consultation'
                } else {
                    let serviceSnap = await fire.firestore().collection('services').doc(acceptedPatientData.service).get()
                    if (serviceSnap.data()) {
                        selectedServicesName = serviceSnap.data().name
                    }
                }
            }
            let receivedDate = patientData.acceptedPatientData.selectedDate
            appointmentDate = receivedDate
            let duration = patientData.acceptedPatientData.duration ? patientData.acceptedPatientData.duration : null
            _this.setState({ appointmentDate, duration, showRequired: true, selectedServicesName, patienDetails, referenceNumber: patienDetails?.RN }, () =>
                this.durationOptions()
            )
        }
        if (patientData.date) {
            let modifiedDate = patientData.date.format('MMM DD, YYYY') + " at " + patientData.date.format('hh:mm A')
            appointmentDate = modifiedDate
            _this.setState({ appointmentDate })

        }
    }

    selectDoctor = (e, { value }) => {
        let _this = this
        let { doctors } = this.state

        doctors = this.props.patientData.clinicDoctors
        let selectedDoctor = doctors.filter(item => {
            return item.name === value
        })
        var services = []
        if (selectedDoctor[0].services) {
            Object.keys(selectedDoctor[0].services).map(serviceKey => {
                fire.firestore().collection('services').doc(serviceKey).get().then(serviceSnap => {
                    if (serviceSnap.data()) {
                        services.push({ key: serviceKey, value: serviceSnap.data().name, text: serviceSnap.data().name })
                        _this.setState({ services })
                    }
                })
            })
        } else {
            _this.setState({ services: [] })
        }
        this.setState({ selectedDoctor: value, edited: true }, () => {
            this.durationOptions()

        })
    }
    calenderEndDate = (date) => {
        let stringDate = date.replace(" at", ",");
        let time = new Date(stringDate);
        time.setMinutes(time.getMinutes() + 30)
        time.setHours(time.getHours() - (time.getTimezoneOffset() / 60))
        return time.toISOString();
    }

    calenderStartDate = (date) => {
        let stringDate = date.replace(" at", ",");
        let formateed = new Date(stringDate)
        formateed.setHours(formateed.getHours() - (formateed.getTimezoneOffset() / 60))

        return formateed.toISOString();
    }
    diff_minutes = (dt2, dt1) => {
        var seconds = (dt2.getTime() - dt1.getTime()) / 1000;
        let mins = seconds / 60;
        let hours = mins / 60;
        let days = hours / 24;
        if (mins > 0 && mins <= 60) {
            return String(Math.round(mins)) + " Minutes ago";
        }
        if (hours <= 24) {
            return String(Math.round(hours)) + " Hours ago";
        } else {
            return String(Math.round(days)) + " Days ago";

        }
    }
    mapStatus(status, online) {
        if (status.after === status.before && status.after === "accepted") {
            return "rescheduled"
        }
        switch (status.after) {
            case "pending":
                return "reviewed";
            case "accepted":
                return online ? "confirmed" : 'created'
            case "rejected":
            case "removed":
                return "canceled"
            case "new":
                return "rescheduled"
            case "failed":
                return "no show"
            case "succeeded":
                return "arrived"
            default:
                return status.after
        }
    }
    getActivityLog() {
        if (this.props.patientData && this.props.patientData.acceptedPatientData) {

            let facilityId = Object.keys(this.props.selectFacility)[0]
            let bookingDetails = this.props.patientData.acceptedPatientData
            let bookingId = bookingDetails.bookingId
            let _this = this
            let activitiesArray = []
            //thrive patient rejected   
            if (bookingDetails.thrive && bookingDetails.status === "rejected") {
                let moment = Moment(new Date(bookingDetails.canceled))
                let date = moment.format('MMM DD,YYYY ') + "at " + moment.format('hh:mm A')

                activitiesArray.push({
                    type: 'Patient',
                    name: bookingDetails.patientName,
                    status: 'canceled',
                    date: bookingDetails.selectedDate,
                    timeStamp: date,
                    moment: bookingDetails.canceled,
                    doctorName: bookingDetails.doctorName,
                    patientName: bookingDetails.patientName
                })

            }
            fire.firestore().collection('activityLog').where('bookingId', '==', bookingId).get().then((resultsSnap) => {
                if (resultsSnap) {
                    //thrive patient rescheduled
                    if (bookingDetails.status === "new" && bookingDetails.thrive) {
                        let moment = Moment(new Date(bookingDetails.rescheduled))
                        let date = moment.format('MMM DD,YYYY ') + "at " + moment.format('hh:mm A')
                        let activity = {
                            ts: bookingDetails.rescheduled,
                            modifiedBy: fire.auth().currentUser.uid,
                            type: 'booking',
                            before: "new",
                            after: "new",
                            bookingId: bookingDetails.bookingId,
                            patientName: bookingDetails.patientName,
                            date: bookingDetails.selectedDate,
                            facilityId: facilityId
                        }
                        fire.firestore().collection('activityLog').add(activity)
                        activitiesArray.push({
                            type: 'Patient',
                            name: bookingDetails.patientName,
                            status: 'rescheduled',
                            date: bookingDetails.selectedDate,
                            timeStamp: date,
                            moment: bookingDetails.rescheduled,
                            doctorName: bookingDetails.doctorName,
                            patientName: bookingDetails.patientName,
                            facilityId: facilityId
                        })
                    }
                    var count = 0
                    resultsSnap.forEach(val => {
                        let record = val.data()
                        let adminKey = record.modifiedBy ? record.modifiedBy : auth().currentUser.uid
                        let moment = Moment(new Date(record.ts))
                        let date = moment.format('MMM DD,YYYY ') + "at " + moment.format('hh:mm A')
                        if (record.after === "requested") {

                            activitiesArray.push({
                                type: 'Patient',
                                name: bookingDetails.patientName,
                                status: 'requested',
                                date: record.date,
                                timeStamp: date,
                                moment: record.ts,
                                doctorName: bookingDetails.doctorName,
                                patientName: bookingDetails.patientName
                            })
                            count++
                            if (count >= resultsSnap.size) {
                                _this.setState({ logObject: activitiesArray })
                            }
                        } else {

                            fire.firestore().collection('users').doc(adminKey).get().then(adminName => {
                                if (adminName.exists) {
                                    activitiesArray.push({
                                        type: (record.after === "new" || record.after === "rejected") ? 'Patient' : 'Admin',
                                        name: (record.after === "new" || record.after === "rejected") ? bookingDetails.patientName : adminName.data().name,
                                        status: _this.mapStatus(record, bookingDetails.online),
                                        date: record.date,
                                        timeStamp: date,
                                        moment: record.ts,
                                        doctorName: bookingDetails.doctorName,
                                        patientName: bookingDetails.patientName
                                    })
                                    count++
                                    // activitiesArray = activitiesArray.filter((v,i) => activitiesArray.indexOf(v)===i);
                                    activitiesArray = activitiesArray.sort((a, b) => {
                                        if (a.moment > b.moment) {
                                            return -1
                                        }
                                        return 1
                                    })

                                    if (count >= resultsSnap.size) {
                                        _this.setState({ logObject: activitiesArray })
                                    }
                                } else {
                                    count++

                                    if (count >= resultsSnap.size) {
                                        _this.setState({ logObject: activitiesArray })
                                    }
                                }
                            })
                        }

                    })
                }
            })
        }
    }
    addPatientAppointment = () => {
        let { doctors, patientBirth, selectedDoctor, selectedPatientRecord, appointmentDate, duration, patientName, insurance, number, email, notes, selectedServices, referenceNumber, cheched } = this.state
        doctors = this.props.patientData.clinicDoctors
        if (!appointmentDate || !patientName || !selectedDoctor || !number) {
            return swal.fire({
                heightAuto: false,
                title: 'Alert',
                text: 'Please fill all required data',
                type: 'error',
            }).then(() => {
                this.setState({ showRequired: true })
            })
        } else {
            let facilityId = Object.keys(this.props.selectFacility)[0]
            let facility = this.props.selectFacility[facilityId]
            let doctor = doctors.filter(doctor => {
                if (doctor.name === selectedDoctor) {
                    return doctor
                }
            })[0]
            let date = appointmentDate.replace(' - ', ' at ')
            let online = selectedPatientRecord && selectedPatientRecord.online
            let thrive = selectedPatientRecord && selectedPatientRecord.thrive

            let userUid = selectedPatientRecord ? (selectedPatientRecord.key) : fire.firestore().collection('offlineUsers').doc().id;
            let bookingId = online ? fire.firestore().collection('bookings').doc().id : fire.firestore().collection("offlineBookings").doc().id;

            let optimizedStringDate = date.replace(" at", ",")

            let DatedDate = new Date(optimizedStringDate)
            DatedDate.setHours(DatedDate.getHours() - DatedDate.getTimezoneOffset() / 60)
            let timeStamp = Date.now()
            if (!thrive) {
                if (online) {
                    fire.firestore().collection('users').doc(userUid).set({ RN: (referenceNumber ? referenceNumber : null) }, { merge: true })
                } else {
                    fire.firestore().collection('offlineUsers').doc(userUid).set({ RN: (referenceNumber ? referenceNumber : null), updated: Date.now(), name: patientName, number: (number ? String(number) : ''), insurance: (insurance ? insurance : ''), email: (email ? email : ''), birthday: patientBirth }, { merge: true })
                }
            }
            let stringDate = date.replace(" at", ",");
            let optimizedDate = new Date(stringDate);
            let post = {
                created: timeStamp,
                date: date,
                dateTS: optimizedDate.getTime(),
                doctor: doctor.doctorId ? doctor.doctorId : doctor.id,
                facilityAdmin: (thrive && this.props.user.thriveId) ? this.props.user.thriveId : facility.users.superAdmin,
                facilityId: facilityId,
                facilityName: facility.facilityName,
                insurance: insurance ? insurance : '',
                patient: userUid,
                patientName: patientName,
                status: "accepted",
                duration: duration,
                notes: notes ? notes : null,
                service: selectedServices,
                modifiedBy: fire.auth().currentUser.uid,
                modified: timeStamp,
                isArchived: false,
                notify: cheched
            }

            fire.firestore().collection(online ? "bookings" : "offlineBookings").doc(bookingId).set(post).catch(error => {
            })


            let splittedDate = date.split(' at ')
            let slotDate = (splittedDate && splittedDate.length > 1) ? splittedDate[0] : "";
            let slotTime = (splittedDate && splittedDate.length > 1) ? splittedDate[1] : "";

            let formatter = Moment(slotTime, 'hh:mm A')
            let addedValue = 0
            let newSlots = []
            while (addedValue < duration) {
                newSlots.push(formatter.format('hh:mm A'))
                formatter.add(15, 'minutes')
                addedValue = addedValue + 15
            }
            let db = fire.firestore().collection('slots').doc((doctor.doctorId ? doctor.doctorId : doctor.id)).collection(facilityId).doc(slotDate)
            fire.firestore().runTransaction(function (transaction) {
                return transaction.get(db).then(value => {
                    let slots = (value.data() && value.data()[slotDate]) ? value.data()[slotDate] : []
                    slots = slots.concat(newSlots)
                    transaction.set(db, { [slotDate]: slots }, { merge: true })
                })
            })
            if (cheched && email && !online) {
                let offlineInvitationsKey = email.replace(/[.*@+?^${}()|[\]\\]/g, "").toLowerCase()
                fire.firestore().collection('offlineInvitations').doc(offlineInvitationsKey).set({ appointment: bookingId, email: email, patientName: patientName, clinicName: facility.facilityName, doctorName: doctor.name, date: date, facilityId: facility.facilityId, facilityAdmin: facility.users.superAdmin })
            }
            this.props.dismiss()
        }
    }
    confirmPatientAppointment = () => {
        let { appointmentDate, referenceNumber, duration } = this.state;
        let activeBooking = this.props.patientData.acceptedPatientData;
        let date = appointmentDate ? appointmentDate.replace(' - ', ' at ') : activeBooking.selectedDate
        swal.fire({
            heightAuto: false,
            title: 'Confirm Appointment',
            showCancelButton: true,
            html: "<div class='title'>Appointment for " + activeBooking.patientName + " will be set to</div> <div class='appointmentDate'> <div class='date'>" + appointmentDate + "</div> <div class='text'>, do you want to confirm?</div></div>",
            customClass: 'reschedulesweetalert',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Confirm and notify',

        }).then((result) => {
            if (result.value) {
                let stringDate = date.replace(" at", ",");
                let optimizedDate = new Date(stringDate);

                if (referenceNumber) {
                    fire.firestore().collection((activeBooking.online ? 'users' : 'offlineUsers')).doc(activeBooking.patientId).set({ "RN": referenceNumber }, { merge: true });
                }
                fire.firestore().collection(activeBooking.online ? 'bookings' : 'offlineBookings').doc(activeBooking.bookingId).update({ status: "accepted", accepted: (activeBooking.accepted ? activeBooking.accepted : Date.now()), date: date, dateTS: optimizedDate.getTime(), modified: Date.now(), duration: (duration ? duration : null), notes: this.state.notes, modifiedBy: fire.auth().currentUser.uid });

                if (activeBooking.thrive) {
                    let activity = {
                        ts: Date.now(),
                        modifiedBy: fire.auth().currentUser.uid,
                        type: 'booking',
                        before: activeBooking.status,
                        after: "accepted",
                        bookingId: activeBooking.bookingId,
                        patientName: activeBooking.patientName,
                        date: date,
                        facilityId: activeBooking.facilityId
                    }

                    fire.firestore().collection('activityLog').add(activity);
                }

                let splittedDate = activeBooking.selectedDate.split(' at ')
                let slotDate = (splittedDate && splittedDate.length > 1) ? splittedDate[0] : "";
                let slotTime = (splittedDate && splittedDate.length > 1) ? splittedDate[1] : "";
                let formatterOld = Moment(slotTime, 'hh:mm A')
                let oldSlots = []
                let addedValue = 0;
                while (addedValue <= (activeBooking.duration ? activeBooking.duration : duration)) {
                    oldSlots.push(formatterOld.format('hh:mm A'))
                    formatterOld.add(15, 'minutes')
                    addedValue = addedValue + 15
                }
                let db = fire.firestore().collection('slots').doc((activeBooking.doctorId ? activeBooking.doctorId : activeBooking.id)).collection(activeBooking.facilityId).doc(slotDate)
                fire.firestore().runTransaction(function (transaction) {
                    return transaction.get(db).then(value => {
                        let slots = (value.data() && value.data()[slotDate]) ? value.data()[slotDate] : []
                        oldSlots.forEach(slot => {
                            let slotindex = slots.indexOf(slot)
                            if (slotindex > -1) {
                                slots.splice(slotindex, 1)
                            }
                        })
                        transaction.set(db, { [slotDate]: slots }, { merge: true })

                    })
                }).then(() => {
                    // accept appointment
                    let splittedDate = date.split(' at ')
                    let slotDate = (splittedDate && splittedDate.length > 1) ? splittedDate[0] : "";
                    let slotTime = (splittedDate && splittedDate.length > 1) ? splittedDate[1] : "";
                    let formatter = Moment(slotTime, 'hh:mm A')
                    let addedValue = 0
                    let newSlots = []
                    while (addedValue < duration) {
                        newSlots.push(formatter.format('hh:mm A'))
                        formatter.add(15, 'minutes')
                        addedValue = addedValue + 15
                    }
                    fire.firestore().runTransaction(function (transaction) {
                        let db = fire.firestore().collection('slots').doc((activeBooking.doctorId ? activeBooking.doctorId : activeBooking.id)).collection(activeBooking.facilityId).doc(slotDate)
                        return transaction.get(db).then(value => {
                            let slots = (value.data() && value.data()[slotDate]) ? value.data()[slotDate] : []
                            slots = slots.concat(newSlots)
                            transaction.set(db, { [slotDate]: slots }, { merge: true })
                        })
                    })
                })
                this.props.dismiss()
            }
        })
    }

    componentDidUpdate() {
        if (document.getElementById("scrollToMonth")) {
            document.getElementById("scrollToMonth").scrollIntoView()
        }
        if (document.getElementById("scrollToDay")) {
            document.getElementById("scrollToDay").scrollIntoView()
        }
        if (document.getElementById("scrollToHours")) {
            document.getElementById("scrollToHours").scrollIntoView()
        }
        if (document.getElementById("scrollToMins")) {
            document.getElementById("scrollToMins").scrollIntoView()
        }
    }
    cancelPatientAppointment = () => {
        let { duration } = this.state;
        let activeBooking = this.props.patientData.acceptedPatientData;
        swal.fire({
            heightAuto: false,
            title: 'Are you sure?',
            text: "Appointment for " + activeBooking.patientName + " will be canceled and this action can't be reverted, are you sure?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3598db',
            cancelButtonText: 'Dismiss',
            confirmButtonText: 'Cancel and notify',
            reverseButtons: true,

        }).then((result) => {
            if (result.value) {
                let timeStamp = Date.now()
                fire.firestore().collection((activeBooking.online ? 'bookings' : 'offlineBookings')).doc(activeBooking.bookingId).update({ isArchived: true, status: "removed", removed: activeBooking.removed ? activeBooking.removed : timeStamp, modified: timeStamp, modifiedBy: fire.auth().currentUser.uid })
                let splittedDate = activeBooking.selectedDate.split(' at ')
                let slotDate = (splittedDate && splittedDate.length > 1) ? splittedDate[0] : "";
                let slotTime = (splittedDate && splittedDate.length > 1) ? splittedDate[1] : "";
                let formatter = Moment(slotTime, 'hh:mm A')
                let addedValue = 0
                let oldSlots = []
                while (addedValue < duration) {
                    oldSlots.push(formatter.format('hh:mm A'))
                    formatter.add(15, 'minutes')
                    addedValue = addedValue + 15
                }

                let db = fire.firestore().collection('slots').doc((activeBooking.doctorId ? activeBooking.doctorId : activeBooking.id)).collection(activeBooking.facilityId).doc(slotDate)
                fire.firestore().runTransaction(function (transaction) {
                    return transaction.get(db).then(value => {
                        let slots = (value.data() && value.data()[slotDate]) ? value.data()[slotDate] : []
                        oldSlots.forEach(slot => {
                            let slotindex = slots.indexOf(slot)
                            if (slotindex > -1) {
                                slots.splice(slotindex, 1)
                            }
                            transaction.set(db, { [slotDate]: slots }, { merge: true })
                        })
                    })
                })

                this.props.dismiss()
            }
        })
    }
    handlePatientSelection(selectedRecord) {

        let { patientOptions } = this.props
        let selectedUser = patientOptions?.filter(item => item.value === selectedRecord)[0]

        if (selectedUser) {
            if (selectedUser.number < 10) {
                selectedUser.number = Number("971" + selectedUser.number)
            }
            this.setState({
                patientBirth: (selectedUser.patientBirth ? selectedUser.patientBirth : ''),
                patientName: selectedUser.name,
                selectedPatientRecord: selectedUser,
                email: selectedUser.email,
                insurance: selectedUser.insurance,
                number: Number(selectedUser.number),
                referenceNumber: selectedUser.RN ? selectedUser.RN : '',
                edited: true
            })
        } else {
            this.setState({
                patientBirth: '',
                patientName: selectedRecord,
                email: '',
                insurance: '',
                selectedPatientRecord: false,
                edited: true
            })
        }
    }
    checkIfNumberExisted(addedRecord) {
        let { asyncOptions } = this.state
        let selectedUser = asyncOptions.find(item => (item.number ? (item.number.toString().includes(Number(addedRecord))) : false))
        if (selectedUser) {
            swal.fire({
                heightAuto: false,
                title: 'Alert',
                icon: 'warning',
                showCancelButton: true,
                text: "This number is already associated with " + selectedUser.name + ",Are you sure you want to add a new patient?",
                cancelButtonText: 'Select ' + selectedUser.name,
                confirmButtonText: 'Add New Patient',
            }).then((result) => {
                if (result.value) {
                    if (addedRecord && addedRecord.length < 12) {
                        addedRecord = "971" + Number(addedRecord)
                    }
                    this.setState({
                        referenceNumber: '',
                        patientBirth: '',
                        number: Number(addedRecord),
                        email: '',
                        patientName: '',
                        insurance: '',
                        selectedPatientRecord: false,
                        edited: true,
                    })

                } else if (result.dismiss) {
                    if (selectedUser.number && selectedUser.number.length < 12) {
                        selectedUser.number = Number("971" + Number(selectedUser.number))
                    }
                    let patientBirth = (selectedUser.patientBirth ? selectedUser.patientBirth : '')
                    this.setState({
                        patientBirth,
                        patientName: selectedUser.name,
                        selectedPatientRecord: selectedUser,
                        email: selectedUser.email ? selectedUser.email : '',
                        insurance: selectedUser.insurance ? selectedUser.insurance : '',
                        number: selectedUser.number ? Number(selectedUser.number) : '',
                        referenceNumber: selectedUser.RN ? selectedUser.RN : '',
                        edited: true,
                    })

                }

            })
        }
    }
    handleNumberSelection(selectedRecord) {
        let { asyncOptions } = this.state
        let selectedUser = asyncOptions?.find(item => item.value === selectedRecord)
        if (selectedUser) {
            if (selectedUser.number && selectedUser.number.length < 12) {
                selectedUser.number = Number("971" + Number(selectedUser.number))
            }
            let patientBirth = (selectedUser.patientBirth ? selectedUser.patientBirth : '')
            this.setState({
                patientBirth,
                patientName: selectedUser.name,
                selectedPatientRecord: selectedUser,
                email: selectedUser.email ? selectedUser.email : '',
                insurance: selectedUser.insurance ? selectedUser.insurance : '',
                number: selectedUser.number ? Number(selectedUser.number) : '',
                referenceNumber: selectedUser.RN ? selectedUser.RN : '',
                edited: true,
                // newAdded: true
            })
        } else {
            if (selectedRecord && selectedRecord.length < 12) {
                selectedRecord = "971" + Number(selectedRecord)
            }
            this.setState({
                patientBirth: '',
                number: Number(selectedRecord),
                email: '',
                patientName: '',
                insurance: '',
                selectedPatientRecord: false,
                edited: true,
                referenceNumber: '',
                // newAdded: true
            })
        }
    }

    checkDoctorTimings = (selectedDoctor, selectedDate) => {
        let { months } = this.state;
        var weekDays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
        let facilityId = Object.keys(this.props.selectFacility)[0]
        var daySelected = ''
        let date = selectedDate.split(',')[0]
        let month = date.split(' ')[0]
        let day = date.split(' ')[1]
        let test = false;
        months.map((item) => {
            if (item.value.toLowerCase() === month.toLowerCase()) {
                return daySelected = weekDays[new Date(new Date().getFullYear(), item.count - 1, day).getDay()]
            }
        })

        // return (
        if (this.props.patientData.clinicDoctors) {
            this.props.patientData.clinicDoctors.filter(item => {
                if (item.name === selectedDoctor) {
                    if (item.doctorTimings) {
                        if (item.doctorTimings[facilityId] && item.doctorTimings[facilityId][daySelected]) {
                            let timeSelected = Moment(selectedDate.split(' at ')[1], 'hh:mm A');
                            if (item.doctorTimings[facilityId][daySelected]) {
                                if (!item.doctorTimings[facilityId][daySelected].isSplitShift) {

                                    let fromTime = Moment(item.doctorTimings[facilityId][daySelected].fromTime, 'hh:mm A').add(-1, 'minute');
                                    let toTime = Moment(item.doctorTimings[facilityId][daySelected].toTime, 'hh:mm A');

                                    if (!timeSelected.isBetween(fromTime, toTime)) {
                                        test = true;
                                    } else {
                                        test = false;
                                    }

                                } else {

                                    let fromTime = Moment(item.doctorTimings[facilityId][daySelected].fromTime, 'hh:mm A').add(-1, 'minute');
                                    let toTime = Moment(item.doctorTimings[facilityId][daySelected].toTime, 'hh:mm A');
                                    let pmFromTime = Moment(item.doctorTimings[facilityId][daySelected].pmFromTime, 'hh:mm A').add(-1, 'minute');
                                    let pmToTime = Moment(item.doctorTimings[facilityId][daySelected].pmToTime, 'hh:mm A');

                                    if (!timeSelected.isBetween(fromTime, toTime) && !timeSelected.isBetween(pmFromTime, pmToTime)) {
                                        test = true;
                                    } else {
                                        test = false;
                                    }
                                }
                            } else {
                                test = true
                            }
                        } else {
                            test = true
                        }
                    } else {
                        test = true
                    }
                }
            })
        }
        return test
        // )
    }
    timeConvert(n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);

        return (rhours > 0 ? (rhours + " hour ") : '') + (rminutes > 0 ? (rminutes + " min") : '');
    }
    durationOptions = async () => {
        let patient = this.props.patientData
        let { selectedDoctor, doctors, duration } = this.state
        let facilityId = Object.keys(this.props.selectFacility)[0]
        let slotDuration = 15

        if (patient.acceptedPatientData) {
            if (patient.acceptedPatientData?.doctorDuration) {
                slotDuration = patient.acceptedPatientData?.doctorDuration[facilityId]

            } else {
                let rec = await fire.firestore().collection('doctors')
                    .doc(patient.acceptedPatientData.doctorId)
                    .collection('services')
                    .doc(patient.acceptedPatientData.facilityId)
                    .get()
                if (rec.exists) {
                    let data = rec.data()
                    let selectedServiceKey = patient?.acceptedPatientData?.service
                    let selectedService = data[selectedServiceKey]
                    if (selectedService) {
                        slotDuration = selectedService.duration
                    }
                }
            }
        } else if (selectedDoctor.length) {
            let doctor = doctors.filter(item => {
                return item.name === selectedDoctor
            })
            slotDuration = doctor[0].duration ? doctor[0].duration[facilityId] : 15
        }
        let options = []
        let step = slotDuration || 15
        slotDuration = step
        while (options.length < 24) {
            options.push({ key: slotDuration, minsValue: slotDuration, value: slotDuration, text: this.timeConvert(slotDuration) })
            slotDuration = slotDuration + step
        }
        if (!duration) {
            duration = step
        }
        this.setState({ durationOptions: options, duration })
    }
    doctorsOptions() {
        let doctors = this.props.patientData.clinicDoctors ? this.props.patientData.clinicDoctors : []
        return doctors.map(item => {
            return ({ text: item.name, value: item.name, key: item.doctorId })
        })
    }
    searchFunction = async (e, { searchQuery }) => {
        let onlineResult = await firestore().collection('users')
            .where("phone", ">=", searchQuery)
            .where("phone", "<=", searchQuery + "\uf8ff")
            .orderBy('phone')
            .limit(3)
            .get()

        let asyncOptionsOnline = onlineResult.docs.map(rec => {
            let userRecord = rec.data()
            let name = userRecord.name ? userRecord.name : userRecord.firstName ? (userRecord.firstName + " " + (userRecord.lastName ? userRecord.lastName : '')) : ''

            return { name, label: { color: 'green' }, RN: userRecord.RN, text: userRecord.phone, value: rec.id, key: rec.id, number: userRecord.phone, email: userRecord.email, patientBirth: userRecord.birthday, insurance: userRecord.insurance, online: true }
        })
        let offlineResult = await firestore().collection('offlineUsers')
            .where("number", ">=", searchQuery)
            .where("number", "<=", searchQuery + "\uf8ff")
            .orderBy('number')
            .limit(3)
            .get()

        let asyncOptionsOffline = offlineResult.docs.map(rec => {
            let userRecord = rec.data()
            let name = userRecord.name ? userRecord.name : userRecord.firstName ? (userRecord.firstName + " " + (userRecord.lastName ? userRecord.lastName : '')) : ''
            return { name, label: { color: 'red' }, RN: userRecord.RN, text: userRecord.number, value: rec.id, key: rec.id, number: userRecord.number, email: userRecord.email, patientBirth: userRecord.birthday, insurance: userRecord.insurance, online: false }
        })
        console.log('patientOptionspatientOptions', asyncOptionsOnline, asyncOptionsOffline)

        this.setState({ asyncOptions: asyncOptionsOnline.concat(asyncOptionsOffline) })
    }

    render() {
        const patient = this.props.patientData
        let { patientName,
            insurance,
            number,
            email,
            appointmentDate,
            selectedDoctor,
            durationOptions,
            services,
            selectPatient,
            notes,
            selectedServicesName,
            cheched,
            referenceNumber,
            showRequired,
            openVideo,
            showInsurance,
            selectedImg,
            patienDetails
        } = this.state;

        if (openVideo) {
            return (
                <div className="addnewpatientpopup">
                    <div className="popup">
                        <div style={{ color: 'red', justifyContent: 'flex-end', display: 'flex', position: 'absolute', right: '30px', top: '10px', cursor: 'pointer', zIndex: '100' }}
                            onClick={() => this.props.dismiss()}>
                            <div style={{ width: '5%', textAlign: 'center', fontWeight: '800', fontSize: '20px' }}>
                                x
                            </div>
                        </div>
                        <VideoChatContainer
                            callId={patient.acceptedPatientData.bookingId}
                            dismissCall={() =>
                                this.props.dismiss()
                            }
                        />
                    </div>
                </div>
            )
        }
        return (
            <div className="addnewpatientpopup">
                <div className="popup">
                    <div style={{ color: 'red', justifyContent: 'flex-end', display: 'flex', position: 'absolute', right: '30px', top: '10px', cursor: 'pointer', zIndex: '100' }} onClick={() => this.props.dismiss()}>
                        <div style={{ width: '5%', textAlign: 'center', fontWeight: '800', fontSize: '20px' }}>
                            x
                        </div>

                    </div>
                    {
                        (patient.acceptedPatientData) ? (
                            <div className="addpatientcopy">
                                {(["new", "pending"].includes(patient.acceptedPatientData.status)) ? 'Confirm appointment' : 'Appointment'}<br />
                                {
                                    (["new", "pending"].includes(patient.acceptedPatientData.status)) ?
                                        <div style={{ fontSize: '11px', color: '#F43A59' }}>Patient will stay <strong>pending</strong> until you confirm the appointment</div>
                                        : ''
                                }

                                <div className='blueLineContainer'>
                                    <div className="blueLine" />
                                </div>

                            </div>
                        ) : (
                            <div className="addpatientcopy">
                                <div>Add appointment</div>
                                <div className='blueLineContainer'>
                                    <div className="blueLine" />
                                </div>
                            </div>
                        )
                    }

                    <div className="stackedgroup4">
                        <div style={{ fontWeight: '800', padding: '0 28px 6px', color: 'black', fontSize: '15px' }}>Patient details:</div>
                        <div className="stackedgroup41" style={selectPatient ? { zIndex: 103 } : { zIndex: 0 }}>
                            <div className="stackedgroup42" style={selectPatient ? { zIndex: 103 } : { zIndex: 0 }}>
                                <div className="inputfieldscopy11">
                                    <div className="patientname">Mobile number</div>
                                    {
                                        (patient.acceptedPatientData) ? (
                                            <Dropdown placeholder="ex. 97156XXXXXXX" selection defaultValue={patienDetails?.phone || patienDetails?.number} text={patienDetails?.phone || patienDetails?.number} disabled className="inputfieldmandatory" />
                                        ) : (
                                            <Dropdown
                                                placeholder="ex. 97156XXXXXXX"
                                                searchInput='number'
                                                type='number'
                                                labeled
                                                selection
                                                minCharacters={4}
                                                allowAdditions
                                                additionLabel='Add New '
                                                onSearchChange={this.searchFunction}
                                                search
                                                selectOnBlur={false}
                                                lazyLoad
                                                options={selectPatient ? this.state.asyncOptions : this.state.asyncOptions}
                                                error={number ? ((number.toString().length > 13 || number.toString().length < 9) ? true : false) : false}
                                                value={number}
                                                text={number}
                                                className="inputfieldmandatory"
                                                onChange={(e, { value }) => this.handleNumberSelection(value)}
                                                onOpen={() => this.setState({ selectPatient: true })}
                                                onAddItem={(e, { value }) => this.checkIfNumberExisted(value)}
                                                onClose={() => this.setState({ selectPatient: false })}
                                                error={showRequired && !number}
                                            />
                                        )
                                    }

                                </div>

                                <div className="inputfieldscopy11">
                                    <div className="patientname">Reference Number</div>
                                    <Input disabled={patient.hideActions} className="inputfieldmandatory" error={(!referenceNumber || referenceNumber === '') && showRequired ? true : false} placeholder="ex. 123" value={referenceNumber ? referenceNumber : ''} text={referenceNumber ? referenceNumber : ''} onChange={(e, { value }) => this.setState({ referenceNumber: value, edited: true })} />
                                </div>
                            </div>
                            <div className="stackedgroup42" style={{ top: '15px' }}>
                                <div className="inputfieldscopy11">
                                    <div className="patientname">Full Name</div>
                                    {
                                        (patient.acceptedPatientData) ? (
                                            <Dropdown placeholder='Jamal Mostafa' selection defaultValue={patient.acceptedPatientData.patientName} text={patient.acceptedPatientData.patientName} disabled className="inputfieldmandatory" />
                                        ) : (
                                            //onSearchChange={(e,{searchQuery})=>{e.persist(); this.debounceing(searchQuery)}}
                                            // this.state.newAdded ?
                                            <Input className="inputfieldmandatory" placeholder='Jamal Mostafa' onChange={(e, { value }) => this.setState({ patientName: value })} error={!patientName && showRequired} value={patientName} text={patientName} />
                                            // : <Dropdown
                                            //     placeholder='Jamal Mostafa'
                                            //     minCharacters={2}
                                            //     selection
                                            //     allowAdditions
                                            //     search={(search, query) => this.searchFunction(search, query)}
                                            //     selectOnBlur={false}
                                            //     lazyLoad
                                            //     options={selectPatient ? patientOptions : []}
                                            //     error={!patientName && showRequired}
                                            //     value={patientName}
                                            //     text={patientName}
                                            //     className="inputfieldmandatory"
                                            //     onChange={(e, { value }) => this.handlePatientSelection(value)}
                                            //     onOpen={() => this.setState({ selectPatient: true })}
                                            //     onClose={() => this.setState({ selectPatient: false })}
                                            // />
                                        )
                                    }

                                </div>
                                <div className="inputfieldscopy11">
                                    <div className="patientname">Email ID</div>
                                    {
                                        (patient.acceptedPatientData) ? (
                                            <Input defaultValue={patienDetails?.email} disabled className="inputfieldmandatory" />
                                        ) : (
                                            <Input className="inputfieldmandatory" error={showRequired ? (email && email !== '') ? !email.includes("@") || !email.includes(".") : true : false} value={email} placeholder="ex. thriver@thrivemedical.ae" text={email} onChange={(e, { value }) => this.setState({ email: value, edited: true })} />
                                        )
                                    }

                                </div>
                            </div>

                        </div>

                        <div className="stackedgroup55">
                            <div className="inputfieldscopy11">
                                <div className="patientname" style={{ marginBottom: '6px' }}>Date of Birth</div>
                                <DateInput
                                    input={true}
                                    inputProps={{ readOnly: true }}
                                    startMode='year'
                                    maxDate={moment()}
                                    style={{ width: '100%', pointerEvents: (patient.acceptedPatientData ? 'none' : 'auto'), color: (patient.acceptedPatientData ? 'rgba(155, 154, 155, 1.0)' : 'rgba(0,0,0,.87)') }}
                                    dateFormat='MMM DD, YYYY'
                                    name="date"
                                    placeholder="Date of Birth"
                                    value={this.state.patientBirth ? this.state.patientBirth : patienDetails?.birthday}
                                    iconPosition="left"
                                    onChange={(e, { value }) => { moment(value, 'MMM DD, YYYY', true).isValid() && this.setState({ patientBirth: value, edited: true }) }}
                                    closable={true}
                                    closeOnMouseLeave={false}
                                    popupPosition='bottom center'
                                    animation='fade'
                                    preserveViewMode={false}
                                />
                            </div>
                            <div className="inputfieldscopy11">
                                <div className="patientname">Insurance</div>
                                {
                                    (patient.acceptedPatientData) ? (
                                        <Input defaultValue={patient.acceptedPatientData.paymentType === "Insurance" ? patient.acceptedPatientData.insurance.insurance : patient.acceptedPatientData.insurance} disabled className="inputfieldmandatory" />
                                    ) : (
                                        <Input className="inputfieldmandatory" placeholder="ex. Insurance(Network)" value={insurance} text={insurance} onChange={(e, { value }) => this.setState({ insurance: value, edited: true })} />
                                    )
                                }
                            </div>
                        </div>
                        <div style={{ fontWeight: '800', padding: '16px 28px 0', color: 'black', fontSize: '15px' }}>Appointment details:</div>
                        <div className="inputfieldscopy14" >
                            <div className="patientname">Doctor</div>
                            {
                                (patient.acceptedPatientData) ? (
                                    <Input defaultValue={patient.acceptedPatientData.doctorName} disabled className="inputfieldmandatory" />
                                ) : (
                                    <Dropdown lazyLoad error={!selectedDoctor && showRequired} upward selection options={this.doctorsOptions()} value={selectedDoctor} search className="inputfieldmandatory" onChange={this.selectDoctor} selectOnBlur={false} />
                                )
                            }
                        </div>
                        <div className="stackedgroup55" >
                            <div className="inputfieldscopy11" >
                                <div className="patientname">Appointment Date</div>
                                <DateTimeInput
                                    inputProps={{ readOnly: true }}
                                    style={{ width: '100%', pointerEvents: (patient.hideActions ? 'none' : 'auto'), color: (patient.hideActions ? 'rgba(155, 154, 155, 1.0)' : 'rgba(0,0,0,.87)') }}
                                    dateFormat='MMM DD, YYYY'
                                    divider=' - '
                                    className="inputfieldmandatory"
                                    hideMobileKeyboard={true}
                                    name="dateTime"
                                    placeholder="Date Time"
                                    value={this.state.appointmentDate ? this.state.appointmentDate : ''}
                                    iconPosition="left"
                                    onChange={(e, { value }) => moment(value, 'MMM DD, YYYY - hh:mm a', true).isValid() && this.setState({ appointmentDate: value.replace(' - ', ' at '), edited: true })}
                                    closable={true}
                                    closeOnMouseLeave={false}
                                    timeFormat='AMPM'
                                    popupPosition='top center'
                                    animation='fade'
                                    preserveViewMode={false}
                                    disable={patient.hideActions}
                                />
                            </div>

                            <div className="inputfieldscopy11">
                                <div className="patientname">Duration</div>
                                <Dropdown lazyLoad disabled={patient.hideActions || !durationOptions} upward={true} selection selectOnBlur={false} options={durationOptions} text={this.state.duration ? this.timeConvert(this.state.duration) : ''} className="inputfieldmandatory" onChange={(e, { value }) => {
                                    durationOptions.filter(item => {
                                        return (item.value === value ? this.setState({ duration: item.minsValue, edited: true }) : '')
                                    })
                                }} />
                            </div>
                        </div>
                        {
                            (patient.acceptedPatientData) ? (
                                (patient.acceptedPatientData.doctorName && appointmentDate) ? (
                                    (this.checkDoctorTimings(patient.acceptedPatientData.doctorName, appointmentDate)) ? (
                                        <div style={{ position: 'relative', color: 'red', fontWeight: '500', margin: '0 30px' }}>Outside doctor timings</div>
                                    ) : ''
                                ) : '') : (
                                (selectedDoctor && appointmentDate) ? (
                                    (this.checkDoctorTimings(selectedDoctor, appointmentDate)) ? (
                                        <div style={{ position: 'relative', color: 'red', margin: '0 30px', fontWeight: '500' }}>Outside doctor timings</div>
                                    ) : ''
                                ) : '')
                        }

                        {
                            (patient.acceptedPatientData && (["new", "pending"].includes(patient.acceptedPatientData.status))) ? (
                                <div style={{ padding: '5px', background: '#3498db14', border: '0.6px solid #3498db', margin: '10px 20px', borderRadius: '4px', fontSize: '9px', color: '#3498db', textAlign: 'center' }}>After you call the patient, add the final appointment timings so we can remind patient before the appointment</div>
                            ) : ''
                        }
                        <div className="stackedgroup55" style={(patient.acceptedPatientData && (["new", "pending"].includes(patient.acceptedPatientData.status))) ? ({ zIndex: '100' }) : { zIndex: '100' }}>
                            <div className="inputfieldscopy11" style={{ height: '52px' }}>
                                <div className="patientname">Services requested</div>
                                {
                                    (patient.acceptedPatientData) ? (
                                        <Input style={{ top: '6px' }} value={selectedServicesName ? selectedServicesName : "General Consultation"} disabled className="inputfieldmandatory" />
                                    ) : (
                                        <Dropdown lazyLoad selectOnBlur={false} className="inputfieldmandatory" style={{ top: '6px' }} upward disabled={!selectedDoctor} selection fluid options={services} search onChange={(e, { value }) => { services.map((item) => { if (item.value === value) { this.setState({ selectedServices: item.key, edited: true }) } }) }} />
                                    )
                                }

                            </div>
                            <div className="inputfieldscopy16">
                                <div className="patientname">Notes</div>
                                <Input disabled={patient.hideActions} className="inputfieldmandatory" placeholder="ex. needs wheelchair on arrival" type='text' value={notes ? notes : ''} onChange={(e, { value }) => this.setState({ notes: value, edited: true })} />
                            </div>
                        </div>
                        {
                            (patient.acceptedPatientData) ? (
                                patient.acceptedPatientData.paymentType === "Insurance" ?
                                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ width: '50%' }}>
                                            <div style={{ fontWeight: '800', padding: '16px 28px 0', color: 'black', fontSize: '15px' }}>Payment details:</div>
                                            <div style={{ padding: '16px 28px 0', display: 'flex', alignItems: 'center' }}>
                                                <div>Payment Type:</div>
                                                <div style={{ fontSize: '13px', color: 'black', fontWeight: '700', marginLeft: '8px' }}>{patient.acceptedPatientData.paymentType}</div>
                                            </div>
                                            <div style={{ padding: '8px 28px 0', display: 'flex', alignItems: 'center' }}>
                                                <div>Insurance:</div>
                                                <div style={{ fontSize: '13px', color: 'black', fontWeight: '700', marginLeft: '8px' }}>{patient.acceptedPatientData.insurance.insurance}</div>
                                            </div>
                                            <div style={{ padding: '8px 28px 0', display: 'flex', alignItems: 'center' }}>
                                                <div>Network:</div>
                                                <div style={{ fontSize: '13px', color: 'black', fontWeight: '700', marginLeft: '8px' }}>{patient.acceptedPatientData.insurance.network}</div>
                                            </div>
                                            <div style={{ padding: '8px 28px 0', display: 'flex', alignItems: 'center' }}>
                                                <div>Company Name:</div>
                                                <div style={{ fontSize: '13px', color: 'black', fontWeight: '700', marginLeft: '8px' }}>{patient.acceptedPatientData.insurance.companyName}</div>
                                            </div>
                                            <div style={{ padding: '8px 28px 0', display: 'flex', alignItems: 'center' }}>
                                                <div>Staff Number:</div>
                                                <div style={{ fontSize: '13px', color: 'black', fontWeight: '700', marginLeft: '8px' }}>{patient.acceptedPatientData.insurance.staffNumber}</div>
                                            </div>
                                            <div style={{ padding: '8px 28px 0', display: 'flex', alignItems: 'center' }}>
                                                <div>Total Price:</div>
                                                <div style={{ fontSize: '13px', color: 'black', fontWeight: '700', marginLeft: '8px' }}>{patient.acceptedPatientData.amount}</div>
                                            </div>
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <div style={{ fontWeight: '800', padding: '16px 0 0', color: 'black', fontSize: '15px' }}></div>
                                            <div style={{ padding: '16px 0 0' }}>
                                                <div>Insurance Front:</div>
                                                <img onClick={() => this.setState({ showInsurance: true, selectedImg: patient.acceptedPatientData.insurance.frontImage })} style={{ height: '50px', width: '40%', objectFit: 'cover', filter: 'contrast(0.5)', cursor: 'pointer' }} src={patient.acceptedPatientData.insurance.frontImage} />
                                            </div>
                                            <div style={{ padding: '8px 0 0' }}>
                                                <div>Insurance Back:</div>
                                                <img onClick={() => this.setState({ showInsurance: true, selectedImg: patient.acceptedPatientData.insurance.backImage })} style={{ height: '50px', width: '40%', objectFit: 'cover', filter: 'contrast(0.5)', cursor: 'pointer' }} src={patient.acceptedPatientData.insurance.backImage} />
                                            </div>

                                        </div>
                                    </div> :
                                    patient.acceptedPatientData.paymentType === "Card" ?
                                        <div>
                                            <div style={{ fontWeight: '800', padding: '16px 28px 0', color: 'black', fontSize: '15px' }}>Payment details:</div>
                                            <div style={{ padding: '16px 28px 0', display: 'flex', alignItems: 'center' }}>
                                                <div>Payment Type:</div>
                                                <div style={{ fontSize: '13px', color: 'black', fontWeight: '700', marginLeft: '8px' }}>{patient.acceptedPatientData.paymentType}</div>
                                            </div>
                                            <div style={{ padding: '8px 28px 0', display: 'flex', alignItems: 'center' }}>
                                                <div>Transaction ID:</div>
                                                <div style={{ fontSize: '13px', color: 'black', fontWeight: '700', marginLeft: '8px' }}>{patient.acceptedPatientData.authId}</div>
                                            </div>
                                            <div style={{ padding: '8px 28px 0', display: 'flex', alignItems: 'center' }}>
                                                <div>Total Price:</div>
                                                <div style={{ fontSize: '13px', color: 'black', fontWeight: '700', marginLeft: '8px' }}>{patient.acceptedPatientData.amount} {process.env.REACT_APP_CURRNECY}</div>
                                            </div>

                                        </div> :
                                        patient.acceptedPatientData.paymentType === "Cash" ?
                                            <div>
                                                <div style={{ fontWeight: '800', padding: '16px 28px 0', color: 'black', fontSize: '15px' }}>Payment details:</div>
                                                <div style={{ padding: '16px 28px 0', display: 'flex', alignItems: 'center' }}>
                                                    <div>Payment Type:</div>
                                                    <div style={{ fontSize: '13px', color: 'black', fontWeight: '700', marginLeft: '8px' }}>{patient.acceptedPatientData.paymentType}</div>
                                                </div>
                                                <div style={{ padding: '8px 28px 0', display: 'flex', alignItems: 'center' }}>
                                                    <div>Total Price:</div>
                                                    <div style={{ fontSize: '13px', color: 'black', fontWeight: '700', marginLeft: '8px' }}>{patient.acceptedPatientData.amount} {process.env.REACT_APP_CURRNECY}</div>
                                                </div>
                                            </div> : ''

                            ) : ''
                        }

                    </div>
                    {
                        (patient.acceptedPatientData) ? (
                            <div className="submitButtons" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                <Button disabled={patient.hideActions} onClick={() => this.cancelPatientAppointment()} className="cancel" color='red' >Cancel Appointment</Button>
                                {
                                    patient.acceptedPatientData.status === "accepted" && patient.acceptedPatientData.service === "onlineConsult" ?
                                        ''
                                        // <Button onClick={() => {
                                        //         this.setState({ openVideo: true })
                                        //     }} className="cancel" color='green' >Create Room</Button>
                                        :
                                        <Button disabled={!this.state.edited && !["new", "pending"].includes(patient.acceptedPatientData.status)} onClick={() => this.confirmPatientAppointment()} className="next" color='blue' style={{ width: '98px', marginLeft: '8px' }}>Confirm</Button>
                                }
                            </div>
                        )
                            : (
                                <div className="submitButtons" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                                        <div style={patient.hideActions ? { display: 'none' } : {}} style={{ position: 'relative' }} onClick={() => this.setState({ cheched: !cheched, edited: true })}>
                                            {
                                                <i style={{ fontSize: '17px', color: '#3598db' }} className={cheched ? "far fa-check-square" : "far fa-square"}></i>
                                            }
                                        </div>
                                        <div style={patient.hideActions ? { display: 'none' } : {}} style={{ position: 'relative', left: '10px', color: 'black' }}>Notify patient by SMS and Email</div>
                                    </div>
                                    <Button style={patient.hideActions ? { display: 'none' } : ''} disabled={!this.state.edited} onClick={() => this.addPatientAppointment()} className="next" color='blue' style={{ width: '98px', marginLeft: '8px' }}>Save</Button>
                                </div>
                            )
                    }
                    {
                        this.state.logObject ?
                            <div>
                                <div style={{ color: 'rgba(151, 151, 151, 1)', textAlign: 'center', width: '100%', margin: '12px 0', fontSize: '19px' }}>--------------------------------------------------------------------------------------</div>
                                <div className="activityLog">
                                    <div className="title">Activity</div>
                                    <div className="content">
                                        {

                                            (this.state.logObject).map((item, index) => {
                                                return (
                                                    <div key={index} className="logRecord">
                                                        {
                                                            ((item.status === "confirmed") || (item.status === "requested")) ?
                                                                <div className="recordDetail">{item.type + ": "}<span className="bolded">{item.name}</span>{" " + item.status + " appointment "}<span className="bolded"></span> {" with "}<span className="bolded">{item.doctorName}</span>{" on "}<span className="bolded">{item.date}</span></div>
                                                                : ((item.status === "created") || (item.status === "rescheduled")) ?
                                                                    <div className="recordDetail">{item.type + ": "}<span className="bolded">{item.name}</span>{" " + item.status + " appointment "}{" for "}<span className="bolded">{item.patientName}</span> {" with "}<span className="bolded">{item.doctorName}</span>{item.status === "created" ? " at " : " to "}<span className="bolded">{item.date}</span></div>
                                                                    :
                                                                    <div className="recordDetail">{item.type + ": "}<span className="bolded">{item.name}</span>{" " + item.status + " " + item.patientName}</div>

                                                        }
                                                        <div className="timeStamp"> {item.timeStamp}</div>
                                                        <div className="separator" />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                </div>
                            </div>
                            : ''
                    }
                    <Dimmer active={showInsurance} onClick={() => this.setState({ showInsurance: false })}>
                        <div style={{ color: 'red', justifyContent: 'flex-end', display: 'flex', position: 'absolute', right: '30px', top: '10px', cursor: 'pointer', zIndex: '100' }}
                            onClick={() => this.setState({ showInsurance: false })}>
                            <div style={{ width: '15%', textAlign: 'center', fontWeight: '800', fontSize: '24px' }}>x</div>
                        </div>
                        <img style={{ width: '30vw' }} src={selectedImg} />
                    </Dimmer>

                </div>

            </div >

        )
    }


}
function mapStateToProps(state) {
    return {
        user: state.user.data.user,
        branches: state.branches,
        Bookings: state.Bookings
    }
}

const mapDispatchToProps = dispatch => ({
    // getBookings: (data, onlineBookingList, clinicDoctors, oldList) => dispatch(getBookings(data, onlineBookingList, clinicDoctors, oldList)),
    updateBookings: (updatedList) => dispatch(updateBookings(updatedList)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddPatient)