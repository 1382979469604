import React, { Component } from 'react';
import PropTypes from 'prop-types'
import ImageUploader from 'react-images-upload'
import fire from 'firebase/app'
import 'firebase/database';
import 'react-image-crop/lib/ReactCrop.scss';
import ReactCrop from 'react-image-crop';
import { Input } from 'semantic-ui-react'
import Resizer from 'react-image-file-resizer';

function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width*scaleX;
    canvas.height = crop.height*scaleY;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY,
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob(file => {
            if (file) {
                Resizer.imageFileResizer(
                    file,
                    800,
                    800,
                    'jpeg',
                    70,
                    0,
                    uri => {
                      var fr = new FileReader();
                      fr.onload = function () {
                        uri.base64 = fr.result
                        uri.name = fileName;
                        resolve(uri);            
                      }
                      fr.readAsDataURL(uri);
                    },
                    'blob'
                  );
            }
        }, 'image/jpeg');
    });
}
class BasicInfo extends Component {

    static propTypes = {
        branchList: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        facilityType: PropTypes.string.isRequired,
        updateParentState: PropTypes.func.isRequired,
        changeClaimed: PropTypes.func.isRequired,
        fileTypes: PropTypes.array.isRequired,
    }
    state = {
        descriptionLength: 450,
        descriptionCurrentLength: 450,
        descriptionCurrentLength_ar: 450,
        // facilityNameArabicSupport: !!this.props.branchList[this.props.activeBranchKey].facilityName_ar.length,
        descriptionArabicSupport: !!this.props.branchList[this.props.activeBranchKey].description_ar.length,
        clinic_ar: 'العيادة',
        hospital_ar: 'المستشفى',
        errors: {},
        clinics: [],

        suggestions: null,
        suggestionId: null,
        suggestionClinic: null,
        modal: null,
        crop: {
            aspect: 16 / 9,
            width: 100,
            x: 0,
            y: 0,
            height: 56.25,
            unit: 'px'
        },
        cropedPicture: null,
        croppedImg: null,
        pic: null,
        width: 1920,
        height: 1080
    }

    constructor(props, context) {
        super(props, context);
    }



    /**
     * Add pictures
     * @param picture
     */
    onDropPic = (picture) => {
        let _this = this,
            list = this.props.branchList,
            err = '';
        const { activeBranchKey } = this.props;

        Array.from(picture).forEach(pic => {
            let allow = true,
                sizeErr = false
            //check if exist uploaded item
            list[activeBranchKey].pictures.map(function (item) {
                if (item.name === pic.name) {
                    allow = false;
                }
            })

            if (allow) {
                let extension = pic.name.split('.').pop().toLowerCase(),
                    isSuccess = _this.props.fileTypes.indexOf('.' + extension) > -1;
                if (pic.size > 5242880) {
                    isSuccess = true;
                    sizeErr = false;
                }
                if (isSuccess) {
                    let reader = new FileReader();
                    reader.readAsDataURL(pic);
                    reader.onload = function () {
                        _this.setState({
                            modalCrop: document.createElement('div'),
                            cropedPicture: reader.result,
                            pic: pic,
                        }, () => {
                            // document.body.appendChild(_this.state.modalCrop);
                            // _this.renderCropPopup(_this.state.modalCrop, reader.result);
                        })


                    };
                } else {
                    err = sizeErr ? '* Attachment size limit is 5 MB' : '* .' + extension + ' is not supported file extension '

                }
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        [activeBranchKey]: err
                    }
                }))
            }
        });

    }
    /**
     * Remove pic by one
     * @param index
     */
    onRemovePic = (index) => {
        let list = this.props.branchList;
        const { activeBranchKey } = this.props;
        list[activeBranchKey].pictures.splice(index, 1);
        this.props.updateParentState(list);
    }
    /**
     *
     * @param arabic
     * @param e
     */
    changeDescription = (arabic = false, e) => {
        let val = e.target.value,
            max = this.state.descriptionLength - val.toString().length,
            lengthKey = 'descriptionCurrentLength',
            descriptionKey = 'description';

        if (arabic) {
            lengthKey = 'descriptionCurrentLength_ar';
            descriptionKey = 'description_ar';
        }
        //check the allowed length
        if (max >= 0) {
            let list = this.props.branchList,
                _this = this;
            this.setState(prevState => ({
                [lengthKey]: {
                    ...prevState[lengthKey],
                    [this.props.activeBranchKey]: max
                }
            }))

            Object.keys(list).forEach(function (item) {
                if (item === _this.props.activeBranchKey) {
                    list[item][descriptionKey] = val
                }
            });
            this.props.updateParentState(list);
        }
    }

    /**
     * @param e
     */
    changeNumberOfBeds = (e) => {
        let { branchList, activeBranchKey } = this.props;
        let beds = branchList[activeBranchKey].numberOfBeds;
        if (e.target.id === 'minus') {
            if (beds > 1)
                beds = +beds - 1;
        } else {
            beds = +beds + 1;
        }
        branchList[activeBranchKey].numberOfBeds = beds;
        this.props.updateParentState(branchList);
    }
    /**
     *
     * @param e
     */
    numberChange = (e) => {
        let { branchList, activeBranchKey } = this.props;
        if (e.target.value > 0) {
            branchList[activeBranchKey].numberOfBeds = (!isNaN(e.target.value) && e.target.value.length && e.target.value < 1000) ? e.target.value : 1
            this.props.updateParentState(branchList);
        }
    }
    /**
     * @param e
     */
    changeEmergencyService = (e) => {
        let { branchList, activeBranchKey } = this.props;
        branchList[activeBranchKey].emergencyService = !branchList[activeBranchKey].emergencyService;
        this.props.updateParentState(branchList);

    }


    /**
     *
     * @param arabic
     * @param e
     */
    changeFacilityNameDefault = (arabic = false, e) => {
        let val = e.target.value,
            _this = this;
        let { branchList } = this.props;

        Object.keys(branchList).forEach(function (item) {
            if (item === _this.props.activeBranchKey) {
                if (arabic)
                    branchList[item].facilityName_ar = val
                else
                    branchList[item].facilityName = val
            }
        });
        this.props.updateParentState(branchList);
    }

    skipCrop = () => {
        this.setState({ cropedPicture: null })
    }

    renderCropPopup = (text) => {
        let _this = this;
        var styles = {
            display: 'block',
            'maxWidth': '500px',
            'maxHeight': '400px',
            width: 'auto',
            height: 'auto'
        };
        return (
            <div className="claimPup" id="claimMe">
                <div className="claimContainer">
                    <div className="pop" style={{ 'textAlign': 'center', 'width': '660px', 'height': '540px' }}>
                        <ReactCrop src={text} crop={_this.state.crop} onChange={_this.onChange} onImageLoaded={_this.onImageLoaded} keepSelection={true} onComplete={_this.onComplete} imageStyle={styles} />
                        <div className="butts">
                            <div className="defX claim" onClick={this.cropThis}><span> Crop</span></div>
                            <div className="defX skip" onClick={this.skipCrop}><span>Cancel</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    cropThis = () => {
        let _this = this,
            list = this.props.branchList;
        const { activeBranchKey } = this.props;

        let { croppedImg } = this.state

        Object.keys(list).forEach(function (item) {
            if (item === activeBranchKey) {
                list[item].pictures = list[activeBranchKey].pictures.concat(croppedImg)
                _this.props.updateParentState(list);

            }
        });

        _this.skipCrop()
    }

    onChange = (crop, pixelCrop) => {
        this.setState({ crop });
    }


    onImageLoaded = (image) => {
        let { pic, crop } = this.state
        this.setState({ onLoadImg: image })
        let _this = this
        if (image) {
            getCroppedImg(image, crop, pic.name).then(function (result) {
                _this.setState({
                    croppedImg: result
                })
            })
        }
    }

    onComplete = (crop, pixelCrop) => {

        let { pic, onLoadImg } = this.state

        let _this = this;
        if (onLoadImg) {
            getCroppedImg(onLoadImg, crop, pic.name).then(function (result) {
                _this.setState({
                    croppedImg: result
                })
            });
        }



    }
    render() {
        let { cropedPicture } = this.state
        return (
            <div>
                {this.renderFacilityName()}
                {this.renderDescription()}
                {this.renderHospitalFields()}
                {this.renderAttachPhotos()}
                {cropedPicture ?
                    this.renderCropPopup(cropedPicture)
                    : ''
                }
            </div>
        )
    }

    /**
     * Facility Name (& ar)
     * @returns {XML}
     */
    CheckErrors = () => {
        let errors = false;

        let { branchList } = this.props;
        Object.keys(branchList).forEach(function (item) {
            if (branchList[item].noFacilityName) {
                errors = true;
            }
        });
        return errors;

    }

    renderFacilityName = () => {
        const { activeBranchKey, branchList } = this.props;
        return (
            <div>

                <div className="name-input">
                    <div>
                        <label>Facility Name<span> *</span></label>
                        <Input error={!branchList[activeBranchKey].facilityName.length && branchList[activeBranchKey].showError} type="text" name="facilityName" placeholder="Input name" value={branchList[activeBranchKey].facilityName} onChange={this.changeFacilityNameDefault.bind(this, false)} />
                    </div>
                    <div className="arabic-input">
                        <label><span>* </span>الاسم الكامل</label>
                        <Input error={!branchList[activeBranchKey].facilityName_ar.length && branchList[activeBranchKey].showError} type="text" name="facilityName_ar" placeholder="Input Arabic name" value={branchList[activeBranchKey].facilityName_ar} onChange={this.changeFacilityNameDefault.bind(this, true)} />
                    </div>

                </div>
            </div>
        )
    }
    /**
     * Facility Description (& ar)
     * @returns {XML}
     */
    renderDescription = () => {
        //TODO after apply all need fixing chars count(when user not applying change)
        const { activeBranchKey, branchList } = this.props;

        return (
            <div>
                <div className="desc-input">
                    <label>Description <span>*</span></label>
                    {(!branchList[activeBranchKey].description.length && branchList[activeBranchKey].showError) ?
                        <textarea style={{ borderColor: 'darkred' }} name="description" value={branchList[activeBranchKey].description} onChange={this.changeDescription.bind(this, false)} />

                        : (
                            <textarea name="description" value={branchList[activeBranchKey].description} onChange={this.changeDescription.bind(this, false)} />

                        )
                    }
                </div>
                <div className="desc-input arabic-input">
                    <label><span>* </span>نبذة</label>
                    {(!branchList[activeBranchKey].description_ar.length && branchList[activeBranchKey].showError) ?
                        <textarea style={{ borderColor: 'darkred' }} name="description" value={branchList[activeBranchKey].description_ar} onChange={this.changeDescription.bind(this, true)} />

                        : (
                            <textarea name="description" value={branchList[activeBranchKey].description_ar} onChange={this.changeDescription.bind(this, true)} />

                        )
                    }
                </div>
            </div>
        )
    }
    /**
     * Attach Photos
     * @returns {XML}
     */

    renderAttachPhotos = () => {
        const { facilityType, activeBranchKey } = this.props;
        let branchList = this.props.branchList;
        let _this = this;
        let picturesSize = branchList[activeBranchKey].pictures.length;
        let noPictures = false;
        if (picturesSize === 0) {
            noPictures = true;
        } else {
            noPictures = false;
        }
        return (
            <div className="wizardMain" style={{ marginTop: '60px' }}>
                <div className="inputWrap">
                    <div className="title flexBox">
                        <h2>Attach Pictures <span className="req" style={{ color: 'darkred' }}>*</span></h2>
                    </div>
                    <div className="subTitle">
                        <p>The following pictures will be shown on the {facilityType}'s profile</p>
                    </div>
                    <ImageUploader
                        buttonText='Attach Picture'
                        onChange={this.onDropPic}
                        withLabel={false}
                        withIcon={false}
                        className="many"
                        withPreview={false}
                        maxFileSize={115242880}
                        buttonStyles={{ borderColor: (noPictures && branchList[activeBranchKey].showError) ? 'darkred' : '#0096fd' }}
                    />
                    <div className="errWrap">
                        {
                            (this.state.errors[activeBranchKey]) ? <p className="err">{(this.state.errors[activeBranchKey])}</p> : ''
                        }
                    </div>
                    <ul className="imagesList flexBox align_justify_left">
                        {
                            (branchList[activeBranchKey].pictures.length) ? (
                                branchList[activeBranchKey].pictures.map(function (item, index) {
                                    let img = item.base64 ? item.base64 : item;
                                    return (
                                        <li key={item.name || 'Image' + '_' + index} style={{ backgroundImage: 'url("' + img + '")' }} >
                                            <span className="action" onClick={_this.onRemovePic.bind(this, index)} >-</span>
                                        </li>
                                    )
                                })
                            ) : ''
                        }
                    </ul>
                </div>
            </div>
        )
    }

    renderHospitalFields = () => {
        const { facilityType, activeBranchKey } = this.props;
        let { branchList } = this.props;
        if (facilityType !== 'Hospital') return null;
        return (

            <div className="wizardMain">
                <div className="hospitalFields">
                    <div className="flexBox align_justify">
                        <div className="inputWrap">
                            <div className="title flexBox">
                                <h2>Number of beds </h2>
                            </div>
                            <div className="inputParent numberOfBeds flexBox align_justify">
                                <span onClick={this.changeNumberOfBeds} id="minus">-</span>
                                <input type="number" name="numberOfBeds" value={branchList[activeBranchKey].numberOfBeds ? branchList[activeBranchKey].numberOfBeds : 0} onChange={this.numberChange} />
                                <span onClick={this.changeNumberOfBeds} id="plus">+</span>
                            </div>
                        </div>
                        <div className="inputWrap">
                            <div className="title flexBox">
                                <h2>Provide Emergency Services</h2>
                            </div>
                            <div className="inputParent emergencyService">
                                <p data-checked={branchList[activeBranchKey].emergencyService} onClick={this.changeEmergencyService} ><span>No</span><span /><span>Yes</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

export default BasicInfo