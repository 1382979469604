import React, { Component } from 'react';
import './addStaff.css'
import { Input, Checkbox, Button, Dropdown } from 'semantic-ui-react'
import { firestore, app } from 'firebase'
import Swal from 'sweetalert2';
import { clone } from '../../../helpers/Full';
import _ from 'lodash';
class AddStaff extends Component {
    constructor(props) {
        super(props)
        this.handleClinicBox = this.handleClinicBox.bind(this);

    }

    state = {
        staffInfo: {
            accessClinic: []
        },
        editFlag: false,
        showRequired: false
    }
    componentWillMount() {
        let { staffInfo } = this.props
        if (staffInfo) {
            staffInfo["pw"] = "********"
            this.setState({
                staffInfo,
                editFlag: true,
                oldAccess: staffInfo.access,
                oldAccessClinic: _.cloneDeep(staffInfo.accessClinic),
                showRequired: true
            })
        }
    }

    handleInputChange(type, e, { value }) {
        let { staffInfo } = this.state
        staffInfo[type] = value
        this.setState({ staffInfo })
    }
    handleAccessBox(type) {
        let { staffInfo } = this.state
        staffInfo.access = type
        this.setState({ staffInfo })
    }
    handleClinicBox(clinic) {
        let { staffInfo } = this.state
        let { clinicsList } = this.props
        let keys = []
        clinic.forEach(record => {
            keys.push((clinicsList.filter(item => item.facilityName === record)[0]).facilityId)
        })

        staffInfo.accessClinic = keys
        this.setState({ staffInfo })
    }
    submit() {
        let { staffInfo, editFlag } = this.state
        let { clinicsList } = this.props

        if (staffInfo.name && staffInfo.email && staffInfo.pw && staffInfo.adminPosition && staffInfo.access) {
            if (staffInfo.access === 'superAdmin') {
                staffInfo.accessClinic = clinicsList.map(record => record.facilityId)
            }

            if (editFlag) {
                firestore().collection('staff').doc(staffInfo.id).update({
                    clinics: staffInfo.accessClinic.length ? staffInfo.accessClinic : [],
                    id: staffInfo.id,
                    access: staffInfo.access
                })



                staffInfo["pw"] = null
                firestore().collection('users').doc(staffInfo.id).set(staffInfo, { merge: true }).then(() => {
                    Swal.fire({
                        heightAuto: false,
                        type: 'success',
                        title: 'database updated'
                    }).then(() => {
                        this.props.dismiss()
                    })
                })

            } else {
                app("userCreator").auth().createUserWithEmailAndPassword(staffInfo.email, staffInfo.pw).then(user => {
                    if (user) {
                        firestore().collection('staff').doc(user.user.uid).set({
                            id: user.user.uid,
                            clinics: firestore.FieldValue.arrayUnion(...(staffInfo.accessClinic?staffInfo.accessClinic:[])),
                            access: staffInfo.access
                        }, { merge: true })

                        staffInfo["pw"] = null
                        firestore().collection('users').doc(user.user.uid).set(staffInfo, { merge: true }).then(() => {
                            Swal.fire({
                                heightAuto: false,
                                type: 'success',
                                title: 'Saved successfully'
                            }).then((result) => {
                                app("userCreator").auth().signOut()
                                this.props.dismiss()
                            })
                        })
                    }
                }).catch(error => {
                    Swal.fire({
                        heightAuto: false,
                        title: 'Error',
                        type: 'error',
                        text: error
                    })
                })
            }
        } else {
            Swal.fire({
                heightAuto: false,
                title: 'Alert',
                type: 'warning',
                text: 'Please fill all required fields'
            }).then(() => {
                this.setState({ showRequired: true })
            })
        }
    }
    render() {
        let { staffInfo, editFlag, showRequired } = this.state
        let { clinicsList, accessType } = this.props
        let defaultValues = clinicsList.map(item => {
            if (staffInfo && staffInfo.accessClinic && staffInfo.accessClinic.includes(item.facilityId)) {
                return item.facilityName
            }
        })
        defaultValues = defaultValues.filter(item => item !== undefined)
        return (
            <div className="mainContainer">
                <div className="popupTitle">
                    <div>Staff Details</div>
                    <div className='blueLineContainer'>
                        <div className="blueLine" />
                    </div>
                </div>
                <>
                    <div className='content'>
                        <div className='row'>
                            <div className='cell'>
                                <div className='title'> Name</div>
                                <Input error={showRequired && !staffInfo.name} value={staffInfo.name ? staffInfo.name : ''} onChange={this.handleInputChange.bind(this, "name")} className='input' />
                            </div>
                            <div className='cell'>
                                <div className='title'>Role</div>
                                <Input error={showRequired && !staffInfo.adminPosition} value={staffInfo.adminPosition ? staffInfo.adminPosition : ''} onChange={this.handleInputChange.bind(this, "adminPosition")} className='input' />
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        <div className='row'>
                            <div className='cell'>
                                <div className='title'> Email</div>
                                <Input error={showRequired && !staffInfo.email} disabled={editFlag} value={staffInfo.email ? staffInfo.email : ''} onChange={this.handleInputChange.bind(this, "email")} type='email' className='input' />
                            </div>
                            <div className='cell'>
                                <div className='title'>Password</div>
                                <Input error={showRequired && !staffInfo.pw} disabled={editFlag} value={staffInfo.pw ? staffInfo.pw : ''} onChange={this.handleInputChange.bind(this, "pw")} type='password' className='input' />
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        <div className='row'>
                            <div className='cell'>
                                <div className='title'> Contact Number</div>
                                <Input error={showRequired && !staffInfo.number} value={staffInfo.number ? staffInfo.number : ''} onChange={this.handleInputChange.bind(this, "number")} type='number' className='input' />
                            </div>
                        </div>
                    </div>
                    <div className='content' style={{ paddingTop: '20px', fontSize: '16px', color: 'rgba(74, 74, 74, 1)' }}>
                        <div className='row'>
                            - User Access
                        </div>
                    </div>
                    {
                        accessType === 'superAdmin' ? <div className='content' style={{ paddingBottom: '0px' }}>
                            <div className='row'>
                                <Checkbox checked={staffInfo.access && staffInfo.access === 'superAdmin' ? true : false} onChange={this.handleAccessBox.bind(this, "superAdmin")} label='Super Admin' />
                            </div>
                        </div> : ''
                    }

                    <div className='content' style={{ paddingBottom: '0px' }}>
                        <div className='row'>
                            <Checkbox checked={staffInfo.access && staffInfo.access === 'admin' ? true : false} onChange={this.handleAccessBox.bind(this, "admin")} label='Admin (Full access to appointmnets, patients, calendar in selected clinics below)' />
                        </div>
                    </div>
                    <div className='content' style={{ paddingBottom: '0px' }}>
                        <div className='row'>
                            <Checkbox checked={staffInfo.access && staffInfo.access === 'frontD' ? true : false} onChange={this.handleAccessBox.bind(this, "frontD")} label='Front Desk (Limited to access appointments and patients )' />
                        </div>
                    </div>
                </>
                {
                    staffInfo.access !== 'superAdmin' ?
                        <>
                            <div className='content' style={{ paddingTop: '20px', fontSize: '16px', color: 'rgba(74, 74, 74, 1)' }}>
                                <div className='row'>
                                    - Practice Access
            </div>
                            </div>
                            <div className='content' style={{ paddingBottom: '0px', justifyContent: 'left', padding: '0 26px' }}>
                                <Dropdown
                                    fluid
                                    multiple
                                    selection
                                    search
                                    defaultValue={defaultValues}
                                    style={{ zIndex: '99' }}
                                    options={clinicsList.map(item => {
                                        return { key: item.facilityId, value: item.facilityName, text: item.facilityName }
                                    })}
                                    onChange={(e, { value }) => {
                                        this.handleClinicBox(value)
                                    }}
                                />
                            </div>
                        </>
                        : ''
                }

                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '24px', alignItems: 'flex-end' }}>
                    <Button basic style={{ width: '98px', marginLeft: '8px' }} onClick={() => this.props.dismiss()}>Cancel</Button>
                    <Button onClick={() => this.submit()} color='blue' style={{ width: '98px', marginLeft: '8px' }}>Save</Button>
                </div>
            </div>)
    }
}
export default AddStaff