import { createAction, createReducer } from 'redux-action';
import { fetchClinics } from './../services/ClinicsServices';
import { searchActions }  from '../modules/search-module';
import { filterActions }  from '../modules/filter-module';

export const GET_CLINICS = 'GET_CLINICS ';

const getClinicsAction = createAction(GET_CLINICS, (user) => {
  return fetchClinics(user).then(data => {
    return data
  }).catch(err => console.log('LOG ::: err ::: ',err));
});

export const actions = {
    getClinics: getClinicsAction,
    getSearchParams: searchActions.getSearchParams,
    getSpecialities: searchActions.getSpecialities,
    getFilterParams: filterActions.getFilterParams,
    setFilterParams: filterActions.setFilterParams,
};

// Actions that need dispatch reference


// All actions
export const allActions = {
  ...actions,
};

const initialState = {
  data: [],
};

// const clinicsReducer = createReducer(initialState, {
//   [GET_CLINICS]: (actionPayload, state) => {
//     return { ...state, data: actionPayload };
//   }
// });

// export default clinicsReducer;

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLINICS:
      return {
        ...state,
        data: action.payload,
      };
      break
    default:
      return state;
  }
};