import React from 'react'
// import './App.css'
import { createOffer, initiateConnection, startCall, sendAnswer, addCandidate, initiateLocalStream, listenToConnectionEvents } from './RTCModule'
import { database } from 'firebase/app'
import { doOffer, doAnswer, doLogin, doCandidate } from './FirebaseModule'
import 'webrtc-adapter'
import VideoChat from './VideoChat'
import { Loader, Dimmer } from 'semantic-ui-react'

class VideoChatContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      database: null,
      connectedUser: null,
      localStream: null,
      localConnection: null
    }
    this.localVideoRef = React.createRef()
    this.remoteVideoRef = React.createRef()
  }

  componentDidMount = async () => {

    // let { callId } = this.props.callId
    // if (callId) {
    //   this.onLogin(callId)
    //   console.log("this.props.callId", callId)

    // }
    // getting local video stream
    const localStream = await initiateLocalStream()

    const localConnection = await initiateConnection()

    this.setState({
      database: database(),
      localStream,
      localConnection
    })
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.state.database !== nextState.database) {
  //     return false
  //   }
  //   if (this.state.localStream !== nextState.localStream) {
  //     return false
  //   }
  //   if (this.state.localConnection !== nextState.localConnection) {
  //     return false
  //   }

  //   return true
  // }

  startCall = async (username, userToCall) => {
    const { localConnection, database, localStream } = this.state
    listenToConnectionEvents(localConnection, username, userToCall, database, this.remoteVideoRef, doCandidate)
    // create an offer
    createOffer(localConnection, localStream, userToCall, doOffer, database, username)
  }

  onLogin = async (username) => {
    return await doLogin(username, this.state.database, this.handleUpdate)
  }

  setLocalVideoRef = ref => {
    let { localStream } = this.state
    this.localVideoRef = ref
    if (ref){
      this.localVideoRef.srcObject = localStream
    }
  }

  setRemoteVideoRef = ref => {
    this.remoteVideoRef = ref
  }

  handleUpdate = (notif, username) => {
    const { localConnection, database, localStream } = this.state

    if (notif) {
      switch (notif.type) {
        case 'offer':
          this.setState({
            connectedUser: notif.from
          })

          listenToConnectionEvents(localConnection, username, notif.from, database, this.remoteVideoRef, doCandidate)

          sendAnswer(localConnection, localStream, notif, doAnswer, database, username)
          break
        case 'answer':

          this.setState({
            connectedUser: notif.from
          })
          startCall(localConnection, notif)
          break
        case 'candidate':
          addCandidate(localConnection, notif)
          break
        default:
          break
      }
    }
  }

  render() {
    let { localStream, localConnection } = this.state
    if (!localStream) {
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      )
    }

    return <VideoChat
      callId={this.props.callId}
      startCall={this.startCall}
      onLogin={this.onLogin}
      setLocalVideoRef={this.setLocalVideoRef}
      setRemoteVideoRef={this.setRemoteVideoRef}
      connectedUser={this.state.connectedUser}
      dismissCall={async() => {
        const tracks = await localStream.getTracks()
        tracks.forEach(track=>{
          console.log("tracktrack", track)
          track.stop()
        })
        // localStream.getTracks().forEach(function (track) {
        //   console.log("tracktrack", track)
        //   localStream.removeTrack(track)
        // })
        localConnection.close()
        this.props.dismissCall()
      }}
      updateState={async (state) => {
        localStream.getVideoTracks()[0].enabled = state.video
        localStream.getAudioTracks()[0].enabled = state.mic
      }}
    />
  }
}

export default VideoChatContainer
