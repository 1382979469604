import React, { Component } from 'react';
import {firestore,storage,auth} from 'firebase'
import { Dimmer, Icon, Input, Button, Loader } from 'semantic-ui-react';
import './account.css';
import swal from 'sweetalert2';
import { Redirect } from 'react-router-dom';
class AboutPage extends Component {
    state = {
        fileTypes: ['.jpg', '.jpeg', '.gif', '.png'],
        step:0

    }
    getUser = () => {
        const _this = this;
        auth().onAuthStateChanged(function (user) {
            if (user && user.emailVerified) {
                //get current user
                let userR = firestore().collection('users').doc(user.uid);
                userR.get().then(snapshot => {
                    _this.setState({
                        user: {
                            id: snapshot.id,
                            info: snapshot.data()
                        }
                    });
                })
            }
        });
    }

    componentWillMount() {
        this.getUser();
    }

    handleInputChange(type, e, { value }) {
        let { user } = this.state
        user.info[type] = value
        this.setState({ user, editFlag: true })
    }
    submit() {
        let { user } = this.state
        if (user.info.name && user.info.name !== '' && user.info.number && user.info.number !== '') {
            firestore().collection('users').doc(user.id).update({
                number: user.info.number,
                name: user.info.name
            }).then(() => {
                swal.fire({
                    heightAuto:false,
                    title: 'Success',
                    type: 'success',
                    text: 'Information updated successfully'
                }).then((result) => {
                    if (result.value){
                        this.setState({ goBack: true })
                    }
                    // window.history.back()
                })
            })
        } else {
            swal.fire({
                heightAuto:false,
                title: 'Alert',
                type: 'warning',
                text: 'Please fill all required fields'
            })
        }
    }
    changePicture = (e) => {
        let { user } = this.state
        let _this = this;
        
        if (!e.target.files) {
            return
        }
        this.setState({ loading: true })
        let extension = e.target.files[0].name.split('.').pop().toLowerCase(),
            isSuccess = this.state.fileTypes.indexOf('.' + extension) > -1;
        if (isSuccess) {
            let reader = new FileReader(),
                file = e.target.files[0];
            reader.onloadend = () => {
                let cvs = document.createElement('canvas');
                let ctx = cvs.getContext("2d");
                let image = new Image();

                image.onload = function () {
                    let ratio = this.height/this.width
                    cvs.width = 100;
                    cvs.height = 100*ratio;
                    ctx.drawImage(image, 0, 0, 100, 100*ratio);
                    let newImageData = cvs.toDataURL(file.type, 80 / 100);
                    let task = storage().ref('users/' + user.id + '/profilePic').putString(newImageData, "data_url")
                    task.on('state_changed', function (snapshot) {
                    }, function (error) {
                    }, function () {
                        task.snapshot.ref.getDownloadURL().then((url) => {
                            firestore().collection('users').doc(user.id).update({ profilePicture: url }).then(() => {
                                user.info.profilePicture = url
                                _this.setState({ user, loading: false })
                            })
                        })
                    })
                };
                image.src = reader.result;
            };
            reader.readAsDataURL(file);
        } else {
            swal.fire({
                heightAuto:false,
                title: 'Error',
                type: 'error',
                text: '* .' + extension + ' file is not supported'
            })
        }
    }
    changePassword() {
        let { user } = this.state
        let _this = this
        if (user.info.pw && user.info.vpw) {
            if (user.info.pw === user.info.vpw) {
                auth().currentUser.updatePassword(user.info.pw).then(() => {
                    swal.fire({
                        heightAuto:false,
                        title: "Success",
                        type: 'success',
                        text: 'Password updated successfully'
                    }).then((result) => {
                        if (result.value){
                            _this.setState({ changePass: false })
                        }
                    })
                })
            } else {
                this.setState({ modefied: true, passwordError: 'Passwords are not matched' })
            }
        } else {
            this.setState({ modefied: true, passwordError: 'Please fill all required fields' })
        }

    }
    reAuth(){
        this.setState({btnLoading:true});
        setTimeout(()=>{
            let {user} = this.state
            let _this = this
            let pw = user.info.opw
            let email = user.info.email
            if (pw&&email){
                auth().signInWithEmailAndPassword(email,pw).then((user)=>{
                    _this.setState({step:1,btnLoading:false,passwordError:null})
                }).catch(error=>{
                    _this.setState({passwordError:error.message,btnLoading:false})
                })
            }
        },1000)
       
    }
    render() {
        let { user, editFlag, goBack, loading, changePass, modefied, passwordError, step ,btnLoading} = this.state
        if (goBack) {
            return (<Redirect to='/' />)
        }

        return (
            <Dimmer active>
                {
                    user ?
                        changePass ?
                            <div className="profileMainContainer">
                                <div className="popupTitle">
                                    <div>Change Password</div>
                                    <div className='blueLineContainer'>
                                        <div className="blueLine"></div>
                                    </div>
                                </div>
                                {
                                    step === 0 ?
                                        <div>
                                            <div className='content'>
                                                <div className='row'>
                                                    <div className='cell'>
                                                        <div className='title'> Old Password</div>
                                                        <Input error={modefied && !user.info.opw} value={user.info ? user.info.opw : ''} onChange={this.handleInputChange.bind(this, "opw")} type='password' className='input' />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                passwordError ?
                                                    <div className='content'>
                                                        <div className='row'>
                                                            <div className='cell'>
                                                                <div className='title' style={{ color: 'red' }}>{passwordError}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                                            }

                                            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px', alignItems: 'flex-end', height: '60px' }}>
                                                <Button loading={btnLoading} disabled={!editFlag} onClick={() => this.reAuth()} color={editFlag ? 'blue' : 'grey'} style={{ width: '98px', marginLeft: '8px' }}>Next</Button>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div>
                                                <div className='content'>
                                                    <div className='row'>
                                                        <div className='cell'>
                                                            <div className='title'> New Password</div>
                                                            <Input error={modefied && !user.info.pw} value={user.info ? user.info.pw : ''} onChange={this.handleInputChange.bind(this, "pw")} type='password' className='input' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='content'>
                                                    <div className='row'>
                                                        <div className='cell'>
                                                            <div className='title'>Verify New Password</div>
                                                            <Input error={modefied && !user.info.vpw} value={user.info ? user.info.vpw : ''} onChange={this.handleInputChange.bind(this, "vpw")} type='password' className='input' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                passwordError ?
                                                    <div className='content'>
                                                        <div className='row'>
                                                            <div className='cell'>
                                                                <div className='title' style={{ color: 'red' }}>{passwordError}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                                            }

                                            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px', alignItems: 'flex-end', height: '60px' }}>
                                                <Button basic style={{ width: '98px', marginLeft: '8px' }} onClick={() => { user.info.pw = ''; user.info.vpw = ''; this.setState({ changePass: false, user, passwordError: null, modefied: false }) }}>Cancel</Button>
                                                <Button disabled={!editFlag} onClick={() => this.changePassword()} color={editFlag ? 'blue' : 'grey'} style={{ width: '98px', marginLeft: '8px' }}>Submit</Button>
                                            </div>
                                        </div>

                                }



                            </div>
                            :
                            <div className="profileMainContainer">
                                <div className="popupImg">
                                    {
                                        loading ?
                                            <Loader active inline='centered' style={{ top: '20px', height: '112px' }} />
                                            :
                                            <img src={user.info.profilePicture} />
                                    }
                                </div>
                                <div className='content'>
                                    <div className='row' style={{ width: '65%' }}>
                                        <div className='cell'>
                                            <label className='btns' type="file" >
                                                Change Image
                                <input onChange={this.changePicture} type="file" style={{ display: 'none' }} />
                                            </label>
                                        </div>
                                        <div className='cell'>
                                            <div className='btns' onClick={() => this.setState({ changePass: true ,step:0})}> Change Password</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='content'>
                                    <div className='row'>
                                        <div className='cell'>
                                            <div className='title'> Name</div>
                                            <Input value={user.info ? user.info.name : ''} onChange={this.handleInputChange.bind(this, "name")} type='text' className='input' />
                                        </div>
                                    </div>
                                </div>
                                <div className='content'>
                                    <div className='row'>
                                        <div className='cell'>
                                            <div className='title'> Email</div>
                                            <Input disabled={true} value={user.info ? user.info.email : ''} onChange={this.handleInputChange.bind(this, "email")} type='email' className='input' />
                                        </div>
                                    </div>
                                </div>
                                <div className='content'>
                                    <div className='row'>
                                        <div className='cell'>
                                            <div className='title'> Contact Number</div>
                                            <Input value={user.info ? user.info.number : ''} onChange={this.handleInputChange.bind(this, "number")} type='number' className='input' />
                                        </div>
                                    </div>
                                </div>
                                <div className='content'>
                                    <div className='row'>
                                        <div className='cell'>
                                            <div className='title'> Role</div>
                                            <Input disabled={true} value={user.info ? user.info.adminPosition : ''} onChange={this.handleInputChange.bind(this, "adminPosition")} type='text' className='input' />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px', alignItems: 'flex-end', height: '75px' }}>
                                    <Button basic style={{ width: '98px', marginLeft: '8px' }} onClick={() => this.setState({ goBack: true })}>Cancel</Button>
                                    <Button disabled={!editFlag} onClick={() => this.submit()} color={editFlag ? 'blue' : 'grey'} style={{ width: '98px', marginLeft: '8px' }}>Save</Button>
                                </div>
                            </div>
                        :
                        <Icon.Group size='big'>
                            <Icon loading size='big' name='spinner' />
                            <div style={{ fontSize: '16px', padding: '10px', fontStyle: 'initial' }}>loading</div>
                        </Icon.Group>
                }
            </Dimmer>
        )
    }
}
export default AboutPage