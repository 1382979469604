import React, { Component } from 'react';
import PropTypes from 'prop-types'

class RightMedicalSchool extends Component {
    static propTypes = {
        doctorObject: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        updateParentState: PropTypes.func.isRequired,
    }
    state = {

    }

    /**
        * @param index
        */
    removeSchoolSnap = (index) => {
        const { activeBranchKey } = this.props;
        let { doctorObject } = this.props;
        let numbers = doctorObject[activeBranchKey].doctorDegrees;
        numbers.splice(index, 1);
        this.props.updateParentState(doctorObject);
    }
    render = () => {
        const _this = this;
        const { activeBranchKey } = this.props;
        let doctorObject = this.props.doctorObject;
        return (
            <div style={{
                backgroundColor: '#f9f9f9',
                padding: '5px',
                border: '1px solid #dadfe4',
                borderRadius: '4px',
                marginBottom: '20px',
                minHeight: '300px',
                flex: '1'
            }
            }>
                <label className="section-title" style={{ marginLeft: '15px' }}>Degrees</label>
                {
                    doctorObject[activeBranchKey].doctorDegrees.map(function (item, index) {
                        return (
                            <div key={index} className="doctor-gray-box box side-gray-box">
                                <p className="head">{item.facilityName}</p>
                                <p className="degree">Degree: {item.degree}</p>
                                <p className="date">{item.to}</p>
                                <span onClick={_this.removeSchoolSnap.bind(this, index)} className="action"><i className="fas fa-times"></i></span>
                            </div>
                        )
                    })
                }
            </div >
        )
    }

}

export default RightMedicalSchool