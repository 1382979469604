import React from 'react'
// import './App.css'
import 'firebase/database'
import classnames from 'classnames'
import { setTimeout } from 'timers'
import { Button } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

export default class VideoChat extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoggedIn: false,
      userToCall: null,
      username: this.props.callId,
      video:true,
      mic:true
    }
  }
  componentDidMount(){
    if (this.props.callId){
      setTimeout(()=>{
        this.setState({username:this.props.callId},()=>{
          this.onLoginClicked(this.props.callId)
  
        })
      },3000)

    }
  }

  onLoginClicked = async () => {
    await this.props.onLogin(this.state.username)
    this.setState({
      isLoggedIn: true
    })
  }

  onStartCallClicked = () => {
    this.props.startCall(this.state.username, this.state.userToCall)
  }

  renderVideos = () => {
    let { mic, video } = this.state
    return <div className={classnames('videos', { active: this.state.isLoggedIn })}>
      <div>
        <video style={{ position:'absolute',width:'20%', transform: 'scaleX(-1)' }} ref={this.props.setLocalVideoRef} muted autoPlay playsInline></video>
      </div>
      <div>
        <video style={{width:'100%',height:'100%',display:'flex'}} ref={this.props.setRemoteVideoRef} autoPlay playsInline></video>
      </div>
      <div style={{ position: 'absolute', bottom: '40px', display: 'flex', flexDirection: 'row' ,width:'100%',justifyContent:'center'}}>
        <div>
          <Button icon='video'  onClick={() => {
            this.props.updateState({ video: !video, mic })
            this.setState({ video: !video })
          }} />
        </div>
        <div>
          <Button
            icon={mic ? 'microphone' : 'microphone slash'}
            
            onClick={() => {
              this.props.updateState({ mic: !mic, video })
              this.setState({ mic: !mic })
            }}
          />
        </div>
        <Button style={{ background: 'red' }} icon='call' 
          onClick={() => {
            this.props.dismissCall()

            if (window.webkit) {
              console.log("webkit")
              window.webkit.messageHandlers.webMesseges.postMessage(JSON.stringify({ dismiss: true }));
            }
            // this.webview.postMessage("Hello from RN");
          }}
        />
      </div>
    </div>
  }

  // renderForms = () => {
  //   return this.state.isLoggedIn
  //     ? <div key='a' className='form'>
  //       <label>Call to</label>
  //       <input value={this.state.userToCall} type="text" onChange={e => this.setState({ userToCall: e.target.value })} />
  //       <button onClick={this.onStartCallClicked} id="call-btn" className="btn btn-primary">Call</button>

  //     </div>
  //     : <div key='b' className='form'>
  //       <label>Type a name</label>
  //       <input value={this.state.username} type="text" onChange={e => this.setState({ username: e.target.value })} />

  //       <button onClick={this.onLoginClicked} id="login-btn" className="btn btn-primary">Login</button>

  //     </div>
  // }

  render() {
    return <section id="container">
      {/* {this.props.connectedUser ? null : this.renderForms()} */}

      {this.renderVideos()}

    </section>
  }
}
