import React, { Component } from 'react';
import PropTypes from 'prop-types'
import fire from 'firebase/app'
import 'firebase/database';

import {
    searchInArrayWithObjects,
    searchKeyInArrayWithObjects,
    toObjectIns,
    searchKeySelectedInsurances,
    searchCustomInsurance
} from '../../../helpers/Full';
import Masonry from 'react-masonry-component';
import { Dropdown } from 'semantic-ui-react';


class Insurances extends Component {
    static propTypes = {
        branchList: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        applyToAll: PropTypes.func.isRequired,
        updateParentState: PropTypes.func.isRequired,
    }

    state = {
        defaultInsurances: [],
        allInsurances: [],
        insurances: {},
        loading:true,
        canAdd: false,
        currentText: '',
        currentNetworkText: {},
        currentCustomNetworkText: {},
        networkSelected: true
    }
    /**
     * @param id
     * @param e
     */
    changeCurrentNetworkText = (id, e) => {
        let val = e.target.value;
        let { currentNetworkText } = this.state;
        currentNetworkText[id] = val;
        this.setState({
            currentNetworkText: currentNetworkText
        })
    }
    changeCurrentCustomNetworkText = (name, e) => {
        let val = e.target.value;
        let { currentCustomNetworkText } = this.state;
        currentCustomNetworkText[name] = val;

        this.setState({
            currentCustomNetworkText: currentCustomNetworkText
        })
    }

    /**
     *
     * @param id
     */
    addInsurance = (e, { value }) => {
        let { allInsurances, insurances } = this.state,
            { branchList, activeBranchKey } = this.props,
            selectedInsurances = branchList[activeBranchKey].selectedInsurances,
            index = searchKeyInArrayWithObjects(value, 'id', allInsurances[activeBranchKey]);
        let networks = allInsurances[activeBranchKey][index].networks;

        selectedInsurances[value] = networks?networks:{};

        insurances[activeBranchKey].splice(index, 1);
        allInsurances[activeBranchKey].splice(index, 1);

        this.props.updateParentState(branchList);
        this.setState({
            insurances: insurances,
            currentText: '',
            networkSelected: networks?false:true
        })
    }
    /**
     * @param id_networks_array
     */
    removeSelectedInsurances = (id) => {
        let { allInsurances, insurances } = this.state,
            { branchList, activeBranchKey } = this.props,
            selectedInsurances = branchList[activeBranchKey].selectedInsurances,
            name = this.getNameById(id, 'defaultInsurances');
        if (selectedInsurances[id]) {
            branchList[activeBranchKey].customNetworks[id] = [];
            let val = { text: name, value: id };
            let val2 = { name: name, id: id, networks: selectedInsurances[id] };
            insurances[activeBranchKey].push(val);
            allInsurances[activeBranchKey].push(val2)
            delete selectedInsurances[id]
        }

        this.setState({
            insurances: insurances,
            allInsurances: allInsurances,
            networkSelected: true
        })
        this.props.updateParentState(branchList);
    }
    /**
     *
     */
    addCustomInsurance = () => {
        let { currentText, defaultInsurances } = this.state;
        let { branchList, activeBranchKey } = this.props;
        let customInsurances = branchList[activeBranchKey].customInsurances;
        if (currentText.length > 3
            &&
            !searchInArrayWithObjects(currentText, 'name', defaultInsurances)
            &&
            !searchCustomInsurance(currentText, customInsurances)) {

            customInsurances.push([currentText, []]);
        }
        this.props.updateParentState(branchList);
    }

    removeCustomInsurances = (id) => {
        let { branchList, activeBranchKey } = this.props,
            customInsurances = branchList[activeBranchKey].customInsurances,
            index = customInsurances.indexOf(id);
        customInsurances.splice(index, 1);

        this.props.updateParentState(branchList);
    }

    addNetworkToInsurance = (insKey, networkIndex) => {
        let { activeBranchKey, branchList } = this.props,
            activeBranch = branchList[activeBranchKey],
            selectedInsurances = activeBranch.selectedInsurances;

        // selectedInsurances = selectedInsurances.slice(0);
        selectedInsurances[insKey][networkIndex] = (selectedInsurances[insKey]&&selectedInsurances[insKey][networkIndex])?!selectedInsurances[insKey][networkIndex]:true;
        
        this.props.updateParentState(branchList);

        this.setState({ networkSelected: true })

    }
    /**
     * @param id
     */
    addCustomNetworkInInsurance = (id) => {
        let { currentNetworkText } = this.state;
        if (currentNetworkText[id]) {

            let { branchList, activeBranchKey } = this.props;
            branchList[activeBranchKey].customNetworks[id] =
                branchList[activeBranchKey].customNetworks[id]
                    ? branchList[activeBranchKey].customNetworks[id]
                    : [];

                    if (branchList[activeBranchKey].customNetworks[id].indexOf(currentNetworkText[id]) === -1){
                        branchList[activeBranchKey].customNetworks[id].push(currentNetworkText[id])
                    }
            this.props.updateParentState(branchList);
            currentNetworkText[id] = '';

            this.setState({ currentNetworkText: currentNetworkText })
        }
    }
    /**
     * @param id
     * @param text
     */
    removeCustomNetworkFromInsurance = (id, text) => {
        let { branchList, activeBranchKey } = this.props;
        branchList[activeBranchKey].customNetworks[id].map(function (item, index) {
            if (item === text) {
                branchList[activeBranchKey].customNetworks[id].splice(index, 1);
            }
        })
        this.props.updateParentState(branchList);
    }
    /**
     *
     * @param name
     * @param key
     */
    addCustomNetworkInCustomInsurance = (name, key) => {
        let { currentCustomNetworkText } = this.state;
        if (currentCustomNetworkText[name]) {

            let { branchList, activeBranchKey } = this.props;
            if(branchList[activeBranchKey].customInsurances[key][1].indexOf(currentCustomNetworkText[name]) === -1){
                branchList[activeBranchKey].customInsurances[key][1].push(currentCustomNetworkText[name])
            }

            this.props.updateParentState(branchList);
            currentCustomNetworkText[name] = '';

            this.setState({ currentCustomNetworkText: currentCustomNetworkText })
        }
    }
    /**
     *
     * @param id
     * @param indexParent
     * @param index
     */
    removeCustomNetworkFromCustomInsurance = (id, index, indexParent) => {
        let { branchList, activeBranchKey } = this.props;
        branchList[activeBranchKey].customInsurances[indexParent][1].splice(index, 1)
        this.props.updateParentState(branchList);
    }
    /**
     *
     * @param id
     * @param where
     */
    getNameById = (id, where) => {
        let x = this.state[where].filter(function (v) {
            return v.id === id; // Filter out the appropriate one
        })[0];
        if (x) {
            return x.name
        }
    };

    applyAllFromLocal = () => {
        const { applyToAll } = this.props;
        applyToAll(['selectedInsurances', 'customInsurances', 'customNetworks'], this.getInsurances);
    }

    componentWillMount = () => {
        this.getInsurances();
    }

    render = () => {
        let { branchList, activeBranchKey } = this.props;
        let selectedInsurances = branchList[activeBranchKey].selectedInsurances;
        return (
            <div className="wizardMain">
                <div className="Insurances">
                    {this.renderFindInsurances()}
                    {
                        (!selectedInsurances) ? (
                            this.renderNoInsurancesSelected()
                        ) : (
                            !this.state.loading?
                                this.renderSelectedInsurances()
                                :''
                            )
                    }
                    {this.renderCustomInsurances()}
                </div>
            </div>
        )
    }

    getInsurances = () => {
        let { insurances, allInsurances, defaultInsurances } = this.state;
        const { branchList } = this.props;

        Object.keys(branchList).map(function (item) {
            insurances[item] = [];
            allInsurances[item] = [];
        })

        let Insurances = fire.firestore().collection('insurances');
        Insurances.get().then(snap => {
            snap.forEach(snapshot=>{
                    let insurance = snapshot.data()
                    insurance.id = snapshot.id
                    if (insurance.networks) {
                       insurance.networks = toObjectIns(insurance.networks)
                   }
                   defaultInsurances.push(insurance)
                   Object.keys(branchList).map(function (item) {
                       let index = searchKeySelectedInsurances(snapshot.id, branchList[item].selectedInsurances);
                       if (index === undefined) {
                           insurances[item].push({
                               value: snapshot.id,
                               text: insurance.name,
                           });
                           allInsurances[item].push(insurance)
                       }
                   })
            })
      
            this.setState({
                insurances: insurances,
                allInsurances: allInsurances,
                defaultInsurances: defaultInsurances,
                loading:false
            });
        });
    }

    /*
     * Functions for content getting
     * */

    /**
     *
     * @returns {XML}
     */

    renderFindInsurances = () => {
        let { insurances } = this.state;
        let { activeBranchKey } = this.props;
        return (

            <div className="findInsurances">
                <div className="inputWrap">
                    <div className="title flexBox align_justify">
                        <h2>Add Insurances</h2>
                    </div>
                    <div className="subTitle">
                        <p>If you are not accepting any insurance proceed to the next step</p>
                    </div>
                    <Dropdown
                        style={{ width: '374px' }}
                        options={insurances[activeBranchKey]}
                        selection
                        search
                        value={""}
                        selectOnBlur={false}
                        onChange={this.addInsurance}
                        placeholder="Type insurance name"
                    />
                </div>
            </div>

        )
    }
    renderNoInsurancesSelected = () => {
        return (
            <div className="selectedSpecialties ins" style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ position: 'relative', fontSize: '18px', display: 'block', fontFamily: 'tfMedium' }}>
                    No selected Insurances
                    </div>
            </div>


        )
    }
    renderSelectedInsurances = () => {
        let { networkSelected } = this.state;
        let { branchList, activeBranchKey } = this.props;
        let selectedInsurances = branchList[activeBranchKey].selectedInsurances;
        let customNetworks = branchList[activeBranchKey].customNetworks;
        return (
            <div >
                {
                    (!networkSelected) ? (
                        <div style={{ position: 'relative', color: 'darkred', fontSize: '12px', display: 'block', marginTop: '8px', marginBottom: '8px' }}>
                            Please add network(s) for your selected insurance
                        </div>
                    ) : ''
                }

                <div className="selectedSpecialties ins">
                    <div className="inputWrap">
                        <div className="items flexBox align_justify_left">
                            <Masonry>
                                {
                                    (selectedInsurances) ? (
                                        Object.keys(selectedInsurances).map((item, index) => (
                                            <div key={this.getNameById(item, 'defaultInsurances') + '_' + index} className="itemParent flexBox">
                                                <div title={'Remove ' + this.getNameById(item, 'defaultInsurances')} onClick={this.removeSelectedInsurances.bind(this, item)} className="item flexBox">
                                                    <span>{this.getNameById(item, 'defaultInsurances')}</span>
                                                    <span>x</span>
                                                </div>
                                                {
                                                    selectedInsurances[item] ? (
                                                        <div className="networks">
                                                            {
                                                                Object.keys(selectedInsurances[item]).map((net, index2) => (
                                                                    <p title={net} data-checked={selectedInsurances[item][net]} className="flexBox align_justify_left itemIns" onClick={this.addNetworkToInsurance.bind(this, item, net)} key={net + '_' + index}><span className="check" /><span>{net}</span></p>
                                                                ))
                                                            }
                                                            {
                                                                customNetworks[item] ? (
                                                                    customNetworks[item].map((CustomNet, index3) => (
                                                                        <p title="Waiting approval" className="flexBox align_justify_left waiting" key={CustomNet + '_' + index3}><span className="check" /><span>{CustomNet}</span><span onClick={this.removeCustomNetworkFromInsurance.bind(this, item, CustomNet)} title="Click to remove" className="remove">x</span></p>
                                                                    ))
                                                                ) : ''
                                                            }
                                                           
                                                        </div>
                                                    ) : ''
                                                }
                                            </div>
                                        ))
                                    ) : ''
                                }
                            </Masonry>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    renderCustomInsurances = () => {
        let { branchList, activeBranchKey } = this.props;
        let customInsurances = branchList[activeBranchKey].customInsurances;
        return (
            (customInsurances.length) ? (
                <div className="selectedSpecialties ins  custom">
                    <div className="inputWrap">
                        <div className="title flexBox align_justify">
                            <h2>Waiting Approval</h2>
                        </div>
                        <div className="subTitle">
                            <p>Your insurance will be added to your facility as soon as our administration approve it.</p>
                        </div>
                        <div className="items flexBox align_justify_left">
                            <Masonry>
                                {
                                    (customInsurances) ? (
                                        customInsurances.map((item, index) => (
                                            <div key={item[0] + '_' + index} className="itemParent flexBox">
                                                <div title={'Remove ' + item[0] + 'defaultInsurances'} onClick={this.removeCustomInsurances.bind(this, item)} className="item flexBox">
                                                    <span>{item[0]}</span>
                                                    <span>x</span>
                                                </div>
                                                {
                                                    item[1] ? (
                                                        <div className="networks custom">
                                                            {
                                                                item[1].map((net, index2) => (
                                                                    <p title="Waiting approval" className="flexBox align_justify_left waiting" key={net + '_' + index2}><span className="check" /><span>{net}</span><span onClick={this.removeCustomNetworkFromCustomInsurance.bind(this, item[0], index2, index)} title="Click to remove" className="remove">x</span></p>
                                                                ))
                                                            }
                                                           
                                                        </div>
                                                    ) : ''
                                                }
                                            </div>
                                        ))
                                    ) : ''
                                }
                            </Masonry>
                        </div>
                    </div>
                </div>
            ) : ''
        )
    }
}

export default Insurances