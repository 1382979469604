import React, { Component } from 'react';
import './PatientsHistory.css'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'

class PatientsHistory extends Component {

    static propTypes = {
        clinicsList: PropTypes.object.isRequired,
        currentUser: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        doctorKey: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            bookings: [],
            filteredBookings: [],
        }
    }

    componentWillReceiveProps(nextProps) {
        let { doctorKey, allBooking } = this.props;
        let { filteredBookings } = this.state;

        if (nextProps) {
            doctorKey = nextProps.doctorKey;
            allBooking = nextProps.allBooking;
        }
        filteredBookings = [];
        (allBooking ? allBooking : []).map(item => {
            filteredBookings.push(item)
        });
        this.setState({ filteredBookings })
    }

    render() {
        let _this = this;

        let { filteredBookings } = this.state;
        let { allBooking, doctorsArray, clinicsList, activeBranchKey, clinicDoctors, patientNumber, statusArray } = this.props;
        let doctorsKeys = clinicsList[activeBranchKey].doctors

        let doctors = doctorsArray.map(doc => doc.key)

        filteredBookings = allBooking.filter(booking => {
            return (
                (doctors && doctors.length ? doctors.includes(booking.doctor) : true) &&
                (patientNumber.length ? (booking.patientNumber.includes(patientNumber))
                    : true) && (statusArray.length ? statusArray.includes(booking.status) : true)
            )
        })


        return (
            <div style={{ height: '80%', padding: '40px' }}>
                {
                    filteredBookings.length ?
                        <Table singleLine style={{ boxShadow: '0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04)', borderRadius: '14px', overflow: 'hidden' }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Patient Name</Table.HeaderCell>
                                    <Table.HeaderCell>Patient Number</Table.HeaderCell>
                                    <Table.HeaderCell>Appointment Date</Table.HeaderCell>
                                    <Table.HeaderCell>Doctor Name</Table.HeaderCell>
                                    <Table.HeaderCell>Facility Name</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    filteredBookings.map((item, index) => {
                                        return (
                                            <Table.Row key={index} onClick={() => { _this.props.patientData({ acceptedPatientData: item, doctorsKeys, clinicDoctors }) }}>
                                                <Table.Cell>{item.patientName}</Table.Cell>
                                                <Table.Cell>{item.userData.phone || item.userData.number}</Table.Cell>
                                                <Table.Cell>{item.date}</Table.Cell>
                                                <Table.Cell>{item.doctorData.name}</Table.Cell>
                                                <Table.Cell>{item.facilityName}</Table.Cell>
                                                <Table.Cell>{item.status}</Table.Cell>
                                                <Table.Cell>{item.type}</Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </Table>
                        : <div style={{
                            height: '80%',
                            display: 'flex',
                            padding: '40px',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>No records found for selected range</div>
                }

            </div >
        )
    }




}

export default PatientsHistory