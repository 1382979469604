import React, { Component } from 'react';
import './ManagePatients.css'
import PropTypes from 'prop-types'
import AcceptPatient from './AcceptPatientCell/AcceptPatient'
import ManagePatient from './ManagePatientCell/ManagePatient'
import NewRequestsManageCell from './NewRequestsManageCell/NewRequestsManageCell'
import { debounce } from 'lodash';
import Moment from 'moment';
import { StickyTree } from 'react-virtualized-sticky-tree';

class ManagePatients extends Component {

    static propTypes = {
        clinicsList: PropTypes.object.isRequired,
        currentUser: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        updateParentState: PropTypes.func.isRequired,
        doctorKey: PropTypes.string.isRequired,
        clinicDoctors: PropTypes.array,
    }

    constructor(props) {
        super(props);
        this.state = {
            dateSelected: new Date(),
            patientsStatus: 'all',
            bookingsStatus: 'newrequests',
            bookings: [],
            updated: false,
            filterParameter: '',
            items: Array.from({ length: 20 }),
            filteredBookings: [],
            counter: 0,
            filteredManageList: [],
            filteredNewManageList: [],
            filteredNewRequestsList: [],

        }
    }

    addPatientClicked = () => {
        let doctorsKeys = this.props.clinicsList[this.props.activeBranchKey].doctors
        this.props.patientData({ doctorsKeys: doctorsKeys })

    }

    removeDuplicates(arr, created) {
        arr.map((offlineItem, index) => {
            if (offlineItem.created === created) {
                arr.splice(index, 1);
            }
        })
    }
    scrollToBottom = () => {
        if (this.BookingsEnd){
            this.BookingsEnd.scrollIntoView({ behavior: "auto" })
        }
    }
    componentWillReceiveProps(nextProps) {
        let { doctorKey, allBooking } = this.props;
        let { patientsStatus, bookingsStatus, filterParameter, filteredBookings } = this.state;

        if (nextProps) {
            doctorKey = nextProps.doctorKey;
            allBooking = nextProps.allBooking;
        }
        let patientsIds = []
        filteredBookings = [];
        let counter = 0;
        (allBooking ? allBooking : []).map(item => {
            patientsIds.push(item.patientId)
            if (item.status === 'new' || item.status === 'pending') {
                counter++
            }
            if (bookingsStatus === 'newrequests') {
                if (item.online && (item.status === "new" || item.status === 'pending')) {
                    filteredBookings.push(item)
                }
            } else if (bookingsStatus === 'manage' && ((doctorKey !== '') ? (item.doctorId === doctorKey) : true)) {
                if (patientsStatus === 'all') {
                    if (this.props.hideExtraData ? (item.status === "succeeded" || item.status === 'accepted') : true) {
                        filteredBookings.push(item)
                    }
                } else if (patientsStatus === 'online') {
                    if (item.online && (this.props.hideExtraData ? (item.status === "succeeded" || item.status === 'accepted') : (item.status === "succeeded" || item.status === 'discharged' || item.status === 'accepted'))) {
                        filteredBookings.push(item)
                    }
                } else if (patientsStatus === 'offline') {
                    if (!item.online && (this.props.hideExtraData ? (item.status === "succeeded" || item.status === 'accepted') : (item.status === "succeeded" || item.status === 'discharged' || item.status === 'accepted'))) {
                        filteredBookings.push(item)
                    }
                }
            }

        });

        var count = {};
        patientsIds.forEach(function (i) { count[i] = (count[i] || 0) + 1; });
        function compare(a, b) {
            if (a.created > b.created)
                return -1;
            if (a.created < b.created)
                return 1;
            return 0;
        }

        switch (bookingsStatus) {
            case 'manage':
                filteredBookings.sort(function (a, b) {
                    let dateA = a.selectedDate.replace(" at", ",")
                    let dateB = b.selectedDate.replace(" at", ",")
                    return new Date(dateA) - new Date(dateB);
                });
                break
            default:
                filteredBookings.sort(compare);
                break
        }
        let newSectionTitle = { root: { name: 'root', children: [], depth: 0 } }
        filteredBookings.map(item => {
            if (count[item.patientId] > 1) {
                item.new = false
            } else {
                item.new = true
            }
            let optimizedDate = Moment(new Date(item.selectedDate.replace(" at", ",")))
            let titleDate = optimizedDate.format('MMM DD, YYYY').toString()
            if (filterParameter !== '') {
                if (item.patientName.toLowerCase().replace(" ", "").includes(filterParameter) || String(item.patientNumber).includes(filterParameter) || item.selectedDate.toLowerCase().replace(" ", "").includes(filterParameter)) {

                    newSectionTitle[titleDate] ? newSectionTitle[titleDate].children.push(item.bookingId) : newSectionTitle[titleDate] = { name: titleDate, isSticky: true, children: [item.bookingId], depth: 1 };

                    if (!newSectionTitle['root'].children.includes(titleDate)) {
                        newSectionTitle['root'].children.push(titleDate);
                    }
                    item.children = [];
                    item.depth = 2;
                    item.isSticky = false;
                    item.name = item.bookingId;
                    return newSectionTitle[item.bookingId] = item;
                }
            } else {

                newSectionTitle[titleDate] ? newSectionTitle[titleDate].children.push(item.bookingId) : newSectionTitle[titleDate] = { name: titleDate, isSticky: true, children: [item.bookingId], depth: 1 };

                if (!newSectionTitle['root'].children.includes(titleDate)) {
                    newSectionTitle['root'].children.push(titleDate);
                }
                item.children = [];
                item.depth = 2;
                item.isSticky = false;
                item.name = item.bookingId;
                return newSectionTitle[item.bookingId] = item;
            }
        })

        this.setState({ newSectionTitle, filteredBookings, counter })

    }
    componentDidMount() {
        this.scrollToBottom()
    }
    componentDidUpdate() {
        this.scrollToBottom()
    }
    filterPatients = (status) => {
        this.setState({ patientsStatus: status, filteredManageList: [] }, () => {
            this.componentWillReceiveProps()
        })
    }
    filterBookings = (status) => {
        this.setState({ bookingsStatus: status, filterParameter: '', filteredManageList: [] }, () => {
            this.componentWillReceiveProps()
        })
    }

    manageClicked = (bookingItem) => {
        this.setState({ dateSelected: new Date(bookingItem.start), bookingsStatus: 'manage' })
    }

    render() {
        return (
            <div style={{ height: '100%' }}>
                {this.renderSendRequestSystem()}
            </div>
        )
    }
    debounceing = debounce((e) => {
        this.setState({ filterParameter: e.target.value.toLowerCase().replace(" ", "") }, () => {
            this.componentWillReceiveProps()
        });
    }, 400)
    newRequestGetChildren = (id) => {
        let { newSectionTitle } = this.state
        return newSectionTitle[id].children.map((childId) => ({ id: childId, isSticky: true, stickyTop: newSectionTitle[id].depth * 10, zIndex: 30 - newSectionTitle[id].depth, height: (newSectionTitle[id].isSticky ? 170 : 0) }));

    };
    newRequestRowRenderer = ({ id, style }) => {
        let { newSectionTitle } = this.state
        let { clinicsList, activeBranchKey } = this.props;
        let item = newSectionTitle[id]
        return (<div key={id} style={style}>
            {
                (item.status === "new" || item.status === 'pending') ? (
                    <AcceptPatient key={id} bookingItem={item} activeBranchKey={activeBranchKey} clinicsList={clinicsList} patientData={(patientData) => { this.props.patientData(patientData) }} />
                ) : ''
            }
        </div>)
    };
    getChildren = (id) => {
        let { newSectionTitle } = this.state
        return newSectionTitle[id].children.map((childId) => ({ id: childId, isSticky: true, stickyTop: newSectionTitle[id].depth * 10, zIndex: 30 - newSectionTitle[id].depth, height: (newSectionTitle[id].isSticky ? 160 : 40) }));

    };
    rowRenderer = ({ id, style }) => {
        let { newSectionTitle } = this.state
        let { clinicsList, activeBranchKey } = this.props;
        let _this = this
        let today = Moment(new Date()).format("MMM DD, YYYY").toString()

        return (<div key={id} style={style}>
            {
                (newSectionTitle[id].status === 'accepted') ? (
                    <ManagePatient patientData={(patientData) => { this.props.patientData(patientData) }} bookingItem={newSectionTitle[id]} manageClicked={_this.manageClicked} updateBookings={(bookings) => this.props.updateBookings(bookings)} oldList={this.props.oldList} doctors={clinicsList[activeBranchKey].doctors} />
                ) : (newSectionTitle[id].status === 'succeeded') ? (
                    <AcceptPatient patientData={(patientData) => { this.props.patientData(patientData) }} bookingItem={newSectionTitle[id]} activeBranchKey={activeBranchKey} clinicsList={clinicsList} manageClicked={_this.manageClicked} updateBookings={(bookings) => this.props.updateBookings(bookings)} oldList={this.props.oldList} />
                ) : (newSectionTitle[id].status === 'discharged' || newSectionTitle[id].status === 'rejected' || newSectionTitle[id].status === 'failed' || newSectionTitle[id].status === 'canceled' || newSectionTitle[id].status === 'removed') ? (
                    <NewRequestsManageCell bookingItem={newSectionTitle[id]} patientData={(patientData) => { this.props.patientData(patientData) }} doctors={clinicsList[activeBranchKey].doctors} />
                ) :

                            <div className="date" key={id} style={style} ref={(el) => { if (today === newSectionTitle[id].date) {this.BookingsEnd = el} }}>
                                <div className="datefixed" style={{ fontFamily: 'tfBold', fontSize: '16px' }}>
                                    {id === Moment(new Date()).format("MMM DD, YYYY").toString() ? "Today" : id === Moment(new Date()).add(1, "day").format("MMM DD, YYYY").toString() ? "Tomorrow" : id}
                                </div>
                            </div>
            }


        </div>)
    };
    renderSendRequestSystem() {
        let { patientsStatus, bookingsStatus, counter, newSectionTitle } = this.state;
        let _this = this;
        return (
            <div className="rightside">
                {
                    (bookingsStatus === 'newrequests') ? (
                        <div className="manage_newrequests">
                            <div className="areaselected" style={{ borderBottom: '2px solid #3498db' }} onClick={() => _this.filterBookings("newrequests")}>New Requests</div>
                            <div className="areaselected" onClick={() => _this.filterBookings("manage")}>Manage</div>
                        </div>
                    ) : (bookingsStatus === 'manage') ? (
                        <div className="manage_newrequests">
                            <div className="areaselected" onClick={() => _this.filterBookings("newrequests")} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>New Requests{counter !== 0 ? <span style={{ background: '#f34747', borderRadius: '50%', width: '20px', height: '20px', display: 'grid', justifyContent: 'center', alignItems: 'center', marginLeft: '6px', color: 'white' }}>{counter}</span> : ''}</div>
                            <div className="areaselected" style={{ borderBottom: '2px solid #3498db' }} onClick={() => _this.filterBookings("manage")}>Manage</div>
                        </div>
                    ) : ''
                }

                {
                    (bookingsStatus === 'manage') ? (
                        (patientsStatus === 'all') ? (
                            <div className="onlineoffline">
                                <div className="selected" style={{ border: '1px solid white', background: '#3498db', color: 'white' }} onClick={() => _this.filterPatients("all")}>All</div>
                                <div className="selected" onClick={() => _this.filterPatients("online")}>Online</div>
                                <div className="selected" onClick={() => _this.filterPatients("offline")}>Offline</div>
                            </div>
                        ) : (patientsStatus === 'online') ? (
                            <div className="onlineoffline">
                                <div className="selected" onClick={() => _this.filterPatients("all")}>All</div>
                                <div className="selected" style={{ border: '1px solid white', background: '#3498db', color: 'white' }} onClick={() => _this.filterPatients("online")}>Online</div>
                                <div className="selected" onClick={() => _this.filterPatients("offline")}>Offline</div>
                            </div>
                        ) : (patientsStatus === 'offline') ? (
                            <div className="onlineoffline">
                                <div className="selected" onClick={() => _this.filterPatients("all")}>All</div>
                                <div className="selected" onClick={() => _this.filterPatients("online")}>Online</div>
                                <div className="selected" style={{ border: '1px solid white', background: '#3498db', color: 'white' }} onClick={() => _this.filterPatients("offline")}>Offline</div>
                            </div>
                        ) : ''
                    ) : ''
                }
                {
                    (bookingsStatus === 'manage') ? (
                        <div className="changeday">
                            <i className="fas fa-search" style={{ color: '#9B9B9B' }}></i>
                            <input type="text" className="searchinput" placeholder="Search for patient or date" onChange={(e) => { e.persist(); this.debounceing(e) }} />
                        </div>
                    ) : ''
                }
                {
                    (bookingsStatus === 'newrequests') ? (
                        newSectionTitle ?
                            <StickyTree
                                className="patientsInfoNewRequest"
                                root={{ id: 'root', height: 0 }}
                                height={'calc(100% - 40px)'}
                                getChildren={this.newRequestGetChildren}
                                rowRenderer={this.newRequestRowRenderer}
                                renderRoot={false}

                            /> : <div>loading...</div>

                    ) : (bookingsStatus === 'manage') ? (
                        newSectionTitle ?
                            <StickyTree
                                className="patientsInfoParent"
                                root={{ id: 'root', height: 0 }}
                                height={'calc(100% - 130px)'}
                                getChildren={this.getChildren}
                                rowRenderer={this.rowRenderer}
                                renderRoot={false}
                            /> : <div>loading...</div>
                    ) : ''
                }
            </div>
        )
    }


}

export default ManagePatients