import React, { Component } from 'react';
import clinicPlaceHolder from '../../img/clinicprofileaspect.png';
import ClinicStatusSteps from './ClinicStatusSteps'
import { Icon, Button } from 'semantic-ui-react'
import ReactTable from "react-table";
import moment from 'moment'
import "react-table/react-table.css";
import { firestore } from 'firebase'

class SelectFacility extends Component {
    state = {
        currentStep: 1,
        allBookings: [],
        selectedFacility: {},
        user: null,
        loggedIn: false,
        claimingPersonName: '',
        suggestionId: null,
        branches: 1,
        readyToStart: false,
    }
    constructor(props, context) {
        super(props, context);
    }

    getTotalBookings(facilityId) {
        let { Analytics } = this.props
        return Analytics?.clinics[facilityId]?.totalBookings || 0
    }

    getTotalRevenue(facilityId) {
        let { Analytics } = this.props
        return Analytics?.clinics[facilityId]?.totalRevenu || 0
    }
    getOnlineConsultaionBookings(facilityId) {
        let { allBookings } = this.props
        if (allBookings?.length) {
            let totalBookings = allBookings.filter(item => {
                return item.facilityId === facilityId && item.service === "onlineConsult"
            })
            return (totalBookings.length)
        }
    }
    getCanceledBookings(facilityId) {
        let { Analytics } = this.props
        return Analytics?.clinics[facilityId]?.totalCanceled || 0
    }
    getSuccessfulBookings(facilityId) {
        let { Analytics } = this.props
        return (Analytics?.clinics[facilityId]?.totalBookings || 0) - (Analytics?.clinics[facilityId]?.totalCanceled || 0)
    }
    getPendingBookings(facilityId) {
        let { allBookings } = this.props
        if (allBookings?.length) {
            let totalBookings = allBookings.filter(item => {
                return item.facilityId === facilityId && (item.status === "new" || item.status === "pending")
            })
            return (totalBookings.length)
        }
    }

    /**
     *
     * @param type
     * @param reset
     * @returns {function(this:SelectFacility)}
     */
    changeLocalFacilityType = (indexx) => {
        return function () {
            const { selectedFacility } = this.state;
            const draftList = this.props.selectFacility.clinics;
            Object.keys(draftList).forEach((item, index) => {
                if (item === indexx) {
                    selectedFacility[item] = draftList[item];
                    this.setState({
                        selectedFacility: selectedFacility
                    })
                    this.props.selectFacility.SelectedFacility(selectedFacility)
                }
            })

            this.props.selectFacility.branchChange(1);
            this.props.selectFacility.readyToStartChange(true)

        }.bind(this)
    }



    /**
     * Change step
     * @param index
     */
    changeCurrentStep = (index) => {
        this.props.selectFacility.clinicsStatus(index)
        this.setState({
            currentStep: index,
        })
    }

    dataTableColumns() {
        let { currentStep } = this.state
        return ([
            {
                accessor: "logoUrl",
                width: currentStep === 1 ? 100 : 150,
                Cell: props => <div className="providerfieldText"><img style={{ minHeight: '60px', maxWidth: '60px', width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={props.value ? props.value : clinicPlaceHolder} alt={props.value} /></div>
            },
            {
                Header: "Name",
                accessor: "facilityName",
                Cell: props => <p className="providerfieldText">{props.value}</p>
            },

            {
                Header: "Total Bookings",
                accessor: "TotalBookings",
                show: currentStep === 1,
                Cell: props => <p className="providerfieldText">{this.getTotalBookings(props.original.facilityId)}</p>
            },
            {
                Header: "Pending Bookings",
                accessor: "PendingBookings",
                show: currentStep === 1,
                Cell: props => <p style={{ color: this.getPendingBookings(props.original.facilityId) !== 0 && 'red', fontWeight: this.getPendingBookings(props.original.facilityId) !== 0 && '700' }} >{this.getPendingBookings(props.original.facilityId)}</p>
            },
            {
                Header: "Online Consultation",
                accessor: "OnlineConsultation",
                show: currentStep === 1,
                Cell: props => <p className="providerfieldText">{this.getOnlineConsultaionBookings(props.original.facilityId)}</p>
            },
            {
                Header: "Canceled Bookings",
                accessor: "CanceledBookings",
                show: currentStep === 1,
                Cell: props => <p className="providerfieldText">{this.getCanceledBookings(props.original.facilityId)}</p>
            },
            {
                Header: "Successful Bookings",
                accessor: "SuccessfulBookings",
                show: currentStep === 1,
                Cell: props => <p className="providerfieldText">{this.getSuccessfulBookings(props.original.facilityId)}</p>
            },
            {
                Header: "Total Revenue",
                accessor: "TotalRevenue",
                show: currentStep === 1,
                Cell: props => <p className="providerfieldText">{this.getTotalRevenue(props.original.facilityId)} {process.env.REACT_APP_CURRNECY}</p>
            },
            {
                Header: `${process.env.REACT_APP_TITLE} Revenue`,
                accessor: "DoctoryRevenue",
                show: currentStep === 1,
                Cell: props => <p className="providerfieldText">{(this.getTotalRevenue(props.original.facilityId) * 0.1).toFixed(2)} {process.env.REACT_APP_CURRNECY}</p>
            },

            {
                width: currentStep === 1 ? 90 : 110,
                accessor: "edit",
                Cell: props => <p className="providerfieldText">
                    {(currentStep === 1) ? (
                        <div>
                            <Button color={'twitter'} onClick={this.changeLocalFacilityType(props.original.facilityId)}>View</Button>
                        </div>
                    ) : (currentStep === 2) ? (
                        <div>
                            <Button color={'twitter'} onClick={this.changeLocalFacilityType(props.original.facilityId)}>Activate</Button>

                        </div>
                    ) : ''}
                </p>
            }
        ])


    }

    render() {
        let { loggedIn, user, currentStep } = this.state;

        return (
            <section id="facility-grid-page">
                <ClinicStatusSteps clinicsList={this.props.selectFacility.clinics} changeCurrentStep={this.changeCurrentStep} currentStep={currentStep} />

                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" style={{ height: '100%' }}>
                        {
                            this.props.loading ?
                                <div style={{ height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Icon.Group size='big'>
                                        <Icon loading size='big' name='spinner' color='grey' />
                                    </Icon.Group>
                                </div>
                                : this.renderFacilitiesList()
                        }
                    </div>
                </div>
            </section>
        )
    }

    /*
    * Functions for content getting
    * */

    /**
     * get Facilities list from redux
     * @param list
     * @returns {XML}
     */
    renderFacilitiesList = (list) => {
        let { currentStep } = this.state;
        let {  Analytics } = this.props
        const draftList = this.props.selectFacility.clinics;
        const search = this.props.selectFacility.search;

        let filteredClinics = Object.values(draftList).filter(item => {
            return (item.facilityName).toLowerCase().includes(search.toLowerCase())
        })




        if (!filteredClinics || Object.keys(filteredClinics).length === 0) return (
            <div className="emptystate">
                <div className="emptystate1">
                    <div className="textMesg">No Clinics</div>
                    <div className="detailMesg">Add clinic to see information in this page</div>
                </div>
            </div>
        );
        return (
            <div >
                {currentStep === 1 && <div className="facilityInfo">
                    <div className="cardHeader">
                        <div className="cardCount">{(Analytics?.general?.totalBookings || 0) - (Analytics?.general?.totalCanceled || 0)}</div>
                        <div className="cardTitle">Total Successful Bookings</div>
                        <div className="cardImage"><i className="fas fa-check-circle"></i></div>
                    </div>
                    <div className="cardHeader">
                        <div className="cardCount">{Analytics?.general?.totalRevenu?.toFixed(2)}</div>
                        <div className="cardTitle">Total Revenue</div>
                        <div className="cardImage"><i className="fas fa-dollar-sign"></i></div>

                    </div>
                    <div className="cardHeader">
                        <div className="cardCount">{(Analytics?.general?.totalRevenu * 0.1).toFixed(2)}</div>
                        <div className="cardTitle">Total {process.env.REACT_APP_TITLE} Revenue</div>
                        <div className="cardImage"><i className="fas fa-hand-holding-usd"></i></div>

                    </div>
                </div>}
                <div className="single-medical">
                    {<ReactTable
                        data={Object.values(filteredClinics)}
                        columns={this.dataTableColumns()}
                        defaultPageSize={10}
                        pageSize={Object.values(filteredClinics).length < 10 ? Object.values(filteredClinics).length : 10}
                        showPageJump={false}
                        showPageSizeOptions={false}
                        // PaginationComponent={DataTablePagination}
                        className='custuomReactTable'
                    />
                    }

                </div>
            </div>
        )

    }

}

export default SelectFacility