import React, { Component } from 'react';
import './AddPatient.css'
import Moment from 'moment';
import { Dropdown, Input } from 'semantic-ui-react'
import fire, { auth } from 'firebase/app'
import 'firebase/database';
import 'firebase/auth';
import { connect } from 'react-redux';
import '../sweetalert.css'
import {
    DateInput,
    DateTimeInput,
} from 'semantic-ui-calendar-react';
import moment from 'moment';
class AddPatient extends Component {

    constructor(props) {
        super(props)
        this.state = {
            patientName: '',
            patientBirth: '',
            doctors: this.props.patientData.clinicDoctors ? this.props.patientData.clinicDoctors : [],
            appointmentDate: null,
            services: [],
            selectedServices: null,
            selectedServicesName: null,
            // duration: 30,
            selectPatient: false,
            selectedPatientRecord: false,
            notes: this.props.patientData && this.props.patientData.acceptedPatientData ? this.props.patientData.acceptedPatientData.notes : '',
            cheched: true,
            referenceNumber: this.props.patientData && this.props.patientData.acceptedPatientData ? this.props.patientData.acceptedPatientData.refrenceNumber : null,
            showRequired: false,
            patientOptions: []
        }
    }


    componentWillMount = () => {
        let _this = this
        let appointmentDate = ''
        if (this.props.patientData.acceptedPatientData) {
            this.getActivityLog()
            if (this.props.patientData.acceptedPatientData.service) {
                fire.firestore().collection('services').doc(this.props.patientData.acceptedPatientData.service).get().then(serviceSnap => {
                    if (serviceSnap.data()) {
                        let selectedServicesName = ""
                        selectedServicesName = serviceSnap.data().name
                        _this.setState({ selectedServicesName })
                    }
                })
            }
            let receivedDate = this.props.patientData.acceptedPatientData.date
            appointmentDate = receivedDate
            let duration = this.props.patientData.acceptedPatientData.duration ? this.props.patientData.acceptedPatientData.duration : null
            _this.setState({ appointmentDate, duration, showRequired: true })
        }
        if (this.props.patientData.date) {
            let modifiedDate = this.props.patientData.date.format('MMM DD, YYYY') + " at " + this.props.patientData.date.format('hh:mm A')
            appointmentDate = modifiedDate
            _this.setState({ appointmentDate })

        }
    }


    mapStatus(status, online) {
        if (status.after === status.before && status.after === "accepted") {
            return "rescheduled"
        }
        switch (status.after) {
            case "pending":
                return "reviewed";
            case "accepted":
                return online ? "confirmed" : 'created'
            case "rejected":
            case "removed":
                return "canceled"
            case "new":
                return "rescheduled"
            case "failed":
                return "no show"
            case "succeeded":
                return "arrived"
            default:
                return status.after
        }
    }
    getActivityLog() {
        if (this.props.patientData && this.props.patientData.acceptedPatientData) {

            let bookingDetails = this.props.patientData.acceptedPatientData
            let bookingId = bookingDetails.bookingId
            let _this = this
            let activitiesArray = []
            fire.firestore().collection('activityLog').where('bookingId', '==', bookingId).get().then((resultsSnap) => {
                if (resultsSnap) {

                    var count = 0
                    resultsSnap.forEach(val => {
                        let record = val.data()
                        let adminKey = record.modifiedBy ? record.modifiedBy : auth().currentUser.uid
                        let moment = Moment(new Date(record.ts))
                        let date = moment.format('MMM DD,YYYY ') + "at " + moment.format('hh:mm A')
                        if (record.after === "requested") {

                            activitiesArray.push({
                                type: 'Patient',
                                name: bookingDetails.patientName,
                                status: 'requested',
                                date: record.date,
                                timeStamp: date,
                                moment: record.ts,
                                doctorName: bookingDetails.doctorName,
                                patientName: bookingDetails.patientName
                            })
                            count++
                            if (count >= resultsSnap.size) {
                                _this.setState({ logObject: activitiesArray })
                            }
                        } else {

                            fire.firestore().collection('users').doc(adminKey).get().then(adminName => {
                                if (adminName.exists) {
                                    activitiesArray.push({
                                        type: (record.after === "new" || record.after === "rejected") ? 'Patient' : 'Admin',
                                        name: (record.after === "new" || record.after === "rejected") ? bookingDetails.patientName : adminName.data().name,
                                        status: _this.mapStatus(record, bookingDetails.online),
                                        date: record.date,
                                        timeStamp: date,
                                        moment: record.ts,
                                        doctorName: bookingDetails.doctorName,
                                        patientName: bookingDetails.patientName
                                    })
                                    count++
                                    // activitiesArray = activitiesArray.filter((v,i) => activitiesArray.indexOf(v)===i);
                                    activitiesArray = activitiesArray.sort((a, b) => {
                                        if (a.moment > b.moment) {
                                            return -1
                                        }
                                        return 1
                                    })

                                    if (count >= resultsSnap.size) {
                                        _this.setState({ logObject: activitiesArray })
                                    }
                                } else {
                                    count++

                                    if (count >= resultsSnap.size) {
                                        _this.setState({ logObject: activitiesArray })
                                    }
                                }
                            })
                        }

                    })
                }
            })
        }
    }

    componentDidUpdate() {
        if (document.getElementById("scrollToMonth")) {
            document.getElementById("scrollToMonth").scrollIntoView()
        }
        if (document.getElementById("scrollToDay")) {
            document.getElementById("scrollToDay").scrollIntoView()
        }
        if (document.getElementById("scrollToHours")) {
            document.getElementById("scrollToHours").scrollIntoView()
        }
        if (document.getElementById("scrollToMins")) {
            document.getElementById("scrollToMins").scrollIntoView()
        }
    }


    timeConvert(n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);

        return (rhours > 0 ? (rhours + " hour ") : '') + (rminutes > 0 ? (rminutes + " min") : '');
    }

    render() {
        const patient = this.props.patientData
        let { selectPatient, notes, selectedServicesName, referenceNumber, showRequired } = this.state;
        return (
            <div className="addnewpatientpopup">
                <div className="popup">
                    <div style={{ color: 'red', justifyContent: 'flex-end', display: 'flex', position: 'absolute', right: '30px', top: '10px', cursor: 'pointer', zIndex: '100' }} onClick={() => this.props.dismiss()}>
                        <div style={{ width: '5%', textAlign: 'center', fontWeight: '800', fontSize: '20px' }}>
                            x
                         </div>

                    </div>

                    <div className="stackedgroup4" style={{ margin: '30px 20px' }}>
                        <div style={{ fontWeight: '800', padding: '0 28px 6px', color: 'black', fontSize: '15px' }}>Patient details:</div>
                        <div className="stackedgroup41" style={selectPatient ? { zIndex: 103 } : { zIndex: 0 }}>
                            <div className="stackedgroup42" style={selectPatient ? { zIndex: 103 } : { zIndex: 0 }}>
                                <div className="inputfieldscopy11">
                                    <div className="patientname">Mobile number</div>

                                    {
                                        (patient.acceptedPatientData) ? (
                                            <Dropdown placeholder="ex. 97156XXXXXXX" selection defaultValue={patient.acceptedPatientData.patientNumber} text={patient.acceptedPatientData.patientNumber} disabled className="inputfieldmandatory" />
                                        ) : ''
                                    }

                                </div>

                                <div className="inputfieldscopy11">
                                    <div className="patientname">Reference Number</div>
                                    <Input disabled className="inputfieldmandatory" placeholder="ex. 123" value={referenceNumber ? referenceNumber : ''} text={referenceNumber ? referenceNumber : ''} onChange={(e, { value }) => this.setState({ referenceNumber: value, edited: true })} />
                                </div>
                            </div>
                            <div className="stackedgroup42" style={{ top: '15px' }}>
                                <div className="inputfieldscopy11">
                                    <div className="patientname">Full Name</div>
                                    {
                                        (patient.acceptedPatientData) ? (
                                            <Dropdown placeholder='Jamal Mostafa' selection defaultValue={patient.acceptedPatientData.patientName} text={patient.acceptedPatientData.patientName} disabled className="inputfieldmandatory" />
                                        ) : ''
                                    }

                                </div>
                                <div className="inputfieldscopy11">
                                    <div className="patientname">Email ID</div>
                                    {
                                        (patient.acceptedPatientData) ? (
                                            <Input defaultValue={patient.acceptedPatientData.patientEmail} disabled className="inputfieldmandatory" />
                                        ) : ''
                                    }

                                </div>
                            </div>

                        </div>

                        <div className="stackedgroup55">
                            <div className="inputfieldscopy11">
                                <div className="patientname" style={{ marginBottom: '6px' }}>Date of Birth</div>
                                <DateInput
                                    disable
                                    input={true}
                                    inputProps={{ readOnly: true }}
                                    startMode='year'
                                    maxDate={moment()}
                                    style={{ width: '100%', pointerEvents: (patient.acceptedPatientData ? 'none' : 'auto'), color: (patient.acceptedPatientData ? 'rgba(155, 154, 155, 1.0)' : 'rgba(0,0,0,.87)') }}
                                    dateFormat='MMM DD, YYYY'
                                    name="date"
                                    placeholder="Date of Birth"
                                    value={this.state.patientBirth ? this.state.patientBirth : patient.acceptedPatientData ? patient.acceptedPatientData.patientAge : ''}
                                    iconPosition="left"
                                    onChange={(e, { value }) => { moment(value, 'MMM DD, YYYY', true).isValid() && this.setState({ patientBirth: value, edited: true }) }}
                                    closable={true}
                                    closeOnMouseLeave={false}
                                    popupPosition='bottom center'
                                    animation='fade'
                                    preserveViewMode={false}
                                />
                            </div>
                            <div className="inputfieldscopy11">
                                <div className="patientname">Insurance</div>
                                {
                                    (patient.acceptedPatientData) ? (
                                        <Input defaultValue={patient.acceptedPatientData.insurance} disabled className="inputfieldmandatory" />
                                    ) : ''
                                }
                            </div>
                        </div>
                        <div style={{ fontWeight: '800', padding: '16px 28px 0', color: 'black', fontSize: '15px' }}>Appointment details:</div>
                        <div className="inputfieldscopy14" >
                            <div className="patientname">Doctor</div>
                            {
                                (patient.acceptedPatientData) ? (
                                    <Input defaultValue={patient.acceptedPatientData.doctorName} disabled className="inputfieldmandatory" />
                                ) : ''
                            }
                        </div>
                        <div className="stackedgroup55" >
                            <div className="inputfieldscopy11" >
                                <div className="patientname">Appointment Date</div>
                                <DateTimeInput
                                    disabled
                                    inputProps={{ readOnly: true }}
                                    style={{ width: '100%', pointerEvents: (patient.hideActions ? 'none' : 'auto'), color: (patient.hideActions ? 'rgba(155, 154, 155, 1.0)' : 'rgba(0,0,0,.87)') }}
                                    dateFormat='MMM DD, YYYY'
                                    divider=' - '
                                    className="inputfieldmandatory"
                                    hideMobileKeyboard={true}
                                    name="dateTime"
                                    placeholder="Date Time"
                                    value={this.state.appointmentDate ? this.state.appointmentDate : ''}
                                    iconPosition="left"
                                    onChange={(e, { value }) => moment(value, 'MMM DD, YYYY - hh:mm a', true).isValid() && this.setState({ appointmentDate: value.replace(' - ', ' at '), edited: true })}
                                    closable={true}
                                    closeOnMouseLeave={false}
                                    timeFormat='AMPM'
                                    popupPosition='top center'
                                    animation='fade'
                                    preserveViewMode={false}
                                    disable={patient.hideActions}
                                />
                            </div>

                            <div className="inputfieldscopy11">
                                <div className="patientname">Duration</div>
                                <Dropdown lazyLoad disabled upward={true} selection selectOnBlur={false} text={this.state.duration ? this.timeConvert(this.state.duration) : ''} className="inputfieldmandatory" />
                            </div>
                        </div>


                        <div className="stackedgroup55" style={{ zIndex: '100' }}>
                            <div className="inputfieldscopy11" style={{ height: '52px' }}>
                                <div className="patientname">Services requested</div>
                                {
                                    (patient.acceptedPatientData) ? (
                                        <Input style={{ top: '6px' }} value={selectedServicesName ? selectedServicesName : "General Consultation"} disabled className="inputfieldmandatory" />
                                    ) : ''
                                }

                            </div>
                            <div className="inputfieldscopy16">
                                <div className="patientname">Notes</div>
                                <Input disabled className="inputfieldmandatory" placeholder="ex. needs wheelchair on arrival" type='text' value={notes ? notes : ''} onChange={(e, { value }) => this.setState({ notes: value, edited: true })} />
                            </div>
                        </div>
                    </div>

                    {
                        this.state.logObject ?
                            <div>
                                <div style={{ color: 'rgba(151, 151, 151, 1)', textAlign: 'center', width: '100%', margin: '12px 0', fontSize: '19px' }}>--------------------------------------------------------------------------------------</div>
                                <div className="activityLog">
                                    <div className="title">Activity</div>
                                    <div className="content">
                                        {

                                            (this.state.logObject).map((item, index) => {
                                                return (
                                                    <div key={index} className="logRecord">
                                                        {
                                                            ((item.status === "confirmed") || (item.status === "requested")) ?
                                                                <div className="recordDetail">{item.type + ": "}<span className="bolded">{item.name}</span>{" " + item.status + " appointment "}<span className="bolded"></span> {" with "}<span className="bolded">{item.doctorName}</span>{" on "}<span className="bolded">{item.date}</span></div>
                                                                : ((item.status === "created") || (item.status === "rescheduled")) ?
                                                                    <div className="recordDetail">{item.type + ": "}<span className="bolded">{item.name}</span>{" " + item.status + " appointment "}{" for "}<span className="bolded">{item.patientName}</span> {" with "}<span className="bolded">{item.doctorName}</span>{item.status === "created" ? " at " : " to "}<span className="bolded">{item.date}</span></div>
                                                                    :
                                                                    <div className="recordDetail">{item.type + ": "}<span className="bolded">{item.name}</span>{" " + item.status + " " + item.patientName}</div>

                                                        }
                                                        <div className="timeStamp"> {item.timeStamp}</div>
                                                        <div className="separator" />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                </div>
                            </div>
                            : ''
                    }

                </div>
            </div >

        )
    }


}
function mapStateToProps(state) {
    return {
        clinics: state.clinics.data,
        user: state.user.data.user,
        branches: state.branches,
        clinicsKeys: state.clinicsKeys,
        Bookings: state.Bookings
    }
}


export default connect(mapStateToProps)(AddPatient)