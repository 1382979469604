import React, { Component } from 'react';
import './thrive.css'
import { Input , Button} from 'semantic-ui-react'
import fire from 'firebase/app'
import 'firebase/database';
import 'firebase/auth';

import Swal from 'sweetalert2';
import crypto from 'crypto-js';
class Thrive extends Component {
state={
    
}
connect(){
    let {email,pw} = this.state
        fire.app('thriveApp').auth().signInWithEmailAndPassword(email?email:'',pw?pw:'').then((user)=>{
            if (user){
                let newPw = crypto.AES.encrypt(pw,'thriveingWorld').toString()                
                fire.firestore().collection('thriveCr').doc('thriveCr').set({em:email,pw:newPw}).then(()=>{
                    this.setState({loading:false})
                    this.props.dismiss()
                })
            }
        }).catch(error=>{
            Swal.fire({
                heightAuto:false,
                type:'error',
                title:'Error',
                text:error
            }).then(()=>{
                this.setState({loading:false})
            })
        })
}
render(){
    let {loading} = this.state
    return(
    <div className="mainContainerthrive">
        <div className="popupTitle">
            <div>Thrive Medical</div>
            <div className='blueLineContainer'>
                <div className="blueLine"></div>
            </div>
        </div>
        <div className='content'>
            <div className='row'>
                <div className='cell'>
                    <div className='title'> Thrive ID</div>
                    <Input onChange={(e,{value})=>this.setState({email:value})} type="email" name='email' className='input'/>
                </div>
            </div>
        </div>
        <div className='content'>
            <div className='row'>
                <div className='cell'>
                    <div className='title'> Password</div>
                    <Input onChange={(e,{value})=>this.setState({pw:value})}  type="password" name='password' className='input'/>
                </div>
            </div>
        </div>
 
        <div style={{display:'flex',justifyContent:'center',paddingTop:'10px',alignItems:'flex-end'}}>
            <Button basic onClick={()=>this.props.dismiss()}>Cancel</Button>
            <Button loading={loading} color='blue' onClick={()=>{this.setState({loading:true});this.connect()}}>Connect</Button>
        </div>
    </div>)
}
}
export default Thrive