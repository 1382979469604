import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Swal from 'sweetalert2';
import { Button, Input } from 'semantic-ui-react'
import { functions, auth } from 'firebase'
class Credentials extends Component {
    static propTypes = {
        doctorObject: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        updateParentState: PropTypes.func.isRequired,
    }

    constructor(props, context) {
        super(props, context);
    }
    state = {
        password: ''
    }
    componentDidMount() {
        let { doctorObject, activeBranchKey } = this.props;
        let doctorObjects = doctorObject[activeBranchKey];
        if (doctorObjects.withCred) {
            this.setState({ disabled: true })
        }
    }

    render() {
        let { password, disabled } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let doctorObjects = doctorObject[activeBranchKey];
        return (
            <div>
                <label style={{ fontSize: '18px' }}>Credentials</label>

                <div style={{ display: 'flow-root', width: '40%' }}>
                    <div className="box" >
                        <label>Email <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                        <Input style={{ width: '100%' }}
                            name='email'
                            disabled={disabled}
                            value={doctorObject[activeBranchKey].email}
                            className="inputfieldmandatory"
                            onChange={this.handleChange.bind(this)} />
                    </div>

                    <div className="box" >
                        <label>Password <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                        <Input
                            disabled={disabled}
                            name='password'
                            type='password'
                            style={{ width: '100%' }}
                            value={password}
                            placeholder='*********'
                            className="inputfieldmandatory"
                            onChange={this.handleChange.bind(this)} />
                    </div>
                    {
                        !disabled && <Button loading={this.state.loading} className="save-btn-local" onClick={this.SaveCredentials}>Create</Button>
                    }

                </div>

            </div>
        )
    }
    handleChange(e) {
        let { doctorObject, activeBranchKey } = this.props;

        switch (e.target.name) {
            case 'email':
                doctorObject[activeBranchKey].email = e.target.value
                this.props.updateParentState(doctorObject);

                break;
            case 'password':
                this.setState({ password: e.target.value })
                break;

            default:
                break;
        }
    }
    SaveCredentials = () => {
        let _this = this
        let { password } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        if (doctorObject[activeBranchKey].email && password) {
            this.setState({ loading: true }, () => {
                var addMessage = functions().httpsCallable('createDoctor');
                addMessage({
                    data: {
                        doctorId: doctorObject[activeBranchKey].doctorId || doctorObject[activeBranchKey].id,
                        email: doctorObject[activeBranchKey].email,
                        password: password
                    }
                })
                    .then((result) => {
                        if (result?.data?.success) {
                            Swal.fire({
                                heightAuto: false,
                                type: 'success',
                                title: 'Alert',
                                text: 'Account created successfully'
                            }).then(() => {
                                doctorObject[activeBranchKey].withCred = true
                                _this.props.updateParentState(doctorObject);
                                _this.setState({ disabled: true, loading: false })
                            })
                        } else {
                            _this.setState({ loading: false }, () => {
                                Swal.fire({
                                    heightAuto: false,
                                    type: 'error',
                                    title: 'Error',
                                    text: result?.data?.error?.errorInfo?.message || 'Contuct service provider!'
                                })
                            })

                        }
                    });
            })


        } else {
            Swal.fire({
                heightAuto: false,
                type: 'error',
                title: 'Alert',
                text: 'Please fill all requirments'
            })
        }
        this.props.updateParentState(doctorObject);

    }

}
export default Credentials