import React, { Component } from 'react';
import { Input ,Checkbox, Button} from 'semantic-ui-react'
import fire from 'firebase/app'
import 'firebase/database';

import Swal from 'sweetalert2';
class ExportRecords extends Component {
 
    state = { 
        info:{
            records:[]
        },
        showRequired:false
    }

    handleInputChange(type,e,{value}){
        let {info} = this.state
        info[type] = value
        this.setState({info})
    }

    handleCheckBox(request){
        let {info} = this.state
        if (info.records.includes(request)){
            let index = info.records.indexOf(request)
            info.records.splice(index,1)
        }else{
            info.records.push(request)
        }
        this.setState({info})
    }
    submit(){
        let {info} = this.state
           if (info.records.length&&info.email){
            fire.firestore().collection('exports').add({
                created:Date.now(),
                email:info.email,
                info:info.records,
                status:'Pending',
                name:this.props.userName?this.props.userName:''
            }).then(()=>{
                Swal.fire({
                    heightAuto:false,
                    title:'Success',
                    type:'success',
                    text:'Request sent successfully'
                }).then(()=>{
                    this.props.dismiss()
                })
            })
           }else{
            Swal.fire({
                heightAuto:false,
                title:'Alert',
                type:'warning',
                text:'Please fill all required fields'
            }).then(()=>{
                this.setState({showRequired:true})
            })
           }
    }
render(){
    let {info,showRequired} = this.state
    return(
    <div className="mainContainer" style={{height:'487px', top:'-20%',position:'relative'}}>
        <div className="popupTitle">
            <div>Export Data</div>
            <div className='blueLineContainer'>
                <div className="blueLine"></div>
            </div>
        </div>
        <div className='content' style={{fontSize:'16px',color: 'rgba(74, 74, 74, 1)'}}>
            <div className='row'>
            Export your data to your email in excel format
            </div>
        </div>

        <div className='content' style={{paddingTop:'31px',fontSize:'16px',color: 'rgba(74, 74, 74, 1)'}}>
            <div className='row'>
            Select data you want to export:
            </div>
        </div>
        <div className='content' style={{paddingBottom:'0px'}}>
            <div className='row'>
            <Checkbox checked={info.records.includes("Patients")?true:false} onChange={this.handleCheckBox.bind(this,"Patients")} label='Patients' />
            </div>
        </div>
        <div className='content' style={{paddingBottom:'0px'}}>
            <div className='row'>
            <Checkbox checked={info.records.includes('Services')?true:false} onChange={this.handleCheckBox.bind(this,"Services")} label='Services' />
            </div>
        </div>
        <div className='content' style={{paddingBottom:'0px'}}>
            <div className='row'>
            <Checkbox checked={info.records.includes('Appointments')?true:false} onChange={this.handleCheckBox.bind(this,"Appointments")} label='Appointments' />
            </div>
        </div>
        <div className='content' style={{paddingBottom:'0px'}}>
            <div className='row'>
            <Checkbox checked={info.records.includes('Doctors')?true:false} onChange={this.handleCheckBox.bind(this,"Doctors")} label='Doctors' />
            </div>
        </div>
        <div className='content' style={{paddingTop:'32px',paddingBottom:'9px',fontSize:'16px',color: 'rgba(74, 74, 74, 1)'}}>
            <div className='row'>
            <div>Email <span style={{fontSize:'12px'}}>( Data will be sent to this email )</span></div>
            </div>
        </div>
        <div className='content'>
            <div className='row'>
                <div className='cell'>
                    <Input error={showRequired&&!info.email} value={info.email?info.email:''} onChange={this.handleInputChange.bind(this,"email")} type='email' className='input'/>
                </div>
            </div>
        </div>
        <div style={{display:'flex',justifyContent:'flex-end',paddingRight:'16px',alignItems:'flex-end',height:'70px'}}>
            <Button basic style={{width:'98px',marginLeft:'8px'}} onClick={()=>this.props.dismiss()}>Cancel</Button>
            <Button onClick={()=>this.submit()} color='blue' style={{width:'140px',marginLeft:'8px'}}>Request export</Button>
        </div>
    </div>)
}
}
export default ExportRecords