import React, { Component } from 'react';
import firebase from 'firebase/app'
import 'firebase/database';
import FMSHeader from '../Header/defaultHeader'
import home from '../../img/thriveFmsLogo.png';
import SelectFacility from './SelectFacility';
import PatientPopup from './AddPatient/AddPatient'
import MainWizard from './MainWizard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import alertCircle from '../../img/alert-circle.png';
import { actions } from '../../modules/clinics-module';
import { userActions } from '../../modules/user-module';
import './AppointmentSystem.css'
import HomeLogin from '../../Home Login/index'
import { Dimmer, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class HistorySystem extends Component {



    constructor(props, context) {
        super(props, context);
        this.handleStateChange = this.handleStateChange.bind(this)
        this.state = {
            user: null,
            loggedIn: false,
            claimingPersonName: '',
            branches: 1,
            readyToStart: false,
            draftList: null,
            selectFacility: null,
            clinicsStatus: 1,
            modalLoginIsOpen: false,
            doctorSetupUpdatedData: null,
            addPatientUpdatedData: null,
            // activateClicked: false
            loading: true,
            dismissDoctor: false,
            selectedDoctor: '',
            doctorKey: '',
            showMenu: window.screen.width <= 768 ? false : true,
            patientData: null,

        }
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        let { accessScreens } = this.props.user.data ? this.props.user.data : {}
        let { branches } = this.props
        this.setState({
            allowed: accessScreens && accessScreens.includes("history"),
            branches: Object.keys(branches).length,
            draftList: branches ? branches : {},
            loading: false
        })

        window.addEventListener('offline', this.handleStateChange);
        window.addEventListener('online', this.handleStateChange);
    }
    componentWillUnmount() {
        window.removeEventListener('offline', this.handleStateChange)
        window.removeEventListener('online', this.handleStateChange)

    }
    handleStateChange() {
        this.setState({ offline: !window.navigator.onLine })
    }

    logout = () => {
        // this.setState({
        //     loggedIn: false,
        //     user: null,
        //     modalLoginIsOpen: true
        // });
    }
    /*Simple functions*/
    branchChange = number => {
        this.setState({ branches: number })
    }

    clinicStatusSelected = number => {
        this.setState({ clinicsStatus: number })
    }
    readyToStartChange = bool => {
        // let { activateClicked } = this.state;
        // if (activateClicked) {
        //     this.setState({ activateClicked: false })
        // }
        this.setState({ readyToStart: bool })
    }


    SelectedFacility = object => {
        this.setState({ selectFacility: object })
    }

    handleFacilityClick = bool => {
        if (!this.state.readyToStart) return;
        this.setState({
            readyToStart: false,
            clinicsStatus: 1,
        });

    }
    showMenuFunc = () => {
        let { showMenu } = this.state;
        this.setState({
            showMenu: !showMenu,
        })
    }
    render() {
        let { loading, showMenu, allowed, patientData, selectFacility, patientOptions } = this.state;
        let { loggedIn, user, accessType } = this.props.user.data ? this.props.user.data : {}
        let offlineBookings = this.props.Bookings.bookings.bookings

        return (
            (loggedIn && user) ?
                <div className="bootstrap">
                    <section id="main-wrapper" >
                        <div className="container-fluid">
                            <div className="row" style={{ overflow: 'hidden' }}>
                                <div className="col-1 side-nav" style={{ display: showMenu ? 'block' : 'none' }}>
                                    <ul>
                                        <li style={{ height: '60px' }}><a href="/#/"><img src={home} /></a></li>
                                        <li ><Link to="/dashboard"><i className="fab fa-buromobelexperte"></i>Dashboard</Link></li>
                                        <li ><Link to="/facility-management-system"><i className="far fa-hospital"></i>Facilities</Link></li>
                                        <li ><Link to="/wizard-doctor"><i className="fas fa-user-md"></i>Providers</Link></li>
                                        <li ><Link to="/appointment-system"><i className="far fa-calendar-alt"></i>Calendar</Link></li>
                                        <li ><Link to="/patients"><i className="fas fa-users"></i>Patients</Link></li>
                                        <li onClick={this.handleFacilityClick} style={{ borderLeft: '3px solid #0096FD' }}><a><i style={{ color: '#0096FD' }} className="fa fa-history"></i>History</a></li>
                                        <li ><Link to="/feedback"><i className="fa fa-star"></i>Feedback</Link></li>
                                        {
                                            accessType === 'superAdmin' ?
                                                (
                                                    process.env.REACT_APP_NAME !== 'saeed' ?
                                                        <li ><Link to="/helpers"><i className="fas fa-database"></i>Helpers</Link></li> : '')
                                                : ''
                                        }
                                        <li ><Link to="/settings"><i className="fas fa-cogs"></i>Settings</Link></li>
                                    </ul>

                                </div>
                                <div className="col-11 main-body" style={{ width: '100%', maxWidth: !showMenu ? 'none' : '' }}>
                                    <FMSHeader showMenuFunc={this.showMenuFunc} query={this.props.location.search} logout={this.logout} title="History" offline={this.state.offline} />
                                    {
                                        (loggedIn && user) ? (
                                            allowed ?
                                                this.renderContent()
                                                :
                                                this.renderAccessDenied()
                                        ) : <div className="main-side">Please login</div>
                                    }
                                </div>
                                {
                                    patientData ?
                                        <PatientPopup offlineBookings={offlineBookings} patientData={patientData} patientOptions={patientOptions} dismiss={() => this.setState({ patientData: null })} selectFacility={selectFacility} adminInfo={user.info} /> : ''
                                }
                            </div>
                        </div>
                    </section>
                </div >
                : loading ?
                    <Dimmer active>
                        <Icon.Group size='big'>
                            <Icon loading size='big' name='spinner' />
                            <div style={{ fontSize: '16px', padding: '10px', fontStyle: 'initial' }}>loading</div>
                        </Icon.Group>
                    </Dimmer>
                    : <HomeLogin show={this.state.modalLoginIsOpen} getUser={() => this.props.actions.getUser(true)} />
        );

    }
    // }
    /*
     * Functions for content getting
     * */
    renderAccessDenied() {
        return (
            <div style={{ height: 'calc( 100% - 60px )' }}>
                <div className="emptystate">
                    <div className="emptystate1">
                        <img src={alertCircle} alt="Img" />
                        <div className="textMesg">No Permission</div>
                        <div className="detailMesg">Contact your admin to access this page</div>
                    </div>
                </div>
            </div>
        )
    }

    selectDoctor = (e, { value }) => {
        let { selectFacility, draftList } = this.state;

        let clinicDoctors = []
        let facilityId = selectFacility ? Object.keys(selectFacility)[0] : ''
        if (facilityId) {
            clinicDoctors = (draftList[facilityId] && draftList[facilityId].doctorsData) ? draftList[facilityId].doctorsData : []
        }

        if (value === "All Doctors") {
            this.changeDoctorKey('')
        } else {
            clinicDoctors.map(item => {
                if (item.name === value) {
                    this.changeDoctorKey(item.id)
                }
            })
        }

        this.setState({ selectedDoctor: value })
    }


    changeDoctorKey = (key) => {
        this.setState({
            doctorKey: key,
        })
    }
    renderContent = () => {
        let { draftList, selectFacility, clinicsStatus, doctorSetupUpdatedData, dismissDoctor, doctorKey } = this.state;
        let { user } = this.props.user.data
        let clinicDoctors = []
        let facilityId = selectFacility ? Object.keys(selectFacility)[0] : ''
        if (facilityId) {
            clinicDoctors = (draftList[facilityId] && draftList[facilityId].doctorsData) ? draftList[facilityId].doctorsData : []
        }
        let doctorsOptions = [{ text: 'All Doctors', value: 'All Doctors', key: 'All Doctors' }]
        clinicDoctors.map(item => {
            return doctorsOptions.push({ text: item.name, value: item.name, key: item.doctorId })
        })

        if (this.state.readyToStart) {
            return (
                <div style={{ height: 'calc( 100% - 60px )' }}>
                    <MainWizard
                        doctorKey={doctorKey}
                        changeDoctorKey={this.changeDoctorKey}
                        draftList={selectFacility}
                        wizardOptions={
                            {
                                branches: this.state.branches,
                            }
                        }
                        selectFacility={
                            {
                                readyToStartChange: this.readyToStartChange
                            }
                        }
                        patientData={(patientData) => {
                            this.setState({ patientData })
                        }}
                        currentUser={user}
                        clinicsStatus={clinicsStatus}
                        changeClinicsStatus={this.clinicStatusSelected}
                        clinicDoctors={clinicDoctors}
                        doctorSetupUpdatedData={doctorSetupUpdatedData}
                        patientOptions={(patientOptions) => this.setState({ patientOptions })}
                        dismissDoctor={dismissDoctor}
                        resetDismissDoctor={() => this.setState({ dismissDoctor: false })}
                    />
                </div>
            )

        }
        return (
            <SelectFacility selectFacility={
                {
                    branchChange: this.branchChange,
                    branches: this.state.branches,
                    clinics: draftList,
                    clinicsStatus: this.clinicStatusSelected,
                    SelectedFacility: this.SelectedFacility,
                    facilityNameChange: this.facilityNameChange,
                    readyToStartChange: this.readyToStartChange,

                }

            }
                loading={this.state.loading}
            />
        )
    }

}

function mapStateToProps({ user, branches, Bookings }) {
    return {
        user,
        branches: branches ? branches.data : {},
        Bookings
    }
}

const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators(
            actions,
            dispatch,
        ),
        ...bindActionCreators(
            userActions,
            dispatch,
        ),
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(HistorySystem);