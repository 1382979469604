import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Select from 'react-select';
import firebase from 'firebase/app'
import 'firebase/database';
import { validateEmail } from '../../../helpers/Full'
import { Dropdown, Input } from 'semantic-ui-react'
import DatePicker from 'react-modern-datepicker';
import moment from 'moment';
import './RightBasicInfo.css'
class RightBasicInfo extends Component {
    static propTypes = {
        doctorObject: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        updateParentState: PropTypes.func.isRequired,
    }
    state = {
        nationalities: [],
        languages: [],
        yearsOfExp: null,
        selectedYear: null,
        years: {
            value: '',
            text: ''
        },
        options: [

        ],
        ToDate: this.props.doctorObject[this.props.activeBranchKey].ExpiryDate ? moment(this.props.doctorObject[this.props.activeBranchKey].ExpiryDate, 'YYYY-MM-DD') : null
    }
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount = () => {
        this.getYears();
        firebase.firestore().collection('helpers').get().then(result=>{
            let state = {}
            result.forEach(doc=>{
                state[doc.id] = doc.data()[doc.id]
            })
            this.setState(state)
        })
    }

    render() {
        return (
            <div>
                {this.renderAdminInfoTop()}
                {this.renderLegal()}
                {this.renderAdminInfoBottom()}
            </div>
        )
    }

    /**
    *
    * @param arabic
    * @param e
    */
    changeShortBio = (arabic = false, e) => {
        let val = e.target.value,
            _this = this;
        let { doctorObject } = this.props;

        Object.keys(doctorObject).forEach(function (item) {
            if (item === _this.props.activeBranchKey) {
                if (arabic)
                    doctorObject[item].shortBio_ar = val
                else
                    doctorObject[item].shortBio = val
            }
        });
        this.props.updateParentState(doctorObject);
    }

    /**
    *
    * @param index
    * @param type
    * @param e
    */
    changeYears = (index, type, e) => {
        const { activeBranchKey } = this.props;
        let { doctorObject } = this.props;
        doctorObject[activeBranchKey][index][type] = e.target.value;
        this.props.updateParentState(doctorObject);
    }
    getYears = () => {
        let { years, options } = this.state;
        var i;
        for (i = 1960; i <= 2018; i++) {
            years = {
                value: i,
                text: i
            }
            options.push(years)
        }
        this.setState({
            years: years,
            options: options
        });
    }

    handleChange = (selectedYear, { value }) => {
        const { doctorObject, activeBranchKey } = this.props;
        doctorObject[activeBranchKey].yearsOfExp = value
        this.setState({ selectedYear: doctorObject[activeBranchKey].yearsOfExp });
        this.props.updateParentState(doctorObject);
    }
    /*
     * Functions for content getting
     * */
    /**
     *
     * @returns {XML}
     */
    renderAdminInfoBottom = () => {
        const { doctorObject, activeBranchKey } = this.props;
        const { selectedYear, options } = this.state;
        const _this = this;
        return (
            <div className="right-side-box">
                <div className="box-title">
                    <strong>Year started medical practice</strong>
                </div>
                <div className="box">
                    <Dropdown
                        className="rightselectiondropdown"
                        onChange={this.handleChange}
                        options={options}
                        selection
                        fluid
                        placeholder="select Year"
                        upward={false}
                        text={String(doctorObject[activeBranchKey].yearsOfExp)}
                        value={doctorObject[activeBranchKey].yearsOfExp}
                    />
                </div>
                <div className="box">
                    <label>Short Bio</label>
                    <textarea type="text" name="name" placeholder="Short Bio" value={doctorObject[activeBranchKey].shortBio} onChange={this.changeShortBio.bind(this, false)} />
                </div>
                <div className="box arabic-input">
                    <label>سيرة الذاتية</label>
                    <textarea style={{ textAlign: 'right' }} type="text" name="name" placeholder="السيرة الذاتية" value={doctorObject[activeBranchKey].shortBio_ar} onChange={this.changeShortBio.bind(this, true)} />
                </div>
            </div>
        )
    }
    changeGender = (e) => {
        const { doctorObject, activeBranchKey } = this.props;
        doctorObject[activeBranchKey].gender = e.target.id;
        this.props.updateParentState(doctorObject);
    }

    addNationality = (type, { value }) => {

        const { doctorObject, activeBranchKey } = this.props;
        let val = value

        let index = doctorObject[activeBranchKey].nationalities.indexOf(val)
        if (index === -1) {
            doctorObject[activeBranchKey].nationalities.push(val);
        }

        this.props.updateParentState(doctorObject);
    }
    removeSelected = (index, type) => {
        const { doctorObject, activeBranchKey } = this.props;

        let item = doctorObject[activeBranchKey][type][index]
        if (item) {
            doctorObject[activeBranchKey][type].splice(index, 1)
        }

        this.props.updateParentState(doctorObject);

    }
    subRenderNationality = () => {
        let _this = this;
        const { nationalities } = this.state;

        const { doctorObject, activeBranchKey } = this.props;
        let x = [];
        nationalities.map(function (item, index) {
            x.push({ value: item, key: item, text: item })
        })
        return (
            <div className="box">
                <label>Nationality <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                <Dropdown
                    error={!doctorObject[activeBranchKey].nationalities.length && doctorObject[activeBranchKey].showError}
                    selection
                    fluid
                    options={x}
                    noResultsMessage="Can't find this nationality!"
                    text="Type nationality name"
                    className="rightselectiondropdown"
                    upward={false}
                    search
                    onChange={this.addNationality} />
                {
                    (doctorObject[activeBranchKey].nationalities.length) ? (
                        <div className="doctor-gray-box">
                            {doctorObject[activeBranchKey].nationalities.map(function (item, index) {
                                return (
                                    <button onClick={_this.removeSelected.bind(this, index, 'nationalities')} key={item} className="tag-button">{item} <i className="fas fa-times"></i></button>
                                )
                            })}
                        </div>

                    ) : null
                }
            </div>
        )
    }

    addLanguage = (type, { value }) => {
        const { doctorObject, activeBranchKey } = this.props;
        let val = value

        let index = doctorObject[activeBranchKey].languages.indexOf(val)
        if (index === -1) {
            doctorObject[activeBranchKey].languages.push(val);
        }
        this.props.updateParentState(doctorObject);
    }
    subRenderLanguage = () => {
        let _this = this;
        const { doctorObject, activeBranchKey } = this.props;
        const { languages } = this.state;

        let x = [];
        languages.map(function (item, index) {
            x.push({ value: item, key: item, text: item })
        })
        return (

            <div className="box">
                <label>Spoken Languages <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                <Dropdown
                    error={!doctorObject[activeBranchKey].nationalities.length && doctorObject[activeBranchKey].showError}
                    selection
                    fluid
                    options={x}
                    noResultsMessage="Can't find this language!"
                    text="Type language name"
                    className="rightselectiondropdown"
                    upward={false}
                    search
                    onChange={this.addLanguage} />
                {
                    (doctorObject[activeBranchKey].languages.length) ? (
                        <div className="doctor-gray-box">
                            {doctorObject[activeBranchKey].languages.map(function (item, index) {
                                return (
                                    <button onClick={_this.removeSelected.bind(this, index, 'languages')} key={item} className="tag-button">{item} <i className="fas fa-times"></i></button>
                                )
                            })}
                        </div>

                    ) : null
                }

            </div>
        )
    }
    /**
       *
       * @param e
       */
    changeEmail = (type, e) => {
        let val = e.target.value
        let { doctorObject, activeBranchKey } = this.props;
        doctorObject[activeBranchKey][type] = val
        this.props.updateParentState(doctorObject);
    }
    renderAdminInfoTop = () => {
        const { applyToAll, doctorObject, activeBranchKey } = this.props;
        const _this = this;
        return (

            <div className="right-side-box marginBottom30">
                <div className="box-title">
                    <strong>Personal</strong>
                </div>
                <div className="box">
                    <label>Gender <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                    {
                        (doctorObject[activeBranchKey].gender === 'Male') ? (
                            <div>
                                <button onClick={this.changeGender} data-active={doctorObject[activeBranchKey].gender === 'Male'} id="Male" className="color-btn"><i className="fas fa-male"></i> Male</button>
                                <button onClick={this.changeGender} data-active={doctorObject[activeBranchKey].gender === 'Female'} id="Female" className="normal-btn"><i className="fas fa-female"></i> Female</button>
                            </div>

                        ) : (doctorObject[activeBranchKey].gender === 'Female') ? (
                            <div>
                                <button onClick={this.changeGender} data-active={doctorObject[activeBranchKey].gender === 'Male'} id="Male" className="normal-btn"><i className="fas fa-male"></i> Male</button>
                                <button onClick={this.changeGender} data-active={doctorObject[activeBranchKey].gender === 'Female'} id="Female" className="color-btn"><i className="fas fa-female"></i> Female</button>
                            </div>
                        ) : (<div>
                            <button onClick={this.changeGender} data-active={doctorObject[activeBranchKey].gender === 'Male'} id="Male" className="normal-btn"><i className="fas fa-male"></i> Male</button>
                            <button onClick={this.changeGender} data-active={doctorObject[activeBranchKey].gender === 'Female'} id="Female" className="normal-btn"><i className="fas fa-female"></i> Female</button>
                        </div>)
                    }
                </div>
                {this.subRenderNationality()}
                {this.subRenderLanguage()}
                {/* <div className="box">
                    <label>Doctor Email <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                    <Input style={{ width: '98%' }} error={!validateEmail(doctorObject[activeBranchKey].email) && doctorObject[activeBranchKey].showError} type="text" name="name" placeholder="Input email" value={doctorObject[activeBranchKey].email} onChange={this.changeEmail.bind(this, "email")} />
                </div> */}
            </div>
        )
    }

    changeAttachVerify = (empty = false, type, e) => {
        let { doctorObject, activeBranchKey } = this.props;

        let result = '';
        if (!empty) {
            if (e.target.files[0] === undefined) return
            let reader = new FileReader();
            let file = e.target.files[0];
            result = {
                name: file.name
            };
            reader.readAsDataURL(file);
            reader.onload = function () {
                result.base64 = reader.result;
            }
        } else {
            result = '';
        }
        if (!doctorObject[activeBranchKey].licenseDoc){
            doctorObject[activeBranchKey].licenseDoc = {}
        }
        doctorObject[activeBranchKey].licenseDoc[type] = result;
        doctorObject[activeBranchKey].certificateModified = true
        this.props.updateParentState(doctorObject);
    }
    handleToDateChange(date) {
        let { doctorObject, activeBranchKey } = this.props;
        // doctorObject[activeBranchKey].doctorResidencyObject.to = date.format('YYYY-MM-DD')
        doctorObject[activeBranchKey].ExpiryDate = date
        this.setState({
            ToDate: date
        });
        this.props.updateParentState(doctorObject);

    }
    renderLegal = () => {
        const { doctorObject, activeBranchKey } = this.props;
        const _this = this;
        return (

            <div className="right-side-box marginBottom30">
                <div className="box-title">
                    <strong>Medical license</strong>
                </div>
                <div className="box">
                    <label>license number <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                    
                    <Input error={!doctorObject[activeBranchKey].licenseNumber && doctorObject[activeBranchKey].showError}  style={{ width: '100%' }} type="text" name="name" placeholder="Enter license number" value={doctorObject[activeBranchKey].licenseNumber} onChange={this.changeEmail.bind(this, "licenseNumber")}/>
                    {/* {(doctorObject[activeBranchKey].licenseNumber) ?
                        <input style={{ width: '98%' }} type="text" name="name" placeholder="Enter license number" value={doctorObject[activeBranchKey].licenseNumber} onChange={this.changeEmail.bind(this, "licenseNumber")} />
                        : (
                            <input style={{ borderColor: 'darkred', width: '100%' }} type="text" name="name" placeholder="Enter license number" value={doctorObject[activeBranchKey].licenseNumber} onChange={this.changeEmail.bind(this, "licenseNumber")} />
                        )
                    } */}
                </div>
                <div className="box">
                    <label>Expiry date <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                    <DatePicker
                        format={'YYYY-MM-DD'}
                        className="custom-select"
                        date={doctorObject[activeBranchKey].ExpiryDate ? doctorObject[activeBranchKey].ExpiryDate : null}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        peekNextMonth
                        fixedHeight
                        onChange={this.handleToDateChange.bind(this)}
                        endDate={this.state.ToDate}
                        placeholderText="Select date"
                    />

                </div>
                <div className="box">
                    <label>Upload File <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                    <div className="attachVerifyParent">

                        {
                            doctorObject[activeBranchKey].licenseDoc && doctorObject[activeBranchKey].licenseDoc.front ?
                                (
                                    <div className="added">
                                        <label className="addedlabel"><i style={{ position: 'relative', right: '5px' }} className="fas fa-file"></i>Front Page<i style={{ position: 'absolute', right: '10px' }} onClick={this.changeAttachVerify.bind(this, true, "front")} className="fa fa-close" /></label>
                                    </div>
                                ) :
                                <div className="attachVerify">
                                    <label className="attachVerifylabel" type="file" name="attachVerify" id="attachVerify">Upload Front Page
                                    <input onChange={this.changeAttachVerify.bind(this, false, "front")} type="file" name="attachVerify" id="attachVerify" />
                                        <i style={{ position: 'relative', left: '5px' }} className="fas fa-file"></i>
                                    </label>
                                </div>
                        }
                        {
                            doctorObject[activeBranchKey].licenseDoc && doctorObject[activeBranchKey].licenseDoc.back ?
                                (
                                    <div className="added">
                                        <label className="addedlabel"><i style={{ position: 'relative', right: '5px' }} className="fas fa-file"></i>Back Page<i style={{ position: 'absolute', right: '10px' }} onClick={this.changeAttachVerify.bind(this, true, "back")} className="fa fa-close" /></label>
                                    </div>
                                ) :
                                <div className="attachVerify">
                                    <label className="attachVerifylabel" type="file" name="attachVerify" id="attachVerify">Upload Back Page
                            <input onChange={this.changeAttachVerify.bind(this, false, "back")} type="file" name="attachVerify" id="attachVerify" />
                                        <i style={{ position: 'relative', left: '5px' }} className="fas fa-file"></i>
                                    </label>
                                </div>

                        }
                    </div>
                </div>

            </div>
        )
    }
}

export default RightBasicInfo