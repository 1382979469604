import React, { Component } from 'react';
import './AcceptPatient.css'
import PropTypes from 'prop-types'
import fire from 'firebase/app'
import 'firebase/database';
import swal from 'sweetalert2';
import '../../sweetalert.css'
import { Icon } from 'semantic-ui-react';


class AcceptPatient extends Component {
    static propTypes = {
        bookingItem: PropTypes.object.isRequired,
    }
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    newRequestClicked = (bookingitem) => {

        fire.firestore().collection('bookings').doc(bookingitem.bookingId).update({ "status": "pending", "pending": Date.now(), modifiedBy: fire.auth().currentUser.uid })

        let doctorsKeys = this.props.clinicsList[this.props.activeBranchKey].doctors
        this.props.patientData({ doctorsKeys: doctorsKeys, acceptedPatientData: bookingitem })
    }
    acceptedRequestClicked = (item, type) => {
        let doctorsKeys = this.props.clinicsList[this.props.activeBranchKey].doctors;
        let updateValue = {
            doctorsKeys,
            acceptedPatientData: item
        };
        if (type === "succeeded") {
            updateValue.hideActions = true
        }
        this.props.patientData(updateValue)
    }

    dischargePatientClicked = (bookingitem) => {
        swal.fire({
            heightAuto: false,
            title: 'Patient status',
            showCancelButton: true,
            html: "<div class='appointmentDate'> <div class='text'>Are you sure you want to discharge patient: </div> <div class='date'>" + bookingitem.patientName + "</div></div>",
            customClass: 'reschedulesweetalert',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.value) {
                let timeStamp = Date.now()
                fire.firestore().collection(bookingitem.online ? 'bookings' : 'offlineBookings').doc(bookingitem.bookingId).update({ isArchived: true, status: "discharged", discharged: timeStamp, modified: timeStamp, modifiedBy: fire.auth().currentUser.uid })
                this.props.manageClicked(bookingitem)
            }
        });

    }
    render() {
        let { bookingItem } = this.props;
        let _this = this;

        return (
            <div>
                {
                    (bookingItem.status === 'new' || bookingItem.status === 'pending') ? (
                        <div className="patientinfo">
                            <div className="tarekjamal">{bookingItem.patientName}</div>
                            <div className="noteandresaonoft">{bookingItem.notes}</div>
                            <div className="drsamehsuliman">{bookingItem.doctorName}</div>
                            <div className="appointment">Appointment: <div className="appointmentdate">{bookingItem.selectedDate}</div></div>

                            <div className="shownoshow" style={{ background: '#53CB8B', borderTop: 'none' }} onClick={() => {
                                if (bookingItem.status === 'new') {
                                    _this.newRequestClicked(bookingItem)
                                } else if (bookingItem.status === 'pending') {
                                    _this.acceptedRequestClicked(bookingItem, "pending")
                                }
                            }

                            } >
                                <div className="confirmbutton">Show appointment information</div>
                            </div>
                            <div className="status">
                                {
                                    (bookingItem.service === 'onlineConsult') ? (
                                        <Icon className={"onlineConsultation"} style={{ color: '#fff', backgroundColor: 'red' }} name='video' />
                                    ) : ''
                                }
                                {
                                    (bookingItem.online) ? (
                                        <div className={bookingItem.thrive ? "thrive" : "online"}>{bookingItem.thrive ? "Thrive" : "online"}</div>
                                    ) : ''
                                }
                                {
                                    (bookingItem.new) ? (
                                        <div className="group1">new</div>
                                    ) : ''
                                }
                            </div>
                        </div>
                    ) : (bookingItem.status === 'succeeded') ? (
                        <div className="patientinfo">
                            <div style={{ cursor: 'pointer' }} onClick={() => this.acceptedRequestClicked(bookingItem, "succeeded")}>
                                <div className="succeededdate">{bookingItem.selectedDate.split(" at")[1]}</div>
                                <div className="tarekjamal" >{bookingItem.patientName}</div>
                                <div className="noteandresaonoft">{bookingItem.notes}</div>
                                <div className="drsamehsuliman">{bookingItem.doctorName}</div>
                            </div>
                            <div className="shownoshow" style={{ background: '#3598DB', borderTop: 'none' }} onClick={() => _this.dischargePatientClicked(bookingItem)} >
                                <div className="confirmbutton">Discharge</div>
                            </div>

                            <div className="status">
                                {
                                    (bookingItem.service === 'onlineConsult') ? (
                                        <Icon className={"onlineConsultation"} style={{ color: '#fff', backgroundColor: 'red' }} name='video' />
                                    ) : ''
                                }
                                {
                                    (bookingItem.online) ? (

                                        <div className={bookingItem.thrive ? "thrive" : "online"}>{bookingItem.thrive ? "Thrive" : "online"}</div>
                                    ) : ''
                                }
                                {
                                    (bookingItem.new) ? (
                                        <div className="group1">new</div>
                                    ) : ''
                                }
                            </div>
                        </div>
                    ) : ''
                }


            </div>
        )
    }


}

export default AcceptPatient