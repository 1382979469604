import React, { Component } from 'react';
import './filter.css'
import Select from 'react-select'
import DatePicker from "react-multi-date-picker"
import PropTypes from 'prop-types'
import moment from 'moment'


const startDate = new Date();
startDate.setDate(1);
startDate.setMonth(startDate.getMonth() - 1);

class Filter extends Component {

    static propTypes = {
        clinicDoctors: PropTypes.array.isRequired,

    }

    constructor(props) {
        super(props);
        this.state = {
            doctors: [],
            dateRange: [startDate, new Date()],
        }
    }
    componentDidMount() {
        let { doctors } = this.state
        if (this.props.clinicDoctors) {
            this.props.clinicDoctors.map(item => {
                doctors.push({ label: item.name, value: item.name, key: item.id })
            })
            this.setState(doctors)
        }
    }


    render() {
        return (
            <div style={{ height: '20%', padding: '40px' }}>
                {this.renderSendRequestSystem()}
            </div>
        )
    }

    handleChange(value, type) {
        switch (type.name) {
            case 'doctor':
                let doctorsArray = []
                if (value !== null && value.length) {
                    value.map(item => {
                        doctorsArray.push(item)
                    })
                } else {
                    doctorsArray = []
                }
                this.props.doctorsArray(doctorsArray)


                break;
            default:
                break;
        }
    }
    renderSendRequestSystem() {
        let { dateRange } = this.state

        return (

            <div className="filtercss" style={{ boxShadow: '0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04)' }}>
                <div className="selectParent">
                    <Select
                        placeholder="Doctors"
                        isMulti
                        name="doctor"
                        options={this.state.doctors}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={this.handleChange.bind(this)}
                    />
                </div>
                <div className="selectParent">
                    <DatePicker
                        value={dateRange}
                        range
                        onChange={(dates) => {
                            this.setState({ dateRange: dates }, () => {
                                this.props.dateRange(dates);
                            })
                        }}
                        maxDate={(moment(new Date(dateRange[0])).add(30, "days")).toDate()}
                    />

                </div>
            </div>
        )
    }


}

export default Filter