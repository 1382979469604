export const fetchSearchParams = () => {
    return new Promise( ( resolve, reject ) => {
        let params = {
            area: null,
            searchString: '',
            TypeValues: {
                specialties: [''],
                conditions: [''],
                services: [''],
                insurances: [''],
            },
        };
        resolve(params);
    })
};
export const SetSearchParams = (type) => {
    return new Promise( ( resolve, reject ) => {
        resolve(type);
    })
};
