import React, { Component } from 'react';
import doctorManLogo from '../../img/dr_man.png';
import { Icon, Button, Radio, Dimmer } from 'semantic-ui-react'
import ReactTable from "react-table";
import { firestore } from 'firebase'
import "react-table/react-table.css";
import { connect } from 'react-redux';

// import "./reacttable.css";
class SelectDoctor extends Component {
    state = {
        currentStep: 1,
        selectedDoctor: {},
        user: null,
        loggedIn: false,
        claimingPersonName: '',
        suggestionId: null,
        specialties: {},
        allBookings: []

    }
    constructor(props, context) {
        super(props, context);
    }

    /**
     *
     * @param type
     * @param reset
     * @returns {function(this:SelectDoctor)}
     */
    changeLocalFacilityType = (type, doctorId, reset = false) => {
        return function () {
            const { selectedDoctor } = this.state;
            const doctorObject = this.props.selectDoctor.clinics;

            Object.keys(doctorObject).forEach((item, index) => {
                if (item === doctorId) {
                    selectedDoctor[item] = doctorObject[item];
                    this.setState({
                        selectedDoctor: selectedDoctor
                    })
                    this.props.selectDoctor.SelectedDoctor(selectedDoctor)
                }
            })

            this.props.selectDoctor.branchChange(1);
            this.props.selectDoctor.readyToStartChange(true)

        }.bind(this)
    }

    /**
     * Change step
     * @param index
     */
    changeCurrentStep = (index) => {
        this.props.selectDoctor.doctorsStatus(index)
        this.setState({
            currentStep: index,
        })
    }
    getTotalBookings(doctorId) {
        let { Analytics } = this.props
        return Analytics?.doctors[doctorId]?.totalBookings || 0

    }
    getTotalRevenue(doctorId) {
        let { Analytics } = this.props
        return Analytics?.doctors[doctorId]?.totalRevenu || 0
    }
    componentWillMount = () => {
        this.getSpecialityNameById();
        this.updateAndNotify();

    }
    updateAndNotify = async () => {
        let { allBookings } = this.props
        const doctorObject = this.props.selectDoctor.clinics;
        let allBookingsFiltered = allBookings.filter(rec => Object.keys(doctorObject)?.includes(rec.doctor))
        this.setState({ allBookings: allBookingsFiltered })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.allBookings !== this.props.allBookings) {
            this.updateAndNotify();
        }
    }
    render() {
        return (
            <section id="facility-grid-page">
                {/* <DoctorStatusSteps doctorObject={this.props.selectDoctor.clinics} changeCurrentStep={this.changeCurrentStep} currentStep={currentStep} /> */}
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" style={{ height: '100%' }}>
                        {
                            this.props.loading ?
                                <div style={{ height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Icon.Group size='big' inverted>
                                        <Icon loading size='big' name='spinner' color='grey' />
                                    </Icon.Group>
                                </div>
                                : this.renderFacilitiesList()
                        }

                    </div>
                </div>
            </section>
        )
    }

    /*
    * Functions for content getting
    * */

    getSpecialityNameById = () => {
        const allSpecialties = this.props.selectDoctor.specialties;
        let specialties = {}
        allSpecialties && Object.keys(allSpecialties).map(item => {
            let data = allSpecialties[item]
            specialties[item] = data.name
        })
        this.setState({
            specialties: specialties
        })

    };
    /**
     * get Facilities list from redux
     * @param list
     * @returns {XML}
     */
    dataTableColumns() {
        let { specialties } = this.state;
        return ([
            {
                width: 100,
                accessor: "deactivated",
                Cell: props => <Radio
                    style={{ verticalAlign: 'middle !important' }}
                    toggle
                    checked={props.value && (Object.keys(props.value) !== Object.keys(props.original.clinics)) ? Object.values(props.value).includes(false) : true}
                    onChange={() => {
                        this.setState({ showDeactivate: true, selectedDoctor: props.original, doctorClinics: Object.keys(props.original.clinics) })
                    }} />
            },

            {
                width: 100,
                accessor: "profilePicture",
                Cell: props => <div className="providerfieldText"><img style={{ minHeight: '60px', maxWidth: '60px', width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={props.value ? props.value : doctorManLogo} alt={props.value} /></div>
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: props => <p className="providerfieldText">{props.value}</p>
            },

            {
                Header: "Speciality",
                accessor: "views",

                Cell: props =>
                    <p className="providerfieldText">
                        {specialties[props.original.clinics && Object.entries(props.original.clinics)?.length && Object.entries(props.original.clinics)[0][1][0]]}
                    </p>
            },
            {
                Header: "Total Services",
                accessor: "services",

                Cell: props => <p className="providerfieldText">{Object.keys(props.value).length}</p>
            },
            {
                Header: "Total Bookings",
                accessor: "id",
                Cell: props => <p className="providerfieldText">{this.getTotalBookings(props.value)}</p>

            },
            {
                Header: "Total Revenue",
                accessor: "id",
                Cell: props => <p className="providerfieldText">{this.getTotalRevenue(props.value)} {process.env.REACT_APP_CURRNECY}</p>

            },
            {
                Header: `${process.env.REACT_APP_TITLE} Revenue`,
                accessor: "id",
                Cell: props => <p className="providerfieldText">{(this.getTotalRevenue(props.value) * 0.1).toFixed(2)} {process.env.REACT_APP_CURRNECY}</p>
            },

            {
                width: 90,
                accessor: "edit",
                Cell: props => <p className="providerfieldText">
                    <Button color={'twitter'} onClick={this.changeLocalFacilityType(props.original.facilityType, props.original.id)}>Edit</Button>
                </p>
            }
        ])


    }
    renderFacilitiesList = () => {
        let { showDeactivate } = this.state;
        const doctorObject = this.props.selectDoctor.clinics;
        const search = this.props.selectDoctor.search;

        let filteredDoctors = Object.values(doctorObject).filter(item => {
            return (item.name).toLowerCase().includes(search.toLowerCase())
        })

        let views = 0
        Object.values(filteredDoctors).forEach(item => {
            return views += item.views ? item.views : 0
        })
        let totalServices = []
        Object.values(filteredDoctors).forEach(item => {
            if (item.services && Object.keys(item.services).length) {
                (Object.keys(item.services)).map(service => {
                    if (!totalServices.includes(service)) {
                        return totalServices.push((service))
                    }
                })
            }
        })


        if (window.screen.width <= 768) return (
            <div className="emptystate">
                <div className="emptystate1">
                    {/* <img src={Doctor} alt="Img" /> */}
                    <div className="textMesg">Open on desktop</div>
                    <div className="detailMesg">Add doctors to see information in this page</div>
                </div>
            </div>
        );

        if (!filteredDoctors || !Object.keys(filteredDoctors).length) return (
            <div className="emptystate">
                <div className="emptystate1">
                    {/* <img src={Doctor} alt="Img" /> */}
                    <div className="textMesg">No Doctors</div>
                    <div className="detailMesg">Add doctors to see information in this page</div>
                    <a onClick={() => this.props.selectDoctor.addDoctor(true)} className="addfacbutton">Add Doctor</a>
                </div>
            </div>
        );

        if (filteredDoctors) {
            return (
                <div>
                    {
                        showDeactivate ? <Dimmer style={{ position: 'fixed' }} active>
                            {this.renderPopup()}
                        </Dimmer> : ''
                    }
                    <div className="facilityInfo">
                        <div className="cardHeader">
                            <div className="cardCount">{Object.values(filteredDoctors).length}</div>
                            <div className="cardTitle">Profiles Created</div>
                            <div className="cardImage"><i className="fas fa-user-md"></i></div>
                        </div>
                        <div className="cardHeader">
                            <div className="cardCount">{views}</div>
                            <div className="cardTitle">Profiles Views</div>
                            <div className="cardImage"><i className="far fa-eye"></i></div>

                        </div>
                        <div className="cardHeader">
                            <div className="cardCount">{totalServices.length}</div>
                            <div className="cardTitle">Services Provided</div>
                            <div className="cardImage"><i className="fas fa-toolbox"></i></div>
                        </div>
                    </div>
                    <div className="single-medical" >
                        {<ReactTable
                            data={Object.values(filteredDoctors)}
                            columns={this.dataTableColumns()}
                            defaultPageSize={10}
                            pageSize={Object.values(filteredDoctors).length < 10 ? Object.values(filteredDoctors).length : 10}
                            showPageJump={false}
                            showPageSizeOptions={false}
                            // PaginationComponent={DataTablePagination}
                            className='custuomReactTable'
                        />}

                    </div>
                </div>

            )
        }
    }
    renderPopup() {
        let { branches } = this.props
        let { doctorClinics, selectedDoctor } = this.state
        let _this = this
        return (
            <div className='helpersPopup'>
                <div className='helperTitle' style={{ padding: '0', margin: '24px 28px', marginBottom: '0', textAlign: 'start' }}>Doctor Availability</div>
                <div className='contents'>
                    {
                        doctorClinics ?
                            doctorClinics.map((item, index) => {
                                return (
                                    <div key={index} className="select-input" style={{ alignItems: 'center', display: "flex", padding: "12px", position: "relative" }}>
                                        <Radio
                                            style={{ verticalAlign: 'middle !important', marginRight: '14px' }}
                                            toggle
                                            checked={selectedDoctor.deactivated && selectedDoctor.deactivated[item] ? false : true}
                                            onChange={() => {
                                                if (selectedDoctor.deactivated) {
                                                    selectedDoctor.deactivated[item] = !selectedDoctor.deactivated[item]
                                                } else {
                                                    selectedDoctor.deactivated = {}
                                                    selectedDoctor.deactivated[item] = !selectedDoctor.deactivated[item]

                                                }
                                                firestore().collection('doctors').doc(selectedDoctor.id).update({
                                                    [`deactivated.${[item]}`]:
                                                        selectedDoctor.deactivated[item], updated: Date.now()
                                                }).then(function () {
                                                    _this.setState(selectedDoctor)
                                                });
                                            }} />
                                        <div style={{ fontSize: '14renderConditionspx', width: "50%" }}>{branches[item].facilityName}</div>

                                    </div>)
                            }) : ''
                    }
                </div>
                <div className='actionBtn'>
                    <Button color='blue' onClick={() => this.setState({ showDeactivate: false, selectedDoctor: {} })}>Dismiss</Button>
                </div>
            </div>
        )
    }

}

const mapStateToProps = ({ user, branches, Bookings }) => {
    return {
        user,
        branches: branches && branches.data ? branches.data : {},
        allBookings: Bookings?.allBookings || []
    };
};
export default connect(
    mapStateToProps,

)(SelectDoctor);
