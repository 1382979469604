import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SetupBooking from './steps/SetupBooking'
import ManageBooking from './steps/ManageBooking'
import ManagePatients from './RightSteps/ManagePatients'
import StepsBar from './StepsBar'
import StepButtons from './StepButtons'
import _ from 'lodash'
import fire from 'firebase/app'
import 'firebase/database';
import 'firebase/auth';
import { connect } from 'react-redux';
import { getBookings, updateBookings } from '../../modules/bookingsModule';
import { Dimmer, Icon } from 'semantic-ui-react';

const calenderEndDate = (item) => {
    let stringDate = item.date.replace(" at", ",");
    let time = new Date(stringDate);
    time.setHours(time.getHours() - time.getTimezoneOffset() / 60)
    item.duration ? time.setMinutes(time.getMinutes() + item.duration) : time.setMinutes(time.getMinutes() + 30)
    return time.toISOString();
}
const diff_minutes = (dt2, dt1) => {
    var seconds = (dt2.getTime() - dt1.getTime()) / 1000;
    let mins = seconds / 60;
    let hours = mins / 60;
    let days = hours / 24;
    if (mins > 0 && mins <= 60) {
        return String(Math.round(mins)) + " Minutes ago";
    }
    if (hours <= 24) {
        return String(Math.round(hours)) + " Hours ago";
    } else {
        return String(Math.round(days)) + " Days ago";

    }
}
const updateRecord = (value, clinicDoctors, online) => {
    let stringDate = value.date.replace(" at", ",");
    let date = new Date(stringDate);
    date.setHours(date.getHours() - date.getTimezoneOffset() / 60)
    let doctorItem = clinicDoctors.filter(doctorItem => doctorItem.id === value.doctor)
    let post = {
        accepted: value.accepted,
        canceled: value.canceled,
        created: value.created,
        facilityAdmin: value.facilityAdmin,
        facilityName: value.facilityName,
        paymentType: value.paymentType,
        amount: value.amount,
        authRequest: value.authRequest,
        authId: value.authId,
        insurance: value.insurance,
        bookingId: value.bookingId,
        patientName: value.patientName,
        start: date.toISOString(),
        end: calenderEndDate(value),
        dateStart: date.toISOString(),
        dateEnd: calenderEndDate(value),
        doctorName: doctorItem ? doctorItem.name : '',
        status: value.status,
        online: online,
        new: false,
        facilityId: value.facilityId,
        doctorId: value.doctor,
        patientId: value.patient,
        patientImg: value.userData.profilePicture,
        patientEmail: value.userData.email,
        patientAge: value.userData.birthday,
        patientGender: value.userData.gender,
        patientInsurance: value.insurance,
        patientNumber: (online ? value.userData.phone : value.userData.number),
        doctorColor: doctorItem ? doctorItem.color : '',
        className: 'appointmentlabel',
        date: date,
        time: diff_minutes(new Date(), new Date(value.created)),
        selectedDate: value.date,
        modified: value.modified,
        duration: value.duration,
        doctorDuration: doctorItem ? doctorItem.duration : '',
        notes: value.notes ? value.notes : 'No added notes',
        service: value.service,
        refrenceNumber: value.userData.RN
    }
    // oldList.push(post)
    return post;

}
class MainWizard extends Component {

    static propTypes = {
        wizardOptions: PropTypes.shape({
            branches: PropTypes.isRequired,
        }).isRequired,
        selectFacility: PropTypes.shape({
            readyToStartChange: PropTypes.func.isRequired,
        }).isRequired,
        currentUser: PropTypes.object.isRequired,
        clinicsStatus: PropTypes.number.isRequired,
        changeClinicStatus: PropTypes.func,

    };
    state = {
        clinicsList: {},
        activeBranchKey: 'branch1',
        currentStep: 1,
        logoUrl: null,
        doctorData: this.props.doctorData,
        clinicDoctors: this.props.clinicDoctors ? this.props.clinicDoctors : [],
        allBooking: [],
        onlineBoolean: false,
        offlineBoolean: false,
        onlinearray: [],
        offlinearray: [],
        clinicDoctorsarray: [],
        loading: true,
        hideExtraData: true,
        userData: {},
        patientOptions: []

    };
    constructor(props, context) {
        super(props, context);
        this.handleStateChangeMain = this.handleStateChangeMain.bind(this)
    }

    componentWillMount = () => {
        let clinicsList = this.getClinicList()

        this.getBookingsArray(clinicsList)

        window.addEventListener('offline', this.handleStateChangeMain);
        window.addEventListener('online', this.handleStateChangeMain);

    }
    componentDidUpdate(prevProps, prevState) {
        let { clinicsList } = this.state
        if (prevProps?.Bookings?.allBookings !== this.props?.Bookings?.allBookings) {
            this.getBookingsArray(clinicsList)
        }
    }
    getClinicList() {
        let _this = this;
        const { currentUser } = this.props;
        let listOfBranches = {};
        let { draftList } = _this.props;

        for (let i = 1; i <= this.props.wizardOptions.branches; i++) {
            listOfBranches['branch' + i] = {
                facilityName: '',
                facilityName_ar: '',
                bookingEmail: currentUser.info.bookingEmail ? currentUser.info.bookingEmail : '',
                bookingNumber: currentUser.info.bookingNumber ? currentUser.info.bookingNumber : '',
                created: '',
                deactivatedDoctors: [],
                doctors: [],
                selectedDoctors: [],
                facilityId: Object.keys(draftList)[0],
                status: 'new',
                userId: currentUser.id,
                //Global
                logoUrl: false,
                haveError: true,
                users: {
                    superAdmin: currentUser.id
                },
            }
        }
        let clinicsList = listOfBranches;
        if (draftList) {
            Object.keys(draftList).map(function (item, index) {
                return Object.keys(draftList[item]).map(function (subItem) {
                    clinicsList[Object.keys(clinicsList)[index]][subItem] = draftList[item][subItem];
                    if (subItem === 'logoUrl') {
                        _this.setState({
                            logoUrl: draftList[item].logoUrl
                        })
                    }
                })
            })
        }
        _this.setState({ clinicsList: clinicsList })
        return clinicsList
    }
    componentWillUnmount() {
        // this.onlineQuery()
        // this.offlineQuery()
        window.removeEventListener('offline', this.handleStateChangeMain)
        window.removeEventListener('online', this.handleStateChangeMain)

    }
    handleStateChangeMain() {
        this.setState({ offline: !window.navigator.onLine })
    }

    removeDuplicates(arr, created) {
        arr.map((offlineItem, index) => {
            if (offlineItem.created === created) {
                return arr.splice(index, 1);
            }
        })
    }

    getBookingsFunc = () => {
        let _this = this
        let { offlinearray, onlinearray, clinicDoctorsarray } = this.state;
        _this.props.getBookings(offlinearray, onlinearray, clinicDoctorsarray, _this.props.Bookings.bookings.bookings).then((data) => {
            _this.setState({ allBooking: data.payload.bookings, loading: false })
        })

    }

    getBookingsArray = async (clinicsList) => {
        let { activeBranchKey, hideExtraData } = this.state;
        let { allBookings } = this.props.Bookings
        let clinicDoctors = []
        let _this = this

        let facilityId = clinicsList[activeBranchKey] ? clinicsList[activeBranchKey].facilityId : null

        if (facilityId) {
            clinicDoctors = this.props.branches.data[facilityId].doctorsData
        } else {
            return
        }

        if (clinicsList && activeBranchKey) {
            let onlinearray = allBookings?.filter(r => ["new", 'pending', 'accepted', 'succeeded'].includes(r.status) && r.online && (r.facilityId === facilityId))
            onlinearray = onlinearray?.map(value => {
                value.bookingId = value.id;
                return value
            })
            let offlinearray = allBookings?.filter(r => ["new", 'pending', 'accepted', 'succeeded'].includes(r.status) && !r.online && (r.facilityId === facilityId))
            offlinearray = offlinearray?.map(value => {
                value.bookingId = value.id;
                return value
            })
            _this.setState({ onlinearray, offlinearray, clinicDoctorsarray: clinicDoctors }, () => {
                _this.getBookingsFunc()
            })
        }
    }

    changeDoctorKey = (key) => {
        let { clinicsList } = this.state;
        this.setState({
            doctorKey: key,
            clinicsList: clinicsList
        })
    }

    /**
     *
     * @param list
     * @param callback
     */
    updateCurrentState = (list, callback) => {

        // if (this.props.doctorSetupUpdatedData) {
        //     return (
        //         this.setState({
        //             clinicsList: this.props.doctorSetupUpdatedData
        //         })
        //     )
        // }
        this.setState({
            clinicsList: list
        })
        if (callback)
            callback();
    }
    render() {
        let { currentStep, clinicsList, logoUrl, offline } = this.state;
        const { clinicsStatus } = this.props;
        let _this = this
        return (
            <div style={{ height: 'calc( 100% - 60px)' }}>
                {
                    offline && <Dimmer active />
                }

                {
                    (clinicsStatus === 1) ? (
                        <section data-branches-length={Object.keys(clinicsList).length} id="facility-page" style={{ padding: '0', height: '100%' }}>
                            <div className="left-side" style={{ height: '100%' }}>
                                {this.renderSidebar()}
                            </div>
                            <div className="tab-content sub-pages" id="myTabContent" style={{ overflow: 'hidden' }}>

                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" style={{ height: '100%' }}>
                                    <div className="main-side" style={{ width: '75%', height: '100%', padding: '0', margin: '0' }}>
                                        {this.renderStep()}
                                    </div >
                                    <div className="right-side right-side-box" style={{ width: '25%', height: '100%', padding: '0' }}>
                                        {this.renderRightSteps()}
                                    </div >
                                </div >
                            </div >
                        </section>
                    ) : (clinicsStatus === 2) ? (
                        <section data-branches-length={Object.keys(clinicsList).length} id="facility-page" style={{ padding: '0', height: '100%', overflowY: 'auto' }}>
                            <div className="left-side" style={{ height: '100%' }}>
                                {this.renderSidebar()}
                            </div>
                            <div className="tab-content sub-pages" id="myTabContent">

                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" style={{ height: '85%', overflow: 'auto', background: 'white' }}>
                                    <div className="main-side" style={{ width: '100%', height: '100%', padding: '0', margin: '0' }}>
                                        {this.renderStep()}
                                    </div >
                                </div >
                                <StepButtons
                                    updateParentState={this.updateCurrentState}
                                    logoUrl={logoUrl}
                                    readyToStartChange={this.props.selectFacility.readyToStartChange}
                                    clinicsList={clinicsList}
                                    currentStep={currentStep}
                                    clinicsStatus={clinicsStatus}
                                    dismiss={(clinicKey) => _this.props.changeClinicsStatus(1, clinicKey).then(() => {
                                        this.getClinicList()
                                    })} />
                            </div >
                        </section>
                    ) : ''
                }
            </div >
        )
    }

    /**
     *
     * @returns {XML}
     */
    renderSidebar = () => {
        let { clinicsList, logoUrl, activeBranchKey, clinicDoctors, loading } = this.state;
        const { clinicsStatus } = this.props;
        return (
            <div style={{ height: '100%', overflow: 'hidden' }}>
                {
                    loading && clinicsStatus === 1 ?
                        <Dimmer active>
                            <Icon.Group size='big'>
                                <Icon loading size='big' name='spinner' />
                                <div style={{ fontSize: '16px', padding: '10px', fontStyle: 'initial' }}>loading</div>
                            </Icon.Group>
                        </Dimmer>
                        : ''
                }

                <div className="logo" >
                    <div className="clinicPic">
                        <img data-isset={!!logoUrl} src={logoUrl} alt="IMG" />
                    </div>
                </div>

                <div style={{ padding: '15px', paddingTop: '0', display: 'grid' }} className="activities-date">
                    <label style={{ textAlign: "center", color: "#7A858A", fontSize: '14px', margin: '0' }}>{clinicsList[activeBranchKey].facilityName}</label>
                    <label style={{ textAlign: "center", color: "#7A858A", fontSize: '14px', margin: '0' }}>{clinicsList[activeBranchKey].facilityName_ar}</label>
                </div>
                <StepsBar changeDoctorKey={this.props.changeDoctorKey} clinicDoctors={this.props.clinicDoctors} clinicsStatus={clinicsStatus} clinicKey={clinicsList[activeBranchKey].facilityId} />
            </div>
        )
    }

    changeClinicStatus = (status) => {
        this.props.changeClinicsStatus(status)
    }
    /**
     *
     * @returns {XML}
     */
    renderStep = () => {
        let { clinicsList, activeBranchKey, allBooking } = this.state;
        const { currentUser, clinicsStatus, dismissDoctor, resetDismissDoctor, clinicDoctors, doctorKey } = this.props;
        switch (clinicsStatus) {
            case 2:
                return <SetupBooking
                    updateParentState={this.updateCurrentState}
                    activeBranchKey={activeBranchKey}
                    clinicsList={clinicsList}
                    currentUser={currentUser}
                    doctorData={(doctorData) => { this.props.doctorData(doctorData); clinicsList[activeBranchKey].selectedDoctors = doctorData; this.updateCurrentState(clinicsList) }}
                    dismissDoctor={dismissDoctor}
                    resetDismissDoctor={() => resetDismissDoctor()}
                    updateBranches={this.props.updateBranches}
                    clinics={this.props.branches.data}
                />;
            case 1:
                return <ManageBooking
                    updateParentState={this.updateCurrentState}
                    changeClinicStatus={this.changeClinicStatus}
                    activeBranchKey={activeBranchKey}
                    clinicsList={clinicsList}
                    currentUser={currentUser}
                    doctorKey={doctorKey}
                    patientData={(patientData) => { this.props.patientData(patientData) }}
                    clinicDoctors={clinicDoctors}
                    allBooking={allBooking}
                    hideExtraData={(flag) => { this.setState({ hideExtraData: flag }); this.getBookingsArray(clinicsList) }}
                />;
        }
    }
    renderRightSteps = () => {
        let { clinicsList, activeBranchKey, allBooking } = this.state;
        const { currentUser, clinicsStatus, clinicDoctors, doctorKey } = this.props;
        switch (clinicsStatus) {
            case 1:
                return <ManagePatients
                    updateParentState={this.updateCurrentState}
                    activeBranchKey={activeBranchKey}
                    clinicsList={clinicsList}
                    currentUser={currentUser}
                    doctorKey={doctorKey}
                    clinicDoctors={clinicDoctors}
                    patientData={(patientData) => { this.props.patientData(patientData) }}
                    allBooking={allBooking}
                    updateBookings={(bookings) => this.props.updateBookings(bookings)}
                    oldList={this.props.Bookings.bookings.bookings}
                    hideExtraData={this.state.hideExtraData}
                />;
        }

    }

}

function mapStateToProps(state) {
    return {
        clinics: state.clinics.data,
        user: state.user.data.user,
        branches: state.branches,
        clinicsKeys: state.clinicsKeys,
        Bookings: state.Bookings,
    }
}

const mapDispatchToProps = dispatch => ({
    getBookings: (offlineBookings, onlineBookings, clinicDoctors, oldList) => dispatch(getBookings(offlineBookings, onlineBookings, clinicDoctors, oldList)),
    updateBookings: (updatedList) => dispatch(updateBookings(updatedList)),

});

export default connect(mapStateToProps, mapDispatchToProps)(MainWizard)