import React, { Component } from 'react';
import PropTypes from 'prop-types'
import 'react-image-crop/lib/ReactCrop.scss';
import DatePicker from 'react-modern-datepicker';
import { searchInArrayWithObjects } from '../../../helpers/Full';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Dropdown } from 'semantic-ui-react'
import RightExperience from '../RightSteps/RightExperience'
import Swal from 'sweetalert2';

class Experience extends Component {
    static propTypes = {
        doctorObject: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        updateParentState: PropTypes.func.isRequired,
    }

    constructor(props, context) {
        super(props, context);
    }
    state = {

        defaultSpecialties: [],
        specialties: {},

        selectedFacility: [],
        canAdd: false,
        canAddSpec: false,
        currentText: '',
        currentTextSpec: '',

        doctorExperience: {
            facilityName: '',
            from: '',
            to: '',
            city: '',
            country: '',
            speciality: '',
            title: ''
        },

        FromDate: null,
        ToDate: null,
    }
    selectCountry(val) {
        let { doctorExperience } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        // doctorObject[activeBranchKey].doctorExperienceObject.country = val
        doctorExperience.country = val

        this.props.updateParentState(doctorObject);
    }

    selectRegion(val) {
        let { doctorExperience } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        // doctorObject[activeBranchKey].doctorExperienceObject.city = val
        doctorExperience.city = val

        this.props.updateParentState(doctorObject);

    }
    handleFromDateChange(date) {
        let { doctorExperience } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        doctorExperience.from = date
        this.setState({
            FromDate: date
        });
        this.props.updateParentState(doctorObject);

    }
    handleToDateChange(date) {
        let { doctorExperience } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        doctorExperience.to = date
        this.setState({
            ToDate: date
        });
        this.props.updateParentState(doctorObject);

    }
    render() {
        let { doctorObject, activeBranchKey } = this.props;
        let { doctorExperience } = this.state;
        let doctorObjects = doctorObject[activeBranchKey].doctorExperience;

        return (
            <div>
                <div style={{ display: 'flow-root' }}>
                    {this.renderTitle()}
                    <div className="box" >
                        <label>Speciality <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                        {this.renderFindSpecialties()}
                    </div>
                    <div className="box">
                        <label>Facility <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                        {this.renderSelectedClinics()}
                    </div>
                    <div className="box address-box" style={{ marginTop: '0' }}>
                        <div className="input-half marg-right">
                            <label>Country <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                            <CountryDropdown
                                classes="custom-select"
                                value={doctorExperience.country}
                                onChange={(val) => this.selectCountry(val)} />
                        </div>
                        <div className="input-half marg-right">
                            <label>City <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                            <RegionDropdown
                                classes="custom-select"
                                blankOptionLabel="Select City"
                                defaultOptionLabel="Select City"
                                country={doctorExperience.country}
                                value={doctorExperience.city}
                                onChange={(val) => this.selectRegion(val)} />
                        </div>
                        <div className="input-half marg-right">
                            <label>From <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                            <DatePicker
                                format={'YYYY-MM-DD'}
                                className="custom-select"
                                date={this.state.FromDate}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                peekNextMonth
                                selectsStart
                                onChange={this.handleFromDateChange.bind(this)}
                                startDate={this.state.FromDate}
                                endDate={this.state.ToDate}
                                placeholderText="Select From date"
                            />
                        </div>
                        <div className="input-half marg-right">
                            <label>To <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                            <DatePicker
                                format={'YYYY-MM-DD'}
                                className="custom-select"
                                date={this.state.ToDate}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                peekNextMonth
                                selectsEnd
                                onChange={this.handleToDateChange.bind(this)}
                                startDate={this.state.FromDate}
                                endDate={this.state.ToDate}
                                placeholderText="Select To date"
                            />
                        </div>
                    </div>
                    <button className="save-btn-local" onClick={this.addDoctorObject}>Add</button>
                </div>
                {
                    (doctorObjects.length) ? (
                        <div className="right-side" style={{ width: '100%', marginTop: '24px' }}>
                            <RightExperience updateParentState={this.props.updateParentState}
                                activeBranchKey={activeBranchKey}
                                doctorObject={doctorObject} />
                        </div>
                    ) : ''
                }
            </div>
        )
    }

    addDoctorObject = () => {
        let { doctorObject, activeBranchKey } = this.props;
        let { doctorExperience, currentTextSpec } = this.state;
        let doctorObjects = doctorObject[activeBranchKey].doctorExperience;
        doctorObject[activeBranchKey].doctorExperienceObject = {};
        let doctorPublicObjects = doctorObject[activeBranchKey].doctorExperienceObject;

        if (doctorExperience.facilityName.length && doctorExperience.speciality.length && doctorExperience.title.length && doctorExperience.country.length && doctorExperience.city.length && doctorExperience.from.length && doctorExperience.to.length) {
            doctorPublicObjects.country = doctorExperience.country
            doctorPublicObjects.city = doctorExperience.city
            doctorPublicObjects.from = doctorExperience.from
            doctorPublicObjects.to = doctorExperience.to
            doctorPublicObjects.speciality = doctorExperience.speciality
            doctorPublicObjects.facilityName = doctorExperience.facilityName
            doctorPublicObjects.title = doctorExperience.title;
            doctorObjects.push(doctorPublicObjects)

            doctorExperience.facilityName = ''
            doctorExperience.speciality = ''
            doctorExperience.title = ''
            doctorExperience.country = ''
            doctorExperience.city = ''
            doctorExperience.from = ''
            doctorExperience.to = ''
            doctorObject[activeBranchKey].experienceFacility = [];
            doctorObject[activeBranchKey].selectedSpecialties = [];

            this.setState({
                FromDate: null,
                ToDate: null,
                currentText: ''
            });
        }else{
            Swal.fire({
                heightAuto:false,
                type:'error',
                title:'Alert',
                text:'Please fill all requirments'
            })
        }
        this.props.updateParentState(doctorObject);

    }

    componentWillMount = () => {
        const { doctorObject, activeBranchKey } = this.props;
        let { doctorExperience } = this.state;

        doctorExperience.facilityName = ''
        doctorExperience.speciality = ''
        doctorExperience.title = ''
        doctorExperience.country = ''
        doctorExperience.city = ''
        doctorExperience.from = ''
        doctorExperience.to = ''
        doctorObject[activeBranchKey].experienceFacility = [];
        doctorObject[activeBranchKey].selectedSpecialties = [];

        this.getSpecialtiesLists();
    }
    /**
    *
    */
    blurSelect = () => {
        let _this = this;
        setTimeout(function () {
            _this.setState({
                canAdd: false,
                currentText: ''
            })
        }, 300)
    }/**
    *
    */
    blurSelectSpec = () => {
        let _this = this;
        setTimeout(function () {
            _this.setState({
                canAddSpec: false,
                currentTextSpec: ''
            })
        }, 300)
    }

    getSpecialtiesLists = () => {
        const { doctorObject, activeBranchKey } = this.props;
        let selectedSpecialties = doctorObject[activeBranchKey].selectedSpecialties;
        let specialties = []
        let defaultSpecialties = []
        let dbSpecialities = this.props.specialties
        for (var index in dbSpecialities) {
            let val = dbSpecialities[index];
            val.id = index;
            if (selectedSpecialties.indexOf(index) === -1) {
                specialties.push({
                    value: index,
                    text: val.name,
                });
            }
            defaultSpecialties.push(val)
        }
        this.setState({ specialties, defaultSpecialties });
        this.props.updateParentState(doctorObject);

    }
    /**
   *
   * @param id
   */
    addSpecialty = (e, { value }) => {
        let { doctorExperience } = this.state;


        let { specialties } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        doctorObject[activeBranchKey].selectedSpecialties = [];
        let selectedSpecialties = doctorObject[activeBranchKey].selectedSpecialties;

        const _this = this;


        if (value === "") {
            _this.removeSelectedSpecialties(value)
        } else {
            doctorExperience.speciality = _this.getNameById(value, 'defaultSpecialties');
            selectedSpecialties.push(value);
        }


        this.props.updateParentState(doctorObject);
        this.setState({
            specialties: specialties,
            currentTextSpec: value,
            doctorExperience: doctorExperience
        })
    }
    renderFindSpecialties = () => {
        let { specialties, currentTextSpec } = this.state;
        let { activeBranchKey } = this.props;
        return (
            <Dropdown
                selection
                search
                fluid
                noResultsMessage="Can't find this speciality!"
                placeholder="Type speciality name"
                className="selectiondropdown"
                upward={false}
                text={currentTextSpec ? this.getNameById(currentTextSpec, 'defaultSpecialties') : ''}
                value={currentTextSpec ? this.getNameById(currentTextSpec, 'defaultSpecialties') : ''}
                options={specialties}
                onChange={this.addSpecialty}
                clearable
            />
        )
    }
    /**
     * @param id
     */
    removeSelectedSpecialties = (id) => {
        let { doctorExperience } = this.state;
        let { specialties } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let selectedSpecialties = doctorObject[activeBranchKey].selectedSpecialties;
        let index = selectedSpecialties.indexOf(id);
        let name = this.getNameById(id, 'defaultSpecialties');

        doctorExperience.speciality = '';

        if (index > -1) {
            selectedSpecialties.splice(index, 1);

            let val = { name: name, id: id };
            specialties.push(val);
        }
        this.setState({
            specialties: specialties
        })
        this.props.updateParentState(doctorObject);
    }
    /**
    *
    * @param id
    * @param where
    */
    getNameById = (id, where) => {
        let x = this.state[where].filter(function (v) {
            return v.id === id; // Filter out the appropriate one
        })[0];
        if (x) {
            return x.name
        }
    };

    addFacility = (e, { value }) => {
        this.setState({ currentText: value })
        let { doctorExperience } = this.state;
        let { selectedFacility } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        doctorObject[activeBranchKey].experienceFacility = [];
        let facility = doctorObject[activeBranchKey].experienceFacility;
        if (value.length > 2 && !searchInArrayWithObjects(value, 'name', selectedFacility) && facility.indexOf(value) === -1) {
            facility.push(value)
            // doctorObject[activeBranchKey].doctorExperienceObject.facilityName = currentText
            doctorExperience.facilityName = value

        }
        this.props.updateParentState(doctorObject);
    }
    removeFacility = (id) => {
        let { doctorExperience } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let facility = doctorObject[activeBranchKey].experienceFacility;
        let index = facility.indexOf(id);
        // doctorObject[activeBranchKey].doctorExperienceObject.facilityName = ''
        doctorExperience.facilityName = ''
        facility.splice(index, 1);

        this.props.updateParentState(doctorObject);
    }
    handleChange = (e, { value }) => {
        let _this = this
        let { doctorObject, activeBranchKey } = this.props;
        let facility = doctorObject[activeBranchKey].experienceFacility;

        if (value === "") {
            facility.filter((item, index) => {
                _this.removeFacility(item)
            })
        }
        this.setState({ currentText: value })
    }
    renderSelectedClinics = () => {
        let { doctorObject, activeBranchKey } = this.props;
        let { canAdd, currentText } = this.state;
        let facility = doctorObject[activeBranchKey].experienceFacility;

        return (
            <Dropdown
                selection
                search
                fluid
                noResultsMessage="Type facility name"
                placeholder="Type facility name"
                className="selectiondropdown"
                upward={false}
                allowAdditions
                text={currentText}
                value={currentText}
                additionLabel='Add facility: '
                onAddItem={this.addFacility}
                onChange={this.addFacility}
                clearable
            />
        )
    }

    changeDocTitle = (e) => {
        let { doctorObject, activeBranchKey } = this.props;
        let { doctorExperience } = this.state;

        // doctorObject[activeBranchKey].doctorExperienceObject.title = e.target.id;
        doctorExperience.title = e.target.id;
        this.props.updateParentState(doctorObject);

    }
    renderTitle = () => {
        const { activeBranchKey } = this.props;
        let doctorObject = this.props.doctorObject;
        let { doctorExperience } = this.state;

        return (
            <div className="box">
                <label>Title <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                {(doctorExperience.title === 'G.P') ? (
                    <div>
                        <button className="color-btn" onClick={this.changeDocTitle} id="G.P">G.P</button>
                        <button className="normal-btn" onClick={this.changeDocTitle} id="Specialist">Specialist</button>
                        <button className="normal-btn" onClick={this.changeDocTitle} id="Consultant">Consultant</button>
                    </div>
                ) : (doctorExperience.title === 'Specialist') ? (
                    <div>
                        <button className="normal-btn" onClick={this.changeDocTitle} id="G.P">G.P</button>
                        <button className="color-btn" onClick={this.changeDocTitle} id="Specialist">Specialist</button>
                        <button className="normal-btn" onClick={this.changeDocTitle} id="Consultant">Consultant</button>
                    </div>
                ) : (doctorExperience.title === 'Consultant') ? (
                    <div>
                        <button className="normal-btn" onClick={this.changeDocTitle} id="G.P">G.P</button>
                        <button className="normal-btn" onClick={this.changeDocTitle} id="Specialist">Specialist</button>
                        <button className="color-btn" onClick={this.changeDocTitle} id="Consultant">Consultant</button>
                    </div>
                ) : (
                                <div>
                                    <button className="normal-btn" onClick={this.changeDocTitle} id="G.P">G.P</button>
                                    <button className="normal-btn" onClick={this.changeDocTitle} id="Specialist">Specialist</button>
                                    <button className="normal-btn" onClick={this.changeDocTitle} id="Consultant">Consultant</button>
                                </div>
                            )
                }
            </div>
        )
    }


}

export default Experience