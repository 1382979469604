import { createAction, createReducer } from 'redux-action';
export const SET_ANALYTICS = 'SET_ANALYTICS';

export const setAnalytics = createAction(SET_ANALYTICS, (data, keys = []) => {

    let activeClinics = keys?.reduce((active, current) => {
        if (data?.clinics && data?.clinics[current]) { active[current] = data?.clinics[current] };
        return active
    }, {})

    let totalBookings = Object.values(activeClinics)?.reduce((allData, currentRecord) => {
        allData.totalBookings = allData?.totalBookings + ((currentRecord?.totalBookings || 0))
        allData.totalCanceled = allData?.totalCanceled + ((currentRecord?.totalCanceled || 0))
        allData.totalRescadualed = allData?.totalRescadualed + ((currentRecord?.totalRescadualed || 0))
        allData.totalRevenu = allData?.totalRevenu + ((currentRecord?.totalRevenu || 0))
        allData.totalPatients = allData?.totalPatients + ((currentRecord?.totalPatients || 0))

        return allData
    }, {
        totalBookings: 0,
        totalCanceled: 0,
        totalRescadualed: 0,
        totalRevenu: 0,
        totalPatients: 0
    })
    data.general = { ...data.general, ...totalBookings }
    return data
})
const initialState = {
    clinics: {},
    doctors: {},
    general: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ANALYTICS:
            return action.payload;
        default:
            return state;
    }
};