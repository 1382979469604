import React, { Component } from 'react';
import PropTypes from 'prop-types'

const hours = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
]
const minutes = [
    '00',
    '05',
    '10',
    '15',
    '20',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '55',
]

class Timings extends Component {
    static propTypes = {
        branchList: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        updateParentState: PropTypes.func.isRequired,
        applyToAll: PropTypes.func.isRequired,
    }

    constructor(props, context) {
        super(props, context);
    }
    state = {

        timeType: '24t',
        currentSelectedTime: null,
        currentSelectedMinute: null,
        currentSelectedHour: null,
        currentSelectedDay: false,
        nonSelectedError: false,
        nonSelectedTimeError: false,
        am_pm: 'am',
        splitNow: false,
        clocks: {
            from: {
                hour: '00',
                minute: '00',
                am_pm: 'am',
                has_set: false
            },
            to: {
                hour: '00',
                minute: '00',
                am_pm: 'pm',
                has_set: false
            },
            split_from: {
                hour: '00',
                minute: '00',
                am_pm: 'am',
                has_set: false
            },
            split_to: {
                hour: '00',
                minute: '00',
                am_pm: 'pm',
                has_set: false
            },

        }
    }

    selectDay = (name) => {
        let { activeBranchKey, branchList } = this.props;
        let hasSelectDay = false;

        let myList = branchList[activeBranchKey].openingDays;
        if (!myList[name].opened) {
            myList[name].selected = !myList[name].selected;
            this.props.updateParentState(branchList);
        }
        Object.keys(myList).map(function (day) {
            if (myList[day].selected) {
                hasSelectDay = true;
            }
        })

        this.setState({
            currentSelectedDay: hasSelectDay,
            nonSelectedError: false
        })
    };

    /*Timing functions*/
    changeTimeType = (type) => {
        this.setState({
            timeType: type
        })
    }
    selectTime = (each) => {

        this.setState({
            currentSelectedTime: each
        }, () => {
            if (this.state.currentSelectedTime !== null) {
                // attach/remove event handler
                document.addEventListener('click', this.handleOutsideClick, false);
            } else {
                document.removeEventListener('click', this.handleOutsideClick, false);
            }
            this.resetSelected()
        })
    }
    handleOutsideClick = (e) => {
        // ignore clicks on the component itself
        // 12 is count for each parts (hours, minutes)
        if (this.node.contains(e.target) || e.path[1].childElementCount === 12) {
            return;
        }
        this.selectTime(null)
    }
    selectTimeItems = (which, key) => {
        this.setState({
            [which]: key
        })
    }
    selectAmPm = (am_pm) => {
        this.setState({
            am_pm: am_pm
        })
    }
    resetSelected = () => {
        this.setState({
            currentSelectedMinute: null,
            currentSelectedHour: null,
            am_pm: 'am'
        })
    }
    setSelectedTime = () => {
        const { currentSelectedTime, currentSelectedMinute, currentSelectedHour, am_pm } = this.state;

        this.setState(prevState => ({
            clocks: {
                ...prevState.clocks,
                [currentSelectedTime]: {
                    hour: currentSelectedHour,
                    minute: currentSelectedMinute,
                    am_pm: am_pm,
                    has_set: true
                }
            },
            currentSelectedTime: null
        }), () => {
            this.resetSelected();
        })
    }
    /**/
    splitToggle = () => {
        let { splitNow } = this.state;
        this.setState({ splitNow: !splitNow })
    }
    addTimeToDay = () => {
        let { clocks, splitNow, timeType, currentSelectedDay } = this.state;
        let { branchList, activeBranchKey } = this.props;
        let allDays = branchList[activeBranchKey].openingDays;
        let haveTimeErrors = false;

        if (currentSelectedDay) {
            Object.keys(allDays).map(function (day) {
                let curDay = allDays[day];

                if (allDays[day].selected) {
                    if (timeType === '24t') {
                        curDay.isTwentyFour = true;
                    } else {
                        haveTimeErrors = splitNow ? (!clocks.from.has_set || !clocks.to.has_set || !clocks.split_from.has_set || !clocks.split_to.has_set) : (!clocks.from.has_set || !clocks.to.has_set);

                        if (!haveTimeErrors) {
                            let from = clocks.from.hour + ':' + clocks.from.minute + ':' + clocks.from.am_pm;
                            let to = clocks.to.hour + ':' + clocks.to.minute + ':' + clocks.to.am_pm;
                            let split_from = clocks.split_from.hour + ':' + clocks.split_from.minute + ':' + clocks.split_from.am_pm;
                            let split_to = clocks.split_to.hour + ':' + clocks.split_to.minute + ':' + clocks.split_to.am_pm;
                            curDay.isSplitShift = splitNow;
                            curDay.fromTime = from;
                            curDay.toTime = to;
                            curDay.pmFromTime = split_from;
                            curDay.pmToTime = split_to;
                        }
                    }

                    if (!haveTimeErrors) {
                        curDay.fixed = curDay.opened = true;
                        curDay.selected = false;
                    }
                }
            });


            if (!haveTimeErrors) {

                clocks.from = clocks.to = clocks.split_from = clocks.split_to =
                    {
                        hour: '00',
                        minute: '00',
                        am_pm: 'am',
                        has_set: false
                    }
                this.setState({
                    currentSelectedDay: false,
                    timeType: '24t',
                    splitNow: false,
                    clocks: clocks,
                }, () => {
                    this.props.updateParentState(branchList);
                })
            }
        }

        this.setState({
            nonSelectedError: !currentSelectedDay,
            nonSelectedTimeError: haveTimeErrors,
        })
    }
    removeTimeFromDay = (name) => {
        let { branchList, activeBranchKey } = this.props;

        let myList = branchList[activeBranchKey].openingDays;
        myList[name].fixed = false;
        myList[name].selected = false;
        myList[name].opened = false;
        myList[name] = {
            selected: false,
            opened: false,
            fixed: false,
            isTwentyFour: false,
            fromTime: false,
            toTime: false,
            isSplitShift: false,
            pmFromTime: false,
            pmToTime: false,
        }
        this.props.updateParentState(branchList);
    };
    render = () => {
        return (
            <div>
                {this.renderOpeningDays()}
            </div>
        )
    }

    componentDidMount = () => {

        // this.props.updateParentState(branchList);
    }


    renderOpeningDays = () => {
        const { applyToAll, activeBranchKey, branchList } = this.props;
        let { timeType, nonSelectedError, nonSelectedTimeError, currentSelectedDay } = this.state;
        let openingDays = branchList[activeBranchKey].openingDays;
        let weekDays = ["SAT", "SUN", "MON", "TUE", "WED", "THU", "FRI"];
        return (
            <div>
                <label className="section-title" style={{ textTransform: 'none' }}>Opening Days</label>
                <small className="sub-title">Non selected days will be considered off-days</small>
                {
                    nonSelectedError ? (
                        <label className="sub-title" style={{ color: 'darkred' }}>Please select day</label>
                    ) : ''
                }
                {
                    nonSelectedTimeError ? (
                        <label className="sub-title" style={{ color: 'darkred' }}>All timing fields are required</label>
                    ) : ''
                }
                <div className="all-days">
                    {
                        weekDays.map((item, index) => (
                            (openingDays[item].opened) ? (
                                <button key={index} className="color-button" onClick={this.selectDay.bind(this, item)}>
                                    {item}
                                </button>
                            ) : (openingDays[item].selected) ? (
                                <button key={index} className="border-button" onClick={this.selectDay.bind(this, item)}>
                                    {item}
                                </button>
                            ) : (<button key={index} className="border-button-notselected" onClick={this.selectDay.bind(this, item)}>
                                {item}
                            </button>)

                        ))
                    }
                </div>
                <div className="hours section">
                    {
                        (this.state.timeType === '24t') ? (
                            <div className="hours-limit ">
                                <button className="left-button-active" data-active={this.state.timeType === '24t'} onClick={this.changeTimeType.bind(this, '24t')}>24 hours</button>
                                <button className="right-button" data-active={this.state.timeType === 'custom'} onClick={this.changeTimeType.bind(this, 'custom')}>custom</button>
                            </div>

                        ) : (
                                <div className="hours-limit ">
                                    <button className="left-button" data-active={this.state.timeType === '24t'} onClick={this.changeTimeType.bind(this, '24t')}>24 hours</button>
                                    <button className="right-button-active" data-active={this.state.timeType === 'custom'} onClick={this.changeTimeType.bind(this, 'custom')}>custom</button>
                                </div>
                            )
                    }



                </div>
                {
                    (timeType === 'custom') ? (
                        <div className="from-to">
                            {this.renderCustomTimes()}
                        </div>

                    ) : ''
                }
                {
                    currentSelectedDay ?
                        <button className="set-button" onClick={this.addTimeToDay}>Set</button>
                        : ''
                }
                {
                    this.renderOpeningDaysList()
                }

            </div>
        )
    }

    renderCustomTimes = () => {
        const _this = this;
        let { currentSelectedTime, currentSelectedMinute, currentSelectedHour, am_pm, clocks, splitNow } = this.state;
        return (
            <div className="wizardMain">
                <div className="timings">
                    <div className="typeList" style={{ paddingTop: "5px" }}>

                        <div className="customTimes flexBox align_justify_left" ref={node => this.node = node} >
                            <div className="defaultTime flexBox">
                                <div className="select flexBox" data-selected={currentSelectedTime === 'from' || clocks.from.has_set} onClick={this.selectTime.bind(this, 'from')}>
                                    <span className="title">From <span> *</span></span>
                                    <div className="selected flexBox">
                                        <div className="slTime">
                                            <span>{clocks.from.hour}</span>
                                            <span>{clocks.from.minute}</span>
                                        </div>
                                        <span className="dt">{clocks.from.am_pm}</span>
                                        <span className="clock" />
                                    </div>
                                    <div className="placeholder flexBox">
                                        <span className="plcText">Select Time</span>
                                        <span className="clock" />
                                    </div>
                                </div>
                                <div className="select flexBox" data-selected={currentSelectedTime === 'to' || clocks.to.has_set} onClick={this.selectTime.bind(this, 'to')}>
                                    <span className="title">To <span> *</span></span>
                                    <div className="selected flexBox">
                                        <div className="slTime flexBox">
                                            <span>{clocks.to.hour}</span>
                                            <span>{clocks.to.minute}</span>
                                        </div>
                                        <span className="dt">{clocks.to.am_pm}</span>
                                        <span className="clock" />
                                    </div>
                                    <div className="placeholder flexBox">
                                        <span className="plcText">Select Time</span>
                                        <span className="clock" />
                                    </div>
                                </div>
                            </div>
                            {
                                splitNow ? (
                                    <div className="splitTime flexBox">
                                        <div className="select flexBox" data-selected={currentSelectedTime === 'split_from' || clocks.split_from.has_set} onClick={this.selectTime.bind(this, 'split_from')}>
                                            <span className="title">From <span> *</span></span>
                                            <div className="selected flexBox">
                                                <div className="slTime">
                                                    <span>{clocks.split_from.hour}</span>
                                                    <span>{clocks.split_from.minute}</span>
                                                </div>
                                                <span className="dt">{clocks.split_from.am_pm}</span>
                                                <span className="clock" />
                                            </div>
                                            <div className="placeholder flexBox">
                                                <span className="plcText">Select Time</span>
                                                <span className="clock" />
                                            </div>
                                        </div>
                                        <div className="select flexBox" data-selected={currentSelectedTime === 'split_to' || clocks.split_to.has_set} onClick={this.selectTime.bind(this, 'split_to')}>
                                            <span className="title">To <span> *</span></span>
                                            <div className="selected flexBox">
                                                <div className="slTime flexBox">
                                                    <span>{clocks.split_to.hour}</span>
                                                    <span>{clocks.split_to.minute}</span>
                                                </div>
                                                <span className="dt">{clocks.split_to.am_pm}</span>
                                                <span className="clock" />
                                            </div>
                                            <div className="placeholder flexBox">
                                                <span className="plcText">Select Time</span>
                                                <span className="clock" />
                                            </div>
                                        </div>
                                    </div>
                                ) : ''
                            }
                            <div className="addSplit" onClick={this.splitToggle}>
                                <span>{!splitNow ? 'Split Shift' : 'One Shift'}</span>
                            </div>


                            {
                                currentSelectedTime ? (
                                    <div data-footer={currentSelectedHour !== null && currentSelectedMinute !== null} className={['popupCalendar', currentSelectedTime].join(' ')}>
                                        <div onClick={() => { this.selectTime(null) }} className="close"><i className="fas fa-times-circle"></i></div>
                                        <div className="fulK">
                                            <div className="head flexBox align_justify">
                                                <span>Hour</span>
                                                <span>Minute</span>
                                            </div>
                                            <div className="body flexBox align_justify">
                                                <div className="left">
                                                    {
                                                        (currentSelectedHour && currentSelectedMinute) ? (
                                                            <div className="choosed">
                                                                <span>{currentSelectedHour}</span>
                                                            </div>
                                                        ) : (
                                                                <ul className="items flexBox align_justify">
                                                                    {
                                                                        hours.map(function (item) {
                                                                            return (
                                                                                <li data-active={item === currentSelectedHour} onClick={_this.selectTimeItems.bind(this, 'currentSelectedHour', item)} key={item} >{item}</li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            )
                                                    }
                                                </div>
                                                <div className="center flexBox">
                                                    <div data-active={am_pm === 'am'} className="day" onClick={this.selectAmPm.bind(this, 'am')}>
                                                        <span>AM</span>
                                                    </div>
                                                    <div data-active={am_pm === 'pm'} className="day" onClick={this.selectAmPm.bind(this, 'pm')}>
                                                        <span>PM</span>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    {
                                                        (currentSelectedMinute && currentSelectedHour) ? (
                                                            <div className="choosed">
                                                                <span>{currentSelectedMinute}</span>
                                                            </div>
                                                        ) : (
                                                                <ul className="items flexBox align_justify">
                                                                    {
                                                                        minutes.map(function (item) {
                                                                            return (
                                                                                <li data-active={item === currentSelectedMinute} onClick={_this.selectTimeItems.bind(this, 'currentSelectedMinute', item)} key={item} >{item}</li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            )
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (currentSelectedHour && currentSelectedMinute) ? (
                                                <div className="footerBack flexBox">
                                                    <div onClick={() => { this.resetSelected() }} className="stButt">
                                                        <span>Cancel</span>
                                                    </div>
                                                    <div className="stButt" onClick={this.setSelectedTime}>
                                                        <span>Set</span>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                    </div>
                                ) : ''
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderOpeningDaysList = () => {
        let { branchList, activeBranchKey } = this.props;
        let openingDays = branchList[activeBranchKey].openingDays;
        let weekDays = ["SAT", "SUN", "MON", "TUE", "WED", "THU", "FRI"];

        return (
            <div className="opening-hour gray-box section marginTop30">
                <h2 className="section-title" style={{ padding: '10px' }}>Opening Hours</h2>
                <div className="table-responsive">
                    <table className="table">{
                        weekDays.map((item, index) => (
                            (openingDays[item].opened) ? (
                                <tbody key={item}>
                                    <tr>
                                        <td>{item}</td>
                                        {
                                            (!openingDays[item].isTwentyFour) ? (
                                                <td>
                                                    {openingDays[item].fromTime.includes(':am') ? openingDays[item].fromTime.replace(':am', ' AM') : openingDays[item].fromTime.replace(':pm', ' PM')} to {openingDays[item].toTime.includes(':am') ? openingDays[item].toTime.replace(':am', ' AM') : openingDays[item].toTime.replace(':pm', ' PM')}
                                                </td>
                                            ) : (
                                                    <td>Full Day</td>
                                                )
                                        }
                                        {
                                            (openingDays[item].isSplitShift && !openingDays[item].isTwentyFour) ? (
                                                <td>
                                                    {openingDays[item].pmFromTime.includes(':am') ? openingDays[item].pmFromTime.replace(':am', ' AM') : openingDays[item].pmFromTime.replace(':pm', ' PM')} to {openingDays[item].pmToTime.includes(':am') ? openingDays[item].pmToTime.replace(':am', ' AM') : openingDays[item].pmToTime.replace(':pm', ' PM')}
                                                </td>
                                            ) : <td></td>
                                        }
                                        <td style={{ color: 'darkred' }} onClick={this.removeTimeFromDay.bind(this, item)}><i className="fa fa-close"></i></td>
                                    </tr>
                                </tbody>
                            ) : null
                        ))}</table>
                </div>
            </div>
        )
    }
}

export default Timings