import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Select from 'react-select';
import fire from 'firebase/app'
import 'firebase/database';
import 'firebase/auth';
import { validateEmail } from '../../../helpers/Full';
import { Dropdown } from 'semantic-ui-react';
import Swal from 'sweetalert2';

class SetupBooking extends Component {

    static propTypes = {
        clinicsList: PropTypes.object.isRequired,
        currentUser: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        updateParentState: PropTypes.func.isRequired,
    }
    state = {
        doctorsOptions: [],
        selectedDoctors: [],
        Doctors: [],
        popupActiveDoctor: null
    }

    constructor(props, context) {
        super(props, context);
    }
    componentWillReceiveProps(props) {
        let { Doctors } = this.state;
        if (props.dismissDoctor === true) {
            this.removeDoctor()

        }
        if (!Doctors.length) {
            this.componentWillMount()
        }
    }
    componentWillMount = () => {
        let { clinicsList, activeBranchKey } = this.props;
        let { doctorsOptions, Doctors, selectedDoctors } = this.state;
        let doctors = clinicsList[activeBranchKey].selectedDoctors;

        doctorsOptions = [];
        Doctors = [];
        selectedDoctors = [];
        let _this = this
        let clinicDoctors = clinicsList[activeBranchKey].doctors ? clinicsList[activeBranchKey].doctors : [];
        clinicDoctors.map(item => {
            let DoctorsSnap = fire.firestore().collection('doctors').doc(item);
            DoctorsSnap.get().then(snapshot => {

                // DoctorsSnap.once('value', snapshot => {
                if (snapshot.exists) {
                    let value = snapshot.data()
                    value["id"] = snapshot.id

                    let specialities = value["clinics"][clinicsList[activeBranchKey].facilityId]
                    let specNames = []

                    var counter = 0;

                    (specialities ? specialities : []).map(spec => {
                        fire.firestore().collection('specialties').doc(spec).get().then(specSnap => {
                            if (specSnap.exists && specSnap.data().name) {
                                counter++
                                specNames.push(specSnap.data().name)
                                value["specNames"] = specNames
                                if (counter >= specialities.length) {
                                    Doctors.push(value)
                                    if (value.bookingActive) {
                                        if (value.bookingActive[clinicsList[activeBranchKey].facilityId]) {
                                            selectedDoctors.push(value)
                                            doctors.push({
                                                consultFee: value.bookingActive[clinicsList[activeBranchKey].facilityId],
                                                doctorId: value.id,
                                                name: value.name
                                            })
                                        } else {
                                            doctorsOptions.push({
                                                value: snapshot.id,
                                                text: snapshot.data().name ? snapshot.data().name : '',
                                            });
                                        }

                                    } else {
                                        doctorsOptions.push({
                                            value: snapshot.id,
                                            text: snapshot.data().name ? snapshot.data().name : '',
                                        });
                                    }
                                    _this.setState({
                                        doctorsOptions,
                                        Doctors,
                                        selectedDoctors
                                    });
                                }
                            }
                        })
                    })

                }
            });
        })
    }
    /**
    *
    * @param id
    */
    addDoctor = (e, { value }) => {
        console.log('valuevalue',value)

        let { doctorsOptions, selectedDoctors, Doctors, popupActiveDoctor } = this.state;
        let { clinicsList, activeBranchKey, doctorData } = this.props;

        Doctors.map((item) => {
            if (item.id === value) {
                item.selectedClinicId = clinicsList[activeBranchKey].facilityId
                item.type = "add"
                selectedDoctors.push(item);
                doctorsOptions.map((val, iindex) => {
                    if (val.value === value) {
                        doctorsOptions.splice(iindex, 1);
                    }

                })
                popupActiveDoctor = item
                doctorData(item)
            }
        })

        clinicsList[activeBranchKey].selectedDoctors = selectedDoctors
        this.setState({
            doctorsOptions,
            selectedDoctors,
            Doctors,
            popupActiveDoctor
        })
        this.props.updateParentState(clinicsList);

    }
    editDoctor = (id) => {
        id = id.value ? id.value : id; //check if added from dropdown

        let { doctorsOptions, selectedDoctors, Doctors, popupActiveDoctor } = this.state;
        let { clinicsList, activeBranchKey, doctorData } = this.props;

        Doctors.map((item) => {
            if (item.id === id) {
                item.selectedClinicId = clinicsList[activeBranchKey].facilityId
                item.type = "edit"
                //   selectedDoctors.push(item);
                doctorsOptions.map((val, iindex) => {
                    if (val.value === id) {
                        doctorsOptions.splice(iindex, 1);
                    }
                })
                popupActiveDoctor = item
                doctorData(item)
            }
        })

        clinicsList[activeBranchKey].selectedDoctors = selectedDoctors
        this.setState({
            doctorsOptions,
            selectedDoctors,
            Doctors,
            popupActiveDoctor
        })
        this.props.updateParentState(clinicsList);

    }
    removeDoctor = () => {
        let { doctorsOptions, selectedDoctors, Doctors, popupActiveDoctor } = this.state;
        let { clinicsList, activeBranchKey } = this.props;
        Doctors.map((item) => {
            if (item.id === popupActiveDoctor.id && popupActiveDoctor.type === "add") {

                doctorsOptions.push({
                    value: item.id,
                    text: item.name ? item.name : '',
                });
                let indexx = selectedDoctors.indexOf(item)
                selectedDoctors.splice(indexx, 1);

            }
        })

        this.props.resetDismissDoctor()
        this.props.updateParentState(clinicsList);
        this.setState({
            doctorsOptions,
            selectedDoctors,
            Doctors
        })
    }

    renderSelectedDoctors = () => {
        let { clinicsList, activeBranchKey } = this.props;
        let { selectedDoctors, doctorsOptions } = this.state;
        let _this = this
        // let doctors = clinicsList[activeBranchKey].selectedDoctors;
        let facilityId = clinicsList[activeBranchKey].facilityId
        return (

            <div className="selecteddoctors">
                <div style={{ display: 'flex', margin: '20px', justifyContent: 'flex-start', width: '100%' }}>
                    <div className="name">Name</div>
                    <div className="speciality">Email</div>
                    <div className="speciality"></div>

                    {/* <div className="consultationfees">Consultation fees</div> */}
                </div>
                {/* <div className="averagewaitingtime">Average waiting time</div> */}
                {
                    selectedDoctors.map((item, index) => {
                        return (
                            <div key={index} className="selecteddoctor" style={index === this.state.activeRowFlag ? { zIndex: 100 } : {}}>
                                <div className="drsamehsuliman">{item.name}</div>
                                <div className="orthopedics">{item.email}</div>
                                <div className="orthopedics"></div>

                                {/* <div style={{ width: '25%' }}>
                                    <div>{item.bookingActive && item.bookingActive[facilityId] ? item.bookingActive[facilityId] : ''}</div>
                                </div> */}

                                {
                                    // (!item.bookingActive) ? (
                                    <div >
                                        <Dropdown onFocus={() => this.setState({ activeRowFlag: index })} icon="setting" text="settings" >
                                            <Dropdown.Menu >

                                                <Dropdown.Item text='Edit' onClick={() => this.editDoctor(item.id)} />
                                                <Dropdown.Item text='Deactivate booking' onClick={() =>
                                                    Swal.fire({
                                                        heightAuto: false,
                                                        title: 'Are you sure?',
                                                        text: "Online appointment booking feature will be deactivated for " + item.name + ". Do you want to proceed",
                                                        type: 'warning',
                                                        confirmButtonText: 'Proceed',
                                                        cancelButtonText: 'Cancel',
                                                        showCancelButton: true,
                                                        allowOutsideClick: false
                                                    }).then(function (result) {
                                                        if (result.value) {
                                                            // let index = selectedDoctors.indexOf(selectedDoctor => {
                                                            //     console.log("selectedDoctor", selectedDoctor)
                                                            //     return selectedDoctor.id == item.id
                                                            // })
                                                            // console.log("indexindex", index)
                                                            doctorsOptions.push({
                                                                value: item.id,
                                                                text: item.name ? item.name : '',
                                                            });
                                                            selectedDoctors = selectedDoctors.filter(doctor => doctor.id !== item.id)
                                                            let bookingActive = item.bookingActive
                                                            delete bookingActive[facilityId]
                                                            fire.firestore().collection('doctors').doc(item.id).update({
                                                                bookingActive: bookingActive
                                                            }).then(() => {
                                                                Swal.fire({
                                                                    heightAuto: false,
                                                                    text: item.name + ' removed from online appointment booking feature successfully',
                                                                    type: 'success',
                                                                    confirmButtonText: 'ok',
                                                                    allowOutsideClick: false
                                                                }).then(() => {
                                                                    _this.setState({ selectedDoctors, doctorsOptions })
                                                                })
                                                            })
                                                        }
                                                    }).catch(error => {
                                                    })} />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                }

                                <div className="rectangle9">
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        )
    }

    render() {
        let { clinicsList, activeBranchKey } = this.props;
        let { doctorsOptions, selectedDoctors } = this.state;
        return (
            <div className="setupbooking">
                <div className="generalinfo">
                    <div className="generalinfo1">General info</div>
                    <div className="bookingemail">
                        <div className="bookingemail1">Booking Email</div>
                        <div className="bookingemail2">notification emails for appointment requests will be sent to this email</div>
                        <input style={(clinicsList[activeBranchKey].bookingEmail && validateEmail(clinicsList[activeBranchKey].bookingEmail)) ? {} : { borderColor: 'darkred' }} className="rectangle7" onChange={this.changeEmail.bind(this)} value={clinicsList[activeBranchKey].bookingEmail} />

                    </div>
                    <div className="contactnumber">
                        <div className="contactnumber1">Contact Number</div>
                        <input style={clinicsList[activeBranchKey].bookingNumber && clinicsList[activeBranchKey].bookingNumber.length >= 10 ? {} : { borderColor: 'darkred' }} placeholder="ex.+971xxxxxxx" className="rectangle7" type="number" onChange={this.changeNumber} value={clinicsList[activeBranchKey].bookingNumber} />
                    </div>
                </div>
                <div className="selectdoctor">
                    <div className="doctors">Doctors</div>
                    <div className="choosethedoctor">Pick your doctor to activate his online booking system</div>
                    <Dropdown
                        selection
                        search
                        options={doctorsOptions}
                        style={selectedDoctors.length ? {} : { borderColor: 'darkred' }}
                        value=''
                        onChange={this.addDoctor}
                        placeholder="Type doctor name"
                        selectOnBlur={false}
                    />

                </div>
                {this.renderSelectedDoctors()}

            </div>
        )
    }

    /**
       * @param e
       */
    changeEmail = (e) => {
        let { clinicsList, activeBranchKey } = this.props;
        let val = e.target.value;
        clinicsList[activeBranchKey].bookingEmail = val
        this.props.updateParentState(clinicsList);

    }

    /**
      * @param e
      */
    changeNumber = (e) => {
        let { clinicsList, activeBranchKey } = this.props;
        let val = e.target.value;
        clinicsList[activeBranchKey].bookingNumber = val
        this.props.updateParentState(clinicsList);
    }
}

export default SetupBooking