import React, { Component } from 'react';
import PropTypes from 'prop-types'
import arrow from '../../img/blueArrow.png'
import arrow90 from '../../img/rotatedArrow.png'
import { Button } from 'semantic-ui-react'
import Swal from 'sweetalert2'
import firebase from 'firebase/app'
import 'firebase/database'

class StepsBar extends Component {
    static propTypes = {
        currentStep: PropTypes.number.isRequired,
        changeCurrentStep: PropTypes.func.isRequired,
        changeErrClick: PropTypes.func.isRequired,
    }
    state = {
        parts: [
            'Basic Info',
            'Timings',
            'Credentials',
            'Rich profile info',
            'Medical School',
            'Residency',
            'Fellowship',
            'Experience',
            'Awards',
        ],
        parts2: [
            'Basic Info',
            'Timings',
            'Credentials',
            'Rich profile info',
        ],
        showMore: false
    }
    constructor(props, context) {
        super(props, context);
    }

    middleChangeCurrentStep = (index) => {
        let { showMore } = this.state;

        let part = index + 1;

        if (part === 4) {
            this.setState({ showMore: !showMore })
        } else {
            this.props.changeCurrentStep(part);
        }
    }

    render() {
        let { doctorObject, user } = this.props
        return (
            <div style={{ height: '50%' }}>
                {this.renderSteps()}
                {/* <div style={{ display: 'flex', justifyContent: 'center', color: 'darkgray' }}>
                    <Button style={{ fontSize: '0.8em' }} onClick={() => Swal.fire({
                        heightAuto: false,
                        title: 'Are you sure?',
                        text: "This doctor will be removed permanently from your clinic",
                        type: 'warning',
                        confirmButtonText: 'Proceed',
                        cancelButtonText: 'Cancel',
                        showCancelButton: true,
                        allowOutsideClick: false
                    }).then(result => {
                        if (result.value) {
                            let doctorClinics = Object.keys(doctorObject["branch1"].clinics)
                            let doctorID = doctorObject["branch1"].doctorId
                            for (let clinicIndex in doctorClinics) {
                                let clinicId = doctorClinics[clinicIndex]
                                //remove from clinic
                                let db = firebase.firestore().collection('clinics').doc(clinicId)
                                db.update({
                                    doctors: firebase.firestore.FieldValue.arrayRemove(doctorID)
                                })

                                //remove from doctor

                                let db1 = firebase.firestore().collection('doctors').doc(doctorID)
                                firebase.firestore().runTransaction(transaction => {
                                    return transaction.get(db1).then(result => {
                                        let doctorsData = result.data()?.clinics
                                        let doctorsBooking = result.data()?.bookingActive
                                        if (doctorsBooking) {
                                            delete doctorsBooking[clinicId]
                                        }
                                        delete doctorsData[clinicId]
                                        transaction.update(db1, { clinics: doctorsData, bookingActive: doctorsBooking || {} });
                                    }).then(() => {
                                        window.location.reload()
                                    })
                                })
                            }
                        }
                    })}>
                        Deactivate Doctor Profile
                </Button>
                </div> */}
            </div>
        )
    }

    renderSteps = () => {
        const { currentStep } = this.props;
        let { parts, parts2, showMore } = this.state;

        return (
            <div className="page-nav" style={{ height: '90%' }}>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {
                        (showMore) ? (
                            parts.map((item, index) => (
                                <li className="nav-item" data-prev={currentStep > (index + 1)} data-active={currentStep === (index + 1)} onClick={this.middleChangeCurrentStep.bind(this, index)} key={item} >
                                    {
                                        (currentStep === (index + 1)) ? (
                                            <a className="nav-link active" aria-selected="true">{item}</a>
                                        ) : (index + 1 == 4) ? (
                                            <div className="shomore" aria-selected="true">{item}
                                                <img style={{ width: "18px", height: "auto" }} className="pic" src={arrow90} />
                                            </div>
                                        ) : (
                                            <a className="nav-link" aria-selected="false">{item}</a>
                                        )
                                    }
                                </li>
                            ))
                        ) : (
                            parts2.map((item, index) => (
                                <li className="nav-item" data-prev={currentStep > (index + 1)} data-active={currentStep === (index + 1)} onClick={this.middleChangeCurrentStep.bind(this, index)} key={item} >
                                    {
                                        (currentStep === (index + 1)) ? (
                                            <a className="nav-link active" aria-selected="true">{item}</a>
                                        ) : (index + 1 == 4) ? (
                                            <div className="shomore" aria-selected="true">{item}
                                                <img style={{ width: "auto", height: "18px" }} className="pic" src={arrow} />
                                            </div>
                                        ) : (
                                            <a className="nav-link" aria-selected="false">{item}</a>
                                        )

                                    }
                                </li>
                            ))
                        )

                    }
                </ul>
            </div>
        )
    }
}

export default StepsBar