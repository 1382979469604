import { createAction, createReducer } from 'redux-action';
import { fetchSearchParams, SetSearchParams } from './../services/SearchServices';
import { fetchSpecialities } from './../services/ClinicOfferServices';

export const GET_SEARCH_PARAMS = 'GET_SEARCH_PARAMS ';
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS ';
export const GET_SPECIALTIES = 'GET_SPECIALTIES';


const getSearchParamsAction = createAction(GET_SEARCH_PARAMS, () => {
  return fetchSearchParams().then(data => {
    return data;
  }).catch(err => console.log('LOG ::: err ::: ',err));
});

const setSearchParamsAction = createAction(SET_SEARCH_PARAMS, (type) => {
  return SetSearchParams(type).then(data => {
      return data;
  }).catch(err => console.log('LOG ::: err ::: ',err));
});

const getSpecialitiesAction = createAction(GET_SPECIALTIES, (type) => {
  return fetchSpecialities(type).then(data => {
      return data;
  }).catch(err => console.log('LOG ::: err ::: ',err));
});

export const searchActions = {
    getSearchParams: getSearchParamsAction,
    setSearchParams: setSearchParamsAction,
    getSpecialities: getSpecialitiesAction
};
// Actions that need dispatch reference
export const complexActions = {

};
// All actions
export const allActions = {
    ...searchActions,
    ...complexActions,
};
const initialState = {
  data: [],
};

const searchReducer = createReducer(initialState, {
  [GET_SEARCH_PARAMS]: (actionPayload, state) => {
      return { ...state, data: actionPayload };
  },
  [SET_SEARCH_PARAMS]: (actionPayload, state) => {
      return { ...state, data: actionPayload };
  },
  [GET_SPECIALTIES]: (actionPayload, state) => {
      return { ...state, data: actionPayload };
  }
});

export default searchReducer;
