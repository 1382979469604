import firebase from 'firebase/app'
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
const firebaseConfig = {
  doctory: {
    apiKey: "AIzaSyCQCPo3y8jVy4XsS0et5UUny_vnB7BBoHo",
    authDomain: "doctory-aec48.firebaseapp.com",
    databaseURL: "https://doctory-aec48.firebaseio.com",
    projectId: "doctory-aec48",
    storageBucket: "doctory-aec48.appspot.com",
    messagingSenderId: "483988549007",
    appId: "1:483988549007:web:0612553c3f2e73d7"
  },
  test: {
    apiKey: "AIzaSyByBkaAPgJoIGkVaZNSXo0wOxWVIxGFCLQ",
    authDomain: "clinicaone-94719.firebaseapp.com",
    databaseURL: "https://clinicaone-94719.firebaseio.com",
    projectId: "clinicaone-94719",
    storageBucket: "clinicaone-94719.appspot.com",
    messagingSenderId: "613950287247",
    appId: "1:613950287247:web:823048a8dd0e6b74"
  },
  clinica: {
    apiKey: "AIzaSyB3orE5DtytEP1M0flPzQDzDNBC0Xuaoqo",
    authDomain: "clincaone.firebaseapp.com",
    databaseURL: "https://clincaone.firebaseio.com",
    projectId: "clincaone",
    storageBucket: "clincaone.appspot.com",
    messagingSenderId: "107789857421",
    appId: "1:107789857421:web:640c66e14a0c81b6",
    measurementId: "G-5V4ESZ0LDT"
  },
  saeed:{
    apiKey: "AIzaSyCptU-wHmLHi64vU_zU_Q4SaLV839gWih8",
    authDomain: "m-saeed-811a8.firebaseapp.com",
    projectId: "m-saeed-811a8",
    storageBucket: "m-saeed-811a8.appspot.com",
    messagingSenderId: "756603383698",
    appId: "1:756603383698:web:44a010c5510c9386f05b4b",
    measurementId: "G-ERGJ1NEP1K"
  }
};

firebase.initializeApp(firebaseConfig[process.env.REACT_APP_NAME], "userCreator");
firebase.initializeApp(firebaseConfig[process.env.REACT_APP_NAME]);

firebase.initializeApp(firebaseConfig.test, "mainBucket");

// Notification.requestPermission().then((permission) => {
//   if (permission === 'granted') {
//     console.log('Notification permission granted.');
//     // TODO(developer): Retrieve an Instance ID token for use with FCM.
//     // ...
//   } else {
//     console.log('Unable to get permission to notify.');
//   }
// });
let messigingKey = {
  doctory: 'BH9VcAA6pD5sF52Oh_-1WJuvH1-CN9W1zg4H7ezpKOIdiXl4tl-YlbgciC5m4UMwUH60rXGSWKs_8_IKT7N0zZQ',
  test: 'BDTLF-Hc7OxiWx3j76UdQQyvSO50htxiNFtf8MFPwyxeJ2inlm0UNsicFOniJTvOBhfsjNi4VeObXLFzcgdzix8',
  clinica: 'BHWvhZeMyhy-A63oc3aT57IeJVUULWxogLzR00MTFXt7xR4HofDguVed6JBHZxI9FKTkKP8Ux6iSjf3976c1Cwo',
  saeed: 'BLJNYThWAC0nsLLPuPDfRBs2TkldBNy1lCvqqIKHNzNYr7E4P0i1wE-5_9kxmz84TFMju3LFYkRpkSLpCaju7yg'

}
if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();
  messaging.usePublicVapidKey(messigingKey[process.env.REACT_APP_NAME]);
}
export default firebase;