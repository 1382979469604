import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Route, Switch, Redirect } from 'react-router';
import AccountPage from './../pages/AccountPage';
import FacilityManagementSystem from './../pages/FacilityManagementSystem';
import DoctorAccountPage from './../pages/DoctorAccountPage';
import BookingSystem from './../pages/BookingSystem';
import Patients from '../pages/Patients';
import Dashboard from '../pages/Dashboard';
import HistorySection from './../pages/HistorySection';
import ReviewsSection from './../pages/ReviewsSection';
import Settings from './../pages/Settings';
import Helpers from './../pages/Helpers';
import n404Page from './../pages/n404Page';
import { connect } from "react-redux";
import { userActions } from './../modules/user-module.js'
import { actions } from './../modules/clinics-module.js'
import Login from './../Home Login'
import { app, auth, firestore } from 'firebase'
import { Dimmer, Icon } from 'semantic-ui-react'
import { setAllBookings } from '../modules/bookingsModule';
import { setAnalytics } from '../modules/analyticsModule';
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    componentWillMount() {
        let _this = this;
        auth().onAuthStateChanged(user => {
            this.props.getUser(user).then(async resutls => {
                if (resutls && resutls.payload) {

                    let branches = await this.props.getBranches(resutls.payload);
                    firestore().collection('analytics').get().then(res => {
                        let analytics = {}
                        res.forEach(doc => {
                            analytics[doc.id] = doc.data()
                        })
                        let clinicKeys = branches.payload && Object.keys(branches.payload)
                        _this.props.setAnalytics(analytics, clinicKeys)
                    })
                    await this.props.getSpecialities()
                    let { accessClinic, access } = resutls?.payload?.user?.info;
                    let bookingRef = firestore().collection('bookings').where('status', 'in', ["new", 'pending', 'accepted', 'succeeded'])
                    let onlineQuries = [bookingRef]
                    let offlineBookingsRef = firestore().collection('offlineBookings').where('status', 'in', ["new", 'pending', 'accepted', 'succeeded'])
                    let offlineQuries = [offlineBookingsRef]

                    if (access !== 'superAdmin') {
                        accessClinic = accessClinic.slice(0, 10)
                        onlineQuries = accessClinic.map(r => bookingRef.where('facilityId', '==', r))
                        offlineQuries = accessClinic.map(r => offlineBookingsRef.where('facilityId', '==', r))
                    }
                    let allBookings = []
                    let onlineMap = {}
                    await onlineQuries.forEach((r, index) => {
                        r.onSnapshot((bookingSnap) => {
                            onlineMap[index] = bookingSnap.docs.map(rec => {
                                return { ...rec.data(), id: rec.id, online: true }
                            })
                            if (Object.keys(onlineMap).length === onlineQuries.length) {
                                allBookings = allBookings?.filter(r => !r.online)
                                allBookings = allBookings.concat(...Object.values(onlineMap))
                                this.props.setAllBookings(allBookings)
                            }
                        })
                    })
                    let offlineMap = {}
                    await offlineQuries.forEach((r, index) => {
                        r.onSnapshot((bookingSnap) => {
                            offlineMap[index] = bookingSnap.docs.map(rec => {
                                return { ...rec.data(), id: rec.id, online: false }
                            })
                            if (Object.keys(offlineMap).length === onlineQuries.length) {
                                allBookings = allBookings?.filter(r => r.online)
                                allBookings = allBookings.concat(...Object.values(offlineMap))
                                this.props.setAllBookings(allBookings)
                            }
                        })
                    })
                    // await bookingRef.onSnapshot((bookingSnap) => {
                    //     allBookings = allBookings?.filter(r => !r.online)

                    //     allBookings = allBookings.concat(bookingSnap.docs.map(rec => {
                    //         return { ...rec.data(), id: rec.id, online: true }
                    //     }))
                    //     this.props.setAllBookings(allBookings)
                    // })
                    // await offlineBookingsRef.onSnapshot((bookingSnap) => {
                    //     allBookings = allBookings?.filter(r => r.online)
                    //     allBookings = allBookings.concat(bookingSnap.docs.map(rec => {
                    //         return { ...rec.data(), id: rec.id, online: false }
                    //     }))
                    //     this.props.setAllBookings(allBookings)
                    // })

                    this.setState({ loading: false, login: false })
                } else {
                    this.setState({ login: true })
                }
            })
        })

    }


    // const App = props => {
    render() {
        let { loading, login } = this.state
        if (login) {
            return <Route path="**" component={Login} />
        }
        if (loading) {
            return (<Dimmer active>
                <Icon.Group size='big'>
                    <Icon loading size='big' name='spinner' />
                    <div style={{ fontSize: '16px', padding: '10px', fontStyle: 'initial' }}>loading</div>
                </Icon.Group>
            </Dimmer>)
        } else {
            return (<div style={{ height: '100%' }}>
                <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route path="/wizard-user" component={AccountPage} />
                    <Route path="/wizard-doctor" component={DoctorAccountPage} />
                    <Route path="/appointment-system" component={BookingSystem} />
                    <Route path="/patients" component={Patients} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/history" component={HistorySection} />
                    <Route path="/feedback" component={ReviewsSection} />
                    <Route path="/facility-management-system" component={FacilityManagementSystem} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/helpers" component={Helpers} />
                    <Route path="/login" component={Login} />
                    {/* <Route path="/*" component={n404Page} /> */}
                </Switch>
            </div>
            )
        }
    }
};

window.addEventListener('scroll', function () {
    if (window.scrollY >= 160) {
        if (document.body.className.replace(/[\n\t]/g, " ").indexOf("sticky") === -1)
            document.body.className += ' ' + 'sticky';
    } else if (window.scrollY <= 120) {
        document.body.classList.remove("sticky");
    }
});
// export default App;

const mapStateToProps = (props) => {
    return {};
};
const mapActionsToProps = (dispatch) => ({
    getUser: (user) => dispatch(userActions.getUser(user)),
    getBranches: (user) => dispatch(userActions.getBranches(user)),
    getSpecialities: () => dispatch(actions.getSpecialities()),
    setAllBookings: (list) => dispatch(setAllBookings(list)),
    setAnalytics: (data, keys) => dispatch(setAnalytics(data, keys))
    // getOrderListSuccess: (updatedList) => dispatch(getOrderListSuccess(updatedList)),
});
export default connect(
    mapStateToProps,
    mapActionsToProps
)(App);