import React, { Component } from 'react';
import PropTypes from 'prop-types'
import 'react-image-crop/lib/ReactCrop.scss';
import DatePicker from 'react-modern-datepicker';
import { searchInArrayWithObjects } from '../../../helpers/Full';
import { CountryDropdown } from 'react-country-region-selector';
import { Dropdown, Input } from 'semantic-ui-react'
import RightMedicalSchool from '../RightSteps/RightMedicalSchool'
import Swal from 'sweetalert2';

class MedicalSchool extends Component {
    static propTypes = {
        doctorObject: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        updateParentState: PropTypes.func.isRequired,
    }

    constructor(props, context) {
        super(props, context);
    }
    state = {

        selectedFacility: [],
        canAdd: false,
        currentText: '',
        ToDate: null,
        country: '',
        doctorDegreeObject: {
            facilityName: '',
            degree: '',
            to: '',
            country: ''
        },
    }
    selectCountry(val) {
        let { doctorObject, activeBranchKey } = this.props;
        let { doctorDegreeObject } = this.state;
        doctorDegreeObject.country = val;
        this.props.updateParentState(doctorObject);
    }

    handleToDateChange(date) {
        let { doctorObject } = this.props;
        let { doctorDegreeObject } = this.state;
        doctorDegreeObject.to = date
        this.props.updateParentState(doctorObject);
        this.setState({
            ToDate: date
        });
        this.props.updateParentState(doctorObject);

    }

    render() {
        let { doctorDegreeObject } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let doctorObjects = doctorObject[activeBranchKey].doctorDegrees;

        return (
            <div>
                <div style={{ display: 'flow-root' }}>
                    {this.renderTitle()}

                    <div className="box" >
                        <label>Facility <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                        {this.renderSelectedClinics()}
                    </div>
                    <div className="box address-box">
                        <div className="input-half marg-right">
                            <label>Country <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                            <CountryDropdown
                                classes="custom-select"
                                value={doctorDegreeObject.country}
                                onChange={(val) => this.selectCountry(val)} />
                        </div>
                        <div className="input-half marg-right">
                            <label>Completion Date <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                            <DatePicker
                                format={'YYYY-MM-DD'}
                                className="custom-select"
                                date={this.state.ToDate}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                peekNextMonth
                                fixedHeight
                                onChange={this.handleToDateChange.bind(this)}
                                endDate={this.state.ToDate}
                                placeholderText="Select date"
                            />
                        </div>
                    </div>
                    <button className="save-btn-local" onClick={this.addDoctorObject}>Add</button>
                </div>
                {
                    (doctorObjects.length) ? (
                        <div className="right-side" style={{ width: '100%', marginTop: '24px' }}>
                            <RightMedicalSchool updateParentState={this.props.updateParentState}
                                activeBranchKey={activeBranchKey}
                                doctorObject={doctorObject} />
                        </div>
                    ) : ''
                }

            </div>
        )
    }
    addDoctorObject = () => {
        let { doctorObject, activeBranchKey } = this.props;
        let { doctorDegreeObject } = this.state;
        let doctorObjects = doctorObject[activeBranchKey].doctorDegrees;
        doctorObject[activeBranchKey].doctorDegreesObject = {}
        let doctorPublicObjects = doctorObject[activeBranchKey].doctorDegreesObject;
        if (doctorDegreeObject.facilityName.length && doctorDegreeObject.degree.length && doctorDegreeObject.country.length && doctorDegreeObject.to.length) {
            doctorPublicObjects.country = doctorDegreeObject.country;
            doctorPublicObjects.to = doctorDegreeObject.to;
            doctorPublicObjects.facilityName = doctorDegreeObject.facilityName;
            doctorPublicObjects.degree = doctorDegreeObject.degree;
            doctorObjects.push(doctorPublicObjects)

            doctorDegreeObject.facilityName = ''
            doctorDegreeObject.degree = ''
            doctorDegreeObject.country = ''
            doctorDegreeObject.to = ''
            doctorObject[activeBranchKey].schoolFacility = [];

            this.setState({
                ToDate: null,
                currentText: ''
            });
        }else{
            Swal.fire({
                heightAuto:false,
                type:'error',
                title:'Alert',
                text:'Please fill all requirments'
            })
        }
        this.props.updateParentState(doctorObject);

    }
    componentWillMount = () => {
        const { doctorObject, activeBranchKey } = this.props;
        let { doctorDegreeObject } = this.state;

        doctorDegreeObject.facilityName = ''
        doctorDegreeObject.degree = ''
        doctorDegreeObject.country = ''
        doctorDegreeObject.city = ''
        doctorDegreeObject.from = ''
        doctorDegreeObject.to = ''
        doctorObject[activeBranchKey].schoolFacility = [];
    }
    /**
    *
    */
    blurSelect = () => {
        let _this = this;
        setTimeout(function () {
            _this.setState({
                canAdd: false,
                currentText: ''
            })
        }, 300)
    }


    /**
    * @param e
    */
    onSelectInput = (e) => {
        if (e) {
            let _this = this;
            if (!e.length) return;
            setTimeout(function () {
                let r = document.querySelector(".Select-noresults");
                _this.setState({
                    canAdd: r !== null,
                    currentText: e
                })
            }, 400)
        }
    }

    addFacility = (e, { value }) => {
        this.setState({ currentText: value })
        let { doctorDegreeObject } = this.state;
        let { selectedFacility } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        doctorObject[activeBranchKey].schoolFacility = [];
        let facility = doctorObject[activeBranchKey].schoolFacility;

        if (value.length > 2 && !searchInArrayWithObjects(value, 'name', selectedFacility) && facility.indexOf(value) === -1) {
            facility.push(value)
            // doctorObject[activeBranchKey].doctorDegreesObject.facilityName = currentText
            doctorDegreeObject.facilityName = value
        }
        this.props.updateParentState(doctorObject);
    }
    removeFacility = (id) => {
        let { doctorDegreeObject } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let facility = doctorObject[activeBranchKey].schoolFacility;

        let index = facility.indexOf(id);
        // doctorObject[activeBranchKey].doctorDegreesObject.facilityName = ''
        doctorDegreeObject.facilityName = ''

        facility.splice(index, 1);

        this.props.updateParentState(doctorObject);
    }
    handleChange = (e, { value }) => {
        let _this = this
        let { doctorObject, activeBranchKey } = this.props;
        let facility = doctorObject[activeBranchKey].schoolFacility;
        if (value === "") {
            facility.filter((item, index) => {
                _this.removeFacility(item)
            })
        }
        this.setState({ currentText: value })
    }

    renderSelectedClinics = () => {
        let { currentText } = this.state;

        return (
            <Dropdown
                selection
                search
                fluid
                noResultsMessage="Type facility name"
                placeholder="Type facility name"
                className="selectiondropdown"
                upward={false}
                allowAdditions
                text={currentText}
                value={currentText}
                options={[]}
                additionLabel='Add facility: '
                onAddItem={this.addFacility}
                onChange={this.addFacility}
                clearable
            />
        )
    }

    changeDocTitle = (e) => {
        let { doctorDegreeObject } = this.state;
        let { doctorObject } = this.props;
        if (e.target.id === "Other Degree") {
            doctorDegreeObject.degree = "";
        } else {
            doctorDegreeObject.degree = e.target.id;
        }
        this.props.updateParentState(doctorObject);

    }
    renderTitle = () => {
        let { doctorDegreeObject } = this.state;
        const { activeBranchKey } = this.props;
        let doctorObject = this.props.doctorObject;
        return (
            <div className="box" style={{ display: 'inline-table' }}>
                <label>Degree <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                <div className="group-button flex">
                    {(doctorDegreeObject.degree === 'MBBS/MBBSCh') ? (
                        <div style={{ width: '100%' }}>
                            <button className="color-btn" onClick={this.changeDocTitle} id="MBBS/MBBSCh">MBBS/MBBSCh</button>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="MD">MD</button>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="DO">DO</button>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="PHD">PHD</button>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="Other Degree">Other Degree</button>
                        </div>
                    ) : (doctorDegreeObject.degree === 'MD') ? (
                        <div style={{ width: '100%' }}>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="MBBS/MBBSCh">MBBS/MBBSCh</button>
                            <button className="color-btn" onClick={this.changeDocTitle} id="MD">MD</button>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="DO">DO</button>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="PHD">PHD</button>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="Other Degree">Other Degree</button>
                        </div>
                    ) : (doctorDegreeObject.degree === 'DO') ? (
                        <div style={{ width: '100%' }}>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="MBBS/MBBSCh">MBBS/MBBSCh</button>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="MD">MD</button>
                            <button className="color-btn" onClick={this.changeDocTitle} id="DO">DO</button>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="PHD">PHD</button>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="Other Degree">Other Degree</button>
                        </div>
                    ) : (doctorDegreeObject.degree === 'PHD') ? (
                        <div style={{ width: '100%' }}>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="MBBS/MBBSCh">MBBS/MBBSCh</button>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="MD">MD</button>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="DO">DO</button>
                            <button className="color-btn" onClick={this.changeDocTitle} id="PHD">PHD</button>
                            <button className="normal-btn" onClick={this.changeDocTitle} id="Other Degree">Other Degree</button>
                        </div>
                    ) : (
                                        <div style={{ width: '100%' }}>
                                            <button className="normal-btn" onClick={this.changeDocTitle} id="MBBS/MBBSCh">MBBS/MBBSCh</button>
                                            <button className="normal-btn" onClick={this.changeDocTitle} id="MD">MD</button>
                                            <button className="normal-btn" onClick={this.changeDocTitle} id="DO">DO</button>
                                            <button className="normal-btn" onClick={this.changeDocTitle} id="PHD">PHD</button>
                                            <button className="color-btn" onClick={this.changeDocTitle} id="Other Degree">Other Degree</button>
                                            <Input className="input-half" type="text" name="name" error={!doctorDegreeObject.degree} placeholder="Other Degrees" value={doctorDegreeObject.degree} onChange={this.changeNameDefault.bind(this)} />

                                        </div>
                                    )}
                </div>
            </div >
        )
    }
    /**
     *
     * @param e
     */
    changeNameDefault = (e) => {
        let { doctorDegreeObject } = this.state;

        let val = e.target.value,
            _this = this;
        const { activeBranchKey } = this.props;
        let doctorObject = this.props.doctorObject;
        Object.keys(doctorObject).forEach(function (item) {
            if (item === _this.props.activeBranchKey) {
                // doctorObject[activeBranchKey].doctorDegreesObject.degree = val;
                doctorDegreeObject.degree = val;
            }
        });
        this.props.updateParentState(doctorObject);
    }

}

export default MedicalSchool