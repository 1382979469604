import React, { Component } from 'react';
import PropTypes from 'prop-types'
import fire from 'firebase/app'
import 'firebase/database';


import { searchInArrayWithObjects } from '../../../helpers/Full';
import { Dropdown } from 'semantic-ui-react';

class Amenities extends Component {
    static propTypes = {
        branchList: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        applyToAll: PropTypes.func.isRequired,
        updateParentState: PropTypes.func.isRequired,
    }
    state = {
        defaultAmenities: [],
        suggestedAmenities: {},
        Amenities: {},

        canAdd: false,
        currentText: '',
    }

    onSelectInput = (e) => {
        if (e) {
            let _this = this;
            if (!e.length) return;
            setTimeout(function () {
                let r = document.querySelector(".Select-noresults");
                _this.setState({
                    canAdd: r !== null,
                    currentText: e
                })
            }, 400)

        }
    }
    blurSelect = () => {
        let _this = this;
        setTimeout(function () {
            _this.setState({
                canAdd: false,
                currentText: ''
            })
        }, 300)
    }
    addCustomAmenity = () => {
        let { currentText, defaultAmenities } = this.state;
        let { branchList, activeBranchKey } = this.props;
        let customAmenities = branchList[activeBranchKey].customAmenities;
        if (currentText.length > 2 && !searchInArrayWithObjects(currentText, 'name', defaultAmenities) && customAmenities.indexOf(currentText) === -1) {
            customAmenities.push(currentText)
        }
        this.props.updateParentState(branchList);
    }

    /**
     *
     * @param id
     */
    addAmenity = (e, { value }) => {

        let { suggestedAmenities, Amenities } = this.state;
        let { branchList, activeBranchKey } = this.props;
        let amenities = branchList[activeBranchKey].amenities;

        suggestedAmenities[activeBranchKey].forEach(function (item, index) {
            if (item.id === value) {
                amenities.push(value);
                suggestedAmenities[activeBranchKey].splice(index, 1);
                Amenities[activeBranchKey].splice(index, 1);
            }
        })
        this.props.updateParentState(branchList);
        this.setState({
            suggestedAmenities: suggestedAmenities,
            Amenities: Amenities,
            currentText: ''
        })
    }
    /**
     * @param id
     */
    removeSelectedAmenities = (id) => {
        let { suggestedAmenities, Amenities } = this.state;
        let { branchList, activeBranchKey } = this.props;
        let amenities = branchList[activeBranchKey].amenities;
        let index = amenities.indexOf(id);
        let name = this.getNameById(id, 'defaultAmenities');

        if (index > -1) {
            amenities.splice(index, 1);

            let val = { name: name, id: id };
            suggestedAmenities[activeBranchKey].push(val);
            Amenities[activeBranchKey].push(val);
        }
        this.setState({
            suggestedAmenities: suggestedAmenities,
            Amenities: Amenities
        })
        this.props.updateParentState(branchList);
    }
    removeCustomAmenities = (id) => {
        let { branchList, activeBranchKey } = this.props;
        let customAmenities = branchList[activeBranchKey].customAmenities;
        let index = customAmenities.indexOf(id);
        customAmenities.splice(index, 1);

        this.props.updateParentState(branchList);
    }
    /**
     *
     * @param id
     * @param where
     */
    getNameById = (id, where) => {
        let x = this.state[where].filter(function (v) {
            return v.id === id; // Filter out the appropriate one
        })[0];
        if (x) {
            return x.name
        }
    };

    applyAllFromLocal = () => {
        const { applyToAll } = this.props;
        applyToAll(['amenities', 'customAmenities'], this.getAmenitiesLists);
    }
    componentWillMount = () => {
        this.getAmenitiesLists();
    }
    getAmenitiesLists = () => {
        let { Amenities, suggestedAmenities, defaultAmenities } = this.state;
        const { branchList, activeBranchKey } = this.props;
        let amenities = branchList[activeBranchKey].amenities;
        Object.keys(branchList).map(function (item) {
            suggestedAmenities[item] = [];
            return Amenities[item] = []
        })

        let Amenitiess = fire.firestore().collection('amenities');
        Amenitiess.get().then(snap => {
            snap.forEach(snapshot=>{
                let val = snapshot.data();
                val.id = snapshot.id;
                Object.keys(branchList).map(function (item) {
                        if (amenities.indexOf(snapshot.id) === -1) {
                            suggestedAmenities[item].push(val);
                            Amenities[item].push({
                                value: snapshot.id,
                                text: snapshot.data().name,
                            });
                        }    
                    return defaultAmenities.push(val)
                })
            })

            this.setState({
                Amenities: Amenities,
                defaultAmenities: defaultAmenities.slice(),
                suggestedAmenities: suggestedAmenities,
            });
        });
    }
    render = () => {
        let { branchList, activeBranchKey } = this.props;
        let amenities = branchList[activeBranchKey].amenities;

        return (
            <div>
                {this.renderFindAmenities()}
                {
                    (!amenities.length) ? (
                        this.renderNoAmenitiesSelected()
                    ) : (
                            this.renderSelectedAmenities()
                        )
                }
                {this.renderCustomAmenities()}
            </div>
        )
    }

    renderFindAmenities = () => {
        let { Amenities, canAdd, currentText } = this.state;
        let { activeBranchKey } = this.props;
        return (

            <div className="wizardMain">
                <div className="findInsurances">
                    <div className="inputWrap">
                        <div className="title flexBox align_justify">
                            <h2>Add Amenities</h2>
                        </div>
                        <Dropdown
                            style={{ width: '374px' }}
                            options={Amenities[activeBranchKey]}
                            selection
                            search
                            value={""}
                            selectOnBlur={false}
                            onChange={this.addAmenity}
                            placeholder="Type amenity name"
                        />
                    </div>
                </div>
            </div>
        )
    }
    renderNoAmenitiesSelected = () => {
        return (
            <div className="seleted-amenities gray-box section" style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                <div style={{ position: 'relative', fontSize: '18px', display: 'block', fontFamily: 'tfMedium' }}>
                    No Selected Amenities
                    </div>
            </div>


        )
    }
    renderSelectedAmenities = () => {
        let { branchList, activeBranchKey } = this.props;
        let amenities = branchList[activeBranchKey].amenities;
        return (
            <div className="seleted-amenities gray-box section" style={{ minHeight: '300px' }}>
                {/* <label className="section-title">Seleted Amenities</label> */}
                <div className="single-amenities marginTop30">
                    {
                        (amenities) ? (
                            amenities.map((item, index) => (
                                <button title={'Remove ' + this.getNameById(item, 'defaultAmenities')} onClick={this.removeSelectedAmenities.bind(this, item)} key={this.getNameById(item, 'defaultAmenities') + '_' + index} className="tag-button">
                                    {this.getNameById(item, 'defaultAmenities')}
                                    <i className="fas fa-times"></i>
                                </button>
                            ))
                        ) : ''
                    }
                </div>
            </div>
        )
    }
    renderCustomAmenities = () => {
        let { branchList, activeBranchKey } = this.props;
        let customAmenities = branchList[activeBranchKey].customAmenities;
        return (

            (customAmenities.length) ? (
                <div className="waiting-approval gray-box section">
                    <div className="inputWrap">
                        <label className="section-title">Waiting Approval</label>
                        <p>Your amenity eill be added to your facility as soon as site administration approve it.</p>
                        {
                            customAmenities.map((item, index) => (
                                <button title={'Remove ' + item} onClick={this.removeCustomAmenities.bind(this, item)} key={item + '_' + index} className="tag-button tag-btn-gray marginTop30">
                                    {item}
                                    <i className="fas fa-times"></i>
                                </button>
                            ))
                        }
                    </div>
                </div>
            ) : ''
        )
    }
}

export default Amenities