import fire from 'firebase/app'
import 'firebase/database';


export const fetchClinics = (userObject) => {
  return new Promise((resolve, reject) => {
    let data = [];
    let clinicsRef = fire.firestore().collection('clinics')
    if (userObject.accessType !== 'superAdmin') {
      let branches = userObject.user && userObject.user.info && userObject.user.info.accessClinic ? userObject.user.info.accessClinic : []
      clinicsRef = fire.firestore().collection('clinics').where('facilityId', 'in', branches)
    }
    clinicsRef.get().then(snapshot => {
      snapshot.forEach(item => {
        data.push({
          id: item.id,
          info: item.data()
        });
      })
      data.sort(function (a, b) {
        return (a.info.featured === b.info.featured) ? 0 : a.info.featured ? -1 : 1;
      });
      resolve(data);
    });
  });
};
