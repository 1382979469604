export const fetchFilterParams = () => {
    return new Promise( ( resolve, reject ) => {
        let params = {
            checkedTime: '',
            checkedFees: '',
        };
        resolve(params);
    })
};
export const SetFilterParams = (type) => {
    return new Promise( ( resolve ) => {
        resolve(type);
    })
};
