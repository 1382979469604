
import cIcon from '../img/clinic-f.png';
import hIcon from '../img/hospital-f.png';
export const GetFacilityType = () => {
    return new Promise( ( resolve, reject ) => {
        let data = {
            facilities: {
                Clinic: {
                    name: 'Clinic',
                    icon: cIcon,
                    selected: false
                },
                Hospital: {
                    name: 'Hospital',
                    icon: hIcon,
                    selected: false
                }
            },
        };
        resolve(data);
    })
};
export const SetFacilityType = (type) => {
    return new Promise( ( resolve, reject ) => {
        resolve(type);
    })
};
