import React, { Component } from 'react';
import 'firebase/database';
import 'firebase/auth';
import Swal from 'sweetalert2'
import FMSHeader from '../Header/defaultHeader';
import home from '../../img/thriveFmsLogo.png';
import HomeLogin from '../../Home Login/index'
import PatientsList from './patientsList';
import { Dimmer, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import alertCircle from '../../img/alert-circle.png';
import { connect } from "react-redux";
import { userActions } from '../../modules/user-module.js'

class WizardClinic extends Component {

    state = {
        suggestionId: null,
        // For Select Facility Component
        branches: 1,
        facilityType: 'Facility',
        readyToStart: false,
        draftList: null,
        selectFacility: null,
        loading: true,
        showMenu: window.screen.width <= 1026 ? false : true

    }

    constructor(props, context) {
        super(props, context);
    }

    componentWillMount() {
        let { branches } = this.props

        window.scrollTo(0, 0);
        let { accessScreens } = this.props.user.data ? this.props.user.data : {}
        let query = null;
        if (this.props.location.search) {
            let querySearch = this.props.location.search;
            query = querySearch.replace('?claim=', '');
        } else {
            query = this.props.location.pathname.split('=')[1];
        }


        this.setState({
            suggestionId: query,
            allowed: accessScreens.includes('fms'),
            loading: false,
        })
    }

    handleFacilityClick = bool => {
        let _this = this;
        if (!this.state.selectFacility) return;
        Swal.fire({
            heightAuto: false,
            title: 'Are you sure?',
            text: "Changes you did will be lost if you didn't save",
            // type: 'success',
            confirmButtonText: 'Discard changes',
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            allowOutsideClick: false
        }).then(function (result) {
            if (result.value) {
                _this.setState({
                    selectFacility: false,
                    readyToStart: false,
                    draftList: null
                });
            }
        })
    }

    showMenuFunc = () => {
        let { showMenu } = this.state;
        this.setState({
            showMenu: !showMenu,
        })
    }
    render() {
        let { loading, showMenu, allowed } = this.state;
        let { loggedIn, user, modalLoginIsOpen, accessType } = this.props.user.data ? this.props.user.data : {}

        return (
            (loggedIn && user) ?
                <div className="bootstrap">
                    <section id="main-wrapper" >
                        <div className="container-fluid">
                            <div className="row" style={{ overflow: 'hidden' }}>
                                <div className="col-1 side-nav" style={{ display: showMenu ? 'block' : 'none' }}>
                                    <ul>
                                        <li style={{ height: '60px' }}><a href="/#/"><img src={home} /></a></li>
                                        <li ><Link to="/dashboard"><i className="fab fa-buromobelexperte"></i>Dashboard</Link></li>
                                        <li ><Link to="/facility-management-system"><i className="far fa-hospital"></i>Facilities</Link></li>
                                        <li ><Link to="/wizard-doctor"><i className="fas fa-user-md"></i>Providers</Link></li>
                                        <li ><Link to="/appointment-system"><i className="far fa-calendar-alt"></i>Calendar</Link></li>
                                        <li onClick={this.handleFacilityClick} style={{ borderLeft: '3px solid #0096FD' }}><a><i style={{ color: '#0096FD' }} className="fas fa-users"></i>Patients</a></li>
                                        <li ><Link to="/history"><i className="fa fa-history"></i>History</Link></li>
                                        <li ><Link to="/feedback"><i className="fa fa-star"></i>Feedback</Link></li>
                                        {
                                            accessType === 'superAdmin' ?
                                            (
                                                process.env.REACT_APP_NAME !== 'saeed' ?
                                                    <li ><Link to="/helpers"><i className="fas fa-database"></i>Helpers</Link></li> : '')
                                                : ''
                                        }
                                        <li ><Link to="/settings"><i className="fas fa-cogs"></i>Settings</Link></li>

                                    </ul>

                                </div>
                                <div className="col-11 main-body" style={{ width: '100%', maxWidth: !showMenu ? 'none' : '' }}>
                                    <FMSHeader showMenuFunc={this.showMenuFunc} query={this.props.location.search} addFacility={() => this.setState({ readyToStart: true, selectFacility: {} })} title="Patients" />
                                    {
                                        (loggedIn && user) ? (
                                            allowed ?
                                                this.renderContent()
                                                :
                                                this.renderAccessDenied()
                                        ) : <div className="main-side">Please login</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                : loading ?
                    <Dimmer active>
                        <Icon.Group size='big'>
                            <Icon loading size='big' name='spinner' />
                            <div style={{ fontSize: '16px', padding: '10px', fontStyle: 'initial' }}>loading</div>
                        </Icon.Group>
                    </Dimmer>
                    :
                    <HomeLogin onClose={this.toggleModalLogin} show={modalLoginIsOpen} getUser={() => this.props.getUser} />

        );

    }
    /*
     * Functions for content getting
     * */
    renderAccessDenied() {
        return (
            <div style={{ height: 'calc( 100% - 60px )' }}>
                <div className="emptystate">
                    <div className="emptystate1">
                        <img src={alertCircle} alt="Img" />
                        <div className="textMesg">No Permission</div>
                        <div className="detailMesg">Contact your admin to access this page</div>
                    </div>
                </div>
            </div>
        )
    }
    renderContent = () => {
        let { allBookings, allPatients } = this.props
        return (
            <PatientsList
                selectFacility={
                    {
                        bookings: allBookings,
                    }
                }
                allPatients={allPatients}
                loading={this.state.loading}
                addFacility={() => this.setState({ readyToStart: true, selectFacility: null })}
            /> 
        )
    }

}
const mapStateToProps = ({ user, branches, specialties, Bookings }) => {
    return {
        user,
        allBookings: Bookings?.allBookings || [],
        branches: branches ? branches.data : {},
        specialties: specialties ? specialties.data : {},
        allPatients: Bookings.allUsers || {}
    };
};
const mapActionsToProps = (dispatch) => ({
    getUser: () => dispatch(userActions.getUser()),
    updateBranches: (clinic, oldList) => dispatch(userActions.updateBranches(clinic, oldList)),
    // getOrderListSuccess: (updatedList) => dispatch(getOrderListSuccess(updatedList)),
});
export default connect(
    mapStateToProps,
    mapActionsToProps
)(WizardClinic);