import noPhoto from '../img/no_photo.jpg';

/**
 *
 * @param Text
 * @returns {string}
 */
export const convertToSlug = (Text) => {
    return Text
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
        ;
};
/**
 *
 * @param source
 * @param id
 * @returns {boolean}
 */
export const findByIdAds = (source, id) => {
    for (let i = 0; i < source.length; i++) {
        if (source[i].info.clinicId === id && source[i].info.endDate > Date.now()) {
            if (source[i].info.active === true)
                return true;
        }
    }
    return false;
};
/**
 *
 * @param array
 * @returns {*}
 */
export const shuffle = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};
/**
 *
 * @param number
 * @returns {string}
 */
export const getDayName = (number) => {
    let days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    return days[number];
};
/**
 *
 * @param key
 * @param sourceURL
 * @returns {*}
 */
export const removeParam = (key, sourceURL) => {
    let rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (let i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        rtn = rtn + "" + params_arr.join("&");
    }
    return rtn;
}

/**
 *
 * @param time
 * @returns {string}
 */
export function replaceTime(time) {
    time = time.split(":");
    return time[0] + ':' + time[1] + ' ' + time[2];

}
/**
 *
 * @param value
 * @param myArray
 * @param key
 * @returns {*}
 */
export function searchInArrayWithObjects(value, key, myArray) {
    for (let i = 0; i < myArray.length; i++) {
        if (myArray[i][key].toLowerCase().replace(/\s/g, '') === value.toLowerCase().replace(/\s/g, '')) {
            return true;
        }
    }
}
/**
 *
 * @param value
 * @param myArray
 * @returns {boolean}
 */
export function searchCustomInsurance(value, myArray) {
    for (let i = 0; i < myArray.length; i++) {
        if (myArray[i][0].toLowerCase().replace(/\s/g, '') === value.toLowerCase().replace(/\s/g, '')) {
            return true;
        }
    }
}
/**
 *
 * @param value
 * @param key
 * @param myArray
 * @returns {number}
 */
export function searchKeyInArrayWithObjects(value, key, myArray) {
    for (let i = 0; i < myArray.length; i++) {
        if (myArray[i][key].toLowerCase().replace(/\s/g, '') === value.toLowerCase().replace(/\s/g, '')) {
            return i;
        }
    }
}
/**
 *
 * @param value
 * @param myArray
 * @returns {boolean}
 */
export function searchKeySelectedInsurances(value, myArray) {
    for (let i = 0; i < myArray.length; i++) {
        // console.log('---', myArray[i][0])
        if (myArray[i][0] === value) {
            return true;
        }

    }
}
/**
 *
 * @param arr
 * @returns {{}}
 */
export function toObjectIns(arr) {
    let rv = {};
    for (let i = 0; i < arr.length; ++i)
        rv[arr[i]] = false;
    return rv;
}
/***
 *
 * @param obj
 * @returns {*}
 */
export function clone(obj) {
    if (null == obj || "object" != typeof obj) return obj;
    let copy = obj.constructor();
    for (let attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
}
/**
 *
 * @param email
 * @returns {boolean}
 */
export const validateEmail = (email) => {
    if (email.length === 0){
        return false
    }
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};
/**
 *
 * @param value
 * @param clinics
 * @returns {Array}
 */
export const getSuggestions = (value, clinics) => {
    const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    if (escapedValue === '') {
        return [];
    }

    const regex = new RegExp('^' + escapedValue, 'i');

    return clinics.filter(clinic => regex.test(clinic.name));
}
/**
 *
 * @param url
 * @returns {*}
 */
export const checkPhoto = (url) => {
    if (url)
        return (url.length > 0) ? url : noPhoto;
    else
        return noPhoto
};
/**
 *
 * @param days
 * @returns {{}}
 * @constructor
 */
export const WeekSorting = (days) => {
    let sorter = {
        "SAT": 1,
        "SUN": 2,
        "MON": 3,
        "TUE": 4,
        "WED": 5,
        "THU": 6,
        "FRI": 7,
    };

    let tmp = [];
    Object.keys(days).forEach(function (key) {
        let value = days[key];
        let index = sorter[key];
        tmp[index] = {
            key: key,
            value: value
        };
    });

    let orderedWeek = {};
    tmp.forEach(function (obj) {
        orderedWeek[obj.key] = obj.value;
    });
    return orderedWeek;
};
/**
 *
 * @param obj
 * @returns {number}
 */
Object.size = function (obj) {
    let size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key) && obj[key].length > 0 && obj[key][0].length > 0) {
            size++;
        }
    }
    return size;
}
/**
 *
 * @type {[*]}
 */
export const countries = [
    'OMN 968',
    'UAE 971',
];

/**
 *
 * @type {[*]}
 */
export const codes = [
    '800',
];
export const codesMobile = [
    '50',
    '52',
    '55',
    '56',
    '54',
    '58',
];

export const currency = [
    'OMR',
    'AED',
];

/**
 *
 * @type {[*]}
 */
export const codesLandline = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
];
/**
 *
 * @type {[*]}
 */
export const nationalities = [
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivan",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "New Zealander",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian or Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean"
]