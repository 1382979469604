import React, { Component } from 'react';
import { Button, Dropdown, Input } from 'semantic-ui-react'
import './DoctorDetailsPopup.css'
import swal from 'sweetalert2';
import '../sweetalert.css'
import { firestore } from 'firebase';
import { Object } from 'core-js';

const hours = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
]
const minutes = [
    '00',
    '05',
    '10',
    '15',
    '20',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '55',
]
const avrageTimeOptions = [
    { key: 0, value: '15 min', text: '15 min', minsValue: 15 },
    { key: 1, value: '30 min', text: '30 min', minsValue: 30 },
    { key: 2, value: '45 min', text: '45 min', minsValue: 45 },
    { key: 3, value: '1 hour', text: '1 hour', minsValue: 60 },
    { key: 4, value: '1 hour 15 min', text: '1 hour 15 min', minsValue: 75 },
    { key: 5, value: '1 hour 30 min', text: '1 hour 30 min', minsValue: 90 },
    { key: 6, value: '1 hour 45 min', text: '1 hour 45 min', minsValue: 105 },
    { key: 7, value: '2 hour', text: '2 hour', minsValue: 120 },
    { key: 8, value: '2 hour 15 min', text: '2 hour 15 min', minsValue: 135 },
    { key: 9, value: '2 hour 30 min', text: '2 hour 30 min', minsValue: 150 },
    { key: 10, value: '2 hour 45 min', text: '2 hour 45 min', minsValue: 165 },
    { key: 11, value: '3 hour', text: '3 hour', minsValue: 180 },


]
class DoctorPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSelectedTime: null,
            currentSelectedMinute: null,
            currentSelectedHour: null,
            currentSelectedDay: false,
            nonSelectedError: false,
            nonSelectedTimeError: false,
            am_pm: 'am',
            splitNow: false,
            clocks: {
                from: {
                    hour: '00',
                    minute: '00',
                    am_pm: 'am',
                    has_set: false
                },
                to: {
                    hour: '00',
                    minute: '00',
                    am_pm: 'pm',
                    has_set: false
                },
                split_from: {
                    hour: '00',
                    minute: '00',
                    am_pm: 'am',
                    has_set: false
                },
                split_to: {
                    hour: '00',
                    minute: '00',
                    am_pm: 'pm',
                    has_set: false
                },

            },
            step: 1,
            doctorData: JSON.parse(JSON.stringify(this.props.doctorData)),
        }
    }

    selectDay = (name) => {
        let { doctorData, serviceObject } = this.state;
        let hasSelectDay = false;

        let myList = serviceObject.doctorTimings;

        if (!myList[name]) {
            myList[name] = { selected: false }
        }
        if (!(myList[name].opened)) {
            myList[name].selected = !myList[name].selected;
            // this.props.updateParentState(doctorData);
        }
        Object.keys(myList).forEach(function (day) {
            if (myList[day].selected) {
                hasSelectDay = true;
            }
        })

        this.setState({
            currentSelectedDay: hasSelectDay,
            nonSelectedError: false,
            currentText: '',
            serviceObject
        })
    };
    selectAmPm = (am_pm) => {
        this.setState({
            am_pm: am_pm
        })
    }
    splitToggle = () => {
        let { splitNow } = this.state;
        this.setState({ splitNow: !splitNow })
    }
    selectTimeItems = (which, key) => {
        this.setState({
            [which]: key
        })
    }
    resetSelected = () => {
        this.setState({
            currentSelectedMinute: null,
            currentSelectedHour: null,
            am_pm: 'am'
        })
    }
    selectTime = (each) => {

        this.setState({
            currentSelectedTime: each
        }, () => {
            if (this.state.currentSelectedTime !== null) {
                // attach/remove event handler
                document.addEventListener('click', this.handleOutsideClick, false);
            } else {
                document.removeEventListener('click', this.handleOutsideClick, false);
            }
            this.resetSelected()
        })
    }
    setSelectedTime = () => {
        const { currentSelectedTime, currentSelectedMinute, currentSelectedHour, am_pm } = this.state;

        this.setState(prevState => ({
            clocks: {
                ...prevState.clocks,
                [currentSelectedTime]: {
                    hour: currentSelectedHour,
                    minute: currentSelectedMinute,
                    am_pm: am_pm,
                    has_set: true
                }
            },
            currentSelectedTime: null
        }), () => {
            this.resetSelected();
        })
    }
    addTimeToDay = () => {
        let { clocks, splitNow, currentSelectedDay } = this.state;
        let { serviceObject } = this.state;
        let allDays = serviceObject.doctorTimings
        let haveTimeErrors = false;
        if (currentSelectedDay) {
            Object.keys(allDays).map(function (day) {
                let curDay = allDays[day];

                if (allDays[day].selected) {
                    haveTimeErrors = splitNow ? (!clocks.from.has_set || !clocks.to.has_set || !clocks.split_from.has_set || !clocks.split_to.has_set) : (!clocks.from.has_set || !clocks.to.has_set);

                    if (!haveTimeErrors) {
                        let from = clocks.from.hour + ':' + clocks.from.minute + ':' + clocks.from.am_pm;
                        let to = clocks.to.hour + ':' + clocks.to.minute + ':' + clocks.to.am_pm;
                        let split_from = clocks.split_from.hour + ':' + clocks.split_from.minute + ':' + clocks.split_from.am_pm;
                        let split_to = clocks.split_to.hour + ':' + clocks.split_to.minute + ':' + clocks.split_to.am_pm;
                        curDay.isSplitShift = splitNow;
                        curDay.fromTime = from;
                        curDay.toTime = to;
                        curDay.pmFromTime = split_from;
                        curDay.pmToTime = split_to;
                    }

                    if (!haveTimeErrors) {
                        curDay.fixed = curDay.opened = true;
                        curDay.selected = false;
                    }
                }
                return ''
            });


            if (!haveTimeErrors) {

                clocks.from = clocks.to = clocks.split_from = clocks.split_to =
                {
                    hour: '00',
                    minute: '00',
                    am_pm: 'am',
                    has_set: false
                }
                this.setState({
                    currentSelectedDay: false,
                    splitNow: false,
                    clocks: clocks,
                })
            }
        }

        this.setState({
            nonSelectedError: !currentSelectedDay,
            nonSelectedTimeError: haveTimeErrors,
        })
    }
    renderCustomTimes = () => {
        const _this = this;
        let { currentSelectedTime, currentSelectedMinute, currentSelectedHour, am_pm, clocks, splitNow } = this.state;
        return (
            <div className="wizardMain">
                <div className="timings">
                    <div className="typeList" style={{ paddingTop: "0px", paddingLeft: '3%' }}>

                        <div className="customTimes flexBox align_justify_left" ref={node => this.node = node} >
                            <div className="defaultTime flexBox">
                                <div className="select flexBox" style={{ width: '160px' }} data-selected={currentSelectedTime === 'from' || clocks.from.has_set} onClick={this.selectTime.bind(this, 'from')}>
                                    <span className="title">From <span> *</span></span>
                                    <div className="selected flexBox">
                                        <div className="slTime" style={{ width: '61%' }}>
                                            <span>{clocks.from.hour}</span>
                                            <span>{clocks.from.minute}</span>
                                        </div>
                                        <span className="dt">{clocks.from.am_pm}</span>
                                        <span className="clock" />
                                    </div>
                                    <div className="placeholder flexBox">
                                        <span className="plcText">Select Time</span>
                                        <span className="clock" />
                                    </div>
                                </div>
                                <div className="select flexBox" style={{ width: '160px' }} data-selected={currentSelectedTime === 'to' || clocks.to.has_set} onClick={this.selectTime.bind(this, 'to')}>
                                    <span className="title">To <span> *</span></span>
                                    <div className="selected flexBox">
                                        <div className="slTime flexBox" style={{ width: '61%' }}>
                                            <span>{clocks.to.hour}</span>
                                            <span>{clocks.to.minute}</span>
                                        </div>
                                        <span className="dt">{clocks.to.am_pm}</span>
                                        <span className="clock" />
                                    </div>
                                    <div className="placeholder flexBox">
                                        <span className="plcText">Select Time</span>
                                        <span className="clock" />
                                    </div>
                                </div>
                            </div>
                            {
                                splitNow ? (
                                    <div className="splitTime flexBox">
                                        <div className="select flexBox" style={{ width: '160px' }} data-selected={currentSelectedTime === 'split_from' || clocks.split_from.has_set} onClick={this.selectTime.bind(this, 'split_from')}>
                                            <span className="title">From <span> *</span></span>
                                            <div className="selected flexBox">
                                                <div className="slTime" style={{ width: '61%' }}>
                                                    <span>{clocks.split_from.hour}</span>
                                                    <span>{clocks.split_from.minute}</span>
                                                </div>
                                                <span className="dt">{clocks.split_from.am_pm}</span>
                                                <span className="clock" />
                                            </div>
                                            <div className="placeholder flexBox">
                                                <span className="plcText">Select Time</span>
                                                <span className="clock" />
                                            </div>
                                        </div>
                                        <div className="select flexBox" style={{ width: '160px' }} data-selected={currentSelectedTime === 'split_to' || clocks.split_to.has_set} onClick={this.selectTime.bind(this, 'split_to')}>
                                            <span className="title">To <span> *</span></span>
                                            <div className="selected flexBox">
                                                <div className="slTime flexBox" style={{ width: '61%' }}>
                                                    <span>{clocks.split_to.hour}</span>
                                                    <span>{clocks.split_to.minute}</span>
                                                </div>
                                                <span className="dt">{clocks.split_to.am_pm}</span>
                                                <span className="clock" />
                                            </div>
                                            <div className="placeholder flexBox">
                                                <span className="plcText">Select Time</span>
                                                <span className="clock" />
                                            </div>
                                        </div>
                                    </div>
                                ) : ''
                            }
                            <div className="addSplit" onClick={this.splitToggle}>
                                <span>{!splitNow ? 'Split Shift' : 'One Shift'}</span>
                            </div>


                            {
                                currentSelectedTime ? (
                                    <div data-footer={currentSelectedHour !== null && currentSelectedMinute !== null} className={['popupCalendar', currentSelectedTime].join(' ')}>
                                        <div onClick={() => { this.selectTime(null) }} className="close">X</div>
                                        <div className="fulK">
                                            <div className="head flexBox align_justify">
                                                <span>Hour</span>
                                                <span>Minute</span>
                                            </div>
                                            <div className="body flexBox align_justify">
                                                <div className="left">
                                                    {
                                                        (currentSelectedHour && currentSelectedMinute) ? (
                                                            <div className="choosed">
                                                                <span>{currentSelectedHour}</span>
                                                            </div>
                                                        ) : (
                                                            <ul className="items flexBox align_justify">
                                                                {
                                                                    hours.map(function (item) {
                                                                        return (
                                                                            <li data-active={item === currentSelectedHour} onClick={_this.selectTimeItems.bind(this, 'currentSelectedHour', item)} key={item} >{item}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        )
                                                    }
                                                </div>
                                                <div className="center flexBox">
                                                    <div data-active={am_pm === 'am'} className="day" onClick={this.selectAmPm.bind(this, 'am')}>
                                                        <span>AM</span>
                                                    </div>
                                                    <div data-active={am_pm === 'pm'} className="day" onClick={this.selectAmPm.bind(this, 'pm')}>
                                                        <span>PM</span>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    {
                                                        (currentSelectedMinute && currentSelectedHour) ? (
                                                            <div className="choosed">
                                                                <span>{currentSelectedMinute}</span>
                                                            </div>
                                                        ) : (
                                                            <ul className="items flexBox align_justify">
                                                                {
                                                                    minutes.map(function (item) {
                                                                        return (
                                                                            <li data-active={item === currentSelectedMinute} onClick={_this.selectTimeItems.bind(this, 'currentSelectedMinute', item)} key={item} >{item}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        )
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (currentSelectedHour && currentSelectedMinute) ? (
                                                <div className="footerBack flexBox">
                                                    <div onClick={() => { this.resetSelected() }} className="stButt">
                                                        <span>Cancel</span>
                                                    </div>
                                                    <div className="stButt" onClick={this.setSelectedTime}>
                                                        <span>Set</span>
                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                    </div>
                                ) : ''
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderActivateService = () => {
        let { doctorData, allServices, servicesOptions } = this.state;
        let { profilePicture, name, specNames } = doctorData
        return (
            <div className="innerCard" style={{ padding: '0 32px ' }}>
                <div style={{ padding: '16px 0', display: 'flex', alignItems: 'center', borderBottom: '1px solid #cacaca' }}>

                    <div className="doctorHeader">
                        <h3>Activate services for online booking</h3>
                        {/* <p>Add the available timings where patient can book with doctors</p> */}
                    </div>
                    <div style={{ color: 'red', cursor: 'pointer', position: 'relative', marginRight: '10px' }} onClick={() => this.setState({ step: 1 }, () => this.props.dismiss())}>
                        <div style={{ textAlign: 'center', fontWeight: '800', fontSize: '20px' }}>
                            x
                        </div>
                    </div>
                </div>

                <div className="basicInfo">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img style={{ width: '50px', height: '50px', borderRadius: '50%', margin: '10px 10px 10px 0' }} src={profilePicture}></img>
                        <div>
                            <div style={{ fontSize: '16px', fontWeight: '600' }}>
                                {name}
                            </div>
                            <div>
                                {specNames.join(',')}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%' }}>
                    <div style={{ borderRadius: '8px', border: '1px solid #cacaca' }}>
                        <div style={{ borderBottom: '1px solid #cacaca', fontWeight: '600', fontSize: '16px', padding: '16px' }}>
                            Services
                        </div>
                        <div style={{ padding: '20px' }}>
                            {
                                allServices && servicesOptions && Object.keys(allServices).length ?
                                    Object.keys(allServices).map(serviceKey => {
                                        let serviceObject = allServices[serviceKey];
                                        let service = servicesOptions.find(item => item.key === serviceKey)
                                        return (
                                            <div key={serviceKey} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px 0' }}>
                                                <div style={{ width: '25%' }}>{service?.value}</div>
                                                <div>{serviceObject?.fees}</div>
                                                <div>
                                                    <Button onClick={() => this.setState({ serviceObject, activeService: service.key, step: 2 })}>Edit</Button>
                                                    <Button onClick={() => {
                                                        swal.fire({
                                                            heightAuto: false,
                                                            title: 'Are you Sure?',
                                                            showCancelButton: true,
                                                            html: "<div class='appointmentDate'> <div class='text'>You are about to deactivate online booking for this service. Do you want to Proceed?</div></div>",
                                                            customClass: 'reschedulesweetalert',
                                                            cancelButtonText: 'cancel',
                                                            confirmButtonText: 'Proceed',
                                                        }).then((result) => {
                                                            if (result.value) {
                                                                firestore().collection('doctors').doc(doctorData.doctorId || doctorData.id).collection('services').doc(doctorData.selectedClinicId).update({
                                                                    [service.key]: firestore.FieldValue.delete()
                                                                }).then(() => {
                                                                    delete allServices[service.key]
                                                                    this.setState({ allServices })
                                                                })
                                                            }
                                                        });
                                                    }}>Deactivate</Button>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    'no services'
                            }
                        </div>
                    </div>
                </div>
                <div style={{ padding: '20px 0' }}>
                    <Button color='blue' onClick={() => this.setState({ step: 2 })}>Add new</Button>
                </div>
            </div>
        )
    }
    renderTimingsList = () => {
        let { serviceObject } = this.state;
        if (!serviceObject.doctorTimings) {
            serviceObject.doctorTimings = {
                'SAT': {},
                'SUN': {},
                'MON': {},
                'TUE': {},
                'WED': {},
                'THU': {},
                'FRI': {}
            }
        }

        let doctorClinicTimings = serviceObject.doctorTimings;
        let weekDays = ["SAT", "SUN", "MON", "TUE", "WED", "THU", "FRI"];

        return (
            <div className="opening-hour gray-box section" style={{ border: 'none' }}>
                <h2 className="section-title" style={{ padding: '10px' }}>Service Hours</h2>
                <div className="table-responsive">
                    <table className="table">{
                        weekDays.map((item, index) => (
                            (doctorClinicTimings[item] && doctorClinicTimings[item].opened) ? (
                                <tbody key={item}>
                                    <tr>
                                        <td>{item}</td>
                                        {
                                            (!doctorClinicTimings[item].isTwentyFour) ? (
                                                <td>
                                                    {doctorClinicTimings[item].fromTime} to {doctorClinicTimings[item].toTime}
                                                </td>
                                            ) : (
                                                <td>Full Day</td>
                                            )
                                        }
                                        {
                                            (doctorClinicTimings[item].isSplitShift && !doctorClinicTimings[item].isTwentyFour) ? (
                                                <td>
                                                    {doctorClinicTimings[item].pmFromTime} to {doctorClinicTimings[item].pmToTime}
                                                </td>
                                            ) : <td></td>
                                        }
                                        <td style={{ color: 'darkred' }} onClick={this.removeTimeFromDay.bind(this, item)}><i className="fa fa-close"></i></td>
                                    </tr>
                                </tbody>
                            ) : null
                        ))}</table>
                </div>
            </div>
        )
    }
    removeTimeFromDay = (name) => {
        let { currentText, serviceObject } = this.state;
        let myList = serviceObject.doctorTimings;
        myList[name] = {
            selected: false,
            opened: false,
            fixed: false,
            isTwentyFour: false,
            fromTime: false,
            toTime: false,
            isSplitShift: false,
            pmFromTime: false,
            pmToTime: false,
        }
        this.setState({ serviceObject })
        // this.props.updateParentState(doctorData);
    };

    handleSelection = (e, { value }) => {
        let { serviceObject } = this.state
        let selected = avrageTimeOptions.find(item => {
            return (item.value === value)
        })
        serviceObject.duration = selected.minsValue
        this.setState({ serviceObject })
        // this.props.updateParentState(doctorData);
    }
    handleFeesChange = (e, { value }) => {
        let { serviceObject } = this.state
        serviceObject.fees = Number(value)
        this.setState({ serviceObject })
        // this.props.updateParentState(doctorData);

    }
    getServicesOptions = (services) => {
        let _this = this
        let allServices = Object.keys(services).map(async (serviceKey) => {
            let service = await firestore().collection('services').doc(serviceKey).get();
            if (service && service.data()) {
                return ({ key: serviceKey, value: service.data().name, text: service.data().name })
            }
        })
        Promise.all(allServices).then((results) => {
            let array = [
                { key: 'inpersonConsult', value: 'In Clinic Consultation', text: 'In Clinic Consultation' },
                { key: 'onlineConsult', value: 'Online Consultation', text: 'Online Consultation' }
            ]
            array = array.concat(results)
            _this.setState({ servicesOptions: array })
        })
    }
    componentDidMount() {
        let { doctorData } = this.state;
        let { doctorId, selectedClinicId, id } = doctorData
        firestore().collection('doctors').doc(doctorId || id).collection('services').doc(selectedClinicId).get().then((results) => {
            let data = results.data()
            if (data) {
                this.setState({ allServices: data, serviceObject: {} })
            } else {
                let serviceObject = {
                    doctorTimings: doctorData.doctorTimings ? doctorData.doctorTimings[doctorData.selectedClinicId] : {},
                }
                this.setState({ serviceObject })
            }
        }).catch((error) =>
            console.log('error', error))
        this.getServicesOptions(doctorData.services)
    }

    render() {
        let weekDays = ["SAT", "SUN", "MON", "TUE", "WED", "THU", "FRI"];
        let { doctorData, servicesOptions, activeService, step, serviceObject, allServices } = this.state
        if (!serviceObject) {
            return ('')
        }
        if (!serviceObject.doctorTimings) {
            serviceObject.doctorTimings = {}
        }

        if (!serviceObject.doctorTimings) {
            serviceObject.doctorTimings = {
                'SAT': {},
                'SUN': {},
                'MON': {},
                'TUE': {},
                'WED': {},
                'THU': {},
                'FRI': {}
            }
        }
        let doctorClinicTimings = serviceObject.doctorTimings;

        var showTimePicker = false
        var showTimingList = false
        weekDays.map((item) => (
            (doctorClinicTimings[item] && doctorClinicTimings[item].selected) ?
                showTimePicker = true
                : (doctorClinicTimings[item] && doctorClinicTimings[item].opened) ?
                    showTimingList = true
                    : ''
        ))


        return (
            <div className="DoctorPopup">
                {
                    step === 1 ?
                        this.renderActivateService()
                        :
                        <div className="innerCard">
                            <div style={{ color: 'red', justifyContent: 'flex-end', display: 'flex' }} onClick={() => this.props.dismiss()}>
                                <div style={{ width: '5%', textAlign: 'center', fontWeight: '800', fontSize: '20px' }}>
                                    x
                                </div>
                            </div>
                            <div className="doctorHeader">
                                <h3>Doctor's timings</h3>
                                <p>Add the available timings where patient can book with doctors</p>
                            </div>
                            <div className="basicInfo">
                                <div className="inputFields" >
                                    <div className="title">Provider</div>
                                    <Dropdown selection text={doctorData.name} style={{ width: '100%', marginTop: '10px' }} />
                                </div>
                                <div className="inputFields" >
                                    <div className="title">Service</div>
                                    <Dropdown
                                        selection
                                        style={{ width: '100%', marginTop: '10px' }}
                                        options={servicesOptions}
                                        text={servicesOptions.find(item => item.key === activeService)?.value}
                                        onChange={(e, { value, options }) => {
                                            let selectedOption = options.find((item) => item.value === value)
                                            this.setState({ activeService: selectedOption.key })
                                        }
                                        } />
                                </div>
                            </div>
                            <div className="basicInfo">
                                <div className="inputFields">
                                    <div className="title">Slot duration</div>
                                    {
                                        <Dropdown selection error={!serviceObject.duration} style={{ width: '100%', marginTop: '10px' }} text={serviceObject.duration ? serviceObject.duration : "Please select average wating time"} options={avrageTimeOptions} onChange={this.handleSelection} />
                                    }
                                </div>
                                <div className="inputFields">

                                    <div className="title">Fees</div>
                                    {
                                        !doctorData.bookingActive || !doctorData.bookingActive[doctorData.selectedClinicId] ?
                                            <Input error placeholder={`100 ${process.env.REACT_APP_CURRNECY}`} style={{ width: '100%', marginTop: '10px' }} type={'number'} onChange={this.handleFeesChange} />
                                            :
                                            <Input value={serviceObject.fees} type={'number'} style={{ width: '100%', marginTop: '10px' }} onChange={this.handleFeesChange} />
                                    }
                                </div>
                            </div>
                            <div className="basicInfo" style={{ display: 'block' }}>
                                <div className="inputFields">
                                    <div className="title">Week days</div>
                                </div>
                                <div className="all-days">
                                    {
                                        weekDays.map((item, index) => (
                                            (doctorClinicTimings[item] && doctorClinicTimings[item].opened) ? (
                                                <button key={index} className="color-button" onClick={this.selectDay.bind(this, item)}>
                                                    {item}
                                                </button>
                                            ) : (doctorClinicTimings[item] && doctorClinicTimings[item].selected) ? (
                                                <button key={index} className="border-button" onClick={this.selectDay.bind(this, item)}>
                                                    {item}
                                                </button>
                                            ) :
                                                (<button key={index} className="border-button-notselected" onClick={this.selectDay.bind(this, item)}>
                                                    {item}
                                                </button>)
                                        ))
                                    }
                                </div>
                            </div>
                            {
                                showTimePicker ?
                                    <div style={{ position: 'relative', paddingTop: '25px', height: '100px' }}>
                                        {this.renderCustomTimes()}
                                        <div style={{ paddingLeft: '3.5%' }}>
                                            <button className="set-button" onClick={this.addTimeToDay}>Set</button>
                                        </div>
                                    </div> : ''
                            }
                            {
                                (showTimingList) ?
                                    this.renderTimingsList()
                                    : ''
                            }
                            {
                                (serviceObject.duration && serviceObject.fees && showTimingList && activeService) ?
                                    <div style={{ position: 'relative', width: '12%', height: '50px', float: 'right' }}>
                                        <button className="set-button" style={{ position: 'relative', width: '100%%' }} onClick={() => {
                                            firestore().collection('doctors').doc(doctorData.doctorId || doctorData.id).collection('services').doc(doctorData.selectedClinicId).set({
                                                [activeService]: serviceObject
                                            }, { merge: true }).then(() => {
                                                let minFees = serviceObject.fees
                                                if (allServices) {
                                                    Object.keys(allServices).map(rec => {
                                                        let service = allServices[rec]
                                                        if (service.fees < minFees) {
                                                            minFees = service.fees
                                                        }
                                                        return false
                                                    })
                                                }
                                                firestore().collection('doctors').doc(doctorData.doctorId || doctorData.id).update({
                                                    [`bookingActive.${doctorData.selectedClinicId}`]: minFees
                                                }).then(() => {
                                                    this.props.save()
                                                })
                                            })
                                        }}>Save</button>
                                    </div>
                                    : <div style={{ position: 'relative', width: '12%', height: '50px', float: 'right' }}>
                                        <button className="set-button" style={{ position: 'relative', width: '100%%', opacity: '0.5' }} onClick={() => {
                                            swal.fire({
                                                heightAuto: false,
                                                title: 'Alert',
                                                showCancelButton: false,
                                                html: "<div class='appointmentDate'> <div class='text'>Please fill all requirments!</div></div>",
                                                customClass: 'reschedulesweetalert',
                                                confirmButtonText: 'Dismiss',
                                            }).then((result) => {
                                            });
                                        }}>Save</button>
                                    </div>
                            }
                        </div>

                }

            </div>
        )

    }

}
export default DoctorPopup