import React, { Component } from 'react';
import './filter.css'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { Input } from 'semantic-ui-react';
import DatePicker from "react-multi-date-picker"

import moment from 'moment'

const statusOptions = [
    { label: 'Accepted Appointments', value: 'accepted' },
    { label: 'Succeeded Appointments', value: 'succeeded' },
    { label: 'Canceled Appointments', value: 'canceled' },
    { label: 'Discharged Appointments', value: 'discharged' },
    // { label: 'Rejected Appointments', value: 'rejected' },
    // { label: 'Removed Appointments', value: 'removed' },
    { label: 'Failed Appointments', value: 'failed' },

]

const startDate = new Date();
startDate.setDate(1);
startDate.setMonth(startDate.getMonth());

class Filter extends Component {

    static propTypes = {
        clinicDoctors: PropTypes.array.isRequired,

    }

    constructor(props) {
        super(props);
        this.state = {
            bookingsStatus: 'all',
            doctors: [],
            dateRange: [startDate, new Date()],

        }
    }
    componentDidMount() {
        let { doctors ,dateRange} = this.state
        if (this.props.clinicDoctors) {
            this.props.clinicDoctors.map(item => {
                doctors.push({ label: item.name, value: item.name, key: item.id })
            })
            this.setState({ doctors })
        }
        this.props.dateRange(dateRange);

    }



    render() {
        return (
            <div style={{ padding: '40px' }}>
                {this.renderSendRequestSystem()}
            </div>
        )
    }

    handleChange(value, type) {
        let { allOrders } = this.state

        switch (type.name) {
            case 'doctor':
                let doctorsArray = []
                if (value !== null && value.length) {
                    value.map(item => {
                        doctorsArray.push(item)
                    })
                } else {
                    doctorsArray = []
                }
                this.props.doctorsArray(doctorsArray)


                break;

            case 'status':
                let statusArray = []
                if (value !== null && value.length) {
                    value.map(item => {
                        if (item.value === 'canceled') {
                            statusArray.push('canceled')
                            statusArray.push('rejected')
                            statusArray.push('removed')
                            //      statusArray.concat(['canceled', 'rejected', 'removed'])
                        }
                        statusArray.push(item.value)
                    })
                } else {
                    statusArray = ['accepted', 'succeeded', 'canceled', 'discharged', 'rejected', 'removed', 'failed']
                }
                this.props.statusArray(statusArray)
                break;

            default:
                break;
        }
    }
    searchFunction = (e) => {
        this.props.patientNumber(e.target.value)
    }
    renderSendRequestSystem() {
        let { dateRange } = this.state
        return (

            <div className="filtercss" style={{ boxShadow: '0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04)' }}>
                <div className="selectParent">
                    <DatePicker
                        value={dateRange}
                        range
                        onChange={(dates) => {
                            this.setState({ dateRange: dates }, () => {
                                this.props.dateRange(dates);
                            })
                        }}
                        maxDate={(moment(new Date(dateRange[0])).add(30, "days")).toDate()}
                    />

                </div>
                <div className="selectParent">
                    <Select
                        placeholder="Doctors"
                        isMulti
                        name="doctor"
                        options={this.state.doctors}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={this.handleChange.bind(this)}
                    />
                </div>
                <div className="selectParent">
                    <Input
                        placeholder="Patients Number"
                        searchInput='number'
                        type='number'
                        // value={number}
                        // text={number}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        style={{ width: '100%', borderColor: 'black' }}
                        onChange={this.searchFunction}
                    />
                </div>

                <div className="selectParent">
                    <Select
                        placeholder="Status"
                        isMulti
                        name="status"
                        options={statusOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={this.handleChange.bind(this)}
                    />
                </div>
            </div>
        )
    }


}

export default Filter