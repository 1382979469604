import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { countries, codesLandline, codes, validateEmail } from '../../../helpers/Full';
import { Input, Select } from 'semantic-ui-react';



class Location extends Component {
    static propTypes = {
        branchList: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        facilityType: PropTypes.string.isRequired,
        updateParentState: PropTypes.func.isRequired,
    }

    constructor(props, context) {
        super(props, context);
    }
    state = {
        // mapArabicSupport: !!this.props.branchList[this.props.activeBranchKey].address_ar.country.length
    }

    /**
     *
     * @param index
     * @param type
     * @param e
     */
    changeNumber = (index, type, e, { value }) => {
        // e.preventDefault()
        const { activeBranchKey } = this.props;
        let { branchList } = this.props;
        let val = value;
        let max = (type === 'landLineNumbers') ? 8 : 11;
        if (!isNaN(val)) {
            if (val.length < max && val < 9999999) {
                branchList[activeBranchKey][type][index].number = val;
                this.props.updateParentState(branchList);
            }
        } else {
            alert('please input number')
        }
    }
    /**
     *
     * @param index
     * @param type
     * @param e
     */
    changeCountry = (index, type, e, { value }) => {
        const { activeBranchKey } = this.props;
        let { branchList } = this.props;
        branchList[activeBranchKey][type][index].country = value;
        this.props.updateParentState(branchList);
    }
    /**
     *
     * @param index
     * @param type
     * @param e
     */
    changeCode = (index, type, e, { value }) => {
        const { activeBranchKey } = this.props;
        let { branchList } = this.props;
        branchList[activeBranchKey][type][index].code = value;
        this.props.updateParentState(branchList);
    }
    /**
     * New object for landline number
     */
    addLandLineNumber = () => {
        const { activeBranchKey } = this.props;
        let { branchList } = this.props;
        let numbers = branchList[activeBranchKey].landLineNumbers;
        if (numbers.length < 10) {
            numbers.push({
                country: 'OMN 968',
                code: '800',
                number: ''
            });
            this.props.updateParentState(branchList);
        }
    }
    /**
     * @param index
     */
    removeLandLineNumber = (index) => {
        const { activeBranchKey } = this.props;
        let { branchList } = this.props;
        let numbers = branchList[activeBranchKey].landLineNumbers;
        numbers.splice(index, 1);
        this.props.updateParentState(branchList);
    }
    /**
     *
     * @param e
     */
    changeFacilityEmail = (e) => {
        const { activeBranchKey } = this.props;
        let { branchList } = this.props;
        branchList[activeBranchKey].facilityEmail = e.target.value;
        this.props.updateParentState(branchList);
    }
    /**
     *
     */
    getCurrent = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.setMap);

        }
    }
    /**
     * Generate Map
     * @param position
     */
    setMap = (position) => {
        const _this = this;
        const { activeBranchKey } = this.props;
        let { branchList } = this.props;
        let { zoom, location } = branchList[activeBranchKey];
        let latC = location.latitude,
            lngC = location.longitude;
        if (position) {
            branchList[activeBranchKey].location = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            }
            latC = position.coords.latitude;
            lngC = position.coords.longitude;
            _this.props.updateParentState(branchList);

            _this.setPosNames(position.coords.latitude, position.coords.longitude, 'en')
            _this.setPosNames(position.coords.latitude, position.coords.longitude, 'ar')

        }

        let mapOptions = {
            zoom: zoom,
            scrollwheel: false,
            center:  new window.google.maps.LatLng(
                latC,
                lngC
            )
        };
        let map = new window.google.maps.Map(this.refs.mapC, mapOptions);
        let marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
                latC,
                lngC
            ),
            map: map
        });
        window.google.maps.event.addListener(map, "click", function (event) {

            let lat = event.latLng.lat(),
                lng = event.latLng.lng();

            branchList[activeBranchKey].location = {
                latitude: lat,
                longitude: lng
            }

            _this.props.updateParentState(branchList);

            map.panTo(event.latLng); //Make map global
            marker.setPosition(event.latLng);

            if (event.latLng) {
                _this.setPosNames(lat, lng, 'ar')
                _this.setPosNames(lat, lng, 'en')
            }

        });



        // Create the search box and link it to the UI element.
        let input = _this.refs.pacInput;
        // console.log('---', _this.refs);
        let searchBox = new window.google.maps.places.SearchBox(input);
        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

        // Bias the SearchBox results towards current map's viewport.
        map.addListener('bounds_changed', function () {
            searchBox.setBounds(map.getBounds());
        });

        let markers = [];
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener('places_changed', function () {

            let places = searchBox.getPlaces();
            if (places.length == 0) {
                return;
            }

            // Clear out the old markers.
            markers.forEach(function (marker) {
                marker.setMap(null);
            });
            markers = [];

            // For each place, get the icon, name and location.
            let bounds = new window.google.maps.LatLngBounds();
            places.forEach(function (place) {
                if (!place.geometry) {
                    return;
                }

                marker.setPosition(place.geometry.location);
                _this.setPosNames(place.geometry.location.lat(), place.geometry.location.lng(), 'en')
                _this.setPosNames(place.geometry.location.lat(), place.geometry.location.lng(), 'ar')
                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            map.fitBounds(bounds);

        });
    }
    /**
     * Add names
     * @param lat
     * @param lng
     * @param lang
     */
    setPosNames = (lat, lng, lang) => {
        let _this = this;
        const { branchList, activeBranchKey } = this.props;
        let { zoom, location } = branchList[activeBranchKey];
        let addresGolb = (lang === 'ar') ? 'address_ar' : 'address';

        branchList[activeBranchKey].location = {
            latitude: lat,
            longitude: lng
        }

        _this.props.updateParentState(branchList);
        axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
            params: {
                latlng: lat + ',' + lng,
                key: process.env.REACT_APP_GEO_KEY,
                language: lang
            }
        }).then(function (response) {

            if (response.data.results && response.data.results[1] && response.data.results[1]['address_components']) {

                let allRes = response.data.results;
                branchList[activeBranchKey][addresGolb] = {
                    country: '',
                    city: '',
                    area: '',
                    route: '',
                    street: '',
                    building: branchList[activeBranchKey][addresGolb].building ? branchList[activeBranchKey][addresGolb].building : '',
                    boxNumber: branchList[activeBranchKey][addresGolb].boxNumber ? branchList[activeBranchKey][addresGolb].boxNumber : '',
                    apartment: branchList[activeBranchKey][addresGolb].apartment ? branchList[activeBranchKey][addresGolb].apartment : '',
                    notes: branchList[activeBranchKey][addresGolb].notes ? branchList[activeBranchKey][addresGolb].notes : ''

                }


                _this.props.updateParentState(branchList);

                let neighborhood = false;
                for (let r = 0; r < allRes.length; r++) {
                    let allResmin = allRes[r]['address_components'];
                    for (let k = 0; k < allResmin.length; k++) {
                        if (allResmin[k].types.indexOf("country") >= 0) {
                            branchList[activeBranchKey][addresGolb].country = allResmin[k]['long_name']
                        }
                        if (allResmin[k].types.indexOf("administrative_area_level_1") >= 0) {
                            branchList[activeBranchKey][addresGolb].city = allResmin[k]['long_name'];
                        }
                        if (allResmin[k].types.indexOf("locality") >= 0) {
                            branchList[activeBranchKey][addresGolb].city = allResmin[k]['long_name'];
                        }

                        if (allResmin[k].types.indexOf("route") >= 0) {
                            branchList[activeBranchKey][addresGolb].route = allResmin[k]['long_name'];
                        }
                        if (allResmin[k].types.indexOf("street_number") >= 0) {
                            branchList[activeBranchKey][addresGolb].street = allResmin[k]['long_name'];
                        }
                        branchList[activeBranchKey][addresGolb].street_route = branchList[activeBranchKey][addresGolb].route + ' ' + branchList[activeBranchKey][addresGolb].street

                        if (!neighborhood) {
                            if (allResmin[k].types.indexOf("sublocality") >= 0) {
                                branchList[activeBranchKey][addresGolb].area = allResmin[k]['long_name'];
                                if (lang === 'en')
                                    branchList[activeBranchKey].branchName = allResmin[k]['long_name'];
                            }
                        }
                        if (allResmin[k].types.indexOf("neighborhood") >= 0) {
                            neighborhood = true;
                            branchList[activeBranchKey][addresGolb].area = allResmin[k]['long_name'];
                            if (lang === 'en')
                                branchList[activeBranchKey].branchName = allResmin[k]['long_name'];
                        }
                    }
                }
            }
            _this.props.updateParentState(branchList);

            this.sendRequest();

        }).catch(function (error) {

        });
    }
    /**
     * For editable fields
     * @param type
     * @param arabic
     * @param e
     */
    changeAddress = (type, arabic, e) => {
        let val = e.target.value;
        let { branchList, activeBranchKey } = this.props;
        let addressGlobal = arabic ? 'address_ar' : 'address';

        branchList[activeBranchKey][addressGlobal][type] = val;
        this.props.updateParentState(branchList);
    }
    /**
     * Allow Arabic for selected field
     */
    allowArabic = () => {
        const { activeBranchKey, branchList } = this.props;
        let { mapArabicSupport } = this.state;
        this.setState({ mapArabicSupport: !mapArabicSupport })

        if (!mapArabicSupport) {
            this.setPosNames(branchList[activeBranchKey].location.latitude, branchList[activeBranchKey].location.longitude, 'en')
            this.setPosNames(branchList[activeBranchKey].location.latitude, branchList[activeBranchKey].location.longitude, 'ar')
        }
    }
    /**
     * Toll number default
     */
    setTollNumberDefault = () => {
        let { branchList, activeBranchKey } = this.props;
        branchList[activeBranchKey].isTollNumberDefault = !branchList[activeBranchKey].isTollNumberDefault;
        this.props.updateParentState(branchList);
    }

    render = () => {
        return (
            <div className="wizardMain">
                <div className="contacts">
                    {/* <div className="flexBox ">
                        {this.renderLandLineNumber()}
                        {this.renderTollNumber()}
                    </div>
                    <div className="flexBox align_justify" style={{ paddingTop: '25px' }}>
                        {this.renderEmail()}
                    </div> */}
                    
                        {this.renderLocation()}
                   
                </div>
            </div>
        )
    }
    componentDidMount = () => {

        let { branchList, activeBranchKey } = this.props;
        let el = document.getElementById('mapC');
        if (el) {
            this.setPosNames(branchList[activeBranchKey].location.latitude, branchList[activeBranchKey].location.longitude, 'en')
            this.setPosNames(branchList[activeBranchKey].location.latitude, branchList[activeBranchKey].location.longitude, 'ar')
            if (el.innerHTML.length < 10) {
                this.setMap();
            }
        }
    }

    /*
     * Functions for content getting
     * */
    /**
     *
     * @returns {XML}
     */
    renderLandLineNumber = () => {
        const _this = this;
        const { activeBranchKey, branchList } = this.props;
        let countriesArray = countries.map((item, index) => {
            return { key: index, text: item, value: item }
        })
        let codesLandlineArray = codesLandline.map((item, index) => {
            return { key: index, text: item, value: item }
        })
        return (
            <div className="inputWrap s4 number" style={{ marginRight: '60px' }}>
                <div className="title flexBox">
                    <h2>Landline work number <span className="req" style={{ color: 'darkred' }}>*</span></h2>
                </div>
                <div className="info flexBox" >
                    <p style={{ width: '108px' }}>Country</p>
                    <p>Area</p>
                </div>
                {
                    branchList[activeBranchKey].landLineNumbers.map((item, index) => {
                        return (<div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', padding: '8px 0' }}>
                            <Select options={countriesArray} value={item.country} onChange={_this.changeCountry.bind(this, index, 'landLineNumbers')} style={{ minWidth: '30%', marginRight: '2px' }} />
                            <Select options={codesLandlineArray} value={item.code} onChange={_this.changeCode.bind(this, index, 'landLineNumbers')} style={{ minWidth: '20%', justifyContent: 'center', marginRight: '2px' }} />
                            <Input type='tel' onChange={_this.changeNumber.bind(this, index, 'landLineNumbers')}>
                                <input value={item.number} style={{ height: '40px', fontSize: '10px', height: '100%', minWidth: '50%' }} />
                            </Input>
                            {
                                (index + 1) === branchList[activeBranchKey].landLineNumbers.length ? <span onClick={_this.addLandLineNumber} className="action">+</span> : <span onClick={_this.removeLandLineNumber.bind(this, index)} className="action">-</span>
                            }
                        </div>)
                    })
                }
            </div>
        )
    }
    /**
     *
     * @returns {XML}
     */
    renderTollNumber = () => {
        const _this = this;
        const { activeBranchKey, branchList } = this.props;
        let countriesArray = countries.map((item, index) => {
            return { key: index, text: item, value: item }
        })
        let codesLandlineArray = codes.map((item, index) => {
            return { key: index, text: item, value: item }
        })
        return (
            <div className="inputWrap s4 number">
                <div className="title flexBox align_justify">
                    <h2>Toll free number</h2>
                    <p onClick={this.setTollNumberDefault} className="setDef flexBox">
                        <span style={{ paddingRight: '4px' }}>Set Default</span>

                        <span style={{ fontSize: '16px' }} className={branchList[activeBranchKey].isTollNumberDefault ? "far fa-check-square" : "far fa-square"}></span>
                    </p>
                </div>
                <div className="inputParent">
                    <div className="info flexBox">
                        <p>Country</p>
                        <p>Area</p>
                    </div>
                    {
                        branchList[activeBranchKey].tollNumber.map(function (item, index) {
                            return (<div key={index} style={{ display: 'flex', alignItems: 'center', fontSize: '12px', padding: '8px 0' }}>
                                <Select options={countriesArray} value={item.country} onChange={_this.changeCountry.bind(this, index, 'tollNumber')} style={{ minWidth: '30%', marginRight: '2px' }} />
                                <Select options={codesLandlineArray} value={item.code} onChange={_this.changeCode.bind(this, index, 'tollNumber')} style={{ minWidth: '20%', justifyContent: 'center', marginRight: '2px' }} />
                                <Input type='tel' onChange={_this.changeNumber.bind(this, index, 'tollNumber')} name='tollNumber'>
                                    <input value={item.number} style={{ height: '40px', fontSize: '10px', height: '100%', minWidth: '50%' }} />
                                </Input>
                            </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
    /**
     *
     * @returns {XML}
     */
    renderEmail = () => {
        const _this = this;
        const { activeBranchKey, branchList, facilityType } = this.props;
        let email = branchList[activeBranchKey].facilityEmail ? branchList[activeBranchKey].facilityEmail : "";
        return (
            <div className="inputWrap s3 number">
                <div className="title flexBox">
                    <h2>Facility e-mail <span className="req" style={{ color: 'darkred' }}>*</span></h2>
                </div>
                <div className="inputParent">
                    <div data-error={email.length === 0 || !validateEmail(email)} className="fRt facilityEmail">
                        <input type="text" name="facilityEmail" value={email} onChange={_this.changeFacilityEmail} />
                    </div>
                </div>
            </div>
        )
    }
    /**
     *
     * @returns {XML}
     */
    renderLocation = () => {
        let _this = this;

        const { activeBranchKey, branchList, facilityType } = this.props;
        return (
            <div>
                <div className="inputWrap">
                    <div className="title flexBox">
                        <h2>Location</h2>
                    </div>
                    <div className="map">
                        <div className="searchLoc">
                            <input ref='pacInput' id="pac-input" className="controls" type="text" placeholder="Search Box" />
                        </div>
                        <div className="GMap">
                            <p className="highlight">Use search bar, auto locator or manual placement of the pin to update the below fields and save the exact location coordinates.</p>
                            <div onClick={this.getCurrent} className="current" />
                            <div id="mapC" className='GMap-canvas' ref='mapC' />
                        </div>
                    </div>
                </div>
                <div className="fieldsContact">
                    <div className="flexBox align_justify">
                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="country">Country <span className="req">*</span></label></h2>
                                </div>
                                <input type="text" id="country" value={branchList[activeBranchKey].address.country ? branchList[activeBranchKey].address.country : ""} readOnly={true} />
                            </div>
                        </div>
                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="city">City <span className="req">*</span></label></h2>
                                </div>
                                <input type="text" id="city" value={branchList[activeBranchKey].address.city ? branchList[activeBranchKey].address.city : ""} readOnly={true} />
                            </div>
                        </div>

                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="area">Area</label></h2>
                                </div>
                                <input type="text" id="area" onChange={this.changeAddress.bind(this, 'area', false)} value={branchList[activeBranchKey].address.area ? branchList[activeBranchKey].address.area : ""} readOnly={_this.state.readOnly} />
                            </div>
                        </div>
                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="street">Street</label></h2>
                                </div>
                                <input onChange={this.changeAddress.bind(this, 'street_route', false)} type="text" id="street" value={branchList[activeBranchKey].address.street_route ? branchList[activeBranchKey].address.street_route : ""} />
                            </div>
                        </div>
                    </div>

                    <div className="flexBox align_justify">
                       
                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="building">Building <span className="req">*</span></label></h2>
                                </div>
                                <div className="inputParent">
                                    <div data-error={branchList[activeBranchKey].address.building.length === 0} className="fRt">
                                        <input onChange={this.changeAddress.bind(this, 'building', false)} type="text" id="building" value={branchList[activeBranchKey].address.building ? branchList[activeBranchKey].address.building : ""} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="street">Apartment</label></h2>
                                </div>
                                <input onChange={this.changeAddress.bind(this, 'apartment', false)} type="text" id="apartment" value={branchList[activeBranchKey].address.apartment ? branchList[activeBranchKey].address.apartment : ""} />
                            </div>
                        </div>
                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="boxNumber">P.O.Box number</label></h2>
                                </div>
                                <input onChange={this.changeAddress.bind(this, 'boxNumber', false)} type="text" id="boxNumber" value={branchList[activeBranchKey].address.boxNumber ? branchList[activeBranchKey].address.boxNumber : ""} />
                            </div>
                        </div>
                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="street">Other notes</label></h2>
                                </div>
                                <input onChange={this.changeAddress.bind(this, 'notes', false)} type="text" id="notes" value={branchList[activeBranchKey].address.notes ? branchList[activeBranchKey].address.notes : ""} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fieldsContact" style={{ marginTop: '60px' }} dir="rtl">
                    <div className="flexBox align_justify">
                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="country">اسم البلد<span className="req">*</span></label></h2>
                                </div>
                                <input type="text" id="country" value={branchList[activeBranchKey].address_ar.country ? branchList[activeBranchKey].address_ar.country : ""} readOnly={true} />
                            </div>
                        </div>
                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="city">المدينة <span className="req">*</span></label></h2>
                                </div>
                                <input type="text" id="city" value={branchList[activeBranchKey].address_ar.city ? branchList[activeBranchKey].address_ar.city : ""} readOnly={true} />
                            </div>
                        </div>

                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="area">المنطقة</label></h2>
                                </div>
                                <input type="text" id="area" onChange={this.changeAddress.bind(this, 'area', true)} value={branchList[activeBranchKey].address_ar.area ? branchList[activeBranchKey].address_ar.area : ""} readOnly={_this.state.readOnly} />
                            </div>
                        </div>
                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="street">الشارع</label></h2>
                                </div>
                                <input onChange={this.changeAddress.bind(this, 'street_route', true)} type="text"
                                    id="street" value={branchList[activeBranchKey].address_ar.street_route ? branchList[activeBranchKey].address_ar.street_route : ""} />
                            </div>
                        </div>
                    </div>

                    <div className="flexBox align_justify">
                      
                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="building">المبنى <span className="req">*</span></label></h2>
                                </div>
                                <div className="inputParent">
                                    <div data-error={branchList[activeBranchKey].address_ar.building.length === 0} className="fRt">
                                        <input onChange={this.changeAddress.bind(this, 'building', true)} type="text" id="building" value={branchList[activeBranchKey].address_ar.building ? branchList[activeBranchKey].address_ar.building : ""} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="street">الشقة</label></h2>
                                </div>
                                <input onChange={this.changeAddress.bind(this, 'apartment', true)} type="text"
                                    id="apartment" value={branchList[activeBranchKey].address_ar.apartment ? branchList[activeBranchKey].address_ar.apartment : ""} />
                            </div>
                        </div>
                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="boxNumber">رقم البريد</label></h2>
                                </div>
                                <input onChange={this.changeAddress.bind(this, 'boxNumber', true)} type="text"
                                    id="boxNumber" value={branchList[activeBranchKey].address_ar.boxNumber ? branchList[activeBranchKey].address_ar.boxNumber : ""} />
                            </div>
                        </div>
                        <div className="inputWrap s3">
                            <div>
                                <div className="title">
                                    <h2><label htmlFor="notes">معلومات اخرى</label></h2>
                                </div>
                                <input onChange={this.changeAddress.bind(this, 'notes', true)} type="text"
                                    id="notes" value={branchList[activeBranchKey].address_ar.notes ? branchList[activeBranchKey].address_ar.notes : ""} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Location