import React, { Component } from 'react';
import { firestore } from 'firebase'
import clinicPlaceHolder from '../../img/clinicprofileaspect.png';
import { Icon, Input } from 'semantic-ui-react'
import ReactTable from "react-table";
import moment from 'moment'
import "react-table/react-table.css";

// import "./reacttable.css";

class SelectFacility extends Component {

    state = {
        allBookings: {},
        loading: false,
        allUsers: {},
        step: 25,
        asyncOptions: []
    }
    constructor(props, context) {
        super(props, context);
    }

    dataTableColumns() {
        return ([
            {
                accessor: "logoUrl",
                Cell: props => <div className="providerfieldText"><img style={{ minHeight: '60px', maxWidth: '60px', width: '60px', height: '60px', borderRadius: '200px', border: '1px solid lightgrey', padding: '2px', objectFit: 'cover' }} src={props.value ? props.value : clinicPlaceHolder} alt={props.value} /></div>
            },
            {
                Header: "Name",
                accessor: "firstName",
                Cell: props => <p className="providerfieldText">{props.value}</p>
            },
            {
                Header: "Number",
                accessor: "number",
                Cell: props => <p className="providerfieldText">{props.value}</p>
            },
            {
                Header: "Gender",
                accessor: "gender",
                Cell: props => <p className="providerfieldText">{props.value}</p>
            },
            {
                Header: "Age",
                accessor: "birthday",
                Cell: props => <p className="providerfieldText">{props.value}</p>
            },
            {
                Header: "First Booking",
                accessor: "firstOrder",

                Cell: props => <p className="providerfieldText">{moment(props.value).format('DD/MM/YYYY hh:mm A')}</p>
            },
            {
                Header: "Last Booking",
                accessor: "lastOrder",
                Cell: props => <p className="providerfieldText">{moment(props.value).format('DD/MM/YYYY hh:mm A')}</p>
            },
            {
                Header: "Total Bookings",
                accessor: "ordersPlaced",
                Cell: props => <p className="providerfieldText">{props.value}</p>
            },
            {
                Header: "Total Revenue",
                accessor: "revenue",
                Cell: props => <p className="providerfieldText">{props.value} {process.env.REACT_APP_CURRNECY}</p>
            },


        ])
    }
    searchFunction = async (e) => {
        let searchQuery = e.target.value

        let onlineResult = await firestore().collection('users')
            .where('phone', '>=', searchQuery).where('phone', '<=', searchQuery + '\uf8ff')
            .orderBy('phone')
            .limit(3)
            .get()
        let onlineResult2 = await firestore().collection('users')
            .where('number', '>=', searchQuery).where('number', '<=', searchQuery + '\uf8ff')
            .orderBy('number')
            .limit(3)
            .get()

        let asyncOptionsOnline = onlineResult.docs.concat(onlineResult2.docs).map(async res => {
            let userBookingsSnap = await firestore().collection('bookings').where('patient', '==', res.id).get()
            let userBookings = userBookingsSnap.docs.map(r => { return { ...r.data(), key: r.id } }) || []
            let createdArray = userBookings.map(r => r.created)
            let totalRevenue = userBookings?.map(r => (r.authRequest?.amount ? (r.authRequest?.amount * 1) : 0))?.reduce((a, b) => a + b, 0)
            if (userBookings.length && res.exists) {
                return {
                    ...res.data(),
                    key: res.id,
                    ordersPlaced: createdArray.length,
                    firstOrder: Math.min.apply(null, createdArray),
                    lastOrder: Math.max.apply(null, createdArray),
                    revenue: totalRevenue,
                    number: res.data()?.phone || res.data()?.number,
                    name: res.data()?.name || res.data()?.firstName
                }
            }
            return false
        })
        let offlineResult = await firestore().collection('offlineUsers')
            .where('number', '>=', searchQuery).where('number', '<=', searchQuery + '\uf8ff')
            .orderBy('number')
            .limit(3)
            .get()

        let asyncOptionsOffline = offlineResult.docs.map(async res => {
            let userBookingsSnap = await firestore().collection('bookings').where('patient', '==', res.id).get()
            let userBookings = userBookingsSnap.docs.map(r => { return { ...r.data(), key: r.id } }) || []
            let createdArray = userBookings.map(r => r.created)
            let totalRevenue = userBookings?.map(r => (r.authRequest?.amount ? (r.authRequest?.amount * 1) : 0))?.reduce((a, b) => a + b, 0)
            if (userBookings.length && res.exists && res.data().number) {
                return {
                    ...res.data(),
                    key: res.id,
                    ordersPlaced: createdArray.length,
                    firstOrder: Math.min.apply(null, createdArray),
                    lastOrder: Math.max.apply(null, createdArray),
                    revenue: totalRevenue,
                    number: res.data().number

                }
            }
            return false
        })
        let allOptions = asyncOptionsOffline.concat(asyncOptionsOnline)
        Promise.all(allOptions).then((ress) => {
            this.setState({ asyncOptions: searchQuery.length ? ress.filter(rec => rec) : [], loading: false })
        })

    }
    render() {
        return (
            <section id="facility-grid-page">
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" style={{ height: '100%' }}>
                        {
                            this.props.loading || this.state.loading ?
                                <div style={{ height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Icon.Group size='big'>
                                        <Icon loading size='big' name='spinner' color='grey' />
                                    </Icon.Group>
                                </div>
                                : this.renderFacilitiesList()
                        }
                    </div>
                </div>
            </section>
        )
    }

    /*
    * Functions for content getting
    * */

    /**
     * get Facilities list from redux
     * @param list
     * @returns {XML}
     */
    renderFacilitiesList = () => {
        let { number, asyncOptions } = this.state
        const bookings = this.props.selectFacility.bookings;
        if (this.state.loading) {
            return (<div>Loading</div>)
        }

        if (window.screen.width <= 768) return (
            <div className="emptystate">
                <div className="emptystate1">
                    <div className="textMesg">Open on desktop</div>
                </div>
            </div>
        );

        // let averageAge = 0
        // let averageAgeArray = asyncOptions.map(item => {
        //     if (item.birthday) {
        //         averageAge += ((moment(item.birthday).toDate().getTime()) || 0)
        //         return (moment(item.birthday).toDate().getTime()) || 0
        //     }

        // })

        let male = 0
        let female = 0
        let genderUndefiened = 0
        asyncOptions.map(item => {
            if (item.gender) {
                item.gender === "Male" ? male += 1 : female += 1
            } else {
                genderUndefiened += 1
            }
        })

        console.log('bookingsbookings', bookings);
        if (bookings) {
            return (
                <div >
                    <div style={{ margin: '10px 20px', fontSize: '18px', fontWeight: '600' }}>Search Patients</div>
                    <Input
                        placeholder="Search for patients by phone number"
                        searchInput='number'
                        style={{ width: '40%', margin: '0 20px' }}
                        type='number'
                        value={number}
                        text={number}
                        className="inputfieldmandatory"
                        onChange={this.searchFunction}
                    />
                    {/* <div className="facilityInfo">

                        <div className="cardHeader">
                            <div className="cardCount">{asyncOptions.length}</div>
                            <div className="cardTitle">Total Patients</div>
                            <div className="cardImage"><i className="fas fa-users"></i></div>
                        </div>
                        <div className="cardHeader">
                            <div className="cardCount">{asyncOptions && asyncOptions.length && (moment(averageAge / averageAgeArray.length).toNow()).replace('in', '')}</div>
                            <div className="cardTitle">Average Age</div>
                            <div className="cardImage"><i className="fas fa-percentage"></i></div>
                        </div>
                        <div className="cardHeader">
                            <div className="cardCount">{asyncOptions && asyncOptions.length ? Math.round(((male / (asyncOptions.length - genderUndefiened)) * 100).toFixed(2)) : 0} : {asyncOptions && asyncOptions.length ? Math.round(((female / (asyncOptions.length - genderUndefiened)) * 100).toFixed(2)) : 0}</div>
                            <div className="cardTitle">Male : Female</div>
                            <div className="cardImage"><i className="fas fa-user-friends"></i></div>
                        </div>
                    </div> */}
                    <div className="single-medical">
                        {
                            asyncOptions && asyncOptions.length ? <ReactTable
                                data={asyncOptions}
                                columns={this.dataTableColumns()}
                                defaultPageSize={10}
                                pageSize={asyncOptions.length < 10 ? asyncOptions.length : 10}
                                showPageJump={false}
                                showPageSizeOptions={false}
                                // PaginationComponent={DataTablePagination}
                                className='custuomReactTable'
                            /> : <div style={{ width: '100%', textAlign: 'center', paddingTop: '150px' }}>No Patients</div>
                        }

                    </div>



                </div>

            )
        }
    }

}

export default SelectFacility