import React, { Component } from 'react';
import cIcon from '../../img/clinic-f.png';
import { firestore } from 'firebase'

import hIcon from '../../img/hospital-f.png';
import { Icon, Button, Dimmer } from 'semantic-ui-react'
import moment from 'moment'
import "react-table/react-table.css";
// import "./reacttable.css";
import GlideComponent from "./GlideComponent";
import doctorManLogo from '../../img/dr_man.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

class SelectFacility extends Component {
    state = {
        filteredDoctors: {},
        selectedFacility: '',
        selectedDoctors: [],
        selectedDate: [],
        allBookings: {},
        allServices: {},
        currentStep: 1,

        user: null,
        loggedIn: false,
        claimingPersonName: '',
        suggestionId: null,
        // For Select Facility Component
        branches: 1,
        facilityType: 'Facility',
        readyToStart: false,
        showExport: false,

        facilityTypes: {
            Clinic: {
                name: 'Clinic',
                icon: cIcon,
                selected: false
            },
            Hospital: {
                name: 'Hospital',
                icon: hIcon,
                selected: false
            }
        },
    }
    constructor(props, context) {
        super(props, context);
    }


    componentWillMount = async () => {
        // this.getUser();
        let { allServices } = this.state
        firestore().collection('services').get().then((servicesSnap) => {
            servicesSnap.docChanges().forEach((record, index) => {
                allServices[record.doc.id] = record.doc.data()
            })
            this.setState({ allServices })
        })
    }


    render() {
        let { facilityTypes } = this.state;

        return (
            <section id="facility-grid-page">
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" style={{ height: '100%' }}>
                        {
                            this.props.loading ?
                                <div style={{ height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Icon.Group size='big'>
                                        <Icon loading size='big' name='spinner' color='grey' />
                                    </Icon.Group>
                                </div>
                                : this.renderFacilitiesList(facilityTypes)
                        }
                    </div>
                </div>
            </section>
        )
    }
    getDoctorName(doctorKey) {
        let doctors = this.props.selectFacility.doctors;
        let doctor = Object.values(doctors).filter(item => {
            return (item.id === doctorKey)
        })
        if (doctor.length) {
            return doctor[0].name
        } else {
            return ''

        }
    }

    exportCSV(selectedFacility, selectedDoctors, selectedDate) {
        let { allServices } = this.state
        let { allBookings } = this.props
        let prefix = "data:text/csv;charset=utf-8,";
        let header = `Patient name,Appointment date,Facility name,Provider name,Service name,Status,Payment method,Corporate name,Staff number,Arrival date and time,Discharge date and time,Facility Revenue,${process.env.REACT_APP_TITLE} revenue`;
        let csvContent = header + "\r\n";


        allBookings.map(item => {
            let paymentType = item.paymentType
            let charges = item.paymentType === "Card" ? (item.authRequest?.amount * 0.0295).toFixed(2) : "--"
            let fees = item.paymentType === "Card" ? (item.authRequest?.amount - charges) : "--"
            let status = (item.status).toUpperCase()
            if (selectedFacility !== '') {
                // if (selectedDoctors.includes(item.doctor) || selectedFacility === item.facilityId || (moment(selectedDate[0]).isAfter(moment(item.dateTS)) && moment(selectedDate[1]).isBefore(moment(item.dateTS)))) {
                // if (selectedFacility === item.facilityId && (selectedDoctors.length ? selectedDoctors.includes(item.doctor) : true) &&(selectedDate.length ? (moment(selectedDate[0]).isAfter(moment(item.dateTS)) && moment(selectedDate[1]).isBefore(moment(item.dateTS))) : false)) {

                if (selectedFacility === item.facilityId && (selectedDoctors.length ? selectedDoctors.includes(item.doctor) : true) && (selectedDate.length ? (moment(item.dateTS).isAfter(moment(selectedDate[0])) && (moment(item.dateTS).isBefore(moment(selectedDate[1])))) : true)) {
                    csvContent += `${item.patientName},${(item.date).replace(",", " / ")},${item.facilityName},${this.getDoctorName(item.doctor)},${item.service === 'inpersonConsult' ? "Inperson Consult" : item.service === "onlineConsult" ? "Online Consult" : item.service === "General consultation" ? "General Consultation" : allServices[item.service] ? allServices[item.service].name : ''},${status},${paymentType ? paymentType : ''},${paymentType === "Insurance" ? item.insurance.companyName : ''},${paymentType === "Insurance" ? item.insurance.staffNumber : ''},${item.succeeded ? item.succeeded : ''},${item.discharged ? item.discharged : ''},${fees},${charges}\r\n`;
                    return false
                }
            } else {
                csvContent += `${item.patientName},${(item.date).replace(",", " / ")},${item.facilityName},${this.getDoctorName(item.doctor)},${item.service === 'inpersonConsult' ? "Inperson Consult" : item.service === "onlineConsult" ? "Online Consult" : item.service === "General consultation" ? "General Consultation" : allServices[item.service] ? allServices[item.service].name : ''},${status},${paymentType ? paymentType : ''},${paymentType === "Insurance" ? item.insurance.companyName : ''},${paymentType === "Insurance" ? item.insurance.staffNumber : ''},${item.succeeded ? item.succeeded : ''},${item.discharged ? item.discharged : ''},${fees},${charges}\r\n`;
                return false
            }

        })
        var encodedUri = prefix + encodeURIComponent(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "file.csv");
        document.body.appendChild(link);
        link.click();
        this.setState({ showExport: false })
    }

    /*
    * Functions for content getting
    * */

    /**
     * get Facilities list from redux
     * @param list
     * @returns {XML}
     */
    renderFacilitiesList = (list) => {
        let { allServices, showExport } = this.state
        let { allBookings, allPatients, Analytics } = this.props
        const draftList = this.props.selectFacility.clinics;
        let doctors = Object.values(this.props.selectFacility.doctors).sort((a, b) => ((b.views || 0) - (a.views || 0)));

        if (this.props.loading) {
            return (<div>Loading</div>)
        }
        if (window.screen.width <= 768) return (
            <div className="emptystate">
                <div className="emptystate1">
                    {/* <img src={Doctor} alt="Img" /> */}
                    <div className="textMesg">Open on desktop</div>
                    <div className="detailMesg">Add doctors to see information in this page</div>
                </div>
            </div>
        );

        if (!draftList) return (
            <div className="emptystate">
                <div className="emptystate1">
                    {/* <img src={Hospital} alt="Img" /> */}
                    <div className="textMesg">No Clinics</div>
                    <div className="detailMesg">Add clinic to see information in this page</div>
                    <a onClick={() => this.props.addFacility()} className="addfacbutton">Add Clinic</a>

                </div>
            </div>
        );

        let views = 0
        Object.values(draftList).forEach(item => {
            return views += item.views ? item.views : 0
        })
        let totalProviders = []
        Object.values(draftList).forEach(item => {
            if (item.doctors && item.doctors.length) {
                (item.doctors).map(doctor => {
                    if (!totalProviders.includes(doctor)) {
                        return totalProviders.push((doctor))
                    }
                })
            }
        })

        let successfulBookings = (Analytics?.general?.totalBookings || 0) - (Analytics?.general?.totalCanceled || 0)
        let canceledBookings = Analytics?.general?.totalCanceled || 0
        let rescheduledBookings = Analytics?.general?.totalRescadualed || 0
        let total = Analytics?.general?.totalRevenu || 0


        let totalServices = []
        Object.values(doctors).forEach(item => {
            if (item.services && Object.keys(item.services).length) {
                (Object.keys(item.services)).map(service => {
                    if (!totalServices.includes(service)) {
                        return totalServices.push((service))
                    }
                })
            }
        })

        let patients = {}
        allBookings.forEach((item) => {
            patients[item.patient] = (patients[item.patient] || 0) + 1
        })
        let doctorBookings = {}
        allBookings.forEach((item) => {
            doctorBookings[item.doctor] = (doctorBookings[item.doctor] || 0) + 1
        })
        let topDocs = doctors?.map(r => { return { ...r, ...Analytics?.doctors[r?.id] } })
        topDocs = topDocs.sort((a, b) => b.totalBookings - a.totalBookings)



        let averageAge = 0
        let averageAgeArray = Object.values(allPatients).map(item => {
            if (item.birthday) {
                averageAge += (moment(item.birthday).toDate().getTime() || 0)
                return (moment(item.birthday).toDate().getTime()) || 0
            }
        })
        if (draftList) {
            return (
                <div >
                    {
                        showExport ? <Dimmer style={{ position: 'fixed' }} active>
                            {this.renderPopup()}
                        </Dimmer> : ''
                    }
                    <div style={{
                        margin: '10px',
                        fontSize: '20px',
                        fontWeight: '700'
                    }}>Totals</div>

                    <div className="facilityInfo">
                        <div className="cardHeader">
                            <div className="cardCount">{views}</div>
                            <div className="cardTitle">Profile Views</div>
                            <div className="cardImage"><i className="far fa-eye"></i></div>

                        </div>
                        <div className="cardHeader">
                            <div className="cardCount">{successfulBookings}</div>
                            <div className="cardTitle">Successful Bookings</div>
                            <div className="cardImage"><i className="fas fa-check-circle"></i></div>

                        </div>
                        <div className="cardHeader">
                            <div className="cardCount">{canceledBookings}</div>
                            <div className="cardTitle">Cancelled Bookings</div>
                            <div className="cardImage"><i className="fas fa-times-circle"></i></div>
                        </div>
                        <div className="cardHeader">
                            <div className="cardCount">{rescheduledBookings}</div>
                            <div className="cardTitle">Rescheduled Bookings</div>
                            <div className="cardImage"><i className="fas fa-clock"></i></div>
                        </div>

                    </div>
                    <div className="facilityInfo" style={{ width: '75%' }}>

                        {/* <div className="cardHeader">
                            <div className="cardCount">{Object.values(draftList).length}</div>

                            <div className="cardTitle">Facilities Profiles</div>
                            <div className="cardImage"><i className="fas fa-home"></i></div>
                        </div>
                        <div className="cardHeader">
                            <div className="cardCount">{totalProviders.length}</div>
                            <div className="cardTitle">Providers Profiles</div>
                            <div className="cardImage"><i className="fas fa-user-md"></i></div>
                        </div>
                        <div className="cardHeader">
                            <div className="cardCount">{totalServices.length}</div>
                            <div className="cardTitle">Services</div>
                            <div className="cardImage"><i className="fas fa-toolbox"></i></div>
                        </div> */}
                        <div className="cardHeader">
                            <div className="cardCount">{(total).toFixed(2)}</div>
                            <div className="cardTitle">Total Revenue</div>
                            <div className="cardImage"><i className="fas fa-dollar-sign"></i></div>

                        </div>
                        <div className="cardHeader">
                            <div className="cardCount">{Analytics?.general?.totalPatients || 0}</div>
                            <div className="cardTitle">Total Patients</div>
                            <div className="cardImage"><i className="fas fa-users"></i></div>

                        </div>

                        <div className="cardHeader">
                            <div className="cardCount">{(Analytics?.general?.totalBookings / Analytics?.general?.totalPatients).toFixed(0)}</div>
                            <div className="cardTitle">Bookings / Patient</div>
                            <div className="cardImage"><i className="fas fa-users"></i></div>

                        </div>


                    </div>

                    {/* <div style={{
                        margin: '10px',
                        fontSize: '20px',
                        fontWeight: '700'
                    }}>Average</div>
                    <div className="averageParent">
                        <div className="averageItem">
                            <div className="text">Patient<br />Age</div>
                            <div className="countText">{moment(averageAge / averageAgeArray?.length).isValid() ? (moment(averageAge / averageAgeArray.length).toNow())?.replace('in', '') : 'loading...'}</div>
                        </div>
                        <div className="averageItem">
                            <div className="text">Bookings / <br />Patient</div>
                            <div className="countText">{(Analytics?.general?.totalBookings / Analytics?.general?.totalPatients).toFixed(0)}</div>
                        </div>
                        <div className="averageItem">
                            <div className="text">Revenue / <br />Facility</div>
                            <div className="countText">{(total / Object.values(draftList).length).toFixed(2)}</div>
                        </div>

                    </div> */}
                    <div style={{
                        margin: '10px',
                        fontSize: '20px',
                        fontWeight: '700'
                    }}>Top 3</div>
                    <div className="topParent">
                        <div className="topContainer" style={{ flexDirection: 'column' }}>
                            <div className="title">Providers Viewed</div>
                            {
                                Object.values(doctors).slice(0, 3).map(item => {
                                    return (
                                        <div className="providerItem" key={item.id}>
                                            <div className="infoParent">
                                                <img className="providerImage" src={item.profilePicture ? item.profilePicture : doctorManLogo} />
                                                <div className="providerName">{item.name}</div>
                                            </div>

                                            <div className="providerCount">{item.views}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="topContainer" style={{ flexDirection: 'column' }}>
                            <div className="title">Providers Booked</div>
                            {
                                topDocs.slice(0, 3).map(item => {
                                    return (
                                        item ?
                                            <div className="providerItem" key={item.id}>
                                                <div className="infoParent">
                                                    <img className="providerImage" src={item.profilePicture ? item.profilePicture : doctorManLogo} />
                                                    <div className="providerName">{item.name}</div>
                                                </div>
                                                <div className="providerCount">{item.totalBookings}</div>
                                            </div>
                                            : ''


                                    )

                                })
                            }

                        </div>
                    </div>
                    <div style={{ margin: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button color='blue' onClick={() => this.setState({ showExport: true })}>Export Report</Button>
                    </div>


                </div>

            )
        }
    }
    renderPopup() {
        let { startDate, endDate, selectedFacility, selectedDoctors, selectedDate, filteredDoctors } = this.state
        let doctors = this.props.selectFacility.doctors
        let clinics = this.props.selectFacility.clinics;



        let filteredClinics = {}
        Object.keys(clinics).map(item => {
            let data = clinics[item]
            data.label = clinics[item].facilityName
            data.value = clinics[item].facilityName
            data.key = item

            filteredClinics[item] = data
        })

        return (
            <div className='helpersPopup'>
                <div className='helperTitle' style={{ padding: '0', margin: '24px 28px', marginBottom: '0', textAlign: 'start' }}>Select Data you need to Export</div>
                <div className='contents'>
                    <div style={{ marginTop: '16px', marginBottom: '8px', fontSize: '14px' }}>Select Facility</div>
                    <Select
                        styles={{ zIndex: '100000' }}
                        placeholder="Doctors"
                        name="facility"
                        options={Object.values(filteredClinics)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(value => {
                            selectedFacility = value.key
                            filteredDoctors = {};
                            (value.doctors).map(doctorKey => {
                                return (
                                    Object.keys(doctors).filter(item => {
                                        if (item === doctorKey) {
                                            let data = doctors[item]
                                            data.label = doctors[item].name
                                            data.value = doctors[item].name
                                            data.key = item
                                            filteredDoctors[item] = data
                                        }

                                    })
                                )
                            })

                            this.setState({ selectedFacility, filteredDoctors })
                        })} />

                    <div style={{ marginTop: '16px', marginBottom: '8px', fontSize: '14px' }}>Select Doctors</div>
                    <Select
                        placeholder="Doctors"
                        isMulti
                        name="doctors"
                        isDisabled={!selectedFacility}
                        options={Object.values(filteredDoctors)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(value) => {
                            selectedDoctors = []

                            if (value) {
                                value.map(item => {
                                    return (
                                        selectedDoctors.push(item.key)
                                    )
                                })
                                this.setState({ selectedDoctors })
                            } else {
                                this.setState({ selectedDoctors: [] })

                            }
                        }}
                    />

                    <div style={{ marginTop: '16px', marginBottom: '8px', fontSize: '14px' }}>Select Date Range</div>
                    <DatePicker
                        selected={startDate}
                        onChange={(dates) => {
                            const [start, end] = dates;
                            selectedDate = dates

                            this.setState({ startDate: start, endDate: end, selectedDate })
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                    />
                </div>
                <div className='actionBtn'>
                    <Button color='red' onClick={() => this.setState({ showExport: false })}>Dismiss</Button>
                    <Button color='blue' onClick={() => {
                        this.exportCSV(selectedFacility, selectedDoctors, selectedDate)
                    }}>Export</Button>
                </div>
            </div>
        )
    }



}

export default SelectFacility