import React, { Component } from 'react';
import PropTypes from 'prop-types'

class ClinicStatusSteps extends Component {
    static propTypes = {
        clinicsList: PropTypes.object,
        currentStep: PropTypes.number.isRequired,
        changeCurrentStep: PropTypes.func.isRequired,
    }
    state = {
        parts: {
            ACTIVEBOOKING: 'ACTIVE',
            NOTACTIVEBOOKING: 'NOT ACTIVE',
            // DRAFT: 'DRAFT',
            // REJECTED: 'REJECTED',
        }
    }
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount = () => {

    }

    middleChangeCurrentStep = (index) => {
        let part = index + 1
        this.props.changeCurrentStep(part);
    }

    render() {

        if (this.props.clinicsList) {
        }
        return (
            <div>
                {this.renderSteps()}
            </div>

        )
    }

    renderSteps = () => {
        const { currentStep } = this.props;
        let currPartList = this.state.parts

        return (
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                {
                    Object.keys(currPartList).map((item, index) => (
                        <li data-prev={currentStep > (index + 1)} data-active={currentStep === (index + 1)} onClick={this.middleChangeCurrentStep.bind(this, index)} key={item} className="nav-item">
                            {
                                currentStep === (index + 1) ? (
                                    <a className="nav-link active">{currPartList[item]}</a>
                                ) : <a className="nav-link">{currPartList[item]}</a>
                            }
                        </li>
                    ))
                }
            </ul>
        )
    }
}

export default ClinicStatusSteps