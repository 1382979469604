import React, { Component } from 'react';
import firebase from 'firebase/app'
import 'firebase/database';
import FMSHeader from '../Header/defaultHeader'
import home from '../../img/thriveFmsLogo.png';
import SelectFacility from './SelectFacility';
import MainWizard from './MainWizard';
import DoctorPopup from './DoctorPopup/DoctorDetailsPopup';
import PatientPopup from './AddPatient/AddPatient'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import alertCircle from '../../img/alert-circle.png';
import { userActions } from '../../modules/user-module';
import './AppointmentSystem.css'
import HomeLogin from '../../Home Login/index'
import { Dimmer, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react'

class WizardClinic extends Component {



    constructor(props, context) {
        super(props, context);
        this.handleStateChange = this.handleStateChange.bind(this)
        this.state = {
            user: null,
            loggedIn: false,
            claimingPersonName: '',
            branches: 1,
            readyToStart: false,
            draftList: null,
            selectFacility: null,
            clinicsStatus: 1,
            modalLoginIsOpen: false,
            doctorData: null,
            doctorSetupUpdatedData: null,
            patientData: null,
            addPatientUpdatedData: null,
            // activateClicked: false
            loading: true,
            dismissDoctor: false,
            selectedDoctor: '',
            doctorKey: '',
            showMenu: window.screen.width <= 768 ? false : true,
            search: "",

        }
    }

    chechClinicStatus = () => {
        const _this = this;
        let { clinicsStatus } = this.state;
        let { branches } = this.props
        if (!branches) {
            return this.setState({ loading: false })
        };
        let filteredBranches = {}
        Object.keys(branches.data).map(key => {
            let data = branches.data[key]
            if (data.bookingActive && clinicsStatus === 1) {
                data.doctorsData = data?.doctorsData?.filter(item => item.clinics && item.clinics[key] && item.bookingActive && item.bookingActive[key])
                filteredBranches[key] = data
            } else if (!data.bookingActive && clinicsStatus !== 1) {
                filteredBranches[key] = data
            }
        })
        _this.setState({
            branches: filteredBranches ? Object.keys(filteredBranches).length : 0,
            draftList: filteredBranches ? filteredBranches : {},
            loading: false
        })

    }

    componentWillMount() {
        window.scrollTo(0, 0);
        let { user, accessScreens } = this.props.user.data ? this.props.user.data : {}
        if (user) {
            this.chechClinicStatus()
        }

        this.setState({ allowed: accessScreens && accessScreens.includes('appointments') })
        window.addEventListener('offline', this.handleStateChange);
        window.addEventListener('online', this.handleStateChange);
    }
    componentWillUnmount() {
        window.removeEventListener('offline', this.handleStateChange)
        window.removeEventListener('online', this.handleStateChange)

    }
    handleStateChange() {
        this.setState({ offline: !window.navigator.onLine })
    }

    logout = () => {
        // this.setState({
        //     loggedIn: false,
        //     user: null,
        //     modalLoginIsOpen: true
        // });
    }
    /*Simple functions*/
    branchChange = number => {
        this.setState({ branches: number })
    }

    clinicStatusSelected = (number, clinicKey) => {
        const _this = this;

        if (clinicKey) {
            return this.props.updateBranches(clinicKey, this.props.branches ? this.props.branches.data : {}).then((data) => {
                this.setState({ clinicsStatus: number }, () => {
                    _this.chechClinicStatus()
                })
            })
        }

        this.setState({ clinicsStatus: number }, () => {
            _this.chechClinicStatus()
        })
    }
    readyToStartChange = bool => {
        // let { activateClicked } = this.state;
        // if (activateClicked) {
        //     this.setState({ activateClicked: false })
        // }
        this.setState({ readyToStart: bool })
    }
    activateClinicClicked = bool => {
        this.setState({ activateClicked: bool })
    }
    SelectedFacility = object => {
        this.setState({ selectFacility: object })
    }

    handleFacilityClick = bool => {
        if (!this.state.readyToStart) return;
        let { user } = this.props.user.data ? this.props.user.data : {};
        this.setState({
            readyToStart: false,
            clinicsStatus: 1,
            draftList: null
        }, () => {
            // firebase.database().ref('.info/connected').on('value', function (snap) {
            this.chechClinicStatus();
            // })
        });

    }
    showMenuFunc = () => {
        let { showMenu } = this.state;
        this.setState({
            showMenu: !showMenu,
        })
    }

    onSearchKey = e => {
        this.setState(
            {
                search: e.target.value.toLowerCase()
            }
        );
    };

    render() {
        let { doctorData, patientData, selectFacility, loading, showMenu, patientOptions, allowed } = this.state;
        let { loggedIn, user, accessType } = this.props.user.data ? this.props.user.data : {}
        let offlineBookings = this.props.Bookings.bookings.bookings
        return (
            (loggedIn && user) ?
                <div className="bootstrap">
                    <section id="main-wrapper" >
                        <div className="container-fluid">
                            <div className="row" style={{ overflow: 'hidden' }}>
                                <div className="col-1 side-nav" style={{ display: showMenu ? 'block' : 'none' }}>
                                    <ul>
                                        <li style={{ height: '60px' }}><a href="/#/"><img src={home} /></a></li>
                                        {/* <li><a href=""><i className="fab fa-buromobelexperte"></i>Dashboard</a></li> */}
                                        <li ><Link to="/dashboard"><i className="fab fa-buromobelexperte"></i>Dashboard</Link></li>
                                        <li ><Link to="/facility-management-system"><i className="far fa-hospital"></i>Facilities</Link></li>
                                        <li ><Link to="/wizard-doctor"><i className="fas fa-user-md"></i>Providers</Link></li>
                                        <li onClick={this.handleFacilityClick} style={{ borderLeft: '3px solid #0096FD' }}><a><i style={{ color: '#0096FD' }} className="far fa-calendar-alt"></i>Calendar</a></li>
                                        <li ><Link to="/patients"><i className="fas fa-users"></i>Patients</Link></li>
                                        <li ><Link to="/history"><i className="fa fa-history"></i>History</Link></li>
                                        <li ><Link to="/feedback"><i className="fa fa-star"></i>Feedback</Link></li>
                                        {
                                            accessType === 'superAdmin' ? (
                                                process.env.REACT_APP_NAME !== 'saeed' ?
                                                    <li ><Link to="/helpers"><i className="fas fa-database"></i>Helpers</Link></li> : '')
                                                : ''
                                        }
                                        <li ><Link to="/settings"><i className="fas fa-cogs"></i>Settings</Link></li>
                                    </ul>

                                </div>
                                <div className="col-11 main-body" style={{ width: '100%', maxWidth: !showMenu ? 'none' : '' }}>
                                    <FMSHeader showMenuFunc={this.showMenuFunc} query={this.props.location.search} onSearchKey={this.onSearchKey} logout={this.logout} title="Calendar" offline={this.state.offline} />
                                    {
                                        (loggedIn && user) ? (
                                            allowed ?
                                                this.renderContent()
                                                :
                                                this.renderAccessDenied()
                                        ) : <div className="main-side">Please login</div>
                                    }
                                </div>
                                {
                                    doctorData ?
                                        <DoctorPopup
                                            doctorData={doctorData}
                                            updateParentState={(data) => this.setState({ doctorSetupUpdatedData: data })}
                                            save={() => this.setState({ doctorData: null })}
                                            dismiss={() => {
                                                this.setState({ doctorData: null, dismissDoctor: true })
                                            }
                                            } /> : ''
                                }
                                {
                                    patientData ?
                                        <PatientPopup offlineBookings={offlineBookings} patientData={patientData} patientOptions={patientOptions} dismiss={() => this.setState({ patientData: null })} selectFacility={selectFacility} adminInfo={user.info} /> : ''
                                }
                            </div>
                        </div>
                    </section>
                </div >
                : loading ?
                    <Dimmer active>
                        <Icon.Group size='big'>
                            <Icon loading size='big' name='spinner' />
                            <div style={{ fontSize: '16px', padding: '10px', fontStyle: 'initial' }}>loading</div>
                        </Icon.Group>
                    </Dimmer>
                    :
                    <HomeLogin show={this.state.modalLoginIsOpen} getUser={() => this.props.getUser(true)} />
        );

    }

    // }
    /*
     * Functions for content getting
     * */
    renderAccessDenied() {
        return (
            <div style={{ height: 'calc( 100% - 60px )' }}>
                <div className="emptystate">
                    <div className="emptystate1">
                        <img src={alertCircle} alt="Img" />
                        <div className="textMesg">No Permission</div>
                        <div className="detailMesg">Contact your admin to access this page</div>
                    </div>
                </div>
            </div>
        )
    }

    selectDoctor = (e, { value }) => {
        let { selectFacility } = this.state;

        let clinicDoctors = []
        let facilityId = selectFacility ? Object.keys(selectFacility)[0] : ''
        if (facilityId) {
            clinicDoctors = (this.props.branches.data[facilityId] && this.props.branches.data[facilityId].doctorsData) ? this.props.branches.data[facilityId].doctorsData : []
        }

        if (value === "All Doctors") {
            this.changeDoctorKey('')
        } else {
            clinicDoctors.map(item => {
                if (item.name === value) {
                    this.changeDoctorKey(item.id)
                }
            })
        }

        this.setState({ selectedDoctor: value })
    }

    changeDoctorKey = (key) => {
        this.setState({
            doctorKey: key,
        })
    }

    renderContent = () => {
        let { draftList, selectFacility, clinicsStatus, doctorSetupUpdatedData, dismissDoctor, search, doctorKey, selectedDoctor } = this.state;
        let { user } = this.props.user.data
        let clinicDoctors = []
        let facilityId = selectFacility ? Object.keys(selectFacility)[0] : ''
        if (facilityId) {
            clinicDoctors = (this.props.branches.data[facilityId] && this.props.branches.data[facilityId].doctorsData) ? this.props.branches.data[facilityId].doctorsData : []
        }
        let doctorsOptions = [{ text: 'All Doctors', value: 'All Doctors', key: 'All Doctors' }]
        clinicDoctors.map(item => {
            return doctorsOptions.push({ text: item.name, value: item.name, key: item.doctorId })
        })

        if (this.state.readyToStart) {
            return (
                <div style={{ height: 'calc( 100% - 60px )' }}>

                    {
                        (clinicsStatus === 1) ? (
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '60px' }}>
                                {
                                    process.env.REACT_APP_OFFLINE_ENABLED ?
                                        (window.screen.width <= 768) ? (
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '60px', width: '100%' }}>
                                                <Dropdown selection placeholder="Select doctor" options={doctorsOptions} value={selectedDoctor} search style={{ margin: '20px 30px', width: '100%' }} onChange={this.selectDoctor} />
                                            </div>
                                        ) : (
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '60px' }}>
                                                <div style={{ padding: '20px 30px', fontSize: '20px', color: '#4A4A4A' }}>Appointment system</div>
                                                <div className='addOfflineAppointment' onClick={() => {
                                                    let doctorsKeys = selectFacility[facilityId].doctors
                                                    this.setState({ patientData: { doctorsKeys, clinicDoctors } })
                                                }}>Add appointment</div>
                                            </div>
                                        )
                                        : ''
                                }

                            </div>
                        ) : (clinicsStatus === 2) ? (
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '60px' }}>
                                <div style={{ padding: '20px 30px', fontSize: '20px', color: '#4A4A4A' }}>Setup</div>
                            </div>
                        ) : ''
                    }
                    <MainWizard
                        doctorKey={doctorKey}
                        changeDoctorKey={this.changeDoctorKey}
                        draftList={selectFacility}
                        wizardOptions={
                            {
                                branches: this.state.branches,
                            }
                        }
                        selectFacility={
                            {
                                readyToStartChange: this.readyToStartChange
                            }
                        }
                        currentUser={user}
                        clinicsStatus={clinicsStatus}
                        changeClinicsStatus={this.clinicStatusSelected}
                        clinicDoctors={clinicDoctors}
                        doctorData={(doctorData) => this.setState({ doctorData })}
                        doctorSetupUpdatedData={doctorSetupUpdatedData}
                        patientData={(patientData) => {
                            this.setState({ patientData })
                        }}
                        patientOptions={(patientOptions) => this.setState({ patientOptions })}
                        dismissDoctor={dismissDoctor}
                        resetDismissDoctor={() => this.setState({ dismissDoctor: false })}
                    />
                </div>
            )

        }
        return (
            draftList &&
            <SelectFacility
                Analytics={this.props.Analytics}
                selectFacility={
                    {
                        search: search,
                        branchChange: this.branchChange,
                        branches: this.state.branches,
                        clinics: draftList,
                        clinicsStatus: this.clinicStatusSelected,
                        SelectedFacility: this.SelectedFacility,
                        facilityNameChange: this.facilityNameChange,
                        readyToStartChange: this.readyToStartChange,
                        activateClinicClicked: this.activateClinicClicked

                    }

                }
                allBookings={this.props.Bookings?.allBookings || []}
                loading={this.state.loading}
            />
        )
    }

}

function mapStateToProps({ user, branches, Bookings, Analytics }) {
    return {
        user,
        branches,
        Bookings,
        Analytics
    }
}

const mapDispatchToProps = dispatch => ({
    getUser: () => dispatch(userActions.getUser()),
    updateBranches: (clinic, oldList) => dispatch(userActions.updateBranches(clinic, oldList)),
    // getOrderListSuccess: (updatedList) => dispatch(getOrderListSuccess(updatedList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WizardClinic);