import fire from 'firebase/app'
import 'firebase/database';

import Moment, { min } from 'moment';

const calenderEndDate = (item) => {
    let stringDate = item.date.replace(" at", ",");
    let time = new Date(stringDate);
    time.setHours(time.getHours() - time.getTimezoneOffset() / 60)
    item.duration ? time.setMinutes(time.getMinutes() + item.duration) : time.setMinutes(time.getMinutes() + 30)
    return time.toISOString();
}


const diff_minutes = (dt2, dt1) => {
    var seconds = (dt2.getTime() - dt1.getTime()) / 1000;
    let mins = seconds / 60;
    let hours = mins / 60;
    let days = hours / 24;
    if (mins > 0 && mins <= 60) {
        return String(Math.round(mins)) + " Minutes ago";
    }
    if (hours <= 24) {
        return String(Math.round(hours)) + " Hours ago";
    } else {
        return String(Math.round(days)) + " Days ago";

    }
}


export const getBookingsArray = (offlineBookings, onlineBookings, clinicDoctors) => {
    var bookings = []
    return new Promise((resolve, reject) => {
        onlineBookings.map(item => {
            let doctorItem = clinicDoctors.find(doctorItem => doctorItem.id === item.doctor);
            let doctorName = doctorItem ? doctorItem.name : '';
            if (item.date !== "today" && item.date !== "tomorrow") {
                let stringDate = item.date.replace(" at", ",");
                let date = new Date(stringDate);
                date.setHours(date.getHours() - date.getTimezoneOffset() / 60)
                let post = {
                    accepted: item.accepted,
                    canceled: item.canceled,
                    created: item.created,
                    facilityAdmin: item.facilityAdmin,
                    facilityName: item.facilityName,
                    paymentType: item.paymentType,
                    amount: item.amount,
                    authRequest: item.authRequest,
                    authId: item.authId,
                    insurance: item.insurance,
                    bookingId: item.bookingId,
                    patientName: item.patientName,
                    start: date.toISOString(),
                    end: calenderEndDate(item),
                    dateStart: date.toISOString(),
                    dateEnd: calenderEndDate(item),
                    doctorName: doctorName,
                    status: item.status,
                    online: true,
                    new: false,
                    facilityId: item.facilityId,
                    doctorId: item.doctor,
                    patientId: item.patient,
                    patientImg: item.userData ? item.userData.profilePicture : '',
                    patientEmail: item.userData ? item.userData.email : '',
                    patientAge: item.userData ? item.userData.birthday : '',
                    patientGender: item.userData ? item.userData.gender : '',
                    patientInsurance: item.insurance,
                    patientNumber: item.userData ? item.userData.phone : '',
                    refrenceNumber: item.userData ? item.userData.RN : '',
                    doctorColor: doctorItem?.color || '#cacaca',
                    className: 'appointmentlabel',
                    date: date,
                    time: diff_minutes(new Date(), new Date(item.created)),
                    selectedDate: item.date,
                    modified: item.modified,
                    duration: item.duration,
                    doctorDuration: doctorItem?.duration,
                    notes: item.notes ? item.notes : 'No added notes',
                    service: item.service,

                }
                bookings.push(post)

            }

        })


        offlineBookings.map(item => {
            let doctorItem = clinicDoctors.find(doctorItem => doctorItem.id === item.doctor)
            let doctorName = doctorItem ? doctorItem.name : '';
            if (item.date !== "today" && item.date !== "tomorrow") {

                let stringDate = item.date.replace(" at", ",");
                let date = new Date(stringDate);
                date.setHours(date.getHours() - date.getTimezoneOffset() / 60)
                let post = {
                    accepted: item.accepted,
                    canceled: item.canceled,
                    created: item.created,
                    facilityAdmin: item.facilityAdmin,
                    facilityName: item.facilityName,
                    insurance: item.insurance,
                    bookingId: item.bookingId,
                    patientName: item.patientName,
                    start: date.toISOString(),
                    end: calenderEndDate(item),
                    dateStart: date.toISOString(),
                    dateEnd: calenderEndDate(item),
                    doctorName: doctorName,
                    status: item.status,
                    online: false,
                    new: false,
                    facilityId: item.facilityId,
                    doctorId: item.doctor,
                    patientId: item.patient,
                    patientImg: item.userData ? item.userData.profilePicture : '',
                    patientEmail: item.userData ? item.userData.email : '',
                    patientAge: item.userData ? item.userData.birthday : '',
                    patientGender: item.userData ? item.userData.gender : '',
                    patientInsurance: item.insurance,
                    patientNumber: item.userData ? item.userData.number : '',
                    doctorColor: doctorItem?.color,
                    className: 'appointmentlabel',
                    date: date,
                    time: diff_minutes(new Date(), new Date(item.created)),
                    selectedDate: item.date,
                    modified: item.modified,
                    duration: item.duration,
                    doctorDuration: doctorItem?.duration,
                    notes: item.notes ? item.notes : 'No added notes',
                    service: item.service,
                    refrenceNumber: item.userData ? item.userData.RN : ''
                }
                bookings.push(post)

            }

        })

        resolve({ bookings })


    });
}