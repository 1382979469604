import { createAction, createReducer } from 'redux-action';
import { fetchFilterParams, SetFilterParams } from './../services/FilterServices';

export const GET_FILTER_PARAMS = 'GET_FILTER_PARAMS ';
export const SET_FILTER_PARAMS = 'SET_FILTER_PARAMS ';


const getFilterAction = createAction(GET_FILTER_PARAMS, () => {
  return fetchFilterParams().then(data => {
    return data;
  }).catch(err => console.log('LOG ::: err ::: ',err));
});

const setFilterAction = createAction(SET_FILTER_PARAMS, (type) => {
  return SetFilterParams(type).then(data => {
      return data;
  }).catch(err => console.log('LOG ::: err ::: ',err));
});


export const filterActions = {
    getFilterParams: getFilterAction,
    setFilterParams: setFilterAction,
};
// Actions that need dispatch reference
export const complexActions = {

};
// All actions
export const allActions = {
    ...filterActions,
    ...complexActions,
};
const initialState = {
  data: [],
};

const searchReducer = createReducer(initialState, {
  [GET_FILTER_PARAMS]: (actionPayload, state) => {
      return { ...state, data: actionPayload };
  },
  [SET_FILTER_PARAMS]: (actionPayload, state) => {
      return { ...state, data: actionPayload };
  }
});

export default searchReducer;
