import React, { Component } from 'react';
import PropTypes from 'prop-types'
import 'react-image-crop/lib/ReactCrop.scss';
import DatePicker from 'react-modern-datepicker';
import { searchInArrayWithObjects } from '../../../helpers/Full';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Dropdown } from 'semantic-ui-react'
import RightFelowship from '../RightSteps/RightFelowship'
import Swal from 'sweetalert2';

class Felowship extends Component {
    static propTypes = {
        doctorObject: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        updateParentState: PropTypes.func.isRequired,
    }

    constructor(props, context) {
        super(props, context);
    }
    state = {
        selectedFacility: [],
        canAdd: false,
        currentText: '',

        selectedSpeciality: [],
        canAddSpec: false,
        currentTextSpec: '',
        specialties: {},
        ToDate: null,
        doctorFelowship: {
            facilityName: '',
            speciality: '',
            to: '',
            country: ''
        },

    }
    selectCountry(val) {
        let { doctorFelowship } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        // doctorObject[activeBranchKey].doctorFelowshipObject.country = val
        doctorFelowship.country = val;

        this.props.updateParentState(doctorObject);
    }

    handleToDateChange(date) {
        let { doctorFelowship } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        doctorFelowship.to = date

        this.setState({
            ToDate: date
        });
        this.props.updateParentState(doctorObject);

    }

    render() {
        let { doctorObject, activeBranchKey } = this.props;
        let { doctorFelowship } = this.state;
        let doctorObjects = doctorObject[activeBranchKey].doctorFelowship;

        return (
            <div>
                <div style={{ display: 'flow-root' }}>
                    <div className="box" >
                        <label>Speciality <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                        {this.renderSelectedSpecialities()}
                    </div>
                    <div className="box">
                        <label>Facility <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                        {this.renderSelectedClinics()}
                    </div>
                    <div className="box address-box">
                        <div className="input-half marg-right">
                            <label>Country <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                            <CountryDropdown
                                classes="custom-select"
                                value={doctorFelowship.country}
                                onChange={(val) => this.selectCountry(val)} />
                        </div>

                        <div className="input-half marg-right">
                            <label>Completion Date <span style={{ color: 'darkred', fontSize: '14px' }}>*</span></label>
                            <DatePicker
                                format={'YYYY-MM-DD'}
                                className="custom-select"
                                date={this.state.ToDate}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                peekNextMonth
                                fixedHeight
                                onChange={this.handleToDateChange.bind(this)}
                                endDate={this.state.ToDate}
                                placeholderText="Select date"
                            />
                        </div>
                    </div>
                    <button className="save-btn-local" onClick={this.addDoctorObject}>Add</button>
                </div>
                {
                    (doctorObjects.length) ? (
                        <div className="right-side" style={{ width: '100%', marginTop: '24px' }}>
                            <RightFelowship updateParentState={this.props.updateParentState}
                                activeBranchKey={activeBranchKey}
                                doctorObject={doctorObject} />
                        </div>
                    ) : ''
                }
            </div>
        )
    }
    addDoctorObject = () => {
        let { doctorObject, activeBranchKey } = this.props;
        let { doctorFelowship } = this.state;
        let doctorObjects = doctorObject[activeBranchKey].doctorFelowship;
        doctorObject[activeBranchKey].doctorFelowshipObject = {};
        let doctorPublicObjects = doctorObject[activeBranchKey].doctorFelowshipObject;

        if (doctorFelowship.facilityName.length && doctorFelowship.speciality.length && doctorFelowship.country.length && doctorFelowship.to.length) {
            doctorPublicObjects.country = doctorFelowship.country;
            doctorPublicObjects.to = doctorFelowship.to;
            doctorPublicObjects.facilityName = doctorFelowship.facilityName;
            doctorPublicObjects.speciality = doctorFelowship.speciality;
            doctorObjects.push(doctorPublicObjects)

            doctorFelowship.facilityName = ''
            doctorFelowship.speciality = ''
            doctorFelowship.country = ''
            doctorFelowship.to = ''
            doctorObject[activeBranchKey].felowshipFacility = [];
            doctorObject[activeBranchKey].felowshipSpeciality = [];
            this.setState({
                ToDate: null,
                currentText: ''
            });
        }else{
            Swal.fire({
                heightAuto:false,
                type:'error',
                title:'Alert',
                text:'Please fill all requirments'
            })
        }
        this.props.updateParentState(doctorObject);

    }
    componentWillMount = () => {
        const { doctorObject, activeBranchKey } = this.props;
        let { doctorFelowship } = this.state;

        doctorFelowship.facilityName = ''
        doctorFelowship.speciality = ''
        doctorFelowship.country = ''
        doctorFelowship.to = ''
        doctorObject[activeBranchKey].felowshipFacility = [];
        doctorObject[activeBranchKey].felowshipSpeciality = [];
        this.getSpecialtiesLists()
    }
    getSpecialtiesLists = () => {
        const { doctorObject, activeBranchKey } = this.props;
        let selectedResidencySpecialties = doctorObject[activeBranchKey].felowshipSpeciality;
        let specialties = []
        let defaultSpecialties = []
        let dbSpecialities = this.props.specialties
        for (var index in dbSpecialities) {
            let val = dbSpecialities[index];
            val.id = index;
            if (selectedResidencySpecialties.indexOf(index) === -1) {
                specialties.push({
                    value: index,
                    text: val.name,
                });
            }
            defaultSpecialties.push(val)
        }
        this.setState({ specialties, defaultSpecialties });
        this.props.updateParentState(doctorObject);

    }
    /**
    *
    */
    blurSelect = () => {
        let _this = this;
        setTimeout(function () {
            _this.setState({
                canAdd: false,
                currentText: ''
            })
        }, 300)
    }


    /**
    * @param e
    */
    onSelectInput = (e) => {
        if (e) {
            let _this = this;
            if (!e.length) return;
            setTimeout(function () {
                let r = document.querySelector(".Select-noresults");
                _this.setState({
                    canAdd: r !== null,
                    currentText: e
                })
            }, 400)
        }
    }

    addFacility = (e, { value }) => {
        this.setState({ currentText: value })

        let { doctorFelowship } = this.state;

        let { selectedFacility } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        doctorObject[activeBranchKey].felowshipFacility = [];
        let facility = doctorObject[activeBranchKey].felowshipFacility;
        if (value.length > 2 && !searchInArrayWithObjects(value, 'name', selectedFacility) && facility.indexOf(value) === -1) {
            facility.push(value)
            // doctorObject[activeBranchKey].doctorFelowshipObject.facilityName = currentText
            doctorFelowship.facilityName = value

        }
        this.props.updateParentState(doctorObject);
    }
    removeFacility = (id) => {
        let { doctorFelowship } = this.state;

        let { doctorObject, activeBranchKey } = this.props;
        let facility = doctorObject[activeBranchKey].felowshipFacility;

        let index = facility.indexOf(id);
        // doctorObject[activeBranchKey].doctorFelowshipObject.facilityName = ''
        doctorFelowship.facilityName = ''

        facility.splice(index, 1);

        this.props.updateParentState(doctorObject);

    }

    handleChange = (e, { value }) => {
        let _this = this
        let { doctorObject, activeBranchKey } = this.props;
        let facility = doctorObject[activeBranchKey].felowshipFacility;

        if (value === "") {
            facility.filter((item, index) => {
                _this.removeFacility(item)
            })
        }
        this.setState({ currentText: value })
    }

    renderSelectedClinics = () => {
        let { currentText } = this.state;
        return (
            <Dropdown
                selection
                search
                fluid
                noResultsMessage="Type facility name"
                placeholder="Type facility name"
                className="selectiondropdown"
                upward={false}
                allowAdditions
                text={currentText}
                value={currentText}
                additionLabel='Add facility: '
                onAddItem={this.addFacility}
                onChange={this.addFacility}
                clearable
            />
        )
    }

    addSpeciality = (e, { value }) => {
        this.setState({ currentTextSpec: value })

        let { doctorFelowship } = this.state;

        let { specialties } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        doctorObject[activeBranchKey].felowshipSpeciality = [];
        let speciality = doctorObject[activeBranchKey].felowshipSpeciality;
        if (value === "") {
            this.removeSelectedSpecialties(value)
        } else {
            speciality.push(value)
            doctorFelowship.speciality = this.getNameById(value, 'defaultSpecialties');
        }
        this.props.updateParentState(doctorObject);
        this.setState({
            specialties,
            doctorFelowship
        })
    }
    getNameById = (id, where) => {
        let x = this.state[where].filter(function (v) {
            return v.id === id; // Filter out the appropriate one
        })[0];
        if (x) {
            return x.name
        }
    };
    removeSelectedSpecialties = (id) => {
        let { doctorFelowship } = this.state;
        let { specialties } = this.state;
        let { doctorObject, activeBranchKey } = this.props;
        let felowshipSpeciality = doctorObject[activeBranchKey].felowshipSpeciality;
        let name = this.getNameById(id, 'defaultSpecialties');

        let index = felowshipSpeciality.indexOf(id);
        doctorFelowship.speciality = ''

        if (index > -1) {
            felowshipSpeciality.splice(index, 1);

            let val = { name: name, id: id };
            specialties.push(val);
        }
        this.setState({
            specialties: specialties
        })
        this.props.updateParentState(doctorObject);
    }

    handleSpecChange = (e, { value }) => {
        let _this = this
        let { doctorObject, activeBranchKey } = this.props;
        let speciality = doctorObject[activeBranchKey].felowshipSpeciality;

        if (value === "") {
            speciality.filter((item, index) => {
                _this.removeSpeciality(item)
            })
        }
        this.setState({ currentTextSpec: value })
    }
    renderSelectedSpecialities = () => {
        let { currentTextSpec, specialties } = this.state;

        return (
            <Dropdown
                selection
                search
                fluid
                placeholder="Type Speciality name"
                className="selectiondropdown"
                upward={false}
                text={currentTextSpec ? this.getNameById(currentTextSpec, 'defaultSpecialties') : ''}
                value={currentTextSpec ? this.getNameById(currentTextSpec, 'defaultSpecialties') : ''}
                options={specialties}
                onChange={this.addSpeciality}
                clearable
            />
        )
    }
}
export default Felowship