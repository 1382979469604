import React, { Component } from 'react';


class NotFountComponent extends Component {

    render() {
        return (
            <div>
                <main className="not-found">
                   
                    <div className="row">
                        <div className="wrap">
                            <div className="results">
                                <div className="top">
                                    <h1>WE'RE VERY S O R R Y! </h1>
                                    <p>We have an URGENT case! Kindly go back home!</p>
                                    <button><a href="/#/">Home</a></button>
                                </div>
                                <div className="bottom"/>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}
export default NotFountComponent