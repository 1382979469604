import React, { Component } from 'react';
import { firestore, storage } from 'firebase'
import { connect } from 'react-redux';
import FMSHeader from '../Header/defaultHeader'
import { userActions } from '../../modules/user-module';
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import alertCircle from '../../img/alert-circle.png';
import home from '../../img/thriveFmsLogo.png';
import { Button, Icon, Table, Dimmer, Input, Dropdown, TextArea } from 'semantic-ui-react';
import './helpers.css'
import ImageUploader from 'react-images-upload'
import Resizer from 'react-image-file-resizer';
import Swal from 'sweetalert2';

class Helpers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: true,
            step: 0,
            loading: true,
            menuSections: [{ label: "Aminities", ref: 'amenities' },
            { label: "Areas of interest", ref: 'conditions' },
            { label: "Services", ref: "services" },
            { label: "Specialities", ref: 'specialties' },
            { label: "Insurances", ref: 'insurances' }
            ]
        }
        this.onDropPic = this.onDropPic.bind(this);

    }
    componentWillMount() {
        let specPromis = new Promise((resolve, reject) => {
            firestore().collection('specialties').get().then(records => {
                if (records.empty) {
                    resolve([])
                }
                let specialties = []
                records?.forEach(record => {
                    let data = record.data()
                    data.id = record.id
                    specialties.push(data)
                })
                this.setState({ specialties })
                resolve(specialties)
            })
        })
        let srevicePromis = new Promise((resolve, reject) => {
            firestore().collection('services').get().then(records => {
                if (records.empty) {
                    resolve([])
                }
                let services = []
                records?.forEach(record => {
                    let data = record.data()
                    data.id = record.id
                    services.push(data)
                })
                resolve(services)

                this.setState({ services })
            })
        })
        let conditionsPromis = new Promise((resolve, reject) => {

            firestore().collection('conditions').get().then(records => {
                if (records.empty) {
                    resolve([])
                }
                let conditions = []
                records?.forEach(record => {
                    let data = record.data()
                    data.id = record.id
                    conditions.push(data)
                })
                resolve(conditions)
                this.setState({ conditions })
            })
        })
        let aminitiesPromis = new Promise((resolve, reject) => {

            firestore().collection('amenities').get().then(records => {
                if (records.empty) {
                    resolve([])
                }
                let amenities = []
                records?.forEach(record => {
                    let data = record.data()
                    data.id = record.id
                    amenities.push(data)
                })
                resolve(amenities)

                this.setState({ amenities })
            })
        })
        let insuPromis = new Promise((resolve, reject) => {
            firestore().collection('insurances').get().then(records => {
                if (records.empty) {
                    resolve([])
                }
                let insurances = []
                records?.forEach(record => {
                    let data = record.data()
                    data.id = record.id
                    insurances.push(data)
                })
                resolve(insurances)
                this.setState({ insurances })
            })
        })

        Promise.all([specPromis, srevicePromis, conditionsPromis, aminitiesPromis, insuPromis]).then((results) => {
            this.setState({
                specialties: results[0],
                services: results[1],
                conditions: results[2],
                amenities: results[3],
                insurances: results[4],
                loading: false
            })
        })

    }
    render() {
        let { showMenu } = this.state
        let { loggedIn, user, accessType } = this.props.user.data ? this.props.user.data : {}
        return (
            <div className="bootstrap">
                <section id="main-wrapper" >
                    <div className="container-fluid">
                        <div className="row" style={{ overflow: 'hidden' }}>
                            <div className="col-1 side-nav" style={{ display: showMenu ? 'block' : 'none' }}>
                                <ul>
                                    <li style={{ height: '60px' }}><a href="/#/"><img src={home} /></a></li>
                                    <li ><Link to="/dashboard"><i className="fab fa-buromobelexperte"></i>Dashboard</Link></li>
                                    <li ><Link to="/facility-management-system"><i className="far fa-hospital"></i>Facilities</Link></li>
                                    <li ><Link to="/wizard-doctor"><i className="fas fa-user-md"></i>Providers</Link></li>
                                    <li ><Link to="/appointment-system"><i className="far fa-calendar-alt"></i>Calendar</Link></li>
                                    <li ><Link to="/patients"><i className="fas fa-users"></i>Patients</Link></li>
                                    <li ><Link to="/history"><i className="fa fa-history"></i>History</Link></li>
                                    <li ><Link to="/feedback"><i className="fa fa-star"></i>Feedback</Link></li>
                                    {
                                        accessType === 'superAdmin' ?
                                            (
                                                process.env.REACT_APP_NAME !== 'saeed' ?
                                                    <li style={{ borderLeft: '3px solid #0096FD' }}><a><i style={{ color: '#0096FD' }} className="fas fa-database"></i>Helpers</a></li>
                                                    : '')
                                            : ''
                                    }
                                    <li ><Link to="/settings"><i className="fas fa-cogs"></i>Settings</Link></li>
                                </ul>

                            </div>
                            <div className="col-11 main-body" style={{ width: '100%' }}>
                                <FMSHeader showMenuFunc={() => this.setState({ showMenu: !this.state.showMenu })} query={''} title="Helpers" offline={false} />
                                {
                                    (loggedIn && user) ? (
                                        accessType === 'superAdmin' ?
                                            this.renderContent()
                                            :
                                            this.renderAccessDenied()
                                    ) : <div className="main-side">Please login</div>
                                }
                            </div>

                        </div>
                    </div>
                </section>
            </div >
        )

    }
    renderContent = () => {
        let { step, specialties, loading, conditions, services, amenities, insurances, addFlag, menuSections } = this.state
        return (
            <div style={{ height: 'calc( 100% - 60px )', display: 'flex' }}>
                {
                    addFlag ?
                        <Dimmer style={{ position: 'fixed' }} active>
                            {this.renderPopup(step)}
                            {/* <AddStaff dismiss={() => this.setState({ addStafFlag: false, staffInfo: null })} clinicsList={clinicsList} staffInfo={staffInfo} accessType={accessType} /> */}
                        </Dimmer>
                        : ''
                }
                <div className="leftSideMenu">
                    <div className='menuSection'>
                        {
                            menuSections.map((item, index) => {
                                return (
                                    <div key={index} className={index === step ? "selected" : "menuItem"} onClick={() => this.setState({ step: index })}>
                                        {item.label}
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
                <div className="rightSideMenu">
                    <div className="selectedHeaderContainer">
                        <div style={{ width: '50%' }}>{menuSections[step ? step : 0].label}</div>
                        {

                            <div className="headerButtonContainer">
                                <Button onClick={() => this.setState({ addFlag: true, edit: {} })} style={{ width: "180px" }} basic color='blue' content={'Add ' + menuSections[step].label} />
                            </div>
                        }
                    </div>
                    <div className="content">

                        {
                            loading ?
                                <div style={{ height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Icon.Group size='big'>
                                        <Icon loading size='big' name='spinner' color='grey' />
                                    </Icon.Group>
                                </div>
                                : step === 0 ?
                                    amenities && amenities.length > 0 ?

                                        <Table basic='very'>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell className="tableHeadersHelpers" >Name</Table.Cell>
                                                    <Table.Cell className="tableHeadersHelpers" >Arabic Name</Table.Cell>
                                                </Table.Row>
                                                {
                                                    amenities.map((item, index) => {
                                                        return (
                                                            <Table.Row key={index}>
                                                                <Table.Cell className="tableCellsHelpers" >{item.name}</Table.Cell>
                                                                <Table.Cell className="tableCellsHelpers" >{item.name_ar}</Table.Cell>
                                                                <Table.Cell className="tableActionBtn" >
                                                                    <Button className='atnBtn' basic content="Delete" color='red' onClick={() => this.confirmDelete(item)} />
                                                                    <Button className='atnBtn' color='blue' onClick={() => this.setState({ addFlag: true, edit: JSON.parse(JSON.stringify(item)) })} basic content="Edit" />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    })
                                                }
                                            </Table.Body>
                                        </Table>
                                        : 'No Records Found!'

                                    : step === 1 ?
                                        conditions && conditions.length > 0 ?

                                            <Table basic='very'>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell className="tableHeadersHelpers" >Name</Table.Cell>
                                                        <Table.Cell className="tableHeadersHelpers" >Arabic Name</Table.Cell>
                                                    </Table.Row>
                                                    {
                                                        conditions.map((item, index) => {
                                                            return (
                                                                <Table.Row key={index}>
                                                                    <Table.Cell className="tableCellsHelpers" >{item.name}</Table.Cell>
                                                                    <Table.Cell className="tableCellsHelpers" >{item.name_ar}</Table.Cell>
                                                                    <Table.Cell className="tableActionBtn" >
                                                                        <Button className='atnBtn' basic content="Delete" color='red' onClick={() => this.confirmDelete(item)} />
                                                                        <Button className='atnBtn' color='blue' onClick={() => this.setState({ addFlag: true, edit: JSON.parse(JSON.stringify(item)) })} basic content="Edit" />
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })
                                                    }
                                                </Table.Body>
                                            </Table>
                                            : 'No Records Found!'

                                        : step === 2 ?
                                            services && services.length > 0 ?

                                                <Table basic='very'>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell className="tableHeadersHelpers" >Name</Table.Cell>
                                                            <Table.Cell className="tableHeadersHelpers" >Arabic Name</Table.Cell>
                                                        </Table.Row>
                                                        {
                                                            services.map((item, index) => {
                                                                return (
                                                                    <Table.Row key={index}>
                                                                        <Table.Cell className="tableCellsHelpers" >{item.name}</Table.Cell>
                                                                        <Table.Cell className="tableCellsHelpers" >{item.name_ar}</Table.Cell>
                                                                        <Table.Cell className="tableActionBtn" >
                                                                            <Button className='atnBtn' basic content="Delete" color='red' onClick={() => this.confirmDelete(item)} />
                                                                            <Button className='atnBtn' color='blue' onClick={() => this.setState({ addFlag: true, edit: JSON.parse(JSON.stringify(item)) })} basic content="Edit" />
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                )
                                                            })
                                                        }
                                                    </Table.Body>
                                                </Table>
                                                : 'No Records Found!'

                                            : step === 3 ?
                                                specialties && specialties.length > 0 ?
                                                    <Table basic='very'>
                                                        <Table.Body>
                                                            <Table.Row>
                                                                <Table.Cell className="tableHeadersHelpers" >Name</Table.Cell>
                                                                <Table.Cell className="tableHeadersHelpers" >Arabic Name</Table.Cell>
                                                                <Table.Cell className="tableHeadersHelpers" >Area of intrest</Table.Cell>
                                                                <Table.Cell className="tableHeadersHelpers" >Services</Table.Cell>

                                                            </Table.Row>
                                                            {
                                                                specialties.map(item => {
                                                                    return (
                                                                        <Table.Row key={item.id}>
                                                                            <Table.Cell className="tableCellsHelpers" >{item.name}</Table.Cell>
                                                                            <Table.Cell className="tableCellsHelpers" >{item.name_ar}</Table.Cell>
                                                                            <Table.Cell className="tableCellsHelpers" >{item?.conditions?.length}</Table.Cell>
                                                                            <Table.Cell className="tableCellsHelpers" >{item?.services?.length}</Table.Cell>

                                                                            <Table.Cell className="tableActionBtn" >
                                                                                <Button className='atnBtn' basic content="Delete" color='red' onClick={() => this.confirmDelete(item)} />
                                                                                <Button className='atnBtn' onClick={() => {
                                                                                    let newItem = JSON.parse(JSON.stringify(item))
                                                                                    newItem.conditions = this.getSpecs(item, 'conditions')
                                                                                    newItem.services = this.getSpecs(item, 'services')
                                                                                    this.setState({ addFlag: true, edit: newItem })
                                                                                }
                                                                                } basic content="Edit" color='blue' />
                                                                            </Table.Cell>

                                                                        </Table.Row>
                                                                    )
                                                                })
                                                            }
                                                        </Table.Body>
                                                    </Table>
                                                    : 'No Records Found!'

                                                : step === 4 ?
                                                    insurances && insurances.length > 0 ?
                                                        <Table basic='very'>
                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.Cell className="tableHeadersHelpers" >Name</Table.Cell>
                                                                    <Table.Cell className="tableHeadersHelpers" >Arabic Name</Table.Cell>
                                                                    <Table.Cell className="tableHeadersHelpers" >Networks</Table.Cell>
                                                                </Table.Row>
                                                                {
                                                                    insurances.map(item => {
                                                                        return (
                                                                            <Table.Row key={item.id}>
                                                                                <Table.Cell className="tableCellsHelpers" >{item.name}</Table.Cell>
                                                                                <Table.Cell className="tableCellsHelpers" >{item.name_ar}</Table.Cell>
                                                                                <Table.Cell className="tableCellsHelpers" >{item.networks && item.networks.length}</Table.Cell>

                                                                                <Table.Cell className="tableActionBtn" >
                                                                                    <Button className='atnBtn' basic content="Delete" color='red' onClick={() => this.confirmDelete(item)} />
                                                                                    <Button className='atnBtn' onClick={() => {
                                                                                        let newItem = JSON.parse(JSON.stringify(item))
                                                                                        this.setState({ addFlag: true, edit: newItem })
                                                                                    }
                                                                                    } basic content="Edit" color='blue' />
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                        )
                                                                    })
                                                                }
                                                            </Table.Body>
                                                        </Table>
                                                        : 'No Records Found!'
                                                    : ''
                        }
                    </div>
                </div>
            </div>
        )
    }

    onDropPic(pic) {
        let _this = this
        let pictures = []
        if (pic.length) {
            let lastPic = pic[pic.length - 1];
            let splitter = lastPic.type.split('/')
            let type = 'jpeg'
            if (splitter && splitter.length > 0) {
                type = splitter[splitter.length - 1]
            }
            Resizer.imageFileResizer(
                lastPic,
                600,
                600,
                type,
                60,
                0,
                uri600 => {
                    var fr = new FileReader();
                    fr.onload = function () {
                        pictures.push({
                            name: 'img',
                            data: uri600,
                            base64: fr.result
                        });
                        _this.setState({ pictures })
                    }
                    fr.readAsDataURL(pic[0]);
                },
                'blob'
            );
        }
    }
    handleChange(value, key) {
        let { edit } = this.state
        edit[key] = value
        this.setState({ edit })
    }
    getSpecs(spec, key) {
        let array = this.state[key]
        let results = []
        if (array) {
            let keys = spec[key]
            if (keys) {
                keys?.forEach(id => {
                    let item = array.find(item => { return (item.id === id) })
                    if (item) {
                        results.push(item.name)

                    }
                })
            }

            // let specConditions = array.filter(item => item.specialties && item.specialties.includes(specId))
            // console.log("specConditions", specConditions)
            // return specConditions.map(item => {
            //     return item.name
            // })
        }
        return results
    }
    renderPopup(step) {
        let { conditions, services, edit, pictures } = this.state
        return (
            <div className='helpersPopup'>
                <div className='helperTitle'>{edit.name ? edit.name : 'Add New'}</div>
                <div className='contents'>
                    {
                        edit.imageUrl || pictures ?
                            <div style={{ height: '150px', display: 'flex', justifyContent: 'center' }} >
                                <img style={{ width: '150px', objectFit: 'contain' }} src={edit.imageUrl || pictures[0].base64} />
                                <div style={{ fontSize: '28px', color: 'red', cursor: 'pointer' }} onClick={() => {
                                    edit.imageUrl = false;
                                    this.setState({ edit, pictures: false })
                                }}>x</div>
                            </div>
                            :
                            <ImageUploader
                                buttonText='Attach Picture'
                                onChange={this.onDropPic}
                                withLabel={false}
                                withIcon={true}
                                className="many"
                                withPreview={false}
                                defaultImages={edit.imageUrl}

                            />

                    }

                    <div className='cell'>
                        <div className='cellTitle'>Name</div>
                        <Input
                            style={{ width: '50%' }}

                            onChange={(e, { value }) => {
                                this.handleChange(value, 'name')
                            }}
                            value={edit.name}
                            placholder='name'
                            fluid
                        />

                    </div>
                    <div className='cell'>
                        <div className='cellTitle'>Arabic Name</div>
                        <Input
                            style={{ width: '50%' }}
                            onChange={(e, { value }) => {
                                this.handleChange(value, 'name_ar')
                            }}
                            value={edit.name_ar}
                            placholder='arabic name'
                            fluid
                        />
                    </div>
                    {
                        step === 3 ?
                            <>
                                <div className='cell'>
                                    <div className='cellTitle'>Definition</div>
                                    <TextArea
                                        style={{ width: '100%', minHeight: '60px', border: '1px solid lightGray', padding: '4px' }}
                                        value={edit.definition}
                                        onChange={(e, { value }) => {
                                            this.handleChange(value, 'definition')
                                        }}
                                    />
                                </div>
                                <div className='cell'>
                                    <div className='cellTitle'>Arabic Definition</div>
                                    <TextArea
                                        style={{ width: '100%', minHeight: '60px', border: '1px solid lightGray', padding: '4px' }}
                                        onChange={(e, { value }) => {
                                            this.handleChange(value, 'definition_ar')
                                        }}
                                        value={edit.definition_ar}
                                    />
                                </div>
                                <div className='cell'>
                                    <div className='cellTitle'>Area of interest</div>
                                    <Dropdown
                                        multiple
                                        selection
                                        search
                                        value={edit.conditions}
                                        options={conditions ? conditions.map(item => {
                                            return { text: item.name, value: item.name, key: item.id }
                                        }) : []}
                                        style={{ width: '600px' }}
                                        onChange={(e, { value }) => {
                                            this.handleChange(value, 'conditions')
                                        }}
                                    />
                                </div>
                                <div className='cell'>
                                    <div className='cellTitle'>Services</div>
                                    <Dropdown
                                        multiple
                                        selection
                                        search
                                        value={edit.services}
                                        options={services ? services.map(item => {
                                            return { text: item.name, value: item.name, key: item.id }
                                        }) : []}
                                        style={{ width: '600px' }}
                                        onChange={(e, { value }) => {
                                            this.handleChange(value, 'services')
                                        }}
                                    />
                                </div>
                            </>
                            : step === 4 ?
                                <div className='cell'>
                                    <div className='cellTitle'>Networks</div>
                                    <Dropdown
                                        multiple
                                        selection
                                        search
                                        allowAdditions
                                        value={edit.networks}
                                        options={edit.networks ? edit.networks.map((item, index) => {
                                            return { text: item, value: item, key: index }
                                        }) : []}
                                        noResultsMessage='Type to add more..'
                                        style={{ width: '600px' }}
                                        onChange={(e, { value }) => {
                                            this.handleChange(value, 'networks')
                                        }}
                                    />
                                </div>
                                : ''
                    }
                </div>
                <div className='actionBtn'>
                    <Button color='red' onClick={() => this.setState({ edit: {}, addFlag: false, pictures: false })}>Cancel</Button>
                    <Button loading={this.state.uploadLoading} color='blue' onClick={() => this.setState({ uploadLoading: true }, () => {
                        this.submit()
                    })}>Add</Button>
                </div>
            </div>
        )
    }
    confirmDelete(item) {
        let { step, menuSections } = this.state
        let ref = menuSections[step].ref
        let label = menuSections[step].label
        Swal.fire({
            title: 'Do you want to remove ' + item.name + ' from ' + label + '?',
            showCancelButton: true,
            confirmButtonText: `Confirm`,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.value) {
                firestore().collection(ref).doc(item.id).delete().then(() => {
                    let array = this.state[ref];
                    array = array?.filter(record => record.id !== item.id)
                    this.setState({ [ref]: array })
                })
            }
        })

    }

    submit() {
        let { step, edit, menuSections, conditions, services, pictures, specialties } = this.state
        let ref = menuSections[step].ref
        let uploadKey = edit.id ? edit.id : firestore().collection(ref).doc().id

        if (ref === 'specialties') {
            let oldSpec = specialties.find(item => item.id === uploadKey)
            let removeConditions = oldSpec?.conditions?.filter(item => !edit?.conditions.includes(item))
            removeConditions?.forEach(kes => {
                firestore().collection('conditions').doc(kes).update({
                    specialties: firestore.FieldValue.arrayRemove(uploadKey)
                })
            })
            let conditionsKeys = edit?.conditions?.map(item => {
                let condition = conditions?.filter(record => record.name === item)[0]?.id
                firestore().collection('conditions').doc(condition).update({
                    specialties: firestore.FieldValue.arrayUnion(uploadKey)
                })
                return condition
            })
            edit.conditions = conditionsKeys
            let removeServices = oldSpec?.services?.filter(item => !edit.services.includes(item))
            removeServices?.forEach(kes => {
                firestore().collection('services').doc(kes).update({
                    specialties: firestore.FieldValue.arrayRemove(uploadKey)
                })
            })
            let servicesKeys = edit?.services?.map(item => {
                let service = services?.filter(record => record.name === item)[0]?.id
                firestore().collection('services').doc(service).update({
                    specialties: firestore.FieldValue.arrayUnion(uploadKey)
                })
                return service
            })
            edit.services = servicesKeys
        }

        let upload = new Promise((resolve, reject) => {
            let pic = pictures && pictures.length > 0 && pictures[0]
            if (!pic) {
                resolve(edit.imageUrl)
            }
            var storageRef = storage().ref().child(ref).child(uploadKey).child("img").put(pic.data, {
                cacheControl: 'public,max-age=300'
            });
            storageRef.on('state_changed', function (snapshot) {
            }, function (error) {
                resolve('')
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    resolve(downloadURL)
                })
            })
        })
        upload.then((url) => {
            if (url) {
                edit.imageUrl = url
            }
            if (edit.id) {
                firestore().collection(ref).doc(edit.id).update(edit).then(() => {
                    let array = this.state[ref];
                    array = array.map(item => {
                        if (item.id === edit.id) {
                            return edit
                        }
                        return item
                    })
                    this.setState({ edit: false, addFlag: false, [ref]: array, pictures: false, uploadLoading: false })
                })
            } else {
                firestore().collection(ref).doc(uploadKey).set(edit).then((result) => {
                    edit.id = uploadKey
                    let array = this.state[ref];
                    array.push(edit)
                    this.setState({ edit: false, addFlag: false, [ref]: array, pictures: false, uploadLoading: false })
                })
            }
        })
    }
    renderAccessDenied() {
        return (
            <div style={{ height: 'calc( 100vh - 60px )' }}>
                <div className="emptystate">
                    <div className="emptystate1">
                        <img src={alertCircle} alt="Img" />
                        <div className="textMesg">No Permission</div>
                        <div className="detailMesg">Contact your admin to access this page</div>
                    </div>
                </div>
            </div>
        )
    }

}

function mapStateToProps({ user }) {
    return { user }
}
const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators(
            userActions,
            dispatch,
        ),
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(Helpers);