import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BasicInfo from './steps/BasicInfo'
import Timings from './steps/Timings'
import RightBasicInfo from './RightSteps/RightBasicInfo'
import Experience from './steps/Experience'
import Awards from './steps/Awards'
import Credentials from './steps/Credentials'
import MedicalSchool from './steps/MedicalSchool'
import Residency from './steps/Residency'
import Felowship from './steps/Felowship'
import StepsBar from './StepsBar'
import StepButtons from './StepButtons'
import randomstring from "randomstring"
import doctorManLogo from '../../img/dr_man.png';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { Dimmer, Icon } from 'semantic-ui-react'

class MainWizard extends Component {

    static propTypes = {
        wizardOptions: PropTypes.shape({
            branches: PropTypes.isRequired,
        }).isRequired,
        selectDoctor: PropTypes.shape({
            readyToStartChange: PropTypes.func.isRequired,
        }).isRequired,
        currentUser: PropTypes.object.isRequired,
        doctorsStatus: PropTypes.number.isRequired,
        publish_save: PropTypes.bool.isRequired,
    };
    state = {
        doctorObject: {},
        activeBranchKey: 'branch1',
        currentStep: 1,
        profilePicture: null,
        fileTypes: ['.jpg', '.jpeg', '.gif', '.png'],
        activeError: false,
        noLogo: false,
        loading: true

    };
    constructor(props, context) {
        super(props, context);
    }


    componentWillMount = () => {
        // this.getSpecialtiesLists()
        const branchId = '-' + randomstring.generate(15);

        let _this = this;

        const { currentUser, publish_save } = this.props;

        let listOfBranches = {};
        let adminName = currentUser.info.adminName ? currentUser.info.adminName : currentUser.info.name ? currentUser.info.name : '';
        let adminPosition = currentUser.info.adminPosition ? currentUser.info.adminPosition : '';
        let adminPhone = currentUser.info.adminPhone ? currentUser.info.adminPhone : [{ country: 'OMN 968', code: '800', number: '' }];

        let { doctorList } = _this.props;

        for (let i = 1; i <= 1; i++) {
            listOfBranches['branch' + i] = {
                superAdmin: currentUser.id,
                doctorId: doctorList ? Object.keys(doctorList)[0] : '',
                name: '',
                name_ar: '',
                email: '',
                nationalities: [],
                languages: [],
                gender: '',
                title: '',
                yearsOfExp: '',
                shortBio: '',
                shortBio_ar: '',
                clinics: {},
                doctorSelectedClinics: [],
                clinicsLength: 0,
                conditions: [],
                services: {},

                //Timings
                doctorTimings: {},

                doctorClinicTimings: {
                    'SAT': {},
                    'SUN': {},
                    'MON': {},
                    'TUE': {},
                    'WED': {},
                    'THU': {},
                    'FRI': {}
                },

                branchName: 'Branch name',
                branchId: branchId,
                branchesId: [],

                schoolFacility: [],
                doctorDegrees: [],
                doctorDegreesObject: {
                    facilityName: '',
                    degree: '',
                    from: '',
                    to: '',
                    city: '',
                    country: ''
                },

                residencyFacility: [],
                doctorResidency: [],
                selectedResidencySpecialties: [],
                doctorResidencyObject: {
                    facilityName: '',
                    speciality: '',
                    from: '',
                    to: '',
                    city: '',
                    country: ''
                },

                felowshipFacility: [],
                felowshipSpeciality: [],
                doctorFelowship: [],
                doctorFelowshipObject: {
                    facilityName: '',
                    speciality: '',
                    from: '',
                    to: '',
                    city: '',
                    country: ''
                },

                experienceFacility: [],
                doctorExperience: [],
                selectedSpecialties: [],
                doctorExperienceObject: {
                    facilityName: '',
                    from: '',
                    to: '',
                    city: '',
                    country: '',
                    speciality: '',
                    title: ''
                },

                awardFacility: [],
                awards: [],
                doctorAwards: [],
                doctorAwardsObject: {
                    awardName: '',
                    facilityName: '',
                    year: '',
                    city: '',
                    country: '',
                },

                //Verification
                adminName: adminName,
                adminPosition: adminPosition,
                adminPhone: adminPhone,
                attachedCertificateUrl: '',
                interestedAd: false,
                terms: false,
                privacy: false,
                //Global
                profilePicture: false,
                haveError: true,
                status: 'verified',
                added: Date.now(),
                created: Date.now(),
                users: {
                    superAdmin: currentUser.id
                },
            }
        }
        Object.keys(listOfBranches).map(function (item) {
            Object.keys(listOfBranches[item].doctorClinicTimings).map(function (day) {
                listOfBranches[item].doctorClinicTimings[day] = {
                    selected: false,
                    opened: false,
                    fixed: false,
                    isTwentyFour: false,
                    fromTime: false,
                    toTime: false,
                    isSplitShift: false,
                    pmFromTime: false,
                    pmToTime: false,
                }

            })
        })

        if (doctorList) {
            let profilePicture = ""
            Object.keys(doctorList).map(function (item, index) {
                Object.keys(doctorList[item]).map(function (subItem) {
                    listOfBranches[Object.keys(listOfBranches)[index]][subItem] = doctorList[item][subItem];
                    profilePicture = doctorList[item].profilePicture
                })
            })
            _this.setState({ doctorObject: listOfBranches, profilePicture, loading: false }, () => {
                this.checkSelectedReqFields();
            })
        }

    }

    checkSelectedReqFields = () => {
        let { doctorObject } = this.state;

        Object.keys(doctorObject).forEach(function (item) {
            let errors = [];

            let issetLogo = 0;
            if (doctorObject[item].profilePicture !== false) issetLogo = 1;

            let doctorSelectedClinics = doctorObject[item].doctorSelectedClinics;
            if (doctorSelectedClinics.length) {
                doctorSelectedClinics.map(function (item) {
                    let selectedSpeciality = (item[1] !== null) ? item[1] : [];
                    let issetOne = 0;
                    Object.keys(selectedSpeciality).map(function (itemR) {
                        if (selectedSpeciality[itemR]) {
                            issetOne = 1;
                        }
                    })
                    errors.push(issetOne);
                })
            }
            errors.push(
                issetLogo,
                doctorObject[item].name.length,
                doctorObject[item].name_ar.length,
                doctorObject[item].doctorSelectedClinics.length,
                doctorObject[item].nationalities.length,
                doctorObject[item].languages.length,
                doctorObject[item].gender.length,
                doctorObject[item].doctorTimings && Object.keys(doctorObject[item].doctorTimings)?.length,
                // doctorObject[item].email.length,
            );
            doctorObject[item].haveError = (errors.indexOf(0) === -1) ? false : true
        });
        this.setState({
            doctorObject: doctorObject,
            loading: false
        })
    }

    myfunction(arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11, arg12, arg13, arg14, arg15, arg16, arg17) {
        var r;
        r = arg1 + arg2 + arg3 + arg4 + arg5 + arg6 + arg7 + arg8 + arg9 + arg10 + arg11 + arg12 + arg13 + arg14 + arg15 + arg16 + arg17;
        return (r);
    }
    checkCompletitionFields() {

        let { doctorObject } = this.state;
        let arg1 = 0, arg2 = 0, arg3 = 0, arg4 = 0, arg5 = 0, arg6 = 0, arg7 = 0, arg8 = 0, arg9 = 0, arg10 = 0, arg11 = 0, arg12 = 0, arg13 = 0, arg14 = 0, arg15 = 0, arg16 = 0, arg17 = 0;
        let _this = this;
        Object.keys(doctorObject).forEach(function (item) {

            // BasicInfo
            if (doctorObject[item].profilePicture.length) {
                arg1 = 3;
            }
            if (doctorObject[item].name.length) {
                arg2 = 3;
            }
            if (doctorObject[item].name_ar.length) {
                arg3 = 3;
            }
            if (doctorObject[item].nationalities.length) {
                arg4 = 2;
            }
            if (doctorObject[item].languages.length) {
                arg5 = 2;
            }
            if (doctorObject[item].gender.length) {
                arg6 = 2;
            }
            if (doctorObject[item].title.length) {
                arg7 = 2;
            }
            if (doctorObject[item].yearsOfExp) {
                arg8 = 1;
            }
            if (doctorObject[item].shortBio.length) {
                arg9 = 1;
            }
            if (doctorObject[item].shortBio_ar.length) {
                arg10 = 1;
            }
            var specSelected = []
            doctorObject[item].doctorSelectedClinics.map(clinicItem => {
                for (var specialities in Object.keys(clinicItem[1])) {
                    let key = Object.keys(clinicItem[1])[specialities]
                    if (clinicItem[1][key]) {
                        specSelected.push(0)
                        break
                    }
                }
                if (specSelected.length == doctorObject[item].doctorSelectedClinics.length) {
                    arg16 = 4;
                }
            })
            if (doctorObject[item].email.length) {
                arg17 = 2;
            }

            //Medical School
            if (doctorObject[item].doctorDegrees.length) {
                arg11 = 15;
            }


            //Residency
            if (doctorObject[item].doctorResidency.length) {
                arg12 = 15;
            }

            //Felowship
            if (doctorObject[item].doctorFelowship.length) {
                arg13 = 15;
            }

            //Experience
            if (doctorObject[item].doctorExperience.length) {
                arg14 = 15;
            }

            //Awards
            if (doctorObject[item].doctorAwards.length) {
                arg15 = 15;
            }



        });
        let value = _this.myfunction(arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11, arg12, arg13, arg14, arg15, arg16, arg17);
        return value;
    }

    /**
     * Change step
     * @param index
     */
    changeCurrentStep = (index) => {

        let { doctorObject } = this.state;
        if (index === 7) {
            this.setState({
                activeBranchKey: 'branch1'
            })
        }
        this.setState({
            currentStep: index,
            doctorObject: doctorObject
        }, () => {
            this.checkSelectedReqFields();
        })
    }
    /**
     *
     * @param list
     * @param callback
     */
    updateCurrentState = (list, callback) => {
        // this.checkCompletitionFields()

        this.setState({
            doctorObject: list
        })
        this.checkSelectedReqFields();
        if (callback)
            callback();
    }


    /**
     * Left bar Logo
     * @param e
     */
    addLogo = (e) => {
        let _this = this;
        let extension = e.target.files[0].name.split('.').pop().toLowerCase(),
            isSuccess = this.state.fileTypes.indexOf('.' + extension) > -1;
        if (isSuccess) {
            let reader = new FileReader(),
                file = e.target.files[0],
                id = 'profilePicture',
                doctorObjectR = this.state.doctorObject;

            reader.onloadend = () => {

                this.setState({
                    profilePicture: reader.result,
                    uploadError: '',
                    noLogo: false
                })
                let cvs = document.createElement('canvas');
                let ctx = cvs.getContext("2d");
                let image = new Image();
                image.onload = function () {
                    cvs.width = this.width;
                    cvs.height = this.height;
                    ctx.drawImage(image, 0, 0);
                    let newImageData = cvs.toDataURL(file.type, 80 / 100);

                    Object.keys(doctorObjectR).forEach(function (key) {
                        doctorObjectR[key][id] = newImageData;
                    });
                    _this.setState({
                        doctorObject: doctorObjectR,
                        profilePicture: newImageData,
                    },
                        () => {
                            _this.checkSelectedReqFields()
                        }
                    )
                };
                image.src = reader.result;
            };
            reader.readAsDataURL(file);
        } else {
            this.setState({
                uploadError: '* .' + extension + ' file is not supported'
            })
        }

    }

    changeErrClick = (check) => {
        let _this = this;
        this.setState({
            activeError: check,
            noLogo: true
        })
        setTimeout(function () {
            _this.setState({
                activeError: false
            })
        }, 1500)
    }

    render() {
        let { currentStep, doctorObject, profilePicture } = this.state;
        const { doctorsStatus, publish_save } = this.props;
        this.checkCompletitionFields();
        return (
            <div style={{ height: 'calc( 100% - 60px)' }}>

                <section data-branches-length={Object.keys(doctorObject).length} id="page-body" style={{ height: '100%', overflow: 'auto' }}>
                    <div className="left-side">
                        {this.renderSidebar()}
                    </div>
                    <div className="tab-content sub-pages" id="myTabContent">
                        {
                            (currentStep === 1) ? (
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="main-side">
                                        {this.renderStep()}

                                    </div >
                                    <div className="right-side">
                                        {this.renderRightSteps()}
                                    </div >
                                </div >
                            ) : (currentStep === 2) ? (
                                <div className="tab-pane fade show active" id="Timing" role="tabpanel" aria-labelledby="Timing-tab">
                                    <div className="main-side">
                                        {this.renderStep()}
                                    </div >
                                </div >
                            ) : (currentStep === 3) ? (
                                <div className="tab-pane fade show active" id="Insurance" role="tabpanel" aria-labelledby="Insurance-tab">
                                    <div className="main-side">
                                        {this.renderStep()}
                                    </div >
                                </div >
                            ) : (currentStep === 5) ? (
                                <div id="Specialities" className="tab-pane fade show active" role="tabpanel" aria-labelledby="Specialities-tab">
                                    <div className="main-side">
                                        {this.renderStep()}
                                    </div >
                                </div >
                            ) : (currentStep === 6) ? (
                                <div className="tab-pane fade show active" id="Insurance" role="tabpanel" aria-labelledby="Insurance-tab">
                                    <div className="main-side">
                                        {this.renderStep()}

                                    </div >
                                </div >
                            ) : (currentStep === 7) ? (
                                <div className="tab-pane fade show active" id="Amenities" role="tabpanel" aria-labelledby="Amenities-tab">
                                    <div className="main-side">
                                        {this.renderStep()}
                                    </div >
                                </div >
                            ) : (currentStep === 8) ? (
                                <div className="tab-pane fade show active" id="Location" role="tabpanel" aria-labelledby="Location-tab">
                                    <div className="main-side">
                                        {this.renderStep()}
                                    </div >
                                </div >
                            ) : (currentStep === 9) ? (
                                <div className="tab-pane fade show active" id="Timing" role="tabpanel" aria-labelledby="Timing-tab">
                                    <div className="main-side">
                                        {this.renderStep()}
                                    </div >
                                </div >
                            ) : ''
                        }
                        <StepButtons updateParentState={this.updateCurrentState} profilePicture={profilePicture} changeErrClick={this.changeErrClick} readyToStartChange={this.props.selectDoctor.readyToStartChange} changeCurrentStep={this.changeCurrentStep} doctorObject={doctorObject} currentStep={currentStep} doctorsStatus={doctorsStatus} publish_save={publish_save} />

                    </div >
                </section>
            </div>
        )
    }

    convertTimestamp(timestamp) {
        if (!timestamp) return null;

        var d = new Date(timestamp),
            yyyy = d.getFullYear(),
            mm = ('0' + (d.getMonth() + 1)).slice(-2),
            dd = ('0' + d.getDate()).slice(-2),
            hh = d.getHours(),
            h = hh,
            min = ('0' + d.getMinutes()).slice(-2),
            ampm = 'AM',
            time;

        if (hh > 12) {
            h = hh - 12;
            ampm = 'PM';
        } else if (hh === 12) {
            h = 12;
            ampm = 'PM';
        } else if (hh == 0) {
            h = 12;
        }

        time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;

        return time;
    }
    /*
     * Functions for content getting
     * */

    /**
     *
     * @returns {XML}
     */
    renderSidebar = () => {

        let { doctorObject, profilePicture, activeBranchKey, uploadError, activeError, currentStep, publish_save, loading } = this.state;
        let timeAdded = this.convertTimestamp(doctorObject[activeBranchKey].created);
        let timeClamied = this.convertTimestamp(doctorObject[activeBranchKey].added);
        let timeEdited = this.convertTimestamp(doctorObject[activeBranchKey].edited);
        if (currentStep === 10) return null;
        return (
            <div style={{ height: '100%' }}>
                {
                    loading ?
                        <Dimmer active>
                            <Icon.Group size='big'>
                                <Icon loading size='big' name='spinner' />
                                <div style={{ fontSize: '16px', padding: '10px', fontStyle: 'initial' }}>loading</div>
                            </Icon.Group>
                        </Dimmer>
                        : ''
                }
                <div className="logo" data-error-click={activeError}>
                    <div className="clinicPic" style={(!profilePicture && doctorObject[activeBranchKey].showError) ? { border: "7px solid darkred" } : {}}>
                        <img src={profilePicture ? profilePicture : doctorManLogo} alt="IMG" />
                    </div>

                    <label className="imp" type="file" name="profPic" id="profPic">Update picture <span className="req" style={{ color: 'darkred' }}>*</span>
                        <input onChange={this.addLogo} type="file" name="profPic" id="profPic" />
                    </label>
                    {
                        (uploadError) ? <p className="err">{uploadError}</p> : ''
                    }
                </div>

                <div className="profile-completition">
                    <p>Completition {String(this.checkCompletitionFields())}%</p>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{ width: String(this.checkCompletitionFields()).concat("%") }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>

                <div className="activities-date">
                    <i>{(timeAdded) ? <div>Added: {timeAdded}</div> : ''}</i>
                    <i>{(timeClamied) ? <div>Clamied: {timeClamied}</div> : ''}</i>
                    <i>{(timeEdited) ? <div>Edited: {timeEdited}</div> : ''}</i>
                </div>
                <StepsBar
                    changeErrClick={this.changeErrClick}
                    changeCurrentStep={this.changeCurrentStep}
                    doctorObject={doctorObject}
                    currentStep={currentStep}
                    publish_save={publish_save}
                    user={this.props.currentUser} />
            </div>
        )
    }

    /**
     *
     * @returns {XML}
     */
    renderStep = () => {
        const { suggestionId, specialties } = this.props;
        let { doctorObject, activeBranchKey, fileTypes, currentStep } = this.state;
        switch (currentStep) {
            case 1:
                return <BasicInfo
                    updateParentState={this.updateCurrentState}
                    activeBranchKey={activeBranchKey}
                    doctorObject={doctorObject}
                    fileTypes={fileTypes}
                    suggestionId={suggestionId}
                    specialties={specialties}
                />;
            case 2:
                return <Timings
                    updateParentState={this.updateCurrentState}
                    activeBranchKey={activeBranchKey}
                    doctorObject={doctorObject}
                    specialties={specialties}
                />;
            case 3:
                return <Credentials
                    updateParentState={this.updateCurrentState}
                    activeBranchKey={activeBranchKey}
                    doctorObject={doctorObject}
                    specialties={specialties}
                />;
            case 5:
                return <MedicalSchool
                    updateParentState={this.updateCurrentState}
                    activeBranchKey={activeBranchKey}
                    doctorObject={doctorObject}
                    specialties={specialties}
                />;
            case 6:
                return <Residency
                    updateParentState={this.updateCurrentState}
                    activeBranchKey={activeBranchKey}
                    doctorObject={doctorObject}
                    specialties={specialties}
                />;
            case 7:
                return <Felowship
                    updateParentState={this.updateCurrentState}
                    activeBranchKey={activeBranchKey}
                    doctorObject={doctorObject}
                    specialties={specialties}
                />;

            case 8:
                return <Experience
                    updateParentState={this.updateCurrentState}
                    activeBranchKey={activeBranchKey}
                    doctorObject={doctorObject}
                    specialties={specialties}
                />;
            case 9:
                return <Awards
                    updateParentState={this.updateCurrentState}
                    activeBranchKey={activeBranchKey}
                    doctorObject={doctorObject}
                    specialties={specialties}
                />;
        }

    }
    renderRightSteps = () => {
        let { doctorObject, activeBranchKey, currentStep } = this.state;

        switch (currentStep) {

            case 1:
                return <RightBasicInfo
                    updateParentState={this.updateCurrentState}
                    activeBranchKey={activeBranchKey}
                    doctorObject={doctorObject}
                />;
        }

    }

}

export default MainWizard