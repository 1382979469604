import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import fire from 'firebase/app'
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import _ from 'lodash'

// let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
class ActionButtons extends Component {
    static propTypes = {
        currentStep: PropTypes.number.isRequired,
        branchList: PropTypes.object.isRequired,
        changeCurrentStep: PropTypes.func.isRequired,
        readyToStartChange: PropTypes.func.isRequired,
        changeErrClick: PropTypes.func.isRequired,
        facilityType: PropTypes.string.isRequired,
    }
    state = {
        time: 5000,
        loading: false,
        updated: [],
        error: null
    }
    // constructor(props, context) {
    //     super(props, context);
    // }
    /**
     * Go to facility selcet page
     */

    fillrequirmentsAlert = () => {
        Swal.fire({
            heightAuto: false,
            title: 'Alert',
            text: 'Please fill all requirments!',
            // type: 'success',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            showCancelButton: false,
            allowOutsideClick: false
        }).then(() => {
            const { activeBranchKey, branchList } = this.props;
            branchList[activeBranchKey].showError = true
            this.props.updateParentState(branchList);

        });
    }

    myfunction(arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11, arg12, arg13, arg14, arg15, arg16, arg17, arg18, arg19, arg20, arg21, arg22, arg23,) {
        var r;
        r = arg1 + arg2 + arg3 + arg4 + arg5 + arg6 + arg7 + arg8 + arg9 + arg10 + arg11 + arg12 + arg13 + arg14 + arg15 + arg16 + arg17 + arg18 + arg19 + arg20 + arg21 + arg22 + arg23;
        return (r);
    }
    checkError() {

        let { branchList } = this.props;
        let arg1 = 0, arg2 = 0, arg3 = 0, arg4 = 0, arg5 = 0, arg6 = 0, arg7 = 0, arg8 = 0, arg9 = 0, arg10 = 0, arg11 = 0, arg12 = 0, arg13 = 0, arg14 = 0, arg15 = 0, arg16 = 0, arg17 = 0, arg18 = 0, arg19 = 0, arg20 = 0, arg21 = 0, arg22 = 0, arg23 = 0;
        let _this = this;
        Object.keys(branchList).forEach(function (item) {

            arg12 = 5;
            //BasicInfo
            if (branchList[item].logoUrl.length) {
                arg1 = 5;
            }
            if (branchList[item].facilityName.length) {
                arg2 = 5;
            }
            if (branchList[item].facilityName_ar.length) {
                arg3 = 5;
            }
            if (branchList[item].description.length) {
                arg4 = 5;
            }
            if (branchList[item].description_ar.length) {
                arg5 = 5;
            }
            if (branchList[item].pictures.length) {
                arg6 = 5;
            }
            if (branchList[item].adminName.length) {
                arg7 = 5;
            }
            if (branchList[item].adminPosition.length) {
                arg8 = 5;
            }
            if (branchList[item].adminPhone[0].number.length) {
                arg9 = 5;
            }
            if (branchList[item].attachedCertificateUrl) {
                arg10 = 5;
            }
            if (branchList[item].selectedSpecialties.length) {
                arg11 = 5;
            }
            // if (branchList[item].selectedInsurances.length) {

            // }
            if (branchList[item].address.country.length) {
                arg13 = 5;
            }
            if (branchList[item].address.city.length) {
                arg14 = 5;
            }
            if (branchList[item].address.building.length) {
                arg15 = 5;
            }
            if (branchList[item].address_ar.country.length) {
                arg16 = 5;
            }
            if (branchList[item].address_ar.city.length) {
                arg17 = 4;
            }
            if (branchList[item].address_ar.building.length) {
                arg18 = 4;
            }
            if (branchList[item].landLineNumbers[0].number.length) {
                arg19 = 4;
            }
            if (branchList[item].facilityEmail.length) {
                arg20 = 4;
            }
            if (branchList[item].customInsurances.length) {
                arg22 = -5;
                branchList[item].customInsurances.forEach((item) => {
                    if (item[1].length) {
                        arg23 = 5;
                    }
                })
            }
            let allDays = branchList[item].openingDays;
            Object.keys(allDays).forEach(function (day) {
                let curDayStatus = allDays[day].opened;
                if (curDayStatus) {
                    arg21 = 4;
                }
            });
        });
        let value = _this.myfunction(arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11, arg12, arg13, arg14, arg15, arg16, arg17, arg18, arg19, arg20, arg21, arg22, arg23);
        return value;
    }

    haveError() {
        let { branchList } = this.props;
        let errors = false;

        Object.keys(branchList).forEach(function (item) {
            if (branchList[item].haveError) {
                errors = true;
            }
        });
        return !errors;
    }
    toNextStep = (index) => {
        let nextPart = index + 1;
        this.props.changeCurrentStep(nextPart);
    }

    toPrevStep = (index) => {
        let nextPart = index - 1;
        this.props.changeCurrentStep(nextPart);
    }
    /**
     *
     */
    alertSuccess = (clinicKey) => {
        let _this = this;

        Swal.fire({
            heightAuto: false,
            title: 'Saved Successfully',
            // text: 'Please check your email',
            type: 'success',
            confirmButtonText: 'ok',
            allowOutsideClick: false
        }).then(function () {
            _this.props.readyToStartChange(false, clinicKey)
        }).catch(function () {
            _this.props.readyToStartChange(false, clinicKey)
        })
    }

    /**
     * @returns {null}
     */
    saveClinicsInfo = (e, complete = false) => {
        let _this = this;
        let branchList = _.cloneDeep(this.props.branchList);
        if (!navigator.onLine) {
            return Swal.fire({
                heightAuto: false,
                type: 'error',
                title: 'Oops...',
                text: "Please check internet connection and try again..",
                allowOutsideClick: true,
            });
        }
        Swal.fire({
            heightAuto: false,
            title: "Are you sure?",
            showCancelButton: true,
            confirmButtonText: "Modify",
            // closeOnConfirm: false
            text: "This will update your facility's profile information, you can always modify it later!",
            type: 'warning',
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    heightAuto: false,
                    title: "Please wait..",
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Swal.showLoading()
                        if (_this.state.error) {
                            Swal.fire({
                                heightAuto: false,
                                title: _this.state.error,
                                allowOutsideClick: false,
                                onOpen: () => {
                                    return null;
                                }
                            })
                        }
                    }
                });
                let db = 'clinics';
                let { loading } = this.state;
                if (loading) return null;
                this.setState({ loading: true })
                let updateCurrentUser = fire.firestore().collection('users').doc(branchList['branch1'].users.superAdmin);
                let userObj = {
                    adminName: branchList['branch1'].adminName,
                    adminPhone: branchList['branch1'].adminPhone,
                    adminPosition: branchList['branch1'].adminPosition,
                }
                updateCurrentUser.update(userObj)
                Object.keys(branchList).forEach(function (item) {
                    branchList[item].edited = Date.now();
                    let attachedCertificateUrl = branchList[item].attachedCertificateUrl;
                    delete branchList[item].haveError;
                    delete branchList[item].privacy;
                    delete branchList[item].terms;
                    delete branchList[item].zoom;
                    delete branchList[item].numberOfBeds
                    delete branchList[item].attachedCertificateUrl;
                    delete branchList[item].emergencyService
                    delete branchList[item].allDoctors
                    delete branchList[item].doctorsData
                    delete branchList[item].noFacilityName


                    if (!branchList[item].facilityName_ar.length) {
                        delete branchList[item].facilityName_ar
                    }
                    if (!branchList[item].description.length) {
                        delete branchList[item].description
                    }
                    if (!branchList[item].description_ar.length) {
                        delete branchList[item].description_ar
                    }
                    if (!branchList[item].tollNumber[0].number) {
                        delete branchList[item].isTollNumberDefault
                        delete branchList[item].tollNumber
                    }
                    if (!branchList[item].address_ar.country) {
                        delete branchList[item].address_ar
                    }
                    if (!branchList[item].customSpecialties.length) {
                        delete branchList[item].customSpecialties
                    }
                    if (!branchList[item].customInsurances.length) {
                        delete branchList[item].customInsurances
                    }
                    if (!branchList[item].amenities.length) {
                        delete branchList[item].amenities
                    }
                    if (!branchList[item].customAmenities.length) {
                        delete branchList[item].customAmenities
                    }
                    if (!branchList[item].customDoctors.length) {
                        delete branchList[item].customDoctors
                    }
                    if (!branchList[item].addedDoctors.length) {
                        delete branchList[item].addedDoctors
                    }
                    delete branchList[item].adminPhone;
                    delete branchList[item].adminPosition;
                    // delete branchList[item].branchesId;
                    if (!Object.keys(branchList[item].customNetworks).length) {
                        delete branchList[item].customNetworks
                    }
                    if (!branchList[item].facilityId) {
                        (branchList[item].facilityId = fire.firestore().collection('clinics').doc().id)
                    }
                    let clinicKey = branchList[item].facilityId
                    if (clinicKey) {
                        let id = clinicKey;
                        let updateClinic = fire.firestore().collection('clinics').doc(id)
                        //upload logo
                        let uploadLogoDb = db + '/' + id + '/logoUrl';
                        let logo = branchList[item].logoUrl
                        if (logo && logo.substring(0, 4) === 'data') {
                            delete branchList[item].logoUrl;
                            _this.uploadLogo(id, db, uploadLogoDb, logo);
                        }
                        //upload gallery
                        let uploadGalleryDb = db + '/' + id + '/pictures';
                        let pictures = branchList[item].pictures
                        let uploadedPics = pictures.filter(item => {
                            return item.base64
                        })
                        if (uploadedPics.length) {
                            delete branchList[item].pictures;
                            _this.uploadGallery(id, db, uploadGalleryDb, pictures);
                        }
                        //upload certificate
                        _this.addUnderUser(id, db, branchList, attachedCertificateUrl);
                        updateClinic.set(branchList[item]).then(() => {
                            fire.firestore().collection('users').doc(fire.auth().currentUser.uid).update({
                                accessClinic: fire.firestore.FieldValue.arrayUnion(clinicKey)
                            }).then(() => {
                                _this.setState({ loading: false }, () => { _this.alertSuccess(clinicKey) })
                            })
                        }).catch(error => {
                            _this.setState({ loading: false }, () => { window.alert(error) })

                        })
                    }
                })
            }
        });
    }

    uploadLogo = (id, db, uploadLogoDb, logo) => {
        let reportImagesRef = fire.storage().ref().child(db + '/' + id + '/logo');
        if (logo) {
            if (logo && logo.substring(0, 4) === 'data') {
                let task = reportImagesRef.putString(logo, 'data_url')
                task.on('state_changed', function (snapshot) {
                }, function (error) {
                }, function () {
                    task.snapshot.ref.getDownloadURL().then((url) => {
                        fire.firestore().collection(db).doc(id).update({ 'logoUrl': url })
                    })
                });
            } else {
                fire.firestore().collection(db).doc(id).update({ 'logoUrl': logo })
            }
        }
    }
    //***modify
    addUnderUser = (id, db, branchList, attachedCertificateUrl) => {
        //fire.database().ref('users/' + branchList['branch1'].users.superAdmin + '/branches/' + branchList['branch1'].branchId).set('draft')
        if (attachedCertificateUrl.base64) {
            let reportImagesRef = fire.storage().ref().child(db + '/' + id + '/certificate').putString(attachedCertificateUrl.base64, 'data_url');
            reportImagesRef.on('state_changed', function (snapshot) { }, function (error) { }, function () {
                reportImagesRef.snapshot.ref.getDownloadURL().then(url => {
                    fire.firestore().collection('users').doc(branchList['branch1'].users.superAdmin).set({
                        clinics: { [id]: { attachedCertificateUrl: url } }
                    }, { merge: true })
                    // fire.database().ref('users/' + branchList['branch1'].users.superAdmin + '/clinics/' + id + '/attachedCertificateUrl').set(url);
                })
            });
        }
    }

    uploadGallery = (id, db, uploadGalleryDb, pictures) => {
        let upPictures = [];
        pictures.map(function (item, index) {
            if (!item.base64) {
                upPictures.push(item);
            }
        })
        pictures.map(function (item, index) {
            let picture = item.base64;
            if (picture) {
                if (picture.substring(0, 4) === 'data') {
                    let task = fire.storage().ref().child(db + '/' + id + '/gallery/' + item.name).putString(picture, 'data_url');
                    task.on('state_changed', function (snapshot) {
                    }, function (error) {
                    }, function () {
                        task.snapshot.ref.getDownloadURL().then(url => {
                            upPictures.push(url);
                            fire.firestore().collection(db).doc(id).update({ 'pictures': upPictures })
                        })
                    });
                }
            }
        })

    }
    render() {
        // const { branchList, logoUrl, clinicsStatus } = this.props;
        // let { loading } = this.state;
        return (
            <div className="save-btn">
                <div className="navigation">
                    {this.renderActionButtons()}
                </div>
                {/* <div className="saveToCon">
                    {(clinicsStatus === 1) ? '' :
                        <SaveToContinue loading={loading} saveForContinue={this.saveForContinue} updateParentState={this.props.updateParentState} branchList={branchList} logoUrl={logoUrl} />
                    }
                </div> */}
                <div className="submit">
                    <button onClick={
                        (this.checkError() === 100) ?
                            (this.saveClinicsInfo.bind(this, true))
                            : this.fillrequirmentsAlert
                    }>Publish</button>

                </div>
            </div>
            // </div>
        )
    }

    renderActionButtons = () => {
        const { currentStep } = this.props;
        switch (currentStep) {
            case 1:
                return (
                    <button onClick={this.toNextStep.bind(this, currentStep)} >
                        Next
                    </button>
                )

            case 6:
                return (
                    <div>
                        <button onClick={this.toPrevStep.bind(this, currentStep)} >
                            Prev
                        </button>
                    </div>
                )
            default:
                return (
                    <div>
                        <button onClick={this.toPrevStep.bind(this, currentStep)} >
                            Prev
                        </button>
                        <button onClick={this.toNextStep.bind(this, currentStep)} >
                            Next
                        </button>
                    </div>
                )
        }
    }
}

export default ActionButtons