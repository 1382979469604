import React, { Component } from 'react';
import PropTypes from 'prop-types'
import fire from 'firebase/app'
import 'firebase/database';
import { Dropdown } from 'semantic-ui-react';

class Specialties extends Component {
    static propTypes = {
        branchList: PropTypes.object.isRequired,
        activeBranchKey: PropTypes.string.isRequired,
        applyToAll: PropTypes.func.isRequired,
        updateParentState: PropTypes.func.isRequired,
    }

    constructor(props, context) {
        super(props, context);
    }
    state = {
        defaultSpecialties: [],
        topSpecialties: [],

        suggestedSpecialties: {},
        specialties: {},

        canAdd: false,
        currentText: '',
    }
    /**
     *
     * @param id
     */
    addSpecialty = (e, { value }) => {
        let { suggestedSpecialties, specialties } = this.state;
        let { branchList, activeBranchKey } = this.props;
        let selectedSpecialties = branchList[activeBranchKey].selectedSpecialties;
        suggestedSpecialties.forEach(function (item, index) {
            if (item.id === value) {
                selectedSpecialties.push(item.id);
                suggestedSpecialties.splice(index, 1);
                specialties.splice(index, 1);
            }
        })
        this.props.updateParentState(branchList);
        this.setState({
            suggestedSpecialties: suggestedSpecialties,
            specialties: specialties,
            currentText: ''
        })
    }
    /**
     * @param id
     */
    removeSelectedSpecialties = (id) => {
        let { suggestedSpecialties, specialties } = this.state;
        let { branchList, activeBranchKey } = this.props;
        let selectedSpecialties = branchList[activeBranchKey].selectedSpecialties;
        let index = selectedSpecialties.indexOf(id);
        let name = this.getNameById(id, 'defaultSpecialties');

        if (index > -1) {
            selectedSpecialties.splice(index, 1);

            let val = { name: name, id: id, text: name, value: id };
            suggestedSpecialties.push(val);
            specialties.push(val);
        }
        this.setState({
            suggestedSpecialties: suggestedSpecialties,
            specialties: specialties
        })
        this.props.updateParentState(branchList);
    }
    removeCustomSpecialties = (id) => {
        let { branchList, activeBranchKey } = this.props;
        let customSpecialties = branchList[activeBranchKey].customSpecialties;
        let index = customSpecialties.indexOf(id);
        customSpecialties.splice(index, 1);

        this.props.updateParentState(branchList);
    }
    /**
     *
     * @param id
     * @param where
     */
    getNameById = (id, where) => {
        let x = this.state[where].filter(function (v) {
            return v.id === id; // Filter out the appropriate one
        })[0];
        if (x) {
            return x.name
        }
    };

    applyAllFromLocal = () => {
        const { applyToAll } = this.props;
        applyToAll(['selectedSpecialties', 'customSpecialties'], this.getSpecialtiesLists);
    }
    componentWillMount = () => {
        this.getSpecialtiesLists();
    }
    getSpecialtiesLists = () => {
        const { branchList, activeBranchKey } = this.props;
        let allSpecialities = this.props.specialties
        let selectedSpecialties = branchList[activeBranchKey].selectedSpecialties;
        let defaultSpecialties = []
        let suggestedSpecialties = []
        let specialties = []
        Object.keys(allSpecialities).map(specKey => {
            let spec = allSpecialities[specKey]
            spec.id = specKey
            defaultSpecialties.push(spec)

            if (selectedSpecialties.indexOf(specKey) === -1) {
                suggestedSpecialties.push(spec);
                specialties.push({
                    value: spec.id,
                    key: spec.id,
                    text: spec.name,
                });
            }
        })
        this.setState({
            specialties: specialties,
            defaultSpecialties: defaultSpecialties.slice(),
            suggestedSpecialties: suggestedSpecialties,
        });

    }
    render = () => {
        let { branchList, activeBranchKey } = this.props;
        let selectedSpecialties = branchList[activeBranchKey].selectedSpecialties;
        return (
            <div className="wizardMain">
                <div className="Specialties">
                    {this.renderFindSpecialties()}
                    {
                        (!selectedSpecialties.length) ? (
                            this.renderNoSpecialitiesSelected()
                        ) : (
                                this.renderSelectedSpecialties()
                            )
                    }
                    {this.renderCustomSpecialties()}
                </div>
            </div>
        )
    }



    renderFindSpecialties = () => {
        let { specialties } = this.state;
        return (
            <div className="findSpecialties">
                <div className="inputWrap">
                    <div className="title flexBox align_justify">
                        <h2>Find Specialties</h2>
                    </div>
                    <Dropdown
                        style={{ width: '374px' }}
                        selection
                        search
                        clearable
                        placeholder="Type speciality name"
                        value={null}
                        selectOnBlur={false}
                        onChange={this.addSpecialty}
                        options={specialties}
                    />
                </div>
            </div>
        )
    }
    renderNoSpecialitiesSelected = () => {
        return (
            <div className="selectedSpecialties" style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ position: 'relative', fontSize: '18px', display: 'block', fontFamily: 'tfMedium' }}>
                    No Selected Specialties
                    </div>
            </div>


        )
    }
    renderSelectedSpecialties = () => {
        let { branchList, activeBranchKey } = this.props;
        let selectedSpecialties = branchList[activeBranchKey].selectedSpecialties;
        return (
            <div className="selectedSpecialties">
                <div className="inputWrap">
                    <div className="items flexBox align_justify_left">
                        {
                            (selectedSpecialties) ? (
                                selectedSpecialties.map((item, index) => (
                                    <div title={'Remove ' + this.getNameById(item, 'defaultSpecialties')} onClick={this.removeSelectedSpecialties.bind(this, item)} key={this.getNameById(item, 'defaultSpecialties') + '_' + index} className="item flexBox" style={{ height: '40px' }}>
                                        <span>{this.getNameById(item, 'defaultSpecialties')}</span>
                                        <span>x</span>
                                    </div>
                                ))
                            ) : ''
                        }
                    </div>
                </div>
            </div >

        )
    }
    renderCustomSpecialties = () => {
        let { branchList, activeBranchKey } = this.props;
        let customSpecialties = branchList[activeBranchKey].customSpecialties;
        return (

            (customSpecialties.length) ? (
                <div className="selectedSpecialties custom">
                    <div className="inputWrap">
                        <div className="title flexBox align_justify">
                            <h2>Waiting Approval</h2>
                        </div>
                        <div className="subTitle">
                            <p>Your speciality will be added to your facility as soon as our administration approve it.</p>
                        </div>
                        <div className="items flexBox align_justify_left">
                            {
                                customSpecialties.map((item, index) => (
                                    <div title={'Remove ' + item} onClick={this.removeCustomSpecialties.bind(this, item)} key={item + '_' + index} className="item flexBox">
                                        <span>{item}</span>
                                        <span>x</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            ) : ''
        )
    }
}

export default Specialties