import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import fire from 'firebase/app'
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';

import _ from 'lodash'
class ActionButtons extends Component {
    static propTypes = {
        currentStep: PropTypes.number.isRequired,
        doctorObject: PropTypes.object.isRequired,
        changeCurrentStep: PropTypes.func.isRequired,
        readyToStartChange: PropTypes.func.isRequired,
        changeErrClick: PropTypes.func.isRequired,
        doctorsStatus: PropTypes.number.isRequired,
        publish_save: PropTypes.bool.isRequired,
    }
    state = {
        time: 5000,
        loading: false,
        updated: [],
        error: null

    }
    constructor(props, context) {
        super(props, context);
    }
    /**
     * Go to facility selcet page
     */

    fillrequirmentsAlert = () => {
        let _this = this;
        Swal.fire({
            heightAuto: false,
            title: 'Alert',
            text: ' Please fill all required fields',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            showCancelButton: false,
            allowOutsideClick: false
        }).then(() => {
            let { doctorObject, updateParentState } = this.props
            doctorObject['branch1'].showError = true
            updateParentState(doctorObject)
        });
    }

    haveError() {
        let { doctorObject } = this.props;
        let errors = false;

        Object.keys(doctorObject).forEach(function (item) {
            if (doctorObject[item].haveError) {
                errors = true;
            }
        });
        return !errors;
    }
    toNextStep = (index) => {
        if (index === 2) {
            let nextPart = index + 2;
            this.props.changeCurrentStep(nextPart);
        } else {
            let nextPart = index + 1;
            this.props.changeCurrentStep(nextPart);
        }
    }

    toPrevStep = (index) => {
        if (index === 4) {
            let nextPart = index - 2;
            this.props.changeCurrentStep(nextPart);
        } else {
            let nextPart = index - 1;
            this.props.changeCurrentStep(nextPart);
        }

    }
    /**
     *
     */
    alertSuccess = (doctorKey) => {
        let _this = this;

        Swal.fire({
            heightAuto: false,
            title: 'Saved Successfully',
            // text: 'Please check your email',
            type: 'success',
            confirmButtonText: 'ok',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.hideLoading()
            }
        }).then(function () {
            _this.props.readyToStartChange(false, doctorKey)
        }).catch(function () {
            _this.props.readyToStartChange(false, doctorKey)
        })
    }
    /**
     * @returns {null}
     */
    saveForContinue = (e, complete = false) => {
        let _this = this;

        if (!navigator.onLine) {
            return Swal.fire({
                heightAuto: false,
                type: 'error',
                title: 'Oops...',
                text: "Please check internet connection and try again..",
                allowOutsideClick: true,
            });
        }
        Swal.fire({
            heightAuto: false,
            title: "Are you sure?",
            showCancelButton: true,
            text: "This will update your doctor's profile information, you can always modify it later!",
            type: 'warning',
            confirmButtonText: "Modify",
            // closeOnConfirm: true
        }).then((result) => {
            if (!result.value) {
                return
            }
            Swal.fire({
                heightAuto: false,
                title: "Please wait..",
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                    if (_this.state.error) {
                        Swal.fire({
                            heightAuto: false,
                            title: _this.state.error,
                            allowOutsideClick: false,
                            onOpen: () => {

                                return null;
                            }
                        })
                    }
                }
            });
            let db = 'doctors';
            let doctorObject = _.cloneDeep(this.props.doctorObject);
            let { loading } = this.state;
            let updated = [];
            if (loading) return null;
            this.setState({ loading: true })

            Object.keys(doctorObject).map(function (item) {
                doctorObject[item].edited = Date.now();
                let licenseDoc = doctorObject[item].licenseDoc;
                let id = doctorObject[item].doctorId
                doctorObject[item].status = "verified";
                delete doctorObject[item].branchName;
                delete doctorObject[item].branchId;
                delete doctorObject[item].branchesId;
                delete doctorObject[item].users;
                delete doctorObject[item].haveError;
                delete doctorObject[item].privacy;
                delete doctorObject[item].terms;
                delete doctorObject[item].zoom;
                delete doctorObject[item].adminName;
                delete doctorObject[item].adminPhone;
                delete doctorObject[item].adminPosition;
                delete doctorObject[item].licenseDoc;

                delete doctorObject[item].schoolFacility;
                delete doctorObject[item].doctorDegreesObject;
                delete doctorObject[item].residencyFacility;
                delete doctorObject[item].doctorResidencyObject;
                delete doctorObject[item].doctorFelowshipObject;
                delete doctorObject[item].color;
                delete doctorObject[item].selectedResidencySpecialties;
                delete doctorObject[item].selectedResidencySpecialties;
                delete doctorObject[item].felowshipFacility;
                delete doctorObject[item].felowshipSpeciality;

                delete doctorObject[item].selectedSpecialties;
                delete doctorObject[item].experienceFacility;
                delete doctorObject[item].awardFacility;
                delete doctorObject[item].doctorAwardsObject;
                delete doctorObject[item].doctorExperienceObject;
                delete doctorObject[item].awards;
                delete doctorObject[item].doctorId;
                delete doctorObject[item].doctorSelectedClinics;
                delete doctorObject[item].doctorClinicTimings;

                if (!doctorObject[item].name_ar.length) {
                    delete doctorObject[item].name_ar
                }
                if (!doctorObject[item].nationalities.length) {
                    delete doctorObject[item].nationalities
                }
                if (!doctorObject[item].languages.length) {
                    delete doctorObject[item].languages
                }
                if (!doctorObject[item].gender.length) {
                    delete doctorObject[item].gender
                }
                if (!doctorObject[item].title.length) {
                    delete doctorObject[item].title
                }
                if (!doctorObject[item].yearOfExperince) {
                    delete doctorObject[item].yearOfExperince
                }
                if (!doctorObject[item].shortBio.length) {
                    delete doctorObject[item].shortBio
                }
                if (!doctorObject[item].shortBio_ar.length) {
                    delete doctorObject[item].shortBio_ar
                }
                if (!doctorObject[item].conditions.length) {
                    delete doctorObject[item].conditions
                }
                if (!doctorObject[item].yearsOfExp) {
                    delete doctorObject[item].yearsOfExp
                }
                if (!doctorObject[item].email) {
                    delete doctorObject[item].email
                }

                if (licenseDoc) {
                    let reportImagesRef = fire.storage().ref().child(db + '/' + id + '/certificate').putString(licenseDoc.front.base64, 'data_url');
                    reportImagesRef.on('state_changed', function (snapshot) {
                    }, function (error) {
                    }, function () {
                        reportImagesRef.snapshot.ref.getDownloadURL().then((url) => {
                            // fire.database().ref(db).child(id).child('licenseDoc').child('front').set(url);
                            fire.firestore().collection(db).doc(id).set({
                                licenseDoc: { front: url }
                            }, { merge: true })
                        });
                    });
                    let reportImagesRefBack = fire.storage().ref().child(db + '/' + id + '/certificate').putString(licenseDoc.back.base64, 'data_url')
                    reportImagesRefBack.on('state_changed', function (snapshot) {
                    }, function (error) {
                    }, function () {
                        reportImagesRefBack.snapshot.ref.getDownloadURL().then((url) => {
                            fire.firestore().collection(db).doc(id).set({
                                licenseDoc: { back: url }
                            }, { merge: true })
                            // fire.database().ref(db).child(id).child('licenseDoc').child('back').set(url);
                        });
                    });
                }
                let uploadLogoDb = db + '/' + id + '/profilePicture';
                let logo = doctorObject[item].profilePicture;
                delete doctorObject[item].profilePicture;
                _this.uploadLogo(id, db, uploadLogoDb, logo);


                let updateClinic = fire.firestore().collection('doctors').doc(id);
                updateClinic.set(doctorObject[item]).then(() => {
                    _this.setState({ loading: false }, () => { _this.alertSuccess(id) })
                }).catch((error) => {

                })
            })
        });
    }

    /**
     * @returns {null}
     */
    saveClinicsInfo = (e, complete = false) => {
        let _this = this;
        if (!navigator.onLine) {
            return Swal.fire({
                heightAuto: false,
                type: 'error',
                title: 'Oops...',
                text: "Please check internet connection and try again..",
                allowOutsideClick: true,
            });
        }
        Swal.fire({
            heightAuto: false,
            title: "Are you sure?",
            showCancelButton: true,
            confirmButtonText: "Modify",
            // closeOnConfirm: false
            text: "This will update your doctor's profile information, you can always modify it later!",
            type: 'warning',
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    heightAuto: false,
                    title: "Please wait..",
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Swal.showLoading()
                        if (_this.state.error) {
                            Swal.fire({
                                heightAuto: false,
                                title: _this.state.error,
                                allowOutsideClick: false,
                                onOpen: () => {
                                    return null;
                                }
                            })
                        }
                    }
                });
                let db = 'doctors';
                let doctorObject = _.cloneDeep(this.props.doctorObject);
                let { loading } = this.state;
                if (loading) return null;
                this.setState({ loading: true })

                Object.keys(doctorObject).map(function (item) {
                    doctorObject[item].edited = Date.now();
                    doctorObject[item].status = "verified";
                    let licenseDoc = doctorObject[item].licenseDoc;

                    delete doctorObject[item].branchName;
                    delete doctorObject[item].branchId;
                    delete doctorObject[item].branchesId;
                    delete doctorObject[item].users;
                    delete doctorObject[item].haveError;
                    delete doctorObject[item].privacy;
                    delete doctorObject[item].terms;
                    delete doctorObject[item].zoom;
                    delete doctorObject[item].adminName;
                    delete doctorObject[item].adminPhone;
                    delete doctorObject[item].adminPosition;

                    delete doctorObject[item].schoolFacility;
                    delete doctorObject[item].doctorDegreesObject;
                    delete doctorObject[item].residencyFacility;
                    delete doctorObject[item].doctorResidencyObject;
                    delete doctorObject[item].doctorFelowshipObject;
                    delete doctorObject[item].selectedSpecialties;
                    delete doctorObject[item].experienceFacility;
                    delete doctorObject[item].awardFacility;
                    delete doctorObject[item].doctorAwardsObject;
                    delete doctorObject[item].doctorExperienceObject;
                    delete doctorObject[item].awards;
                    delete doctorObject[item].doctorSelectedClinics;
                    delete doctorObject[item].doctorClinicTimings;

                    if (!doctorObject[item].name_ar.length) {
                        delete doctorObject[item].name_ar
                    }
                    if (!doctorObject[item].nationalities.length) {
                        delete doctorObject[item].nationalities
                    }
                    if (!doctorObject[item].languages.length) {
                        delete doctorObject[item].languages
                    }
                    if (!doctorObject[item].gender.length) {
                        delete doctorObject[item].gender
                    }
                    if (!doctorObject[item].title.length) {
                        delete doctorObject[item].title
                    }
                    if (!doctorObject[item].yearOfExperince) {
                        delete doctorObject[item].yearOfExperince
                    }
                    if (!doctorObject[item].shortBio.length) {
                        delete doctorObject[item].shortBio
                    }
                    if (!doctorObject[item].shortBio_ar.length) {
                        delete doctorObject[item].shortBio_ar
                    }
                    if (!doctorObject[item].conditions.length) {
                        delete doctorObject[item].conditions
                    }
                    if (!doctorObject[item].yearsOfExp) {
                        delete doctorObject[item].yearsOfExp
                    }
                    if (!doctorObject[item].email) {
                        delete doctorObject[item].email
                    }
                    let id = doctorObject[item].doctorId ? doctorObject[item].doctorId : fire.firestore().collection('doctors').doc().id;
                    if (id) {
                        delete doctorObject[item].clinicsLength
                        delete doctorObject[item].doctorId
                        if (licenseDoc && doctorObject[item].certificateModified) {
                            delete doctorObject[item].licenseDoc;
                            delete doctorObject[item].certificateModified

                            let reportImagesRef = fire.storage().ref().child(db + '/' + id + '/certificate').putString(licenseDoc.front.base64, 'data_url');
                            reportImagesRef.on('state_changed', function (snapshot) {
                            }, function (error) {
                            }, function () {
                                reportImagesRef.snapshot.ref.getDownloadURL().then((url) => {
                                    fire.firestore().collection(db).doc(id).set({
                                        licenseDoc: { front: url }
                                    }, { merge: true })
                                });
                            });
                            let reportImagesRefBack = fire.storage().ref().child(db + '/' + id + '/certificate').putString(licenseDoc.back.base64, 'data_url')
                            reportImagesRefBack.on('state_changed', function (snapshot) {
                            }, function (error) {
                            }, function () {
                                reportImagesRefBack.snapshot.ref.getDownloadURL().then((url) => {
                                    fire.firestore().collection(db).doc(id).set({
                                        licenseDoc: { back: url }
                                    }, { merge: true })
                                });
                            });
                        }
                        delete doctorObject[item].modifiedFalg
                        // let updateClinic = fire.database().ref("doctors"+ '/' +id);
                        let updateClinic = fire.firestore().collection('doctors').doc(id)

                        let logo = doctorObject[item].profilePicture
                        if (logo.substring(0, 4) === 'data') {
                            delete doctorObject[item].profilePicture
                            let uploadLogoDb = "doctors/" + id + '/profilePicture';
                            _this.uploadLogo(id, "doctors", uploadLogoDb, logo);
                        }

                        updateClinic.set(doctorObject[item]).then((snap) => {
                            let count = 0
                            for (var index in doctorObject[item].clinics) {
                                fire.firestore().collection('clinics').doc(index).set({
                                    doctors: fire.firestore.FieldValue.arrayUnion(id)
                                }, { merge: true }).then(() => {
                                    count++
                                    if (count === Object.keys(doctorObject[item].clinics).length) {
                                        _this.setState({ loading: false }, () => { _this.alertSuccess(id) })
                                    }
                                })
                            }
                        })
                    }
                })
            }
        });
    }

    uploadLogo = (id, db, uploadLogoDb, logo) => {
        let reportImagesRef = fire.storage().ref().child(db + '/' + id + '/profilePicture');
        if (logo) {
            if (logo.substring(0, 4) === 'data') {
                let task = reportImagesRef.putString(logo, 'data_url')
                task.on('state_changed', function (snapshot) {
                }, function (error) {
                }, function () {
                    task.snapshot.ref.getDownloadURL().then((url) => {
                        // fire.database().ref(uploadLogoDb).set(url);
                        fire.firestore().collection('doctors').doc(id).update({ profilePicture: url })
                    })
                });
            } else {
                // fire.database().ref(uploadLogoDb).set(logo);
                fire.firestore().collection('doctors').doc(id).update({ profilePicture: logo })
            }
        }
    }

    render() {
        const { publish_save } = this.props;
        return (
            <div className="save-btn">
                <div className="navigation">
                    {this.renderActionButtons()}
                </div>
                <div className="submit">
                    <button onClick={(this.haveError()) ? (this.saveClinicsInfo.bind(this, true)) : this.fillrequirmentsAlert}>
                        Publish
                    </button>
                    {/* {(!publish_save) ?
                        (
                            <button onClick={(this.haveError()) ? (this.saveClinicsInfo.bind(this, true)) : this.fillrequirmentsAlert}>
                                Publish
                            </button>
                        ) : (
                            <button onClick={(this.haveError()) ? (this.saveForContinue.bind(this, true)) : this.fillrequirmentsAlert}>
                                Submit for review
                            </button>
                        )
                    } */}
                </div>
            </div>
        )
    }

    renderActionButtons = () => {
        const { currentStep, doctorsStatus } = this.props;
        switch (currentStep) {
            case 1:
                return (
                    <button onClick={this.toNextStep.bind(this, currentStep)} >
                        Next
                    </button>
                )

            case 9:
                return (
                    <div>
                        <button onClick={this.toPrevStep.bind(this, currentStep)} >
                            Prev
                    </button>
                    </div>
                )
            default:
                return (
                    <div>
                        <button onClick={this.toPrevStep.bind(this, currentStep)} >
                            Prev
                        </button>
                        <button onClick={this.toNextStep.bind(this, currentStep)} >
                            Next
                        </button>
                    </div>
                )
        }
    }
}

export default ActionButtons