import React, { Component } from 'react';
import { auth } from 'firebase'
import { Input } from 'semantic-ui-react'

import profilePic from '../../img/user-icon.jpg';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
class HeaderComponent extends Component {
    state = {
    }

    constructor(props) {
        super(props);

        // Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
        // ReactGA.initialize('UA-109312003-1');
        // // This just needs to be called once since we have no routes in this case.
        // ReactGA.pageview(window.location.pathname);
    }

    componentWillMount() {
        // this.getUser();
    }
    logOut = () => {
        const _this = this;
        Swal.fire({
            heightAuto: false,
            title: "Do you really want to leave THRIVE?",
            // text: "You will not be able to recover this imaginary file!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, Logout!",
            cancelButtonText: "No, stay on site!",
            closeOnConfirm: false,
            closeOnCancel: false,
            allowOutsideClick: false,
            showCloseButton: true,
            customClass: 'thrive_logout'
        }).then(function (result) {
            if (result.value) {
                // window.location.href = removeParam('login', window.location.href)
                auth().signOut().then(function () {
                    // window.location.href = '/'
                    // _this.props.logout()
                    document.body.classList.remove("loggedin");
                    document.body.classList.remove("sentAfterLogin");

                })
            }
        })
    };
    render() {
        let { user, claimingPersonName } = this.props.user.data ? this.props.user.data : {}
        let { name, firstName, lastName, adminPosition, profilePicture } = user ? user.info : {}
        const { onSearchKey } = this.props;

        return (
            <header style={this.props.offline ? { backgroundColor: "rgb(255, 79, 79)", zIndex: 1001 } : { backgroundColor: "rgb(255, 255, 255)" }}>
                <div className="header-left">
                    <ul>
                        <li className="menuIcon" onClick={() => this.props.showMenuFunc()}><i style={{ fontSize: '20px', color: '#4a4a4a' }} className="fas fa-bars"></i></li>
                        <li> <a style={{ border: 'none', fontSize: '20px', padding: '0', background: 'none', color: '#4a4a4a', fontFamily: 'tfMedium' }}>{this.props.title}</a></li>

                        {this.props.title === "Providers" || this.props.title === "Facilities" || this.props.title === "Calendar" ?
                            <Input
                                type="text"
                                name="keyword"
                                id="search"
                                placeholder={"Search"}
                                onChange={e => onSearchKey(e)}
                            /> : ''
                        }
                        {
                            this.props.title === "Providers" ?
                                <li> <a onClick={!this.props.publish_save ? (this.props.handleFacilityClick) : this.props.readyToStartChange} className="header-button">Add New</a></li>
                                : this.props.title === "Facilities" ?
                                    <li onClick={() => this.props.addFacility()}> <a className="header-button">Add New</a></li>
                                    : ''
                        }

                    </ul>
                    {
                        this.props.offline &&
                        <div style={{ color: 'white', fontSize: '18px', fontWeight: '900', textTransform: 'uppercase' }}>
                            <div>
                                Please check your intetnet connection
                            </div>
                            <div style={{ fontSize: '14px', fontWeight: '500' }}>before changing any appointment data </div>
                        </div>
                    }
                </div>
                <div className="header-right">
                    {
                        (window.screen.width <= 768) ? (
                            <ul>
                                <li onClick={this.logOut} style={{ color: '#dd6b55' }} className="user-profile">Log out</li>
                            </ul>
                        ) : (
                            <ul>
                                <li className="user-profile">
                                    <p className="user-name">{claimingPersonName}</p>
                                    <p className="user-designation">{adminPosition}</p>
                                </li>
                                <li className="user-picture">
                                    <Link to="/wizard-user">{
                                        (profilePicture) ? (
                                            < img className="border1" src={profilePicture} alt="IMG" />
                                        ) : (
                                            < img className="border1" src={profilePic} alt="IMG" />
                                        )
                                    }
                                    </Link>
                                    <div className="userlist">
                                        <a className="listitem" href="/#/privacy-policy">Terms and Conditions</a>
                                        <div onClick={this.logOut} style={{ color: '#dd6b55' }} className="listitem">Log out</div>
                                    </div>
                                </li>
                            </ul>
                        )
                    }


                </div>
            </header>

        )
    };
}
const mapStateToProps = ({ user }) => {
    return { user };
};

export default connect(
    mapStateToProps,
    {}
)(HeaderComponent);