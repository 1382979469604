import React, { Component } from 'react';
import firebase, { firestore } from 'firebase'
import 'firebase/database';
import 'firebase/auth';
import Header from '../Header/defaultHeader';
import home from './img/thriveFmsLogo.png';
import './Settings.css'
import HomeLogin from '../../Home Login/index'
import { Dimmer, Icon, Button, Table, Dropdown } from 'semantic-ui-react'
import AddStaff from './AddStaff/addStaff'
import Thrive from './ConnectToThrive/thrive'
import ExportData from './ExportRecords/export'
import alertCircle from './img/alert-circle.png';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Moment from 'moment';
import { connect } from "react-redux";

class WizardClinic extends Component {

    state = {
        user: null,
        loggedIn: false,
        addStafFlag: false,
        connectToThrive: false,
        step: 0,
        loading: true,
        staffList: [],
        showMenu: window.screen.width <= 768 ? false : true
    }
    constructor(props, context) {
        super(props, context);
    }
    componentWillMount() {
        this.getUser()
        // this.checkThriveConnection()

    }
    checkThriveConnection() {
        firebase.database().ref('.info/connected').on('value', connected => {
            if (connected.val() === true) {
                firebase.firestore().collection('thriveCr').doc('thriveCr').get().then((snap) => {
                    if (snap.exists) {
                        this.setState({ thiveConnected: true })
                    }
                })
            } else {
                this.setState({ thiveConnected: false })
            }
        })
    }
    getClinics = (accessType) => {
        let selectedClinic = {}
        let clinicsList = []
        if (accessType === 'admin') {
            let { user } = this.props.user.data ? this.props.user.data : {};
            let { accessClinic } = user ? user.info : {};
            return accessClinic.forEach(clinicKey => {
                firebase.firestore().collection('clinics').doc(clinicKey).get().then(value => {
                    let clinic = value.data()
                    clinic.key = value.id
                    clinic.facilityId = value.id
                    clinic.value = clinic["facilityName"]
                    clinic.text = clinic["facilityName"]
                    clinic.description = null
                    clinicsList.push(clinic)
                    if (clinicsList.length === accessClinic.length) {
                        selectedClinic = clinicsList[0]
                        this.getStaff(selectedClinic.facilityId)
                        this.setState({ clinicsList, selectedClinic, loading: false })
                    }
                })
            })
        }
        firebase.firestore().collection('clinics').get().then((snap) => {
            snap.forEach(value => {
                let clinic = value.data()
                clinic.key = value.id
                clinic.facilityId = value.id
                clinic.value = clinic["facilityName"]
                clinic.text = clinic["facilityName"]
                clinic.description = null
                clinicsList.push(clinic)
                if (clinicsList.length) {
                    selectedClinic = clinicsList[0]
                    this.getStaff(selectedClinic.facilityId)
                }
            })
            this.setState({ clinicsList, selectedClinic, loading: false })
        }, function (error) {
            this.setState({ clinicsList: [], selectedClinic: {}, loading: false })
        })
    }
    getStaff(clinicId) {
        this.setState({ loading: true })
        let staffList = []
        let { accessType } = this.props.user.data ? this.props.user.data : {};

        firebase.firestore().collection('staff').where('clinics', 'array-contains', clinicId).get().then((snap) => {
            if (!snap.empty) {
                let counter = 0
                snap.forEach(editor => {
                    firebase.firestore().collection('users').doc(editor.id).get().then(editorSnap => {
                        if (editorSnap.exists && (!(accessType === 'admin' && editor.data().access === 'superAdmin'))) {
                            let data = editorSnap.data()
                            data.id = editor.id
                            data.access = editor.data().access
                            data.clinicId = clinicId
                            staffList.push(data)

                        }
                        counter++
                        if (counter >= snap.size) {
                            this.setState({ staffList, loading: false })
                        }
                    })
                })
            } else {
                this.setState({ staffList: [], loading: false })
            }
        })
    }
    getExports() {
        firebase.firestore().collection('exports').get().then(snap => {
            let exportsArray = []
            snap.forEach(record => {
                let value = record.data()
                let date = Moment(new Date(value.created))
                value.date = date.format("DD MMM YYYY").toString()
                exportsArray.push(value)
            })
            this.setState({ exportsArray })
        })
    }

    getUser = () => {
        let _this = this
        let { user, accessType } = this.props.user.data ? this.props.user.data : {};
        if (user) {
            if (['superAdmin', 'admin'].includes(accessType)) {
                _this.getClinics(accessType)
                _this.getExports()
                _this.setState({
                    loggedIn: true,
                    user: user,
                    allowed: true,
                });
            } else {
                _this.setState({
                    loggedIn: true,
                    user: user,
                    loading: false,
                    allowed: false
                });
            }

        } else {
            _this.setState({
                loggedIn: false,
                user: null,
                loading: false,
                allowed: false
            });
        }

    }
    logout = () => {
        this.setState({
            loggedIn: false,
            user: null,
            modalLoginIsOpen: true
        });
    }
    showMenuFunc = () => {
        let { showMenu } = this.state;
        this.setState({
            showMenu: !showMenu,
        })
    }
    render() {
        let { loggedIn, loading, addStafFlag, connectToThrive, clinicsList, staffInfo, exportFlag, showMenu } = this.state;
        let { user, accessType } = this.props.user.data ? this.props.user.data : {};
        return (
            (loggedIn && user) ?
                <div className="bootstrap">
                    <section id="main-wrapper" >
                        <div className="container-fluid">
                            <div className="row" style={{ overflow: 'hidden' }}>

                                {
                                    addStafFlag ?
                                        <Dimmer active>
                                            <AddStaff dismiss={() => this.setState({ addStafFlag: false, staffInfo: null })} clinicsList={clinicsList} staffInfo={staffInfo} accessType={accessType} />
                                        </Dimmer>
                                        : ''
                                }
                                {
                                    exportFlag ?
                                        <Dimmer active>
                                            <ExportData dismiss={() => { this.getExports(); this.setState({ exportFlag: false }) }} userName={this.state.userName} />
                                        </Dimmer>
                                        : ''
                                }
                                <div className="col-1 side-nav" style={{ display: showMenu ? 'block' : 'none' }}>
                                    <ul>
                                        <li style={{ height: '60px' }}><a href="/facility-management-system"><img src={home} /></a></li>
                                        <li ><Link to="/dashboard"><i className="fab fa-buromobelexperte"></i>Dashboard</Link></li>
                                        <li ><Link to="/facility-management-system"><i className="far fa-hospital"></i>Facilities</Link></li>
                                        <li ><Link to="/wizard-doctor"><i className="fas fa-user-md"></i>Providers</Link></li>
                                        <li ><Link to="/appointment-system"><i className="far fa-calendar-alt"></i>Calendar</Link></li>
                                        <li ><Link to="/patients"><i className="fas fa-users"></i>Patients</Link></li>
                                        <li ><Link to="/history"><i className="fa fa-history"></i>History</Link></li>
                                        <li ><Link to="/feedback"><i className="fa fa-star"></i>Feedback</Link></li>
                                        {
                                            accessType === 'superAdmin' ?
                                                (
                                                    process.env.REACT_APP_NAME !== 'saeed' ?
                                                        <li ><Link to="/helpers"><i className="fas fa-database"></i>Helpers</Link></li> : '')
                                                : ''
                                        }
                                        <li style={{ borderLeft: '3px solid #0096FD' }}><a><i style={{ color: '#0096FD' }} className="fas fa-cogs"></i>Settings</a></li>
                                    </ul>

                                </div>
                                <div className="col-11 main-body" style={{ width: '100%', maxWidth: !showMenu ? 'none' : '' }}>
                                    <Header showMenuFunc={this.showMenuFunc} query={this.props.location.search} logout={this.logout} title="Settings" userName={(userName) => this.setState({ userName })} />
                                    {
                                        (loggedIn && user) ? (
                                            (window.screen.width <= 768) ? (
                                                <div style={{ height: 'calc( 100% - 60px )' }}>
                                                    <div className="emptystate">
                                                        <div className="emptystate1">
                                                            {/* <img src={Doctor} alt="Img" /> */}
                                                            <div className="textMesg">Open on desktop</div>
                                                            <div className="detailMesg">Add doctors to see information in this page</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                (this.state.allowed ?
                                                    this.renderContent()
                                                    : this.renderAccessDenied())
                                            )
                                        ) : <div className="main-side">Please login</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                : loading ?
                    <Dimmer active>
                        <Icon.Group size='big'>
                            <Icon loading size='big' name='spinner' />
                            <div style={{ fontSize: '16px', padding: '10px', fontStyle: 'initial' }}>loading</div>
                        </Icon.Group>
                    </Dimmer>
                    : ''

        );

    }

    handleFacilitySelection = (e, { value }) => {
        let { clinicsList } = this.state
        let selectedClinic = clinicsList.filter(item => {
            return item.facilityName === value
        })
        if (selectedClinic.length) {
            selectedClinic = selectedClinic[0]
        }
        this.getStaff(selectedClinic.facilityId)
        this.setState({ selectedClinic })
    }
    renderAccessDenied() {
        return (
            <div style={{ height: 'calc( 100% - 60px )' }}>
                <div className="emptystate">
                    <div className="emptystate1">
                        <img src={alertCircle} alt="Img" />
                        <div className="textMesg">No Permission</div>
                        <div className="detailMesg">Contact your admin to access this page</div>
                    </div>
                </div>
            </div>
        )
    }
    renderContent = () => {
        let menuSections = ["Staff", "Export Data"]
        let { step, clinicsList, selectedClinic, staffList, thiveConnected, exportsArray, loading } = this.state
        return (
            <div style={{ height: 'calc( 100% - 60px )', display: 'flex' }}>
                <div className="leftSideMenu">
                    <div className="mainHeaderContainer" >
                        <div className="mainHeaderTitle" >
                            {
                                <Dropdown
                                    search
                                    scrolling
                                    style={{ maxHeight: '600px' }}
                                    onChange={this.handleFacilitySelection}
                                    text={selectedClinic ? selectedClinic.facilityName : 'Select Facility'}
                                    options={clinicsList ? clinicsList : []}
                                />
                            }
                        </div>
                    </div>
                    <div className='menuSection'>
                        <div className="menuItem" style={{ fontSize: '16px', background: 'rgba(155, 155, 155, 0.2)', height: '45px', fontFamily: 'tfMedium' }}>
                            General
                        </div>
                        {
                            menuSections.map((item, index) => {
                                return (
                                    <div key={index} className={index === step ? "selected" : "menuItem"} onClick={() => this.setState({ step: index })}>
                                        {item}
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
                <div className="rightSideMenu">
                    <div className="selectedHeaderContainer">
                        <div style={{ width: '50%' }}>{menuSections[step ? step : 0]}</div>
                        {
                            step === 0 || step === 1 ?
                                <div className="headerButtonContainer">
                                    <Button onClick={() => this.setState(step === 0 ? { addStafFlag: true } : step === 1 ? { exportFlag: true } : '')} style={{ width: "130px" }} basic color='blue' content={step === 0 ? 'Add Staff' : step === 1 ? 'Export' : ''} />
                                </div>
                                : ''
                        }
                    </div>
                    <div className="content">

                        {
                            loading ?
                                <div style={{ height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Icon.Group size='big'>
                                        <Icon loading size='big' name='spinner' color='grey' />
                                    </Icon.Group>
                                </div>
                                : step === 0 ?
                                    <Table basic='very'>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell className="tableHeaders" >Name</Table.Cell>
                                                <Table.Cell className="tableHeaders" >Email</Table.Cell>
                                                <Table.Cell className="tableHeaders" >Role</Table.Cell>
                                                <Table.Cell className="tableHeaders" >Access Type</Table.Cell>
                                            </Table.Row>
                                            {
                                                staffList ?
                                                    staffList.map(item => {
                                                        return (
                                                            <Table.Row key={item.id}>
                                                                <Table.Cell className="tableCells" >{item.name}</Table.Cell>
                                                                <Table.Cell className="tableCells" >{item.email}</Table.Cell>
                                                                <Table.Cell className="tableCells" >{item.adminPosition}</Table.Cell>
                                                                <Table.Cell className="tableCells" >{item.access}</Table.Cell>
                                                                <Table.Cell className="tableCells" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                    <Button className='atnBtn' basic content="Delete" color='red' onClick={() => this.confirmDelete(item)} />
                                                                    <Button onClick={() => this.setState({ addStafFlag: true, staffInfo: item })} color='blue' basic content="Edit" />
                                                                </Table.Cell>

                                                            </Table.Row>
                                                        )
                                                    })
                                                    : ''
                                            }
                                        </Table.Body>
                                    </Table>
                                    : step === 1 ?
                                        <Table basic='very'>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell className="tableHeaders" >Date</Table.Cell>
                                                    <Table.Cell className="tableHeaders" >Name</Table.Cell>
                                                    <Table.Cell className="tableHeaders" >Email</Table.Cell>
                                                    <Table.Cell className="tableHeaders" >Information</Table.Cell>
                                                    <Table.Cell className="tableHeaders" >status</Table.Cell>
                                                </Table.Row>
                                                {
                                                    exportsArray ?
                                                        exportsArray.map((item, index) => {
                                                            return (
                                                                <Table.Row key={index}>
                                                                    <Table.Cell className="tableCells" >{item.date}</Table.Cell>
                                                                    <Table.Cell className="tableCells" >{item.name}</Table.Cell>
                                                                    <Table.Cell className="tableCells" >{item.email}</Table.Cell>
                                                                    <Table.Cell className="tableCells" >{item.info.join(' - ')}</Table.Cell>
                                                                    <Table.Cell className="tableCells" >{item.status ? item.status : 'pending'}</Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })
                                                        : ''
                                                }
                                            </Table.Body>
                                        </Table>
                                        : step === 2 ?
                                            firebase.app('thriveApp').auth().currentUser ?
                                                <div>
                                                    <div style={{ fontSize: '14px', color: 'rgba(74, 74, 74, 1)' }}>Thrive Medical is <span style={thiveConnected ? { color: 'rgba(53, 152, 219, 1)' } : { color: 'red' }}>{thiveConnected ? "Connected" : "Disconnected"}</span></div>
                                                    <div style={{ color: 'rgba(74, 74, 74, 1)', fontSize: '12px', top: '16px', position: 'relative' }}>
                                                        <div>
                                                            Clinic profile and doctors information are now synced with Thrive Medical marketplace
                                                        </div>
                                                        <div>
                                                            *This Feature will not access your patient information
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                    <div style={{ display: 'grid', fontSize: '16px', fontWeight: '300', color: 'rgba(74, 74, 74, 1)' }}>
                                                        Thrive Medical is not connected
                                                        <Button basic color='blue' style={{ marginTop: '16px' }} onClick={() => this.setState({ connectToThrive: true })}>Connect Thrive Medical</Button>
                                                    </div>
                                                </div>
                                            : ''
                        }


                    </div>
                </div>
            </div>
        )
    }
    confirmDelete(item) {
        let { staffList } = this.state
        Swal.fire({
            title: 'Are you sure you want to delete ' + item.name + ' from Staff ?',
            showCancelButton: true,
            confirmButtonText: `Confirm`,
        }).then(async (result) => {
            if (result.value) {
                await firestore().collection('users').doc(item.id).update({
                    access: firestore.FieldValue.delete(),
                    accessClinic: firestore.FieldValue.delete(),
                    adminPosition: firestore.FieldValue.delete(),
                })
                await firestore().collection('staff').doc(item.id).update({
                    access: '',
                    clinics: []
                })
                this.setState({ staffList: staffList.filter(rec => rec.id !== item.id) })

                // firestore().collection(ref).doc(item.id).delete().then(() => {
                //     let array = this.state[ref];
                //     array = array.filter(record => record.id !== item.id)
                //     this.setState({ [ref]: array })
                // })
            }
        })

    }
}
function mapStateToProps({ user }) {
    return {
        user: user,
    }
}

export default connect(mapStateToProps, {})(WizardClinic)