import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {firestore} from 'firebase/app'

class StepsBar extends Component {
    static propTypes = {
        clinicsStatus: PropTypes.number.isRequired,
        changeDoctorKey: PropTypes.func.isRequired,
        clinicDoctors: PropTypes.array
    }
    state = {
        Doctors: [],
        currentDoctorIndex: 0,
        searchTerm: '',
        specNames : {}

    }
    componentDidMount(){
        var specNames = {}
        let _this = this
        let { clinicDoctors , clinicKey } = this.props;
        for (var doctor in clinicDoctors){
            let keys = clinicDoctors[doctor].clinics[clinicKey];
        for(var index in keys){
            firestore().collection('specialties').doc(keys[index]).get().then(snap=>{
                specNames[snap.key] = snap.data().name
                _this.setState({specNames})
            })
        }
        }
    }
    constructor(props, context) {
        super(props, context);
    }
    getSearchTerm = (text) => {
        this.setState({
            searchTerm: text.target.value
        })
    }

    changeDoctorIndex = (index, item) => {
        let part = index + 1;

        this.props.changeDoctorKey(item.doctorId?item.doctorId:item.id);

        this.setState({
            currentDoctorIndex: part,
        });
    }
    allDoctorClicked = () => {
        this.props.changeDoctorKey('');
        this.setState({
            currentDoctorIndex: 0,
        });
    }


    render() {
        let { clinicsStatus, clinicDoctors ,clinicKey} = this.props;
        let { currentDoctorIndex, searchTerm ,specNames} = this.state;
        if (searchTerm !== '') {
            clinicDoctors = clinicDoctors.filter(item => {
                return (item.bookingActive && item.bookingActive[clinicKey] &&  item.name.toLowerCase().includes(searchTerm.toLowerCase()) ?
                    item.name : ''
                )
            })
        }
        return (
            <div style={{ height: '62%' }}>
                {
                    (clinicsStatus === 1) ? (
                        <div className="page-nav" style={{ height: '100%' }}>
                            <input style={{ position: 'relative', width: '92%', margin: '10px', height: '40px', borderRadius: '6px', border: '1px solid #DADFE4', textAlign: 'center' }} placeholder="Search for doctors" onChange={this.getSearchTerm} />
                            <div style={{ textAlign: 'center', position: 'relative', cursor: 'pointer' }} onClick={this.allDoctorClicked}>All Doctors</div>
                            <ul className="nav nav-tabs" id="myTab" role="tablist" style={{ width: '100%', position: 'relative', top: '15px', overflow: 'auto', height: '90%', display: 'block' }}>
                                {clinicDoctors.map((item, index) => {
                                    return (
                                        <li className="nav-item" style={{ height: '53px' }} key={index} onClick={this.changeDoctorIndex.bind(this, index, item)}>
                                            {
                                                (currentDoctorIndex === (index + 1)) ? (
                                                    <div className="doctor-cell" style={{ background: 'white', borderLeft: '2px solid #0095FC' }}>
                                                        <div className="doctor-color" style={{ background: item.color }} />
                                                        <img src={item.profilePicture} alt="IMG" />
                                                        <div className="doctor-info">
                                                            <div className="name">{item.name}</div>
                                                            <div className="speciality">{specNames[item.clinics[clinicKey][0]]}</div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                        <div className="doctor-cell">
                                                            <div className="doctor-color" style={{ background: item.color }} />
                                                            <img src={item.profilePicture} alt="IMG" />
                                                            <div className="doctor-info">
                                                                <div className="name">{item.name}</div>
                                                                <div className="speciality">{specNames[item.clinics[clinicKey][0]]}</div>
                                                            </div>
                                                        </div>
                                                    )
                                            }
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    ) : (clinicsStatus === 2) ? (
                        <div className="page-nav">
                            <ul className="nav nav-tabs" id="myTab" role="tablist" >
                                <li className="nav-item" style={{ borderLeft: '2px solid #0095FC' }}>
                                    <a className="nav-link active" aria-selected="true">Setup</a>
                                </li>
                            </ul>
                        </div>
                    ) : ''
                }
            </div>
        )
    }
}

export default StepsBar